import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import { SimpleInput } from '~/components/inputs';

export default function Mask({ disabled, ...props }) {
  return (
    <>
      <InputMask
        {...props}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {(inputProps) => <SimpleInput disabled={disabled} {...inputProps} />}
      </InputMask>
    </>
  );
}

Mask.propTypes = {
  disabled: PropTypes.bool.isRequired,
};
