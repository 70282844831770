import React from 'react';

import PropTypes from 'prop-types';

import Navbar from '~/components/Navbar';
import Side from '~/components/Side';

import { Wrapper } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper className="dark:bg-gray-800">
      <Navbar />
      <Side />
      {children}
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
