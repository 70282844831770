import { toast } from 'react-toastify';

import { push } from 'connected-react-router';
import _ from 'lodash';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';
import { getClientId } from '~/util/parseJwt';

import { Types } from '../ducks/trackerSauce';

export function* getTracker(payload) {
  try {
    const { data } = yield call(api.fetchTracker, payload.query);
    if (!_.isArray(data)) {
      yield put({
        type: Types.FETCH_TRACKER_ID_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: Types.FETCH_TRACKER_SUCCESS,
        data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* updateTrackerSaga(payload) {
  console.log('updateTrackerSaga', {
    saveAndRedirect: payload.data.saveAndRedirect,
  });
  try {
    yield call(api.updateTracker, payload.data);

    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }

    yield put({
      type: Types.FETCH_TRACKER_REQUEST,
      query: filterClient ? `?id_clients=${filterClient}` : undefined,
    });
    toast.success('Atualizado com sucesso!');
    if (payload.data.saveAndRedirect) {
      yield put(push('/tracker'));
    }
  } catch (error) {
    yield put({ type: Types.FETCH_TRACKER_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_TRACKER_REQUEST, getTracker)]);
  yield all([takeEvery(Types.UPDATE_TRACKER_REQUEST, updateTrackerSaga)]);
}
