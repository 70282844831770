import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';

import _ from 'lodash';

import { SimpleSelect } from '~/components/selects';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import DropDown from './DropDown';
import selectStyles from './selectStyles';

function GroupSelect() {
  const dispatch = useDispatch();
  const { clientName } = useSelector(({ globalState }) => globalState.me);
  const { groupSelected, groups } = useSelector(
    ({ bufferState }) => bufferState
  );

  const [groupsFormated, setGroupsFormated] = useState([]);

  const [clientSelected, setClientSelected] = useState({});
  const [clients, setClients] = useState([]);

  function clientChange(client) {
    window.localStorage.client = client.value;
    dispatch(BufferActions.bufferRequest());
    dispatch(BufferActions.groupRequest());
    dispatch(BufferActions.setClientSelected(client));
    setClientSelected(client);
    history.push('/monitoring');
  }

  async function getClient() {
    try {
      if (clientName !== 'GEORASTREAMENTO') {
        window.localStorage.removeItem('client');
        dispatch(BufferActions.bufferRequest());
        dispatch(BufferActions.groupRequest());
      } else {
        const { data } = await api.fetchClients();
        const formatedData = data.map((client) => ({
          label: client.name,
          value: client.id,
          status: client.status,
        }));
        setClients(formatedData);
        if (!localStorage.getItem('client')) {
          if (clientName === 'GEORASTREAMENTO') {
            const client = data.find(({ name }) => name === clientName);
            localStorage.setItem('client', client.id);
            setClientSelected({ label: client.name, value: client.id });
            dispatch(BufferActions.bufferRequest());
            dispatch(BufferActions.groupRequest());
          }
        } else {
          const client = data.find(
            (item) => Number(item.id) === Number(localStorage.getItem('client'))
          );
          setClientSelected({ label: client.name, value: client.id });
          dispatch(BufferActions.bufferRequest());
          dispatch(BufferActions.groupRequest());
        }
      }
    } catch (error) {
      toast.error('Ocorreu um erro !');
    }
  }

  async function handleChange(selectedOption) {
    if (selectedOption) {
      // console.log({ selectedOption });
      dispatch(BufferActions.filterGroupBuffer(selectedOption.id_trackers));
      dispatch(BufferActions.setGroupSelected(selectedOption));
    } else {
      dispatch(BufferActions.clearGroupBuffer());
      dispatch(BufferActions.setGroupSelected({}));
    }
    history.push('/monitoring');
  }

  useEffect(() => {
    if (clientName) {
      getClient();
    }
  }, [clientName]);

  useEffect(() => {
    if (!groupSelected) {
      dispatch(
        BufferActions.setGroupSelected({
          label: '',
          value: '',
          id_trackers: [],
          isAdm: false,
        })
      );
    }
  }, [groupSelected]);

  useEffect(() => {
    async function setGroups() {
      const data = groups.map((group) => ({
        label: group.name,
        value: group.id,
        id_trackers: group.trackers,
        isAdm: false,
      }));
      setGroupsFormated(data);
    }
    if (!_.isEmpty(groups)) {
      setGroups();
    }
  }, [groups]);

  return (
    <div className="bg-gray-100 p-2 flex items-center">
      {clientName && clientName === 'GEORASTREAMENTO' ? (
        <Select
          isClearable={false}
          value={
            clientSelected && clientSelected.value
              ? clients.filter((item) => item.value === clientSelected.value)
              : ''
          }
          onChange={clientChange}
          placeholder="Selecione um cliente grupo"
          noOptionsMessage={() => 'Nenhum cliente encontrado'}
          options={clients}
          styles={selectStyles}
        />
      ) : (
        <>
          <SimpleSelect
            isClearable
            value={
              groupSelected && groupSelected.value
                ? groupsFormated.filter(
                    (item) => item.value === groupSelected.value
                  )
                : ''
            }
            onChange={handleChange}
            placeholder="Selecione o grupo"
            noOptionsMessage={() => 'Nenhum grupo encontrado'}
            options={groupsFormated}
          />
          <DropDown />
        </>
      )}
    </div>
  );
}

export default GroupSelect;
