import React from 'react';
import Modal from 'react-awesome-modal';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
  CommandConfirmButton,
} from '~/components/Global';
import api from '~/services/api';
import { Creators as FenceActions } from '~/store/ducks/fenceSauce';

import { ModalButtons, ModalDetail } from '../styles';

function Delete() {
  const dispatch = useDispatch();
  const modalDelete = useSelector((state) => state.fenceState.modalDelete);
  const fence = useSelector((state) => state.fenceState.fence);

  async function deleteGeometry() {
    const response = await api.deleteGeometry(fence.id);
    if (response.status === 200) {
      toast.success('Cerca deletada com sucesso!');
      dispatch(FenceActions.setModalDelete());
      dispatch(FenceActions.fetchFenceRequest('?type=fence'));
      dispatch(FenceActions.fetchFenceFailure());
    }
  }

  return (
    <Modal
      visible={modalDelete}
      width="300"
      height="185"
      effect="fadeInUp"
      onClickAway={() => dispatch(FenceActions.setModalDelete())}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTextHeader>Deletar cerca</ModalTextHeader>
        </ModalHeader>
        <ModalDetail>Deseja realmente deletar a cerca?</ModalDetail>
        <ModalButtons>
          <CommandConfirmButton
            color="#ff4e4e"
            onClick={() => dispatch(FenceActions.setModalDelete())}
          >
            <FaTimes />
            Não
          </CommandConfirmButton>
          <CommandConfirmButton color="#293e52" onClick={deleteGeometry}>
            <FaCheck /> Sim
          </CommandConfirmButton>
        </ModalButtons>
      </ModalContainer>
    </Modal>
  );
}

export default Delete;
