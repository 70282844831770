import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { MdCheck, MdClear, MdDelete } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { IconButton, SimpleButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  DateStyle,
  FormData,
  DataContainer,
  ProfileContainer,
  Row,
  ErrorMessage,
  FieldContainer,
  LabelStyled,
  Input,
  SelectStyled,
  Currency,
} from '~/components/Global';
import SelectTracker from '~/components/Global/SelectTracker';
import Loading from '~/components/Loading';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as PassengerActions } from '~/store/ducks/passengerSauce';
import { MaskedInput } from '~/components/inputs';
import SelectResponsible from '~/components/Global/SelectResponsible';

function Forms({
  match: {
    params: { id: idParams },
  },
}) {
  const dispatch = useDispatch();
  const passenger = useSelector((state) => state.passengerState.passenger);
  const loading = useSelector((state) => state.passengerState.loading);
  const me = useSelector((state) => state.globalState.me);
  const [editablePermission, setEditablePermission] = useState(false);

  registerLocale('pt-BR', el);

  useEffect(() => {
    if (idParams) {
      dispatch(PassengerActions.fetchPassengerRequest(`/${idParams}`));
    }
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  const formik = useFormik({
    initialValues: {
      name: '',
      cpf: '',
      email: '',
      phone: '',
      type: '',
      active: true,
      mother_name: '',
      pays: false,
      responsible_id: '',
      card_number: '',
    },

    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (values.name === '') errors.name = message;
      if (values.type === '') errors.type = message;
      if (values.responsible_id === '') errors.responsible_id = message;
      return errors;
    },

    // onSubmit: (values) => {
    //   if (values.id) {
    //     dispatch(PassengerActions.updatePassengerRequest(values, values.id));
    //     // dispatch(PassengerActions.updatePassengerRequest(values.id));
    //   } else {
    //     dispatch(PassengerActions.createPassengerRequest(values));
    //   }
    //   dispatch(PassengerActions.clearPassenger());
    // },

    onSubmit: (values) => {
      if (values.id) {
        dispatch(PassengerActions.updatePassengerRequest(values, values.id));
      } else {
        dispatch(PassengerActions.createPassengerRequest(values));
      }
    },
  });
  useEffect(() => {
    if (!_.isEmpty(passenger)) {
      const data = {
        ...passenger,
      };
      formik.setValues(data);
    }
  }, [passenger]);
  function cancelButton() {
    history.push('/passenger');
    dispatch(PassengerActions.clearPassenger());
  }

  async function deleteButton() {
    try {
      await api.deletePassenger(idParams);
      dispatch(PassengerActions.clearPassenger());

      history.push('/passenger');
    } catch (error) {
      console.tron.log('error');
    }
  }

  function getHeaderComponent() {
    if (editablePermission) {
      if (loading) {
        return (
          <SimpleButton width="w-44" height="h-8" disabled type="button">
            Aguarde
          </SimpleButton>
        );
      }
      return (
        <div
          className={`grid ${
            idParams ? 'w-96 grid-cols-3' : 'w-64 grid-cols-2'
          } gap-2 h-8`}
        >
          <IconButton
            background="bg-red-600"
            margin="0px"
            Icon={MdClear}
            size={15}
            type="button"
            onClick={() => cancelButton()}
          >
            Cancelar
          </IconButton>
          {idParams && (
            <IconButton
              margin="0px"
              background="bg-red-600"
              Icon={MdDelete}
              size={15}
              onClick={deleteButton}
            >
              Excluir
            </IconButton>
          )}
          <IconButton
            background="bg-gray-600"
            margin="0px"
            Icon={MdCheck}
            size={15}
            type="submit"
          >
            Salvar
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton
        Icon={MdCheck}
        size={15}
        type="button"
        onClick={() => cancelButton()}
        width="w-44"
        height="h-8"
      >
        Voltar
      </IconButton>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de passageiro">
          {getHeaderComponent()}
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <FormData>
              <DataContainer>
                <ProfileContainer>
                  <div>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Nome</LabelStyled>
                          {formik.touched.name && formik.errors.name ? (
                            <ErrorMessage>{formik.errors.name}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <Input
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={formik.touched.name && formik.errors.name}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>E-mail</LabelStyled>
                        </div>
                        <Input
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>CPF</LabelStyled>
                          {formik.touched.cpf && formik.errors.cpf ? (
                            <ErrorMessage>{formik.errors.cpf}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <MaskedInput
                          onChange={formik.handleChange}
                          value={formik.values.cpf}
                          id="cpf"
                          name="cpf"
                          mask="999.999.999-99"
                          maskChar={null}
                          error={formik.touched.cpf && formik.errors.cpf}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Número do Cartão</LabelStyled>
                          {formik.touched.card_number &&
                          formik.errors.card_number ? (
                            <ErrorMessage>
                              {formik.errors.card_number}
                            </ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <MaskedInput
                          onChange={formik.handleChange}
                          value={formik.values.card_number}
                          id="card_number"
                          name="card_number"
                          mask="99999999999"
                          maskChar={null}
                          error={
                            formik.touched.card_number &&
                            formik.errors.card_number
                          }
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Telefone</LabelStyled>
                          {formik.touched.phone && formik.errors.phone ? (
                            <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <MaskedInput
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          id="phone"
                          name="phone"
                          mask="(99) 9 9999-9999"
                          maskChar={null}
                          error={formik.touched.phone && formik.errors.phone}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <SelectResponsible
                          defaultValue={formik.values.responsible_id}
                          parentCallback={(option) =>
                            formik.setFieldValue('responsible_id', option.id)
                          }
                          error={
                            formik.touched.responsible_id &&
                            formik.errors.responsible_id
                          }
                          isDisabled={!editablePermission}
                          value={formik.values.responsible_id}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Nome da mãe</LabelStyled>
                        </div>
                        <Input
                          name="mother_name"
                          onChange={formik.handleChange}
                          value={formik.values.mother_name}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>

                      <FieldContainer>
                        <LabelStyled>Tipo</LabelStyled>
                        <SelectStyled
                          name="type"
                          defaultValue={{
                            label: formik.values.type,
                            value: formik.values.type,
                          }}
                          onChange={(option) =>
                            formik.setFieldValue('type', option.value)
                          }
                          value={{
                            label: formik.values.type,
                            value: formik.values.type,
                          }}
                          options={[
                            { label: 'Aluno', value: 'Aluno' },
                            { label: 'Passageiro', value: 'Passageiro' },
                            { label: 'Acompanhante', value: 'Acompanhante' },
                            { label: 'Responsável', value: 'Responsável' },
                          ]}
                          isDisabled={!editablePermission}
                        />
                      </FieldContainer>

                      {formik.values.pays !== '' && (
                        <FieldContainer>
                          <LabelStyled>Terceiros</LabelStyled>
                          <SelectStyled
                            name="pays"
                            defaultValue={{
                              label: formik.values.pays ? 'Sim' : 'Não',
                              value: true,
                            }}
                            onChange={(option) =>
                              formik.setFieldValue('pays', option.value)
                            }
                            value={{
                              label: formik.values.pays ? 'Sim' : 'Não',
                              value: formik.values.pays,
                            }}
                            options={[
                              { label: 'Sim', value: true },
                              { label: 'Não', value: false },
                            ]}
                            isDisabled={!editablePermission}
                          />
                        </FieldContainer>
                      )}
                      {formik.values.active !== '' && (
                        <FieldContainer>
                          <LabelStyled>Status</LabelStyled>
                          <SelectStyled
                            name="active"
                            defaultValue={{
                              label: formik.values.active ? 'Ativo' : 'Inativo',
                              value: true,
                            }}
                            onChange={(option) =>
                              formik.setFieldValue('active', option.value)
                            }
                            value={{
                              label: formik.values.active ? 'Ativo' : 'Inativo',
                              value: formik.values.active,
                            }}
                            options={[
                              { label: 'Ativo', value: true },
                              { label: 'Inativo', value: false },
                            ]}
                            isDisabled={!editablePermission}
                          />
                        </FieldContainer>
                      )}
                    </Row>
                  </div>
                </ProfileContainer>
              </DataContainer>
            </FormData>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
