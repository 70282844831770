import _ from 'lodash';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import { Container, Button } from './styles';

function Actions() {
  const commandList = useSelector((state) => state.commandState.commandList);
  const tracker = useSelector((state) => state.commandState.tracker);
  const { output1, output2, output3 } = useSelector(
    (state) => state.commandState.buffer
  );
  const dispatch = useDispatch();

  function setModal(type) {
    dispatch(CommandActions.setViewModal(type));
  }

  function getStatusBLock() {
    if (!_.isEmpty(tracker) && tracker.device.output1 === 'BLOCK') {
      return output1;
    }
    if (!_.isEmpty(tracker) && tracker.device.output2 === 'BLOCK') {
      return output2;
    }
    if (!_.isEmpty(tracker) && tracker.device.output3 === 'BLOCK') {
      return output3;
    }

    return false;
  }

  return (
    <Container>
      <header>Comandos</header>
      <Button
        type="button"
        onClick={() => setModal(tracker.hour_meter ? 'orimeter' : 'odometer')}
      >
        Mudar {tracker.hour_meter ? 'horímetro' : 'odômetro'}
      </Button>
      <Button type="button" onClick={() => setModal('timer')}>
        Mudar tempo de trasmissão
      </Button>
      <Button
        type="button"
        onClick={() => setModal('block')}
        disabled={
          _.isEmpty(commandList.commands)
            ? true
            : !commandList.commands
                .map((command) => command.type)
                .includes('BLOCK')
        }
      >
        {getStatusBLock() ? 'Desbloquear' : 'Bloquear'}
      </Button>
      <Button type="button" onClick={() => setModal('siren')} disabled>
        Sirene
      </Button>
    </Container>
  );
}

export default Actions;
