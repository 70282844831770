import React from 'react';

import PropTypes from 'prop-types';

function HeaderForm({ title, children }) {
  return (
    <div className="h-full w-full rounded-lg bg-white dark:bg-gray-600 shadow flex items-center justify-between px-2">
      <div className="text-xl font-bold text-geo-900 dark:text-gray-200">
        {title}
      </div>
      {children}
    </div>
  );
}

HeaderForm.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
};

HeaderForm.defaultProps = {
  title: 'Titulo da página',
  children: <></>,
};

export default HeaderForm;
