import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-awesome-modal';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
  ConfirmBox,
  ConfirmButtonsContainer,
  CommandConfirmButton,
  InputStyled,
  FieldContainer,
  LabelStyled,
} from '~/components/Global';
import Field from '~/components/Global/Field';
import api from '~/services/api';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';
import { Creators as FenceActions } from '~/store/ducks/fenceSauce';
// import history from '~/services/history';

import { ModalDetail } from './styles';
import { SimpleSelect } from '../selects';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const geometryTypeDB = {
  polygon: 'fence',
  LineString: 'route',
  Point: 'point',
};

function Map({ geoJson, type }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const viewModal = useSelector((state) => state.bufferState.viewModal);
  const formik = useFormik({
    initialValues: {
      name: '',
      geometry: {},
      radius: 0,
      type: 'fence',
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.name) err.name = message;
      if (!values.type) err.type = message;
      return err;
    },
    onSubmit: (value) => {
      console.log('value.geometry.geometry.type', value.geometry.geometry.type);
      const geojsonType = geometryTypeDB[value.geometry.geometry.type];
      const data = {
        name: value.name,
        geojson: { ...value.geometry },
        type:
          value.geometry.geometry.type === 'polygon'
            ? formik.values.type
            : geojsonType || 'point',
        // value.geometry.geometry.type === 'polygon' ? 'fence' : 'point',
        trackerId:
          query && query.get && query.get('id') && Number(query.get('id')),
      };
      if (process.env.NODE_ENV !== 'production') {
        console.log('GEOJSON DATA', { data, value });
      }
      if (value.radius !== 0) {
        data.radius = value.radius;
      }
      try {
        const _oldId = value.geometry.properties?.id;
        if (!_oldId) {
          api.createGeometries(data);
        } else {
          data.id = value.geometry.properties.id;
          data.geojson.type = 'Feature';
          delete data.geojson.id;
          delete data.geojson.name;
          delete data.geojson.latitude;
          delete data.geojson.longitude;
          delete data.geojson.properties;
          api.updateGeometries(data);
        }
        toast.success(
          `${value.geometry.geometry.type !== 'polygon' ? 'Ponto' : 'Cerca'} ${
            !_oldId ? 'criad' : 'alterad'
          }${
            value.geometry.geometry.type !== 'polygon' ? 'o' : 'a'
          } com sucesso!`
        );
      } catch (error) {
        toast.error('Ocorreu um erro');
      }
      /*
      const _type =
        value.geometry.geometry.type === 'polygon' ? 'fence' : 'point';
      dispatch(FenceActions.fetchFenceRequest(`?type=${_type}`));
      */
      // const response = api.createGeometry(data);
      dispatch(BufferActions.setViewModal(false));
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      geometry: geoJson,
      latitude:
        geoJson.geometry?.type === 'Point'
          ? geoJson.geometry?.coordinates[1]
          : undefined,
      longitude:
        geoJson.geometry?.type === 'Point'
          ? geoJson.geometry?.coordinates[0]
          : undefined,
      name: geoJson.properties?.name || '',
      radius: 0,
    });
  }, [geoJson]);

  useEffect(() => {
    if (viewModal) {
      dispatch(BufferActions.setViewModal(false));
    }
  }, []);

  useEffect(() => {
    if (viewModal) {
    }
  }, [viewModal]);

  function cancel() {
    dispatch(BufferActions.setViewModal(false));
    formik.setValues({ name: '', geoJson: {}, type: 'fence' });
  }

  function onTypeChange({ value }) {
    formik.setFieldValue('type', value);
  }

  return (
    <Modal
      visible={viewModal}
      height={type === 'marker' ? '350' : '180'}
      width="400"
      effect="fadeInUp"
      onClickAway={() => dispatch(BufferActions.setViewModal(false))}
      // onRequestClose={() => props.setViewModal()}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTextHeader>
            {!geoJson.properties?.id ? 'Criar' : 'Editar'}{' '}
            {type === 'marker' ? 'Ponto de Referência' : 'Área'}
          </ModalTextHeader>
        </ModalHeader>
        <form onSubmit={formik.handleSubmit}>
          <ConfirmBox>
            <ModalDetail>
              <Field
                labelName="Nome"
                value={formik.values.name}
                parentCallback={formik.handleChange}
                name="name"
                error={formik.touched.name && formik.errors.name}
              />
              {viewModal ? (
                <SimpleSelect
                  error={formik.touched.type && formik.errors.type}
                  onChange={(value) => onTypeChange(value)}
                  defaultValue={{ value: 'fence', title: 'CERCA' }}
                  placeholder={'Selecione um tipo de área'}
                  getOptionValue={({ value }) => value}
                  getOptionLabel={({ title }) => title}
                  options={[
                    { value: 'fence', title: 'CERCA' },
                    { value: 'safezone', title: 'ZONA DE SEGURANÇA' },
                  ]}
                />
              ) : (
                ''
              )}
              {type === 'marker' && [
                <FieldContainer>
                  <LabelStyled>Latitude</LabelStyled>
                  <InputStyled
                    style={{ width: '100%' }}
                    type="text"
                    value={formik.values.latitude}
                    onChange={formik.handleChange}
                    name="latitude"
                    error={formik.touched.latitude && formik.errors.latitude}
                  />
                  ,
                </FieldContainer>,
                <FieldContainer>
                  <LabelStyled>Longitude</LabelStyled>
                  <InputStyled
                    style={{ width: '100%' }}
                    type="text"
                    value={formik.values.longitude}
                    onChange={formik.handleChange}
                    name="longitude"
                    error={formik.touched.longitude && formik.errors.longitude}
                  />
                </FieldContainer>,
                // <FieldContainer>
                //   <LabelStyled>Raio (m)</LabelStyled>
                //   <InputStyled
                //     style={{ width: '100%' }}
                //     type="number"
                //     min="0"
                //     value={formik.values.radius}
                //     onChange={formik.handleChange}
                //     name="radius"
                //     error={formik.touched.radius && formik.errors.radius}
                //   />
                // </FieldContainer>,
              ]}
            </ModalDetail>
          </ConfirmBox>
          {/* </ModalDetail> */}
          <ConfirmButtonsContainer>
            <CommandConfirmButton
              type="button"
              color="#ff4e4e"
              onClick={cancel}
            >
              <FaTimes />
              &nbsp;cancelar
            </CommandConfirmButton>
            <CommandConfirmButton type="submit" color="#293e52">
              <FaCheck /> &nbsp;{!geoJson.properties?.id ? 'Criar' : 'Salvar'}
            </CommandConfirmButton>
          </ConfirmButtonsContainer>
        </form>
      </ModalContainer>
    </Modal>
  );
}

Map.propTypes = {
  geoJson: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Map;
