import styled from 'styled-components';

export const Input = styled.input`
  width: 100%;
  height: 33.2px;
  border-radius: 5px;
  border: none;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
`;

export const OriginInputStyled = styled.input`
  width: 100%;
  height: 33.2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
`;

export const PointInputStyled = styled.input`
  width: 100%;
  height: 33.2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
`;

export const AddOriginButtonContainer = styled.div`
  display: flex;
`;

export const OriginInputContainer = styled.div`
  width: 100%;
`;

export const AddButtonStyled = styled.button`
  border: none;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 33.2px;
  width: 33.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
`;

export const Map = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const PositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  margin-top: 10px;
  > div {
    display: flex;
    justify-content: space-between;
  }
  ${(props) =>
    !props.full ||
    `grid-column-start: 1;
     grid-column-end: 4;`}
`;

export const OriginContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    display: flex;
    justify-content: space-between;
  }
  ${(props) =>
    !props.full ||
    `grid-column-start: 1;
     grid-column-end: 4;`}
`;

export const DestinationContainer = styled.div`
  margin-top: 10px;
`;
