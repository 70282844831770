import React from 'react';

import PropTypes from 'prop-types';

const classNameDefault = 'rounded-lg px-4 shadow';
function Simple({
  className,
  children,
  background,
  width,
  rounded,
  height,
  margin,
  color,
  ...props
}) {
  return (
    <button
      className={`${classNameDefault} ${className} ${margin} ${width} ${height} ${rounded} ${background} ${color}`}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
}
Simple.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  rounded: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.element,
};

Simple.defaultProps = {
  className: '',
  background: 'bg-geo-900 dark:bg-blue-500',
  width: 'w-full',
  height: 'h-full',
  color: 'text-white',
  rounded: 'rounded-lg',
  margin: 'mx-2',
  children: <></>,
};

export default Simple;
