import React, { useState, useEffect } from 'react';
import { CalendarContainer } from 'react-datepicker';
import { AiFillPushpin } from 'react-icons/ai';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdClose,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import InputMask from 'react-input-mask';
import Skeleton from 'react-loading-skeleton';

import arraySort from 'array-sort';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import _ from 'lodash';
import matchSorter from 'match-sorter';
import PropTypes from 'prop-types';

import _debug from 'debug';
// import { debug as _debug } from '~/util/logger';

import csvLogo from '~/assets/csv.svg';
import pdfLogo from '~/assets/pdf.svg';
import xlsxLogo from '~/assets/xlsx.svg'

import exportXls from './xls';
import exportCsv from './csv';
import exportPdf from './pdf';
import { numberSort, dateSort } from './sort';
import {
  Container,
  Header,
  HeaderItem,
  Detail,
  DetailRow,
  DetailItem,
  GroupItem,
  Footer,
  NumberContainer,
  DateFilterStyled,
} from './styles';

// const debug = {
//   csv: _debug('elgeo:table:export:csv'),
//   pdf: _debug('elgeo:table:export:pdf'),
//   api: _debug('elgeo:table:api'),
// };

function Table({
  data,
  headers,
  labels,
  fix,
  headerBackground,
  headerColor,
  ColumnsWidth,
  loading,
  align,
  fileTitle,
  stripedColor,
  getData,
  dimensionHeight,
  filterTypes,
  headerTypes,
  groupType,
  filters,
  landscape,
}) {
  const [dataTable, setDataTable] = useState(data || []);
  const [dataImutable, setDataImutable] = useState(data || []);
  const [dataGroupImutable, setDataGroupImutable] = useState(data || []);
  const [headerTable, setHeaderTable] = useState(headers || []);
  const [labelsTable, setLabelsTable] = useState(labels || []);
  const [widthTable, setWidthTable] = useState(ColumnsWidth || []);
  const [isGrouped, setIsGrouped] = useState(false);
  const [groupSelected, setGroupSelected] = useState(false);
  const [showGroupIndex, setShowGroupIndex] = useState(false);
  const [headerSelected, setHeaderSelected] = useState({
    name: '',
    asc: false,
  });
  const [numberSignal, setNumberSignal] = useState(3);
  const [dateSelected, setDateSelected] = useState();
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [isSearchable, setIsSearchable] = useState(false);
  const [page, setPage] = useState(1);

  // useEffect(() => { console.log('ColumnsWidth', ColumnsWidth); }, []);

  useEffect(() => {
    setDataTable([...data]);
    setDataImutable([...data]);
    setDataGroupImutable([...data]);
    setPage(1);
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(dataImutable)) {
      setDataTable(data.slice(0, 50));
      setPage(2);
    }
  }, [dataImutable]);

  async function sortInput(text, header) {
    if (isGrouped) {
      const newData = dataGroupImutable.map((item) => ({
        [groupSelected]: item[groupSelected],
        value: matchSorter(item.value, text, { keys: [header] }),
      }));
      setDataTable([...newData]);
    } else if (text !== undefined) {
      setIsSearchable(true);
      const newData = await matchSorter(dataImutable, text, {
        keys: [header],
      });
      setDataTable([...newData]);
    } else {
      setDataTable([...dataImutable]);
      setIsSearchable(false);
    }
  }

  function sortDate(date, header) {
    if (isGrouped) {
      if (date === null) {
        setIsSearchable(false);
        setDataTable([...dataGroupImutable]);
      } else {
        setIsSearchable(true);
        const newData = dataGroupImutable.map((item) => ({
          [groupSelected]: item[groupSelected],
          value: dateSort({ arr: item.value, date, header }),
        }));
        setDataTable([...newData]);
      }
    } else if (date === null) {
      setIsSearchable(false);
      setDataTable([...dataImutable]);
    } else {
      setIsSearchable(true);
      const newData = dateSort({ arr: dataImutable, date, header });
      setDataTable([...newData]);
    }
  }

  async function sortNumer(number, type, header) {
    if (isGrouped) {
      if (number === undefined) {
        setIsSearchable(false);
        setDataTable([...dataGroupImutable]);
      } else if (number === undefined) {
        setIsSearchable(false);
        setDataTable([...dataImutable]);
      } else {
        setIsSearchable(true);
        const newData = dataGroupImutable.map((item) => ({
          [groupSelected]: item[groupSelected],
          value: numberSort({
            arr: item.value,
            number,
            type,
            header,
          }),
        }));
        setDataTable([...newData]);
      }
    } else if (number === undefined) {
      setIsSearchable(false);
      setDataTable([...dataImutable]);
    } else {
      setIsSearchable(true);
      const newData = numberSort({
        arr: dataImutable,
        number,
        type,
        header,
      });
      setDataTable([...newData]);
    }
  }

  function getNumberSignal() {
    if (numberSignal === 1) {
      return '≤';
    }
    if (numberSignal === 2) {
      return '=';
    }
    return '≥';
  }

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ width: '328px' }}>
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  MyContainer.propTypes = {
    className: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
  };

  function getType(type, labelsTableType, index, header) {
    if (type === 'string') {
      return (
        <input
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          placeholder={`Pesquise por ${labelsTableType[index]}`}
          onChange={(e) => sortInput(e.target.value || undefined, header)}
        />
      );
    }
    if (type === 'number') {
      return (
        <NumberContainer
          headerColor={headerColor}
          headerBackground={headerBackground}
        >
          <input
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
            type="number"
            placeholder={`Pesquise por ${
              labelsTable[index]
            } ${getNumberSignal()}`}
            onChange={(e) =>
              sortNumer(e.target.value || undefined, numberSignal, header)
            }
          />
          <button
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (numberSignal === 1) {
                setNumberSignal(2);
              } else if (numberSignal === 2) {
                setNumberSignal(3);
              } else if (numberSignal === 3) {
                setNumberSignal(1);
              }
            }}
            type="button"
          >
            {getNumberSignal()}
          </button>
        </NumberContainer>
      );
    }
    if (type === 'date') {
      return (
        <DateFilterStyled
          headerColor={headerColor}
          headerBackground={headerBackground}
          isClearable
          // showTimeSelect
          selected={dateSelected}
          dateFormat="dd/MM/yyyy"
          onChange={(value) => {
            setDateSelected(value);
            sortDate(value, header);
          }}
          locale={pt}
          customInput={<InputMask mask="99/99/9999" />}
          calendarContainer={MyContainer}
          placeholderText={`Pesquise por ${labelsTable[index]}`}
        />
      );
    }
    return (
      <input
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
        placeholder={`Pesquise por ${labelsTable[index]}`}
        onChange={(e) => sortInput(e.target.value || undefined, header)}
      />
    );
  }

  function getGroupType(group, value, name) {
    if (group === 'simple') {
      return ' ';
    }
    if (group === 'sum') {
      return value
        .map((item) => item[name])
        .reduce(function (acumulador, valorAtual) {
          return Number(acumulador) + Number(valorAtual);
        }, 0)
        .toFixed(3);
    }
    if (group === 'avg') {
      return (
        value
          .map((item) => item[name])
          .reduce(function (acumulador, valorAtual) {
            return acumulador + valorAtual;
          }, 0)
          .toFixed(3) / value.length
      ).toFixed(3);
    }
    return '-';
  }

  function sortHeader(header) {
    if (headerSelected.name === header) {
      setHeaderSelected({ name: header, asc: !headerSelected.asc });
    } else {
      setHeaderSelected({ name: header, asc: true });
    }
    if (isGrouped) {
      const newData = dataTable.map((item) => {
        const json = {
          [groupSelected]: item[groupSelected],
          value: arraySort(item.value, header, {
            reverse: headerSelected.asc,
          }),
        };
        headers.forEach((teste, index) => {
          if (teste !== groupSelected)
            json[teste] = getGroupType(groupType[index], item.value, teste);
        });
        return json;
      });
      setDataTable([...newData]);
      setDataGroupImutable([...newData]);
    } else {
      const newData = arraySort(data, header, {
        reverse: headerSelected.asc,
      });
      // setDataTable([...newData]);
      setDataImutable([...newData]);
      setDataGroupImutable([...newData]);
      setDataTable([...newData.slice((page - 1) * 50, page * 50)]);
      setPage(1);
    }
  }

  function exportTablePdf() {
    let arr = [];
    if (isGrouped) {
      dataTable.forEach((item) => {
        item.value.forEach((teste, index) => {
          const newArr = [];
          headers.forEach((i) => {
            if (index === 0) {
              if (i === groupSelected) {
                newArr.push({
                  rowSpan: i === groupSelected ? item.value.length + 1 : 1,
                  content: teste[i],
                  styles: {
                    valign: 'middle',
                    halign: 'center',
                  },
                });
              } else {
                newArr.push(teste[i]);
              }
            } else if (i !== groupSelected) {
              newArr.push(teste[i]);
            }
          });
          arr = [...arr, newArr];
        });
        const newFooter = [];
        headers.forEach((i) => {
          if (i !== groupSelected) {
            newFooter.push({
              content: item[i],
            });
          }
        });
        arr = [...arr, newFooter];
      });
    } else if (isSearchable) {
      arr = dataTable.map((item) => headers.map((i) => [item[i]]));
    } else {
      const dateType = ['inicio', 'fim', 'date', 'datetime'];
      arr = dataImutable.map((item) => {
        return headers.map((i) => {
          if (
            headerTypes &&
            headerTypes.length &&
            headerTypes.some((ii) => ['interval'].includes(ii[i]))
          ) {
            const { days, hours, minutes, seconds } = item[i];
            return `${days ? `${days} dia${days > 1 ? 's' : ''} ` : ''}${(
              hours || 0
            )
              .toString()
              .padStart(2, '0')}:${(minutes || 0)
              .toString()
              .padStart(2, '0')}:${(seconds || 0).toString().padStart(2, '0')}`;
          }
          if (
            headerTypes &&
            headerTypes.length &&
            headerTypes.some((ii) => ['date', 'datetime'].includes(ii[i]))
          ) {
            let dateValue = new Date(item[i]);
            if (Number.isNaN(Number(dateValue))) {
              dateValue = item[i];
            } else {
              dateValue = dateValue.toLocaleString();
            }
            return dateValue;
          }
          /*
          if (
            item &&
            i &&
            typeof item[i] === 'string' &&
            item[i].split('T').length === 2 &&
            dateType.includes(i)
          ) {
            return [new Date(item[i]).toLocaleString()];
          }
*/
          return [item[i]];
        });
      });
    }
    exportPdf(
      arr,
      headers,
      labels,
      fileTitle,
      headerBackground,
      headerColor,
      format(new Date(), 'dd/MM/yyyy HH:mm'),
      isGrouped,
      filters,
      landscape
    );
  }
  function exportTableToXlsx(){
    exportXls(dataImutable.map((item) =>
    headers.map((i) => {
      // debug.csv(i);
      const _headerType = headerTypes.find((ht) =>
        Object.keys(ht).some((k) => k === i)
      );
      if (_headerType && _headerType[i] === 'interval') {
        // console.log(item[i])
        return [
          `${item[i].days || ''} ${
            (Number(item[i].hours) &&
              item[i].hours.toString().padStart(2, '0')) ||
            '00'
          }:${
            (Number(item[i].minutes) &&
              item[i].minutes.toString().padStart(2, '0')) ||
            '00'
          }:${
            (Number(item[i].seconds) &&
              item[i].seconds.toString().padStart(2, '0')) ||
            '00'
          }`.trim(),
        ];
      } else if (_headerType && _headerType[i] === 'date') {
        return [new Date(item[i]).toLocaleString()];
      } else {
        return [item[i]];
      }
    })
  ),
  labels,
  `${fileTitle} ${format(new Date(), 'dd-MM-yyyy HH:mm:ss')}`
  );
  }

  function exportTableCsv() {
    // console.log(headerTypes, headers);

    // console.log(
    //   dataImutable.map((item) =>
    //     headers.map((i) => {
    //       const _headerType = headerTypes.find((ht) =>
    //         Object.keys(ht).some((k) => k === i)
    //       );
    //       if (_headerType[i] === 'date') {
    //         return [new Date(item[i]).toLocaleString()];
    //       } else {
    //         return [item[i]];
    //       }
    //     })
    //   )
    // );

    exportCsv(
      dataImutable.map((item) =>
        headers.map((i) => {
          // debug.csv(i);
          const _headerType = headerTypes.find((ht) =>
            Object.keys(ht).some((k) => k === i)
          );
          if (_headerType && _headerType[i] === 'interval') {
            // console.log(item[i])
            return [
              `${item[i].days || ''} ${
                (Number(item[i].hours) &&
                  item[i].hours.toString().padStart(2, '0')) ||
                '00'
              }:${
                (Number(item[i].minutes) &&
                  item[i].minutes.toString().padStart(2, '0')) ||
                '00'
              }:${
                (Number(item[i].seconds) &&
                  item[i].seconds.toString().padStart(2, '0')) ||
                '00'
              }`.trim(),
            ];
          } else if (_headerType && _headerType[i] === 'date') {
            return [new Date(item[i]).toLocaleString()];
          } else {
            return [item[i]];
          }
        })
      ),
      labels,
      `${fileTitle} ${format(new Date(), 'dd-MM-yyyy HH:mm:ss')}`
    );
  }

  async function groupHeader(e, header) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (groupSelected === header) {
      setDataTable([...dataImutable]);
      setDataGroupImutable([...dataImutable]);
      setIsGrouped(false);
      setGroupSelected(false);
      setShowGroupIndex(false);
    } else {
      const indexArr = headerTable.findIndex((item) => item === header);
      headers.splice(0, 0, headers.splice(indexArr, 1)[0]);
      labels.splice(0, 0, labels.splice(indexArr, 1)[0]);
      groupType.splice(0, 0, groupType.splice(indexArr, 1)[0]);
      filterTypes.splice(0, 0, filterTypes.splice(indexArr, 1)[0]);
      ColumnsWidth.splice(0, 0, ColumnsWidth.splice(indexArr, 1)[0]);
      setHeaderTable([...headers]);
      setLabelsTable([...labels]);
      setWidthTable([...ColumnsWidth]);
      const newArr = _.chain(dataImutable)
        .groupBy(header)
        .map((value, key) => {
          const json = { [header]: key, value };
          headers.forEach((item, index) => {
            if (item !== header)
              json[item] = getGroupType(groupType[index], value, item);
          });
          return json;
        })
        .value();
      setDataTable([...newArr]);
      setDataGroupImutable([...newArr]);
      setIsGrouped(true);
      setGroupSelected(header);
    }
  }

  async function handleLoadMore() {
    if (!(isGrouped || isSearchable)) {
      await setLoadingScroll(true);

      await setDataTable([
        ...dataTable,
        ...dataImutable.slice((page - 1) * 50, page * 50),
      ]);
      await setPage(page + 1);
      await setLoadingScroll(false);
    }
  }

  const infiniteRef = useInfiniteScroll({
    loading: loadingScroll,
    hasNextPage: true,
    onLoadMore: handleLoadMore,
    scrollContainer: 'parent',
  });

  function getDetailData() {
    function getHeaderData({ item, header }) {
      // if (['date', 'datetime'].includes(header) && item[header]) {
      //   let dateValue = new Date(item[header]);
      //   if (Number.isNaN(Number(dateValue))) {
      //     dateValue = item[header];
      //   } else {
      //     dateValue = dateValue.toLocaleString();
      //   }
      //   return dateValue;
      // }
      if (
        headerTypes &&
        headerTypes.length &&
        headerTypes.some((i) => ['interval'].includes(i[header]))
      ) {
        const { days, hours, minutes, seconds } = item[header];
        return `${days ? `${days} dia${days > 1 ? 's' : ''} ` : ''}${(
          hours || 0
        )
          .toString()
          .padStart(2, '0')}:${(minutes || 0).toString().padStart(2, '0')}:${(
          seconds || 0
        )
          .toString()
          .padStart(2, '0')}`;
      }
      if (
        headerTypes &&
        headerTypes.length &&
        headerTypes.some((i) => ['date', 'datetime'].includes(i[header]))
      ) {
        let dateValue = new Date(item[header]);
        if (Number.isNaN(Number(dateValue))) {
          dateValue = item[header];
        } else {
          dateValue = dateValue.toLocaleString();
        }
        return dateValue;
      }
      if (item[header]) {
        return item[header].toString();
      }
      if (item.value) {
        return item.value.length;
      }
      return '-';
    }
    if (_.isEmpty(dataTable)) {
      return <div>Sem Registro</div>;
    }
    return (
      <div ref={infiniteRef}>
        {dataTable.map((item, i) => (
          <>
            <DetailRow
              key={`_row_${item}`}
              columns={headerTable.length}
              isWidth={_.isEmpty(widthTable)}
              isGrouped={isGrouped}
              stripedColor={stripedColor}
              onClick={() => !isGrouped && getData(item, i)}
              cursor={isGrouped}
              detailWidth={() => {
                if (_.isEmpty(widthTable)) return false;
                if (widthTable.length !== headerTable.length) return false;
                return widthTable[i];
              }}
            >
              {isGrouped && (
                <GroupItem>
                  {showGroupIndex === i ? (
                    <MdExpandLess onClick={() => setShowGroupIndex(false)} />
                  ) : (
                    <MdExpandMore onClick={() => setShowGroupIndex(i)} />
                  )}
                </GroupItem>
              )}
              {_.isEmpty(headerTable) ? (
                <DetailItem>A</DetailItem>
              ) : (
                headerTable.map((header, index) => (
                  <DetailItem
                    itemsLength={headerTable.length}
                    key={`_col_${header}`}
                    detailWidth={() => {
                      if (_.isEmpty(widthTable)) return false;
                      if (widthTable.length !== headerTable.length)
                        return false;
                      return widthTable[index];
                    }}
                    align={align[index]}
                  >
                    {getHeaderData({ item, header })}
                  </DetailItem>
                ))
              )}
            </DetailRow>
            {showGroupIndex === i &&
              item.value.map((teste) => (
                <DetailRow
                  key={teste}
                  columns={headerTable.length}
                  isWidth={_.isEmpty(widthTable)}
                  isGrouped={isGrouped}
                  stripedColor={stripedColor}
                  onClick={() => getData(teste, i)}
                  cursor={!isGrouped}
                >
                  <GroupItem />
                  {headerTable.map((header, index) => (
                    <DetailItem
                      key={header}
                      detailWidth={() => {
                        if (_.isEmpty(widthTable)) return false;
                        if (widthTable.length !== headerTable.length)
                          return false;
                        return widthTable[index];
                      }}
                      align={align}
                    >
                      {teste[header] ? teste[header].toString() : '-'}
                    </DetailItem>
                  ))}
                </DetailRow>
              ))}
          </>
        ))}
      </div>
    );
  }

  return (
    <Container style={{ height: dimensionHeight }}>
      <Header
        fix={fix}
        headerBackground={headerBackground}
        headerColor={headerColor}
        columns={headerTable.length}
        isGrouped={isGrouped}
        isWidth={_.isEmpty(widthTable)}
      >
        {isGrouped && <GroupItem />}
        {headerTable.map((header, index) => (
          <HeaderItem
            key={`_header_${header}`}
            headerWidth={() => {
              if (_.isEmpty(widthTable)) return false;
              if (widthTable.length !== headerTable.length) return false;
              return widthTable[index];
            }}
            align={align}
            headerBackground={headerBackground}
            headerColor={headerColor}
          >
            <button
              className="flex items-center mx-2 justify-between"
              type="button"
              onClick={() => sortHeader(header)}
            >
              <span className="flex items-center">
                {labelsTable && labelsTable[index]}
                {headerSelected.name === header &&
                  (headerSelected.asc ? (
                    <MdKeyboardArrowUp />
                  ) : (
                    <MdKeyboardArrowDown />
                  ))}
              </span>
              <button type="button" onClick={(e) => groupHeader(e, header)}>
                {groupSelected === header ? (
                  <MdClose color={headerColor} size={15} />
                ) : (
                  <AiFillPushpin color={headerColor} size={15} />
                )}
              </button>
            </button>
            {_.isEmpty(filterTypes) ? (
              <input
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                }}
                placeholder={`Pesquise por ${labelsTable[index]}`}
                onChange={(e) => sortInput(e.target.value || undefined, header)}
              />
            ) : (
              getType(filterTypes[index], labelsTable, index, header)
            )}
          </HeaderItem>
        ))}
      </Header>
      <Detail>
        {loading ? <Skeleton count={10} height={30} /> : getDetailData()}
      </Detail>
      <Footer footerBackground={headerBackground} footerColor={headerColor}>
        <div>
          <button type="button" onClick={() => exportTablePdf()}>
            <img src={pdfLogo} alt="PDF LOGO" />
          </button>
          <button type="button" onClick={() => exportTableCsv()}>
            <img src={csvLogo} alt="CSV LOGO" />
          </button>
          <button type="button" onClick={() => exportTableToXlsx()}>
            <img src={xlsxLogo} alt="CSV LOGO" />
          </button>
        </div>
      </Footer>
    </Container>
  );
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  headers: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  ColumnsWidth: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  fix: PropTypes.bool,
  landscape: PropTypes.bool,
  headerBackground: PropTypes.string,
  headerColor: PropTypes.string,
  align: PropTypes.arrayOf(PropTypes.string),
  fileTitle: PropTypes.string,
  stripedColor: PropTypes.string,
  getData: PropTypes.func.isRequired,
  dimensionHeight: PropTypes.string,
  filters: PropTypes.string,
  filterTypes: PropTypes.arrayOf(PropTypes.string),
  headerTypes: PropTypes.arrayOf(PropTypes.Object),
  groupType: PropTypes.arrayOf(PropTypes.string),
};

Table.defaultProps = {
  loading: false,
  fix: true,
  landscape: false,
  data: [],
  filterTypes: [],
  headerTypes: [],
  groupType: [],
  headers: [],
  labels: [],
  filters: [],
  ColumnsWidth: [],
  headerBackground: '#235277',
  headerColor: '#f2f1f2',
  align: [],
  fileTitle: 'Export',
  stripedColor: '#e9edf1',
  dimensionHeight: '500px',
};

export default Table;
