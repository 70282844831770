import React, { useState, useRef, useLayoutEffect } from 'react';

import PropTypes from 'prop-types';

import { CarsCheckBox } from '~/components/checkBox';
import { GroupRadio } from '~/components/radio';

import { ContainerModel, ContainerModelOptions } from './styles';

export function Trackers({ formik, title, param }) {
  const ref = useRef();
  const [modeOptions] = useState([
    { label: 'Todos', value: 'all' },
    { label: 'Por grupo', value: 'group' },
    { label: 'Por carro', value: 'cars' },
  ]);

  const [height, setHeight] = useState(300);

  useLayoutEffect(() => {
    const dimension = ref.current.offsetHeight - 120;
    setHeight(dimension);
  }, []);

  return (
    <ContainerModel ref={ref}>
      <ContainerModelOptions>
        <header>{title}</header>
        {modeOptions.map(({ label, value }) => (
          <label htmlFor={value}>
            <input
              onChange={({ target: { value: valueType } }) => {
                if (valueType !== 'group') {
                  formik.setFieldValue('group', null);
                }
                if (valueType !== 'cars') {
                  formik.setFieldValue('cars', null);
                }
                formik.setFieldValue(param, valueType);
              }}
              id={value}
              value={value}
              checked={formik.values[param] === value}
              name="modeOptions"
              type="radio"
            />
            <span>{label}</span>
          </label>
        ))}
      </ContainerModelOptions>
      <div style={{ height: `${height}px` }}>
        {formik.values[param] === 'group' && <GroupRadio formik={formik} />}
        {formik.values[param] === 'cars' && <CarsCheckBox formik={formik} />}
      </div>
    </ContainerModel>
  );
}

Trackers.propTypes = {
  formik: PropTypes.objectOf(
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
    PropTypes.number
  ).isRequired,
  title: PropTypes.string,
  param: PropTypes.string,
};

Trackers.defaultProps = {
  title: 'Carros que receberam',
  param: 'type',
};
