import { Calendar } from 'react-big-calendar';
import InputMask from 'react-input-mask';
import styled from 'styled-components';

import { ButtonStyled } from '~/components/Global';

export const CalendarStyled = styled(Calendar)`
  .rbc-event {
    background-color: #245378;
    .rbc-overlay {
      background-color: #245378;
    }
  }
`;

export const Container = styled.div`
  margin: 10px 10px;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  transition: 0.3s;
`;

export const ContainerTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #605f5f;
  margin-bottom: 10px;
  margin-top: 5px;
`;

export const ContainerStyled = styled(Container)`
  display: grid;
  /* grid-template-columns: 1fr 3fr; */
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
`;

export const FormContainer = styled.div`
  display: grid;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
`;

export const FormData = styled.form`
  background: #f2f1f0;
  border-radius: 0 7px 7px 7px;
  grid-area: detail;
  height: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: 'form';
  > div {
    padding: 5px;
    background: #ededed;
    border-radius: 0 0 7px 7px;
  }
`;

export const DataContainer = styled.div`
  grid-area: form;
`;

export const BodyFilterConfirm = styled.div`
  grid-area: card;
  background: #f7f4f7;
  display: grid;
`;

export const CardInfo = styled.div`
  grid-area: info;
  /* background: #f7f4f7; */
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 5px 5px 5px 5px;
  height: 100%;
  width: 100%;
`;

export const CarregandoMessageStyled = styled.label`
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  align-items: center;
  text-align: center;
`;

export const WorkSheetEditButton = styled(ButtonStyled)`
  border: none;
  margin-top: 3px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 3px;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-radius: 5px;
  height: 30px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 15px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const CommandConfirmButton = styled(ButtonStyled)`
  border: none;
  margin-top: 3px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 3px;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 15px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const CommandNameStyled = styled.div`
  color: #f7f4f7;
  font-weight: 700;
  font-size: 14px;
`;

export const ConfirmBox = styled.div`
  /* height: 150px; */
  grid-area: form;
  /* height: 245px !important; */
  /* width: 350px; */
  width: 100%;
  border-radius: 7px;
`;

export const ConfirmButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
`;

export const ConfirmDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90px;
  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const DivFlex = styled.div`
  display: flex;
`;

export const ItemList = styled.div`
  padding: 5px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0 5px;
  img {
    transform: ${(props) => props.rotate && `rotate(${props.rotate}deg)`};
    height: 15px;
    width: 15px;
    opacity: 0.8;
    transition: opacity 0.3s;
    :hover {
      opacity: 1;
    }
  }
`;

export const ModalContainer = styled.form`
  grid-template-rows: 30px 1fr 30px;
  grid-template-areas: 'header' 'form' 'buttons';
  display: grid;
  height: 100%;
  width: 100%;
  border-radius: 7px;
`;

export const ModalHeader = styled.div`
  text-align: center;
  grid-area: header;
  width: 100%;
  height: 30px;
  background: #245378;
  border-radius: 5px 5px 0 0;
  display: flex;
`;

export const ModalTextHeader = styled.div`
  padding-top: 3px;
  /* padding-left: 60px; */
  width: 100%;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

export const PasswordInput = styled.input`
  height: 33.2px;
  border: 1px solid #dbdbdb;
  width: 200px;
  border-radius: 3px;
  padding-left: 5px;
  color: #999;
  font-size: 14px;
  border: '1px solid #dbdbdb';
`;

export const PasswordLabelStyled = styled.label`
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  font-size: 14px;
  padding-top: 10px;
`;

export const TextStyled = styled.label`
  /* font-weight: 700; */
  text-transform: uppercase;
  display: flex;
  font-size: 14px;
  padding-top: 10px;
`;

export const CommandsCard = styled.div`
  grid-area: buttonscard;
  background: #fafafa;
  height: 100%;
  width: 100%;
  border-radius: 5px 5px 5px 5px;
`;

export const BodyFilter = styled.div`
  grid-area: card;
  background: #f7f4f7;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'info buttonscard';
  border-radius: 5px 5px 5px 5px;
`;

export const TextStyledBold = styled.label`
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  font-size: 16px;
  padding-top: 10px;
`;

export const FilterButton = styled.div`
  border: none;
  margin: 5px;
  padding: 5px;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const ModalButtonHeader = styled.div`
  width: 30px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const workSheetListDiv = styled.div`
  display: flex;
  .sc-fgrSAo {
    background: '#245378';
  }
`;

export const workSheetButton = styled.div`
  /* flex-grow: 100; */
  height: 100px;
  width: 300px;
  border: 1px solid green;
  background-color: '#245378' !important;
`;

export const workSheetLabel = styled.label`
  /* flex-grow: 100; */
  border: 1px solid green;
  background-color: '#245378' !important;
`;

export const Input = styled(InputMask)`
  flex: 0 1 100%;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 10px;
  display: block;
  height: 40px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
`;
