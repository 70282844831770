import React from 'react';

import NotReport from '~/assets/report/undrawGoingUp.svg';

import { Container } from './styles';

export default function NoData() {
  return (
    <Container>
      <img src={NotReport} alt="NotReport" />
      <div>Selecione um veículo para enviar comandos...</div>
    </Container>
  );
}
