import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/maintenanceSauce';

export function* getMaintenance(payload) {
  try {
    const response = yield call(
      api.fetchMaintenance,
      payload.query,
      payload.params
    );
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_MAINTENANCE_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_MAINTENANCE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_VIOLATION_FAILURE });
  }
}

export function* createMaintenanceSaga(payload) {
  try {
    yield call(api.createMaintenance, payload.data);
    yield put({
      type: Types.FETCH_MAINTENANCE_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/maintenance'));
  } catch (error) {
    yield put({ type: Types.FETCH_MAINTENANCE_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateMaintenanceSaga(payload) {
  try {
    yield call(api.updateMaintenance, payload.data);
    yield put({
      type: Types.FETCH_MAINTENANCE_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/maintenance'));
  } catch (error) {
    yield put({ type: Types.FETCH_MAINTENANCE_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_MAINTENANCE_REQUEST, getMaintenance)]);
  yield all([
    takeEvery(Types.CREATE_MAINTENANCE_REQUEST, createMaintenanceSaga),
  ]);
  yield all([
    takeEvery(Types.UPDATE_MAINTENANCE_REQUEST, updateMaintenanceSaga),
  ]);
}
