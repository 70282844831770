import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import React from 'react';
import { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';

import {
  FormData,
  DataContainer,
  Row,
  FieldContainer,
  LabelStyled,
  Input,
  ErrorMessage,
  ButtonContainer,
} from '~/components/Global';
import api from '~/services/api';
import { Creators as JourneyActions } from '~/store/ducks/journeySauce';

import { ProfilePasswordContainer } from './styles';

function Forms() {
  registerLocale('pt-BR', el);

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: '',
    },
    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (!values.old_password) errors.old_password = message;
      if (!values.new_password) errors.new_password = message;
      if (!values.confirm_new_password) errors.confirm_new_password = message;
      if (
        values.new_password &&
        values.confirm_new_password &&
        values.new_password !== values.confirm_new_password
      ) {
        errors.confirm_new_password = 'Repita sua nova senha';
      }
      return errors;
    },
    onSubmit: async (data) => {
      try {
        await api.changePassword(data);
        await toast.success('Senha atualizada!');
        await formik.resetForm();
      } catch (error) {
        toast.error('Erro ao atualizar a senha!');
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormData>
        <DataContainer>
          <ProfilePasswordContainer>
            <div>
              <Row>
                <FieldContainer>
                  <div>
                    <LabelStyled>Senha antiga</LabelStyled>
                    {formik.touched.old_password &&
                    formik.errors.old_password ? (
                      <ErrorMessage>{formik.errors.old_password}</ErrorMessage>
                    ) : (
                      ''
                    )}
                  </div>
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.old_password}
                    id="old_password"
                    name="old_password"
                    type="password"
                    error={
                      formik.touched.old_password && formik.errors.old_password
                    }
                  />
                </FieldContainer>
              </Row>
              <Row>
                <FieldContainer>
                  <div>
                    <LabelStyled>Nova Senha</LabelStyled>
                    {formik.touched.new_password &&
                    formik.errors.new_password ? (
                      <ErrorMessage>{formik.errors.new_password}</ErrorMessage>
                    ) : (
                      ''
                    )}
                  </div>
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.new_password}
                    id="new_password"
                    name="new_password"
                    type="password"
                    error={
                      formik.touched.new_password && formik.errors.new_password
                    }
                  />
                </FieldContainer>
              </Row>
              <Row>
                <FieldContainer>
                  <div>
                    <LabelStyled>Confirmação de senha</LabelStyled>
                    {formik.touched.confirm_new_password &&
                    formik.errors.confirm_new_password ? (
                      <ErrorMessage>
                        {formik.errors.confirm_new_password}
                      </ErrorMessage>
                    ) : (
                      ''
                    )}
                  </div>
                  <Input
                    onChange={formik.handleChange}
                    value={formik.values.confirm_new_password}
                    id="confirm_new_password"
                    name="confirm_new_password"
                    type="password"
                    error={
                      formik.touched.confirm_new_password &&
                      formik.errors.confirm_new_password
                    }
                  />
                </FieldContainer>
              </Row>
            </div>
          </ProfilePasswordContainer>
        </DataContainer>
      </FormData>
      <ButtonContainer type="submit">Alterar</ButtonContainer>
    </form>
  );
}

const mapStateToProps = (state) => ({
  journey: state.journeyState.journey,
  loading: state.journeyState.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(JourneyActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
