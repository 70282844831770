import React, { useContext } from 'react';
import Modal from 'react-awesome-modal';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
  CommandConfirmButton,
} from '~/components/Global';
import api from '~/services/api';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import Context from '../Contex';
import { ModalButtons, ModalDetail } from './styles';

function ModalComponent() {
  const dispatch = useDispatch();
  const { value } = useSelector(({ bufferState }) => bufferState.groupSelected);
  const { modal, setViewModal } = useContext(Context);

  async function deleteGroup() {
    const response = await api.deleteGroups(value);
    if (response.status === 200) {
      toast.success('Grupo deletado com sucesso!');
      dispatch(BufferActions.bufferRequest());
      dispatch(BufferActions.groupRequest());
      setViewModal(false);
      dispatch(
        BufferActions.setGroupSelected({
          label: '',
          value: '',
          id_trackers: [],
          isAdm: false,
        })
      );
    }
  }
  return (
    <Modal
      visible={modal}
      height="185"
      effect="fadeInUp"
      onClickAway={() => setViewModal()}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTextHeader>Deletar grupo</ModalTextHeader>
        </ModalHeader>
        <ModalDetail>Deseja realmente deletar o grupo?</ModalDetail>
        <ModalButtons>
          <CommandConfirmButton color="#ff4e4e" onClick={() => setViewModal()}>
            <FaTimes />
            Não
          </CommandConfirmButton>
          <CommandConfirmButton back="#293e52" onClick={deleteGroup}>
            <FaCheck /> Sim
          </CommandConfirmButton>
        </ModalButtons>
      </ModalContainer>
    </Modal>
  );
}

export default ModalComponent;
