import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  color: #245378;
  background: #fff;
  padding: 0 5px;
  font-size: 25px;
  font-weight: 600;
  border-radius: 5px;
  > div {
    display: flex;
  }
`;
