import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchWorksheetRequest: ['query', 'params'],
  fetchWorksheetSuccess: ['data'],
  fetchWorksheetIdSuccess: ['data'],
  fetchWorksheetFailure: [],
  updateWorksheetRequest: ['data'],
  createWorksheetRequest: ['data'],
  clearWorksheet: [],
  setViewModal: [],

  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  worksheets: { docs: [] },
  worksheet: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
  viewModal: false,
};

const clearWorksheet = (state = INITIAL_STATE) => ({
  ...state,
  worksheet: [],
});

const fetchWorksheetRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchWorksheetSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  worksheets: actions.data,
  loading: false,
});

const fetchWorksheetIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    worksheet: actions.data,
    loading: false,
  };
};

const fetchWorksheetFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    worksheets: [],
  };
};

const createWorksheetRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateWorksheetRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

const changeModal = (state = INITIAL_STATE) => ({
  ...state,
  viewModal: !state.viewModal,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_WORKSHEET_REQUEST]: fetchWorksheetRequest,
  [Types.FETCH_WORKSHEET_SUCCESS]: fetchWorksheetSuccess,
  [Types.FETCH_WORKSHEET_ID_SUCCESS]: fetchWorksheetIdSuccess,
  [Types.FETCH_WORKSHEET_FAILURE]: fetchWorksheetFailure,
  [Types.UPDATE_WORKSHEET_REQUEST]: updateWorksheetRequest,
  [Types.CREATE_WORKSHEET_REQUEST]: createWorksheetRequest,
  [Types.CLEAR_WORKSHEET]: clearWorksheet,
  [Types.SET_PAGE]: setPage,
  [Types.SET_VIEW_MODAL]: changeModal,
  [Types.SET_PARAMS]: setParams,
});
