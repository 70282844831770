import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import { Container } from './styles';

function Group({ formik, className }) {
  const dispatch = useDispatch();
  const groups = useSelector(({ bufferState }) => bufferState.groups);

  useEffect(() => {
    dispatch(BufferActions.groupRequest());
  }, []);

  return (
    <Container>
      <header className="text-lg text-geo-900 font-bold">Grupos</header>
      <div className={className}>
        {groups.map(({ name, id }) => (
          <label
            className="
            rounded-lg
            shadow
            bg-gray-50
            hover:bg-gray-100
            transition-all
            cursor-pointer
            h-10
            flex
            items-center px-2"
            htmlFor={id}
          >
            <input
              onChange={({ target: { value } }) =>
                formik.setFieldValue('group', value)
              }
              id={id}
              value={id}
              type="radio"
              name="groupOptions"
            />
            <span>{name}</span>
          </label>
        ))}
      </div>
    </Container>
  );
}

Group.propTypes = {
  formik: PropTypes.objectOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

Group.defaultProps = {
  className: 'grid grid-cols-4 gap-2',
};

export default Group;
