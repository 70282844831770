import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchRouteRequest: ['query', 'params'],
  fetchRouteSuccess: ['data'],
  fetchRouteIdSuccess: ['data'],
  fetchRouteFailure: [],
  updateRouteRequest: ['data'],
  createRouteRequest: ['data'],
  clearRoute: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  routes: [],
  route: {},
  routeByTracker: {},
  loading: true,
  // page: 1,
  // pageSize: 20,
  params: '',
};

const clearRoute = (state = INITIAL_STATE) => ({
  ...state,
  route: {},
});

const fetchRouteRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchRouteSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    routes: actions.data,
    loading: false,
  };
};

const fetchRouteIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    route: actions.data,
    loading: false,
  };
};

const fetchRouteFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  routes: [],
});

const createRouteRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateRouteRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_ROUTE_REQUEST]: fetchRouteRequest,
  [Types.FETCH_ROUTE_SUCCESS]: fetchRouteSuccess,
  [Types.FETCH_ROUTE_ID_SUCCESS]: fetchRouteIdSuccess,
  [Types.FETCH_ROUTE_FAILURE]: fetchRouteFailure,
  [Types.UPDATE_ROUTE_REQUEST]: updateRouteRequest,
  [Types.CREATE_ROUTE_REQUEST]: createRouteRequest,
  [Types.CLEAR_ROUTE]: clearRoute,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
