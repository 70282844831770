import styled from 'styled-components';

export const InputContainerWeek = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
`;

export const WeekList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;
