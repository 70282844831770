import styled, { css } from 'styled-components';

import searchIcon from '~/assets/search.svg';
import { Container, ButtonStyled } from '~/components/Global';

export const ContainerStyled = styled(Container)`
  width: 100%;
  #map {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

export const Overlay = css`
  background: #000;
`;

export const InputPlaces = styled.input`
  margin-top: 6px;
  margin-right: 6px;
  width: 200px;
  border: none;
  height: 30px;
  padding-left: 25px;
  color: #333;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 2px center;
  display: ${(props) => (props.hide ? 'none' : '')};
`;

export const InputName = styled.input`
  margin-top: 6px;
  margin-right: 6px;
  width: 200px;
  border: none;
  height: 30px;
  padding-left: 25px;
  color: #333;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 2px center;
  display: ${(props) => (props.hide ? 'none' : '')};
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 7px;
`;

export const ModalHeader = styled.div`
  width: 300px;
  height: 50px;
  background: #245378;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ModalDetail = styled.div`
  height: 260px; 
  font-size: 15px;
  padding: 10px;
`;

export const ModalButtons = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
`;

export const ButtonModal = styled(ButtonStyled)`
  width: 100px;
  height: 30px;
  margin: 0;
  font-weight: 600;
  :last-child {
    margin-left: 10px;
  }
`;

export const ButtonPdf = styled.button`
  position: absolute;
`;
