import React, { useContext, useEffect } from 'react';
import { MdAdd, MdCreate, MdDelete, MdMoreVert } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';

import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import Context from '../Contex';

function MenuDrop() {
  const dispatch = useDispatch();
  const { setViewModal } = useContext(Context);
  const { label } = useSelector(({ bufferState }) => bufferState.groupSelected);
  useEffect(() => {
    // console.log({ label });
  }, [label]);
  const menu = (
    <Menu className="w-36 rounded-lg shadow" selectable={false}>
      <MenuItem
        onClick={() => {
          dispatch(BufferActions.clearGroupBuffer());
          dispatch(BufferActions.setGroupSelected({}));
          return dispatch(BufferActions.setViewGroup());
        }}
      >
        <div className="flex">
          <MdAdd size={15} />
          <span>Criar grupo</span>
        </div>
      </MenuItem>
      {label && (
        <MenuItem
          onClick={() => {
            return dispatch(BufferActions.setViewGroup());
          }}
        >
          <div className="flex">
            <MdCreate size={15} />
            <span>Editar grupo</span>
          </div>
        </MenuItem>
      )}
      {label && (
        <MenuItem onClick={() => setViewModal()}>
          <div className="flex">
            <MdDelete size={15} />
            <span>Deletar grupo</span>
          </div>
        </MenuItem>
      )}
    </Menu>
  );
  return (
    <Dropdown trigger={['click']} overlay={menu} animation="slide-up">
      <MdMoreVert size={30} color="#625f5f" />
    </Dropdown>
  );
}

export default MenuDrop;
