import React from 'react';
import { useDispatch } from 'react-redux';

import { getMonth, format, set } from 'date-fns';
import PropTypes from 'prop-types';

import { Creators as schedulesActions } from '~/store/ducks/schedulesSauce';

function WeeklyType({ data, day, monthNow }) {
  const dispatch = useDispatch();

  if (data.schedule.type === 'weekly') {
    if (
      data.schedule.week ===
      Number(
        format(
          set(new Date(), {
            month: getMonth(monthNow),
            date: day,
          }),
          'e'
        )
      )
    ) {
      let bgColor;
      if (data.key === 'command') {
        if (data.type === 'lock') {
          bgColor = 'bg-red-800';
        } else {
          bgColor = 'bg-red-600';
        }
      } else {
        bgColor = 'bg-green-600';
      }
      return (
        <button
          type="button"
          className={`
          ${bgColor}
          h-10
          w-full
          text-xs
          flex
          flex-col
          justify-center
          text-white
          px-2
          my-1
          rounded
          overflow-hidden
          shadow`}
          onClick={() => {
            dispatch(schedulesActions.setSchedule(data));
            dispatch(schedulesActions.setModal());
          }}
        >
          <div className="text-sm w-11/12 font-bold truncate">{data.name}</div>
          <div>{data.schedule.time}</div>
        </button>
      );
    }
  }
  return <></>;
}

WeeklyType.propTypes = {
  data: PropTypes.objectOf(PropTypes.object, PropTypes.string, PropTypes.number)
    .isRequired,
  monthNow: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
};

export default WeeklyType;
