import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';

import { MdCheck, MdClear, MdDelete } from 'react-icons/md';
import el from 'date-fns/locale/pt-BR';
import { ErrorMessage, useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import { TrackersField, SelectField, MaskedField } from '~/components/fields';
import Loading from '~/components/Loading';
import { Creators as PreventiveActions } from '~/store/ducks/preventiveSauce';
import { IconButton, SimpleButton } from '~/components/buttons';

import Header from './Header';
import preventiveTypeOptions from './preventiveTypeOptions.json';
import api from '~/services/api';
import { FieldContainer, LabelStyled, Input } from '~/components/Global';

function Forms({
  match: {
    params: { id: idParams },
  },
}) {
  const dispatch = useDispatch();

  const preventive = useSelector((state) => state.preventiveState.preventive);
  const loading = useSelector((state) => state.preventiveState.loading);
  registerLocale('pt-BR', el);
  const [editablePermission] = useState(true);
  const [defaultValueLoading, setDefaultLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      type: '',
      client_id: null,
      tracker_id: null,
      kilometer: 0,
      last_kilometer: 0,
      advanced_notice: 0,
      days: 0,
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.tracker_id) err.tracker_id = message;
      if (!values.last_kilometer) err.last_kilometer = message;
      if (!values.type) err.type = message;
      return err;
    },
    onSubmit: (data) => {
      if (data.id) {
        dispatch(PreventiveActions.updatePreventiveRequest(data));
      } else {
        dispatch(PreventiveActions.createPreventiveRequest(data));
      }
      dispatch(PreventiveActions.clearPreventive());
    },
  });

  useEffect(() => {
    if (idParams) {
      dispatch(PreventiveActions.fetchPreventiveRequest(`/${idParams}`));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(preventive) && idParams) {
      formik.setValues({
        ...preventive,
      });
      setDefaultLoading(false);
    }
    if (!idParams) {
      setDefaultLoading(false);
    }
  }, [preventive]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de manutenção preventiva">
          <Header editablePermission={editablePermission} idParam={idParams} />
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <div className="grid grid-cols-3 gap-2">
              {!defaultValueLoading && (
                <TrackersField
                  label="Veículo"
                  onChange={({ value }) =>
                    formik.setFieldValue('tracker_id', value)
                  }
                  error={formik.touched.tracker_id && formik.errors.tracker_id}
                  defaultValue={formik.values.tracker_id}
                  isDisabled={!editablePermission}
                  // value={formik.values.tracker_id}
                />
              )}
              {/* {!defaultValueLoading && (
                <SelectField
                  label="Tipo "
                  placeholder="Selecione o tipo"
                  options={preventiveTypeOptions}
                  noOptionsMessage={() => 'Nenhuma opção encontrada'}
                  onChange={(option) =>
                    formik.setFieldValue('type', option.value)
                  }
                  defaultValue={
                    preventiveTypeOptions[
                      preventiveTypeOptions.findIndex(
                        (item) => item.value === formik.values.type
                      )
                    ]
                  }
                  isDisabled={!editablePermission}
                />
              )} */}
              <FieldContainer style={{ marginTop: '10px' }}>
                <div>
                  <LabelStyled>Tipo</LabelStyled>
                </div>
                <Input
                  name="type"
                  onChange={formik.handleChange}
                  value={formik.values.type}
                  disabled={!editablePermission}
                />
              </FieldContainer>
              <MaskedField
                label="Quilômetro"
                onChange={formik.handleChange}
                value={formik.values.kilometer}
                mask="99999999"
                id="kilometer"
                name="kilometer"
                disabled={!editablePermission}
              />
              <MaskedField
                label="Último Quilômetro"
                onChange={formik.handleChange}
                value={formik.values.last_kilometer}
                mask="99999999"
                id="last_kilometer"
                name="last_kilometer"
                disabled={!editablePermission}
              />
              <MaskedField
                label="Aviso Prévio"
                onChange={formik.handleChange}
                value={formik.values.advanced_notice}
                mask="99999999"
                id="advanced_notice"
                name="advanced_notice"
                disabled={!editablePermission}
              />
              <MaskedField
                label="Dias"
                onChange={formik.handleChange}
                value={formik.values.days}
                mask="99999999"
                id="days"
                name="days"
                disabled={!editablePermission}
              />
            </div>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
