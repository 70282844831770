import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import PropTypes from 'prop-types';

import AuthLayout from '~/layouts/auth';
import DefaultLayout from '~/layouts/default';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}) {
  const signed = useSelector((state) => JSON.parse(state.authState.signed));
  if (!signed && isPrivate) {
    return <Redirect to="/auth/login" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/monitoring" />;
  }
  let Layout;

  if (signed) {
    Layout = DefaultLayout;
  } else {
    Layout = AuthLayout;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
