import arraySort from 'array-sort';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/alertSauce';

export function* getAlert(payload) {
  try {
    const { data } = yield call(api.fetchAlerts, payload.query);
    const newData = arraySort(data, 'status');
    yield put({
      type: Types.FETCH_ALERT_SUCCESS,
      data: newData,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_ALERT_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export function* updateAlertSaga(payload) {
  try {
    yield call(api.updateAlerts, payload.data);
    toast.success('Atualizado com sucesso!');
  } catch (error) {
    yield put({ type: Types.FETCH_ALERT_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_ALERT_REQUEST, getAlert)]);
  yield all([takeEvery(Types.UPDATE_ALERT_REQUEST, updateAlertSaga)]);
}
