import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import React from 'react';

import { Container } from './styles';

function Actionitem({ rotate, tooltip, icon, text }) {
  return (
    <Container rotate={rotate}>
      <Tooltip
        placement="right"
        overlay={tooltip}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        arrowContent={<div className="rc-tooltip-arrow-inner" />}
      >
        <img src={icon} alt="" />
      </Tooltip>
      <span>{text}</span>
    </Container>
  );
}

Actionitem.propTypes = {
  rotate: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Actionitem;
