import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import moment from 'moment';

import mapStyle from '~/assets/map.json';
import markerFinish from '~/assets/markerFinish.svg';
import markerStart from '~/assets/markerStart.png';
import api from '~/services/api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Daily() {
  const query = useQuery();
  const id_tracker = query.get('id');
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const mapInit = new window.google.maps.Map(
      document.getElementById('map2'),
      {
        center: { lat: -20.361797, lng: -40.660631 },
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        zoom: 13,
        controlSize: 25,
        styles: mapStyle,
        mapId: 'ab82f073aa28fc49'
      }
    );
    setMap(mapInit);
  }, []);

  useEffect(() => {
    async function getDaily() {
      const m = moment();
      const data = `?id=${id_tracker}&start=${m
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ssZ')}&end=${m
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        })

        .format('YYYY-MM-DDTHH:mm:ssZ')}`;
      const response = await api.fetchDaily(data);
      if (response.data[0].st_asgeojson) {
        const coordinates = JSON.parse(response.data[0].st_asgeojson);

        const geojson = {
          type: 'Feature',
          geometry: coordinates,
        };

        map.data.addGeoJson(geojson);
        map.data.setStyle({
          strokeColor: '#3AAAAA',
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });

        markers.push(
          new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              coordinates.coordinates[0][1],
              coordinates.coordinates[0][0]
            ),
            icon: {
              url: markerStart,
              origin: new window.google.maps.Point(0, 0),
              scaledSize: new window.google.maps.Size(20, 20),
              anchor: new window.google.maps.Point(0, 20),
            },

            map,
          })
        );

        markers.push(
          new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              coordinates.coordinates[coordinates.coordinates.length - 1][1],
              coordinates.coordinates[coordinates.coordinates.length - 1][0]
            ),
            icon: {
              url: markerFinish,
              origin: new window.google.maps.Point(0, 0),
              scaledSize: new window.google.maps.Size(20, 20),
              anchor: new window.google.maps.Point(0, 20),
            },

            map,
          })
        );

        setMarkers(markers);
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.coordinates.map((item) =>
          bounds.extend(new window.google.maps.LatLng(item[1], item[0]))
        );
        map.fitBounds(bounds);
      }
    }
    if (map) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      markers.map((marker) => marker.setMap(null));
      getDaily();
    }
    const interval = setInterval(() => {
      if (map) {
        map.data.forEach((feature) => {
          map.data.remove(feature);
        });
        markers.map((marker) => marker.setMap(null));
        getDaily();
      }
    }, 300000);
    return () => clearInterval(interval);
  }, [map, id_tracker, markers]);

  return (
    <div className="p-2 bg-white rounded-lg shadow ">
      <div className="h-full w-full rounded-lg" id="map2" />
    </div>
  );
}

export default Daily;
