import React, { useEffect, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import PerfectScroll from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import whatsappIcon from '~/assets/whatsapp-icon.svg';

import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';

import AnimationLoading from '~/components/Global/Buttons/animationLoading';
import { Creators as AlertActions } from '~/store/ducks/alertSauce';

import api from '~/services/api';

import { Container, Trackers, Clients, Notification, Li } from './styles';

function List() {
  const [centralWhatsapp, setCentralWhatsapp] = useState('');
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alertState.alerts);
  const query = useSelector((state) => state.alertState.query);
  const paramsFilter = useSelector((state) => state.alertState.params);
  const loading = useSelector((state) => state.alertState.loading);
  const visible = useSelector((state) => state.alertState.visible);
  const trackerSelected = useSelector(
    (state) => state.alertState.trackerSelected
  );
  const [height, setHeight] = useState(500);
  let [isCentral, setIsCentral] = useState(false);

  const formik = useFormik({
    initialValues: {
      procedure: '',
      notifications: [],
    },
    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (!values.procedure) errors.procedure = message;
      return errors;
    },
    onSubmit: (data) => {
      if (!query.checked) {
        const params = {
          procedure: data.procedure,
          notifications: trackerSelected.notifications,
        };
        dispatch(AlertActions.updateAlertRequest(params));
        dispatch(AlertActions.setVisible(false));
        dispatch(AlertActions.fetchAlertRequest(paramsFilter));
      } else {
        toast.warn('Este alerta ja foi finalizado!');
      }
    },
  });

  function getclassificastionColor(notification) {
    console.log(notification.classification);
    switch (notification.classification) {
      // case 1:
      //   return '#F58D84';
      // case 2:
      //   return '#F5BA73';
      // case 3:
      //   return '#F5F07A';
      // default:
      //   return '#000';
      case 1:
        return '#F5A2A4';
      case 2:
        return '#F4D1AC';
      case 3:
        return '#F5EEAE';
      default:
        return '#000';
    }
  }

  function contactMonitoringCentral() {}

  function showDetail(tracker) {
    if (tracker.tracker_id === trackerSelected.tracker_id) {
      dispatch(AlertActions.setVisible(false));
      dispatch(AlertActions.setTrackerSelected({}));
    } else {
      dispatch(AlertActions.setVisible(true));
      dispatch(AlertActions.setTrackerSelected(tracker));
    }
  }

  useEffect(() => {
    // dispatch(AlertActions.fetchAlertFailure());
    const heights = document.getElementById('container').offsetHeight - 65;
    setHeight(heights);
    (async () => {
      const response = await api.fetchMe();
      if (response.status === 200) {
        setIsCentral(response.data.central);
      }
    })();
  }, []);

  useEffect(() => {
    if (visible) {
      formik.resetForm();
    }
  }, [visible]);

  return (
    <Container id="container" setHeight={height} visible={visible}>
      <header>Listagem de alertas</header>
      <div>
        <PerfectScroll>
          {loading && <AnimationLoading />}
          {alerts.map((alert) => (
            <Clients key={alert}>
              <header>{alert.status}</header>
              {alert.trackers.map((tracker) => (
                <Trackers
                  key={tracker.id}
                  selected={tracker.tracker_id === trackerSelected.tracker_id}
                >
                  <div>{`O ${tracker.tracker_id} tem ${tracker.notifications.length} alerta(s)!`}</div>
                  <button
                    className="flex items-center justify-center"
                    type="button"
                    onClick={() => showDetail(tracker)}
                  >
                    <MdChevronRight color="#fff" size={20} />
                  </button>
                </Trackers>
              ))}
            </Clients>
          ))}
        </PerfectScroll>
        {visible && (
          <Notification onSubmit={formik.handleSubmit} setHeight={height}>
            <header className="grid grid-rows-2 items-center justify-center">
              <div className="grid grid-cols-2">
                {isCentral && (
                  <div className="col-start-1 col-end-2">
                    <a
                      href={centralWhatsapp}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        setCentralWhatsapp(
                          `https://api.whatsapp.com/send?phone=5527997212549&text=Oi, preciso de ajuda com o veículo ${trackerSelected.tracker_id} ${trackerSelected.notifications[0].contact_emergency} `
                        )
                      }
                    >
                      {/* 5527997212549 */}
                      <img
                        src={whatsappIcon}
                        alt=""
                        title="Entrar em contato com a central de monitoramento"
                      />
                    </a>
                  </div>
                )}

                <div className="text-lg font-bold text-gray-600 text-center col-start-3 col-end-7 ">
                  {' '}
                  {trackerSelected.notifications[0] &&
                    `${trackerSelected.tracker_id} - ${trackerSelected.notifications[0].brand} ${trackerSelected.notifications[0].model} - ${trackerSelected.notifications[0].color}`}
                </div>
              </div>
              <div className="text-center font-light text-gray-600">
                {' '}
                {trackerSelected.notifications[0] &&
                  trackerSelected.notifications[0].contact_emergency &&
                  trackerSelected.notifications[0].phone_emergency &&
                  `${trackerSelected.notifications[0].contact_emergency} - ${trackerSelected.notifications[0].phone_emergency}`}
              </div>
            </header>
            <PerfectScroll>
              <ul>
                {trackerSelected.notifications.map((notification) => (
                  <Li color={getclassificastionColor(notification)} key={notification} checked={notification.checked}>
                    {query.checked ? (
                      <div>
                        <div>
                          <strong>Alerta: </strong>
                          <span>{`No dia ${moment(notification.date).format(
                            'DD/MM/YYYY HH:mm'
                          )} o veiculo registrou ${
                            notification.flags.length > 1
                              ? `as seguintes ocorrências:`
                              : `a seguinte ocorrência:`
                          } ${_.join(notification.flags, ',')}.`}</span>
                        </div>
                        <div>
                          <strong>Procedimento: </strong>
                          <span>{notification.procedure}</span>
                        </div>
                      </div>
                    ) : (
                      `No dia ${moment(notification.date).format(
                        'DD/MM/YYYY HH:mm'
                      )} o veiculo regitrou ${
                        notification.flags.length > 1
                          ? `as seguintes ocorrencias:`
                          : `a seguinte ocorrencia:`
                      } ${_.join(notification.flags, ',')}.`
                    )}
                  </Li>
                ))}
              </ul>
            </PerfectScroll>
          </Notification>
        )}
      </div>
    </Container>
  );
}

export default List;
