import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/passengerSauce';

export function* getPassenger(payload) {
  try {
    const response = yield call(api.fetchPassenger, payload.query);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_PASSENGER_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_PASSENGER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error(JSON.stringify(error));
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createPassengerSaga(payload) {
  try {
    yield call(api.createPassenger, payload.data);
    yield put({
      type: Types.FETCH_PASSENGER_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/passenger'));
  } catch (error) {
    yield put({ type: Types.FETCH_PASSENGER_FAILURE });
    toast.error(error.data.message);
  }
}

export function* updatePassengerSaga(payload) {
  try {
    yield call(api.updatePassenger, payload.data, payload.id);
    yield put({
      type: Types.FETCH_PASSENGER_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/passenger'));
  } catch (error) {
    toast.error(error.data.message);
    yield put({ type: Types.FETCH_PASSENGER_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_PASSENGER_REQUEST, getPassenger)]);
  yield all([takeEvery(Types.CREATE_PASSENGER_REQUEST, createPassengerSaga)]);
  yield all([takeEvery(Types.UPDATE_PASSENGER_REQUEST, updatePassengerSaga)]);
}
