import React, { useRef, useLayoutEffect, useState } from 'react';

import { FormContainer, HeaderFormContainer } from '~/components/containers';

import News from './News';

function Releases() {
  const ref = useRef();
  const [height, setHeight] = useState(200);
  useLayoutEffect(() => {
    setHeight(ref.current.offsetHeight);
  }, []);
  return (
    <FormContainer>
      <HeaderFormContainer title="Novidades" />
      <div ref={ref} className="h-full">
        <News height={height} />
      </div>
    </FormContainer>
  );
}

export default Releases;
