import React from 'react';
import Modal from 'react-awesome-modal';
import { MdDone, MdClear } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { IconButton } from '~/components/buttons';
import { Creators as schedulesActions } from '~/store/ducks/schedulesSauce';

function ModalDelete({ setModalDelete, modalDelete, id }) {
  const dispatch = useDispatch();
  return (
    <Modal
      visible={modalDelete}
      onClickAway={() => setModalDelete(false)}
      width="350px"
      height="200px"
    >
      <div className="h-full">
        <header className="w-full h-10 flex justify-center items-center text-2xl text-geo-900">
          DELETAR
        </header>
        <div className="text-lg h-24 flex justify-center items-center">
          Deseja realmente deletar o agendamento?
        </div>
        <div className="flex justify-center items-center h-10 ">
          <IconButton
            width="w-36"
            type="button"
            Icon={MdClear}
            size={20}
            background="bg-red-500"
            onClick={() => setModalDelete(false)}
          >
            Não
          </IconButton>
          <IconButton
            width="w-36"
            type="button"
            Icon={MdDone}
            size={20}
            background="bg-geo-900"
            onClick={() => {
              setModalDelete(false);
              dispatch(schedulesActions.deleteScheduleRequest(id));
            }}
          >
            Sim
          </IconButton>
        </div>
      </div>
    </Modal>
  );
}

ModalDelete.propTypes = {
  modalDelete: PropTypes.bool.isRequired,
  setModalDelete: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default ModalDelete;
