import React from 'react';

// import { Container } from './styles';
import PropTypes from 'prop-types';

import Simple from './Simple';

function IconButton({ Icon, className, size, colorIcon, children, ...props }) {
  return (
    <Simple
      className={`${className} flex items-center justify-center`}
      {...props}
    >
      <Icon size={size} color={colorIcon} /> &nbsp; {children}
    </Simple>
  );
}

IconButton.propTypes = {
  Icon: PropTypes.element,
  children: PropTypes.element,
  size: PropTypes.number,
  colorIcon: PropTypes.string,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  Icon: <></>,
  children: <></>,
  size: 20,
  colorIcon: '#fff',
  className: '',
};

export default IconButton;
