import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import CarPanel from '~/components/CarPanel';
import Dashboard from '~/components/Dashboard';
import Map from '~/components/Map';

import { Container } from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Monitoring(props) {
  const query = useQuery();
  return (
    <Container className="gap-2 p-2" style={{ paddingLeft: '1rem' }}>
      <CarPanel {...props} />
      <div>
        {!query.get('id') ? <Map {...props} /> : <Dashboard {...props} />}
      </div>
    </Container>
  );
}
