import React, { useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';

import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import moment from 'moment';

import { SimpleButton } from '~/components/buttons';
import { HeaderFormContainer } from '~/components/containers';
// import { ButtonContainer } from '~/components/Global';
import AnimationLoading from '~/components/Global/Buttons/animationLoading';
import { DateInput } from '~/components/inputs';
import { TrackersSelect } from '~/components/selects';
import { Creators as DailyActions } from '~/store/ducks/dailySauce';
import { mountParams } from '~/util/Mountparams';

import { InputCustom } from './styles';

function FilterDaily() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dailyState.loading);
  registerLocale('pt-BR', el);

  const formik = useFormik({
    initialValues: {
      tracker: {},
      start: moment().toDate(),
      end: moment().toDate(),
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.tracker) err.tracker = message;
      return err;
    },
    onSubmit: ({ start, end, tracker }) => {
      const query = mountParams({
        id: tracker.value,
        start: moment(start).format('YYYY-MM-DDTHH:mm:ssZ'),
        end: moment(end).format('YYYY-MM-DDTHH:mm:ssZ'),
      });
      dispatch(
        DailyActions.setDailyFilter({
          tracker: tracker.label,
          start: moment(start).format('DD/MM/YYYY HH:mm:ss'),
          end: moment(end).format('DD/MM/YYYY HH:mm:ss'),
        })
      );

      dispatch(DailyActions.fetchDailyRequest(query));
      dispatch(DailyActions.fetchStopRequest(query));
    },
  });

  useEffect(() => {
    const m = moment();

    formik.setFieldValue(
      'start',
      m
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toDate()
    );
    formik.setFieldValue(
      'end',
      m
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 59,
        })
        .toDate()
    );
  }, []);

  return (
    <HeaderFormContainer title="Diário sobre mapa">
      <form
        onSubmit={formik.handleSubmit}
        className="grid h-full items-center w-8/12 grid-cols-4 gap-2"
      >
        <TrackersSelect
          onChange={(option) => formik.setFieldValue('tracker', option || {})}
          isClearable
          isSearchable
          background="#F2F2F2"
          color="#333"
          borderRadius="0.5rem"
          height="2.5rem"
        />
        <DateInput
          borderRadius="8px"
          height="2.5rem"
          showTimeSelect
          dateFormat="dd/MM/yyyy HH:mm:ss"
          selected={formik.values.start}
          onChange={(value) =>
            formik.setFieldValue('start', moment(value).toDate())
          }
          locale={pt}
          customInput={<InputCustom mask="99/99/9999 99:99" />}
        />
        <DateInput
          borderRadius="8px"
          height="2.5rem"
          showTimeSelect
          dateFormat="dd/MM/yyyy HH:mm:ss"
          selected={formik.values.end}
          onChange={(value) =>
            formik.setFieldValue('end', moment(value).toDate())
          }
          locale={pt}
          customInput={<InputCustom mask="99/99/9999 99:99" />}
        />
        <SimpleButton type="submmit" height="h-10" margin="0" loading={loading}>
          {loading ? <AnimationLoading /> : 'Gerar'}
        </SimpleButton>
      </form>
    </HeaderFormContainer>
  );
}

export default FilterDaily;
