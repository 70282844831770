import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { MdCheck, MdClear } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../services/api'

import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import AvatarImage from '~/assets/avatar.png';
import { IconButton, SimpleButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  FormDataContainerDiv,
  DataContainer,
  ContainerTitle,
  Row,
  FieldContainer,
  ErrorMessage,
  Input,
  LabelStyled,
  SelectStyled,
} from '~/components/Global';
import { MaskedInput } from '~/components/inputs';
import Loading from '~/components/Loading';
import history from '~/services/history';
import { Creators as ResponsibleActions } from '~/store/ducks/responsibleSauce';

import { AvatarContainer, Avatar, ProfileContainer } from './styles';

function Forms({
  match: {
    params: { id: idParams },
  },
}) {
  /*
  	"name": "PAI 1",
	"client_id": 2,
	"user_id": 629,
	"phone": "(27)9 9821-5500",
	"email": "thiagosorio43@gmail.com",
	"cpf": "156.218.317-64"
  */


  const dispatch = useDispatch();
  const responsible = useSelector((state) => state.responsibleState.responsible);
  const loading = useSelector((state) => state.responsibleState.loading);
  const me = useSelector((state) => state.globalState.me);
  registerLocale('pt-BR', el);
  const [zoom, setZoom] = useState(1.0);
  const [responsibleImage] = useState(AvatarImage);
  const [editablePermission, setEditablePermission] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      status: 'Ativo',
      cpf: '',
    },
    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (!values.name) errors.name = message;
      if (!values.cpf) {
        errors.cpf = message;
      }
      // Colocar validação de CPF
      return errors;
    },

    name: '',
    email: '',
    phone: '',
    cpf: '',
    status: true,
    onSubmit: (values) => {
      console.log({values})
      const formData = new FormData();
      if (values.name) {
        formData.append('name', values.name);
      }
      if (values.email) {
        formData.append('email', values.email);
      }
      if (values.birthdate) {
        formData.append(
          'birthdate',
          moment(values.birthdate).format('YYYY-MM-DD')
        );
      }

      [
        'phone',
        'cpf',
        'cnh',
        'status',
        'password',
      ].forEach((key) => {
        if (values[key]) {
          formData.append(key, values[key]);
        } else {
          formData.delete(key);
        }
      });
      if (values.id) {
        dispatch(ResponsibleActions.updateResponsibleRequest(values, values.id));
      } else {
        dispatch(ResponsibleActions.createResponsibleRequest(values));
      }
    },
  });

  useEffect(() => {
    if (idParams) {
      dispatch(ResponsibleActions.fetchResponsibleRequest(`/${idParams}`));
    }
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  useEffect(() => {
    if (!_.isEmpty(responsible)) {
      const data = {
        ...responsible,
      };
      formik.setValues(data);
    }
  }, [responsible]);

  function cancelButton() {
    history.push('/responsible');
  }

  function onChangeZoom(e) {
    if (zoom > 3.5) {
      setZoom(3.5);
    } else if (zoom < 1.5) {
      setZoom(1.5);
    } else if (e.deltaY < 0) {
      setZoom(zoom + 0.5);
    } else {
      setZoom(zoom - 0.5);
    }
  }

  function getHeaderForm() {
    if (editablePermission) {
      if (loading) {
        return (
          <SimpleButton disabled width="w-44" height="h-8" type="button">
            Aguarde
          </SimpleButton>
        );
      }
      return (
        <div className="grid w-60 grid-cols-2 gap-2 h-8">
          <IconButton
            margin="0px"
            background="bg-red-600"
            Icon={MdClear}
            size={15}
            type="button"
            onClick={() => cancelButton()}
          >
            Cancelar
          </IconButton>
          <IconButton
            margin="0px"
            background="bg-gray-600 dark:bg-gray-400"
            Icon={MdCheck}
            size={15}
            type="submit"
          >
            Salvar
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton
        type="button"
        width="w-44"
        height="h-8"
        Icon={MdCheck}
        size={15}
        onClick={() => cancelButton()}
      >
        Voltar
      </IconButton>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de responsáveis">
          {getHeaderForm()}
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <FormDataContainerDiv>
              <DataContainer>
                <ProfileContainer>
                  <AvatarContainer>
                    <Avatar
                      borderRadius={10}
                      image={responsibleImage}
                      width={160}
                      height={160}
                      border={0}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={zoom}
                      onWheel={onChangeZoom}
                    />
                  </AvatarContainer>
                  <div>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Nome</LabelStyled>
                          {formik.touched.name && formik.errors.name ? (
                            <ErrorMessage>{formik.errors.name}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <Input
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={formik.touched.name && formik.errors.name}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>E-mail</LabelStyled>
                        </div>
                        <Input
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                    </Row>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Telefone</LabelStyled>
                          {formik.touched.phone && formik.errors.phone ? (
                            <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <MaskedInput
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          id="phone"
                          name="phone"
                          mask="(99) 9 9999-9999"
                          maskChar={null}
                          error={formik.touched.phone && formik.errors.phone}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                    </Row>
                  </div>
                </ProfileContainer>
                <ContainerTitle>DOCUMENTOS</ContainerTitle>
                <div>
                  <Row>
                    <FieldContainer>
                      <div>
                        <LabelStyled>CPF</LabelStyled>
                        {formik.touched.cpf && formik.errors.cpf ? (
                          <ErrorMessage>{formik.errors.cpf}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <MaskedInput
                        onChange={formik.handleChange}
                        value={formik.values.cpf}
                        id="cpf"
                        name="cpf"
                        mask="999.999.999-99"
                        maskChar={null}
                        error={formik.touched.cpf && formik.errors.cpf}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                    {formik.values.status !== '' && (
                      <FieldContainer>
                        <LabelStyled>Status</LabelStyled>
                        <SelectStyled
                          name="status"
                          defaultValue={{
                            label: formik.values.status ? 'Ativo' : 'Inativo',
                            value: true,
                          }}
                          onChange={(option) =>
                            formik.setFieldValue('status', option.value)
                          }
                          value={{
                            label: formik.values.status ? 'Ativo' : 'Inativo',
                            value: formik.values.status,
                          }}
                          options={[
                            { label: 'Ativo', value: true },
                            { label: 'Inativo', value: false },
                          ]}
                          isDisabled={!editablePermission}
                        />
                      </FieldContainer>
                    )}
                  </Row>
                  <Row>
                  </Row>
                </div>
              </DataContainer>
            </FormDataContainerDiv>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
