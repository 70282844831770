import { Form } from '@rocketseat/unform';
import React, { useState, useEffect } from 'react';
import {
  MdCameraAlt,
  MdEmail,
  MdCreditCard,
  MdPhone,
  MdCheck,
} from 'react-icons/md';

import api from '~/services/api';

import {
  Avatar,
  FileUpload,
  ProfileContainer,
  Side,
  Title,
  Subtitle,
  Input,
  Button,
  StyledContainer,
} from './styles';

export default function Profile() {
  const [, setEditor] = useState('http://example.com/initialimage.jpg');
  const [zoom, setZoom] = useState(2.0);
  const [user, setUser] = useState({});
  const [image, setImage] = useState('');

  function setEditorRef(editorParam) {
    setEditor(editorParam);
  }

  function changeImage(e) {
    setImage(e.target.files[0]);
  }

  function onChangeZoom(e) {
    if (zoom > 3.5) {
      setZoom(3.5);
    } else if (zoom < 1.5) {
      setZoom(1.5);
    } else if (e.deltaY < 0) {
      setZoom(zoom + 0.5);
    } else {
      setZoom(zoom - 0.5);
    }
  }
  async function getProfile() {
    const response = await api.getProfile();
    if (response.status === 200) {
      const { data } = response;
      setUser(data);
      setImage(`${process.env.REACT_APP_FILES_URL}/${data.image}`);
    }
  }
  useEffect(() => {
    getProfile();
  }, []);

  return (
    <StyledContainer>
      <div className="header" />
      <Side>
        <ProfileContainer>
          <Avatar
            ref={setEditorRef}
            borderRadius={100}
            image={image}
            width={250}
            height={250}
            border={0}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={zoom}
            onWheel={onChangeZoom}
          />
          <FileUpload>
            <input
              type="file"
              accept="image/*"
              name="upload"
              onChange={changeImage}
            />
            <MdCameraAlt size={25} color="#fff" />
          </FileUpload>
        </ProfileContainer>
        <div>
          <Form initialData={user}>
            <Title>{user.name}</Title>
            <Subtitle>
              <MdCreditCard color="#666" size={25} />
              <Input name="cpf" />
            </Subtitle>
            <Subtitle>
              <MdEmail color="#666" size={25} />
              <Input name="email" placeholder="E-mail" />
            </Subtitle>
            <Subtitle>
              <MdPhone color="#666" size={25} />
              <Input name="phone" placeholder="Telefone" />
            </Subtitle>
            <Button color="#227ad7">
              <MdCheck size={17} />
              <span>Salvar</span>
            </Button>
          </Form>
        </div>

        {/* <InputStyled name="user" /> */}
      </Side>
    </StyledContainer>
  );
}
