import React from 'react';
import DatePicker from 'react-datepicker';

import styled from 'styled-components';

const DateStyle = styled(DatePicker)`
  height: ${({ height }) => height || '40px'};
  margin: ${({ margin }) => margin || '5px auto'};
  width: 100%;
  font-size: 16px;
  background: ${({ background, error }) =>
    error ? 'rgba(255,0,0,0.2)' : background || 'rgba(0, 0, 0, 0.01)'};
  color: ${({ color, error }) => {
    if (error) return 'rgba(255,0,0,1)';
    if (color) return color;
    return '#333';
  }};
  border-radius: ${({ borderRadius }) => {
    return `${borderRadius} !important` || '3px !important';
  }};
  padding-left: 5px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 300 !important;
  border: 1px solid ${({ error }) => (error ? 'rgba(257,0,0, 1)' : '#ddd')};

  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  :focus {
    border: 1px solid #245378 !important;
  }
  :hover {
    border: 1px solid #245378 !important;
  }
`;

const Container = styled.div`
  width: 100%;
  .react-datepicker-popper {
    z-index: 9999;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

function Date({ ...props }) {
  return (
    <Container>
      <DateStyle {...props} />
    </Container>
  );
}

export default Date;
