import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  forgotRequest: ['data'],
  forgotSuccess: [],
  forgotFailure: [],
  updateRequest: ['data'],
  updateSuccess: [],
});

const INITIAL_STATE = {
  loading: false,
  forgot: {},
  pass: {},
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  forgot: action.payload,
});

const updateSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  pass: action.payload,
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  forgot: null,
  pass: null,
});

export default createReducer(INITIAL_STATE, {
  [Types.FORGOT_REQUEST]: request,
  [Types.UPDATE_REQUEST]: updateRequest,
  [Types.FORGOT_SUCCESS]: success,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.FORGOT_FAILURE]: failure,
});
