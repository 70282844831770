import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '~/services/api';

import { Types } from '../ducks/globalSauce';

export function* meSaga(payload) {
  try {
    const response = yield call(Api.fetchMe, payload.data);
    yield put({ type: Types.GLOBAL_SUCCESS, payload: response.data });
  } catch (error) {
    if (error.data.error) toast.error(error.data.error);

    yield put({ type: Types.GLOBAL_FAILURE });
  }
}

export default function* rootSaga() {
  yield takeLatest(Types.GLOBAL_REQUEST, meSaga);
}
