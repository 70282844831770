import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import {
  Container,
  ButtonStyled,
  InputStyled,
  LinkStyled,
} from '~/components/Global';
import Mask from '~/util/Mask';
import 'react-datepicker/dist/react-datepicker.css';

export const ContainerFlex = styled.div`
  display: flex;
`;

export const ButtonModal = styled(ButtonStyled)`
  width: 100px;
  height: 30px;
  margin: 0;
  font-weight: 600;
  :last-child {
    margin-left: 10px;
  }
`;

export const Map = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const ContainerStyled = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas: 'main map';
  grid-gap: 10px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: 'main' 'map';
  }
`;

export const FilterContainer = styled.div`
  grid-area: main;
  /* background: #3a40d3; */
  /* border-radius: 5px 0 0 5px; */
  padding-top: 5px;
  border-radius: 5px;
  background-color: #fff;
  grid-template-rows: 30.4px 1fr;
  grid-template-areas: 'header' 'table';
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const TableContainer = styled.div`
  grid-area: table;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const MapContainer = styled.div`
  grid-area: map;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const ContainerFilters = styled.div`
  /* padding: 10px; */
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  display: grid;
  grid-template-rows: 30.4px 1fr;
  height: 100%;
  grid-template-areas: 'header' 'filters';
`;

export const ContainerFormStyled = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas: 'filter table';
  grid-gap: 10px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: 'filter' 'table';
  }
`;

export const FiltersContainer = styled.div`
  grid-area: filters;
`;

export const FormDataContainer = styled.div`
  grid-area: form;
`;

export const FormContainer = styled.div`
  grid-area: filter / filter / table / table;
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-areas: 'tab' 'detail';
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;
export const FormForm = styled.form`
  /* padding: 10px; */
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  display: grid;
  height: 100%;
  /* 30.4px  */
  grid-template-rows: 1fr 40px;
  grid-template-areas: 'form' 'buttons';
`;

export const FormFilter = styled.form`
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  /* padding-bottom: 10px; */
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 40px;
  grid-template-areas: 'filters' 'buttons';
`;

export const FormData = styled.div`
  background: #f2f1f0;
  border-radius: 0 7px 7px 7px;
  grid-area: detail;
  height: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: 1fr 40px;
  grid-template-areas: 'form' 'buttons';
  > div {
    padding: 5px;
    background: #ededed;
    border-radius: 0 0 7px 7px;
  }
`;
export const DataContainer = styled.div`
  grid-area: form;
`;

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
`;

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px;
  grid-gap: 20px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 3px;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  resize: none;
  background: #fff;
  margin-bottom: 10px;
`;

export const ContainerTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #605f5f;
  margin-bottom: 10px;
  margin-top: 5px;
`;

export const DateStyle = styled(DatePicker)`
  height: 33.2px;
  border: 1px solid #dbdbdb;
  width: 100%;
  border-radius: 3px;
  padding-left: 5px;
  color: #999;
  font-size: 14px;
`;

export const HeaderTitle = styled.div`
  grid-area: header;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const FilterHeader = styled.div`
  border-radius: 5px 5px 0 0;
  background: #245378;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-left: 5px;
  font-weight: 600;
  color: #fff;
  height: 100%;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  @media screen and (max-width: 700px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const Overlay = css`
  background: #000;
`;

export const OptionPlaceholder = styled.option`
  background-color: #ccc;
`;

export const Select = styled.select`
  width: 100%;
  border: none;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  background: ${(props) => (props.error ? '#fcd7d7' : '#fff')};
  flex: 0 1 100%;
  /* border: solid 1px #dbdbdb; */
  border-radius: 3px;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 10px;
  display: block;
  height: 40px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
`;

export const ModalHeader = styled.div`
  /* width: 300px; */
  height: 40px;
  /* background: #d21212; */
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ModalDetail = styled.div`
  height: 100px;
  width: 300px;
  font-size: 15px;
  padding: 10px;
  width: 300px;
`;

export const ModalButtons = styled.div`
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
`;

export const ButtonFenceModal = styled(ButtonStyled)`
  width: 50px;
  height: 30px;
  margin: 0;
  font-weight: 600;
  :last-child {
    margin-left: 10px;
  }
`;

export const Main = styled.div`
  display: grid;

  justify-content: center;
  align-content: center;

  form {
    width: 300px;
    display: flex;
    flex-flow: row wrap;
  }

  h3 {
    margin-bottom: 15px;
  }
`;

export const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #333;
  border-radius: 5px 0 0 5px;
  height: 100%;
  form {
    width: 80%;
  }
`;

export const Information = styled.div`
  background: #245378;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputMask = styled(Mask)`
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  transition: 0.3s;
  border-bottom: 2px solid #dbdbdb;
  :hover {
    background: #27373b;
  }
  ::placeholder {
    color: #dbdbdb;
    font-weight: 600;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    font-weight: 600;
    border-bottom: 2px solid #dbdbdb;
    -webkit-text-fill-color: #dbdbdb;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Input = styled(InputStyled)`
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  transition: 0.3s;
  border-bottom: 2px solid #dbdbdb;
  :hover {
    background: #27373b;
  }
  ::placeholder {
    color: #dbdbdb;
    font-weight: 600;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    font-weight: 600;
    border-bottom: 2px solid #dbdbdb;
    -webkit-text-fill-color: #dbdbdb;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Title = styled.div`
  color: #dbdbdb;
  font-size: 30px;
  font-weight: 600;
`;

export const Error = styled.span`
  margin-top: 15px;
  color: #d33d39;
`;

export const ProfilePasswordContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'data';
`;

export const Link = styled(LinkStyled)`
  width: 100%;
  :hover {
    color: #dbdbdb;
  }
`;

export const ImageContainer = styled.div`
  > img {
    width: 700px;
    height: 700px;
    transition: 0.2s;
    @media screen and (max-height: 800px) {
      width: 500px;
      height: 500px;
    }
    @media screen and (max-width: 1200px) {
      width: 500px;
      height: 500px;
    }
    @media screen and (max-width: 1100px) {
      width: 400px;
      height: 400px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  img {
    height: 40px;
    width: 22px;
    border-bottom: 2px solid #dbdbdb;
  }
`;
