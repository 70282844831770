import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { AlertContainer, Alerts, NotAlert } from './styles';

export default function Daily() {
  const alerts = useSelector((state) => state.dailyState.alerts);
  return (
    <AlertContainer>
      <Alerts>
        <div>Alertas</div>
        {!_.isEmpty(alerts) ? (
          alerts.map((alert) => (
            <div>
              <div>{alert.count}</div>
              <span>{alert.type}</span>
            </div>
          ))
        ) : (
          <NotAlert>Nenhum alerta pra exibir</NotAlert>
        )}
      </Alerts>
    </AlertContainer>
  );
}
