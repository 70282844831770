import React from 'react';
import PerfectScroll from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';
import moment from 'moment';

import PropTypes from 'prop-types';

import LoadingAnimation from '~/components/Loading';

import CarItem from './CarItem';
import { Triangle } from './styles';
import verifyStatus from './veriyStatus';

function Car({ filter, filterType } = { filter: null, filterType: null }) {
  const me = useSelector((state) => state.globalState.me);
  const { buffer, loading, filterActive } = useSelector(
    ({ bufferState }) => bufferState
  );

  function applyFilter(car) {
    if (!car || !filter) return true;
    const _filter = filter.toUpperCase();
    let hours;
    let minutes;
    let totalMinutes;
    if (['DISCONNECTED', 'OFF'].includes(car.status)) {
      totalMinutes = moment().diff(moment(car.last_time_on), 'minutes');
      hours = Math.floor(totalMinutes / 60);
      minutes = totalMinutes % 60;
    } else {
      totalMinutes = moment().diff(moment(car.date), 'minutes');
      hours = Math.floor(totalMinutes / 60);
      minutes = totalMinutes % 60;
    }
    return (
      (filterType === 'Placa/Identificador' &&
        car.plate &&
        car.plate.toUpperCase().includes(_filter)) ||
      (filterType === 'Placa/Identificador' &&
        car.label &&
        car.label.toUpperCase().includes(_filter)) ||
      (filterType === 'Placa/Identificador' &&
        car.driver &&
        car.driver.toUpperCase().includes(_filter)) ||
      (filterType === 'Velocidade' &&
        car.speed &&
        car.speed >= Number(_filter)) ||
      (filterType === 'Tempo Offline/Parado' &&
        (car.last_time_on || car.date) &&
        `${hours}h:${minutes}m`.toUpperCase().includes(_filter))
    );
  }

  function getBufferLenght() {
    const _buffer = buffer.filter(applyFilter);

    if (_buffer.length !== 0 && !me.isDefaulter) {
      return _buffer.map((item) => (
        <CarItem
          {...item}
          key={item.tracker_id}
          battery={item.power_voltage}
          driver={item.driver || ''}
          speed={item.speed || 0}
          last_time_on={item.last_time_on || ''}
        />
      ));
    }
    return (
      <div className="h-10 bg-gray-100 flex items-center justify-center">
        <Triangle />
        Nenhum registro {verifyStatus(filterActive)}
      </div>
    );
  }
  return (
    <PerfectScroll>
      {loading ? <LoadingAnimation /> : getBufferLenght()}
    </PerfectScroll>
  );
}

Car.propTypes = {
  filter: PropTypes.string,
  filterType: PropTypes.string,
};

Car.defaultProps = {
  filter: '',
};

export default Car;
