import React from 'react';

import PropTypes from 'prop-types';

import { SelectField } from '~/components/fields';

import { Container } from './styles';
import translateConfigurationFence from './translateConfigurationFence';

function ConfigHeader({ data, formik, index }) {
  return (
    <Container>
      <SelectField
        label="TIPO"
        onChange={(option) =>
          formik.setFieldValue(`fences[${index}].typeFence`, option.value)
        }
        defaultValue={{
          name: data.typeFence ? 'Inclusão' : 'Exclusão',
          value: data.typeFence,
        }}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.name}
        options={[
          { name: 'Inclusão', value: true },
          { name: 'Exclusão', value: false },
        ]}
        placeholder="Selecione o tipo"
        // error={props.error}
        // isDisabled={props.isDisabled}
      />
      <SelectField
        label="CONFIGURAÇÕES"
        onChange={(option) => {
          formik.setFieldValue(
            `fences[${index}].configurationFence`,
            option.value
          );
        }}
        defaultValue={{
          name: translateConfigurationFence(data),
          value: data.configurationFence,
        }}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.name}
        options={[
          {
            name: 'Sempre ativa',
            value: 'always',
          },
          {
            name: 'Semanalmente',
            value: 'weekly',
          },
          {
            name: 'Apenas uma vez',
            value: 'once',
          },
        ]}
        placeholder="Selecione a configuração"
        // error={props.error}
        // isDisabled={props.isDisabled}
      />
    </Container>
  );
}

ConfigHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  formik: PropTypes.objectOf(PropTypes.object).isRequired,
  index: PropTypes.string.isRequired,
};

export default ConfigHeader;
