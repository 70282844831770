import React, { useEffect, useState } from 'react';

import { parse, format, isBefore, isEqual } from 'date-fns';
import PropTypes from 'prop-types';

import api from '~/services/axios';

import ViewPublication from './ViewPublication';

function News({ height }) {
  const [news, setNews] = useState([]);
  const [isViewPublication, setIsViewPublication] = useState(false);
  const [publication, setPublication] = useState({});
  async function getNews() {
    try {
      let { data } = await api.get('/news');
      // console.log({ data });
      data = data.map((item) => ({
        ...item,
        date: format(parse(item.date, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
      }));
      // console.log(data);
      setNews(data);
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    getNews();
  }, []);

  function viewPucliation(data) {
    if (publication.id === data.id) {
      setPublication({});
      setIsViewPublication(false);
    } else {
      setPublication(data);
      setIsViewPublication(true);
    }
  }

  return (
    <div
      style={{ height: `${height}px` }}
      className={isViewPublication && 'grid grid-cols-2 gap-2 '}
    >
      <div className="flex flex-col overflow-auto">
        {news
          .filter(({ public: isPublic }) => isPublic)
          .sort((a, b) => {
            const dateA = parse(a.date, 'dd/MM/yyyy', new Date());
            const dateB = parse(b.date, 'dd/MM/yyyy', new Date());

            if (isBefore(dateA, dateB)) return 1;
            if (isEqual(dateA, dateB)) return a.title > b.title ? 1 : -1;
            return -1;
          })
          .map((item) => (
            <button
              type="button"
              className={`${
                item.id === publication.id ? 'bg-gray-100' : 'bg-white'
              } shadow rounded-lg text-left my-1 p-2 hover:bg-gray-200 h-16`}
              onClick={() => viewPucliation(item)}
            >
              <div className="text-base font-bold">{item.title}</div>
              <div>
                Publicado:
                {item.date}
              </div>
            </button>
          ))}
      </div>
      {isViewPublication && <ViewPublication publication={publication} />}
    </div>
  );
}

News.propTypes = {
  height: PropTypes.number.isRequired,
};

export default News;
