import React from 'react';
import { useDispatch } from 'react-redux';

import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';

import {
  SelectField,
  DateField,
  MaskedField,
  SimpleField,
} from '~/components/fields';
import { MaskedInput } from '~/components/inputs';
import { Creators as ReportsActions } from '~/store/ducks/reportSauce';
import reportsOptions from '~/util/reportsOptions';
import translateWeek from '~/util/translateDayWeek';

import weekDays from '../weekDays.json';

function Reports({ keyUpdate, formik }) {
  const dispatch = useDispatch();

  return (
    <>
      <SimpleField
        label="E-mail do destinatário:"
        id="owner"
        onChange={({ target: { value } }) =>
          formik.setFieldValue('owner', value)
        }
        value={formik.values.owner}
        placeholder='separar por "," cada e-mail'
      />
      <SelectField
        key={keyUpdate}
        label="Relatório"
        placeholder="Selecione um relatório"
        noOptionsMessage="Nenhum relatório encontrado"
        getOptionValue={({ id }) => id}
        getOptionLabel={({ name }) => name}
        options={reportsOptions.filter(
          ({ key }) =>
            key === 'speed' || key === 'movWeekEnd' || key === 'movOutHour'
        )}
        isDisabled={formik.values.id}
        defaultValue={
          formik.values.id &&
          reportsOptions.find(({ key }) => key === formik.values.type)
        }
        onChange={(data) => {
          formik.setFieldValue('type', data.key);
          dispatch(ReportsActions.setReport(data));
        }}
        error={formik.touched.type && formik.errors.type}
        errorMessage={formik.errors.type}
      />

      {formik.values.schedule.type === 'once' && (
        <DateField
          borderRadius="10px"
          showTimeSelect
          dateFormat="dd/MM/yyyy HH:mm:ss"
          label="Dia do agendamento"
          selected={formik.values.schedule.date}
          onChange={(value) => formik.setFieldValue('schedule.date', value)}
          locale={pt}
          customInput={<MaskedInput mask="99/99/9999 99:99" />}
          error={formik.errors.date}
          errorMessage={formik.errors.date}
        />
      )}
      {formik.values.schedule.type === 'weekly' && (
        <div className="grid grid-cols-2 w-full items-center">
          <div className="h-full grid grid-rows-7 gap-2">
            {weekDays.map(({ name, week }) => (
              <label htmlFor={name}>
                <input
                  onChange={({ target: { value: valueType } }) => {
                    formik.setFieldValue('schedule.week', Number(valueType));
                  }}
                  id={name}
                  value={week}
                  checked={formik.values.schedule.week === week}
                  name="weekOptions"
                  type="radio"
                />
                <span className="mx-2">{translateWeek(week)}</span>
              </label>
            ))}
          </div>
          <MaskedField
            label="Hora do recebimento:"
            mask="99:99"
            value={formik.values.schedule.time}
            onChange={(e) =>
              formik.setFieldValue(`schedule.time`, e.target.value)
            }
          />
        </div>
      )}
    </>
  );
}

Reports.propTypes = {
  formik: PropTypes.objectOf(PropTypes.string, PropTypes.number, PropTypes.func)
    .isRequired,
  keyUpdate: PropTypes.number.isRequired,
};

export default Reports;
