import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchDriverRequest: ['query', 'params'],
  fetchDriverSuccess: ['data'],
  fetchDriverIdSuccess: ['data'],
  fetchDriverFailure: [],
  updateDriverRequest: ['data', 'id'],
  deleteDriverRequest: ['id'],
  createDriverRequest: ['data'],
  clearDriver: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  drivers: [],
  driver: {},
  driverByTracker: {},
  loading: false,
  page: 1,
  pageSize: 2,
  params: '',
};

const clearDriver = (state = INITIAL_STATE) => ({
  ...state,
  driver: {},
});

const fetchDriverRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  drivers: [],
});

const fetchDriverSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  drivers: actions.data,
  loading: false,
});

const fetchDriverIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    driver: actions.data,
    loading: false,
  };
};

const fetchDriverFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    drivers: [],
  };
};

const createDriverRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateDriverRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DRIVER_REQUEST]: fetchDriverRequest,
  [Types.FETCH_DRIVER_SUCCESS]: fetchDriverSuccess,
  [Types.FETCH_DRIVER_ID_SUCCESS]: fetchDriverIdSuccess,
  [Types.FETCH_DRIVER_FAILURE]: fetchDriverFailure,
  [Types.UPDATE_DRIVER_REQUEST]: updateDriverRequest,
  [Types.CREATE_DRIVER_REQUEST]: createDriverRequest,
  [Types.CLEAR_DRIVER]: clearDriver,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
