import React, { useEffect, useState } from 'react';
import PerfectScroll from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDaysInMonth,
  format,
  startOfMonth,
  getMonth,
  set,
  isSameDay,
} from 'date-fns';

import { Creators as schedulesActions } from '~/store/ducks/schedulesSauce';

import Header from './Header';
import OnceType from './OnceType';
import WeeklyType from './WeeklyType';
import WeeksHeader from './WeeksHeader';

function Calendar() {
  const dispatch = useDispatch();
  const schedules = useSelector(
    ({ schedulesState }) => schedulesState.schedules
  );
  const [daysInMonth, setDaysInMonth] = useState(0);

  const [month, setMonth] = useState(new Date().getMonth());
  const [monthNow, setMonthNow] = useState(new Date().setMonth(month));
  const [startDayMonth, setStartDayMonth] = useState();

  useEffect(() => {
    setMonthNow(new Date().setMonth(month));
  }, [month]);

  useEffect(() => {
    setDaysInMonth(getDaysInMonth(monthNow));
    setStartDayMonth(format(startOfMonth(monthNow), 'e') - 1);
  }, [month, monthNow]);

  useEffect(() => {
    dispatch(schedulesActions.fetchSchedulesRequest());
  }, []);

  return (
    <div className="w-full h-full p-4 bg-white rounded-lg shadow">
      <Header month={month} setMonth={setMonth} monthNow={monthNow} />
      <WeeksHeader />
      <div className="grid grid-cols-7 ">
        {Array.from({ length: startDayMonth }, () => null).map((_) => (
          <div className="h-28 w-full bg-lightBlue-100 " />
        ))}
        {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => (
          <div
            className={`
            h-28

          ${
            isSameDay(
              set(new Date(), { month: getMonth(monthNow), date: day }),
              new Date()
            )
              ? 'border-2 border-geo-900 '
              : 'border border-blue-100 '
          }
          bg-lightBlue-50
          `}
          >
            <div className="h-7 flex items-center pl-1 text-geo-900 text-lg">
              {day}
            </div>
            <div className="h-20 px-1 overflow-auto">
              <PerfectScroll style={{ paddingRight: '12px' }}>
                {schedules.map((data) => {
                  return (
                    <>
                      <OnceType data={data} day={day} monthNow={monthNow} />
                      <WeeklyType data={data} day={day} monthNow={monthNow} />
                    </>
                  );
                })}
              </PerfectScroll>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Calendar;
