import styled from 'styled-components';

import { InputStyled, LinkStyled } from '~/components/Global';
import Mask from '~/util/Mask';

export const Container = styled.div`
  display: grid;
  height: 100%;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
  grid-column-start: header;
  grid-column-end: header;
  grid-row-start: header;
  grid-row-end: side;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'login information';
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Main = styled.div`
  display: grid;

  justify-content: center;
  align-content: center;

  form {
    width: 300px;
    display: flex;
    flex-flow: row wrap;
  }

  h3 {
    margin-bottom: 15px;
  }
`;

export const Form = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #333;
  border-radius: 5px 0 0 5px;
  form {
    width: 80%;
  }
`;

export const Information = styled.div`
  background: #245378;
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputMask = styled(Mask)`
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  transition: 0.3s;
  color: #dbdbdb;
  border-bottom: 2px solid #dbdbdb;
  :hover {
    background: #27373b;
  }
  ::placeholder {
    color: #dbdbdb;
    font-weight: 600;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    font-weight: 600;
    border-bottom: 2px solid #dbdbdb;
    -webkit-text-fill-color: #dbdbdb;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Input = styled(InputStyled)`
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 0;
  transition: 0.3s;
  color: #dbdbdb !important;
  border-bottom: 2px solid #dbdbdb;
  :hover {
    background: #27373b;
  }
  ::placeholder {
    color: #dbdbdb;
    font-weight: 600;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    background: transparent;
    font-weight: 600;
    border-bottom: 2px solid #dbdbdb;
    -webkit-text-fill-color: #dbdbdb;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Title = styled.div`
  color: #dbdbdb;
  font-size: 30px;
  font-weight: 600;
`;

export const Error = styled.span`
  margin-top: 15px;
  color: #d33d39;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Link = styled(LinkStyled)`
  width: 100%;
  :hover {
    color: #dbdbdb;
  }
`;

export const ImageContainer = styled.div`
  > img {
    width: 700px;
    height: 700px;
    transition: 0.2s;
    @media screen and (max-height: 800px) {
      width: 500px;
      height: 500px;
    }
    @media screen and (max-width: 1200px) {
      width: 500px;
      height: 500px;
    }
    @media screen and (max-width: 1100px) {
      width: 400px;
      height: 400px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  img {
    height: 40px;
    width: 22px;
    border-bottom: 2px solid #dbdbdb;
  }
`;
