import JsPDF from 'jspdf';

import 'jspdf-autotable';
import _ from 'lodash';
import moment from 'moment';

import logoImage from '~/components/Table/assets/base64Logo';

export default function Map({
  imgMap,
  statics,
  filters,
  journey,
  stops,
  history,
  alerts,
}) {
  const stopsFormated = [];

  function getStopsTitle({ odometer, index }) {
    if (!_.isEmpty(stops)) {
      if (!odometer) {
        return 'N/D';
      }
      if (stops[index + 1] && stops[index + 1].odometer) {
        return `percorreu ${(odometer - stops[index + 1].odometer).toFixed(1)}`;
      }
      return 'N/D';
    }
    return 'N/D';
  }

  function getSpeedText({ speed, ignition }) {
    if (speed) {
      return `${speed.toFixed(0)} Km/h`;
    }
    if (ignition) {
      return '0 Km/h';
    }
    return '-';
  }

  stops.forEach((item, index) => {
    if (item.event === 'OFF') {
      stopsFormated.push([
        moment(item.date).format('DD/MM/YYYY HH:mm:ss'),
        `${getStopsTitle({ odometer: item.odometer, index })} km`,
        item.address,
      ]);
    }
  });

  const doc = new JsPDF();
  const header = (text, data) => {
    if (data.pageNumber === 1) {
      doc.setFontSize(12);
      doc.setTextColor('#245378');
      // doc.addImage(headerImgData, 'JPEG', data.settings.margin.left, 20, 50, 50);
      doc.text(
        text,
        doc.internal.pageSize.width / 2,
        data.settings.startY - 2,
        'center'
      );
      // doc.text(text, data.settings.margin.left + 75, data.cursor.y - 2);C
    }
  };
  doc.addImage(logoImage(), 'JPEG', 15, 10, 30, 15);
  doc.setFontSize(12);
  doc.text('Diário sobre Mapa', 196, 15, 'right');
  doc.setFontSize(8);
  doc.text(moment().format('DD/MM/YYYY HH:mm'), 196, 25, 'right');
  doc.line(15, 26, 196, 26);
  doc.setFontSize(10);
  doc.text(
    `Filtros: ${filters.tracker} de ${filters.start} à ${filters.end}`,
    15,
    31
  );
  doc.line(15, 33, 196, 33);
  doc.addImage(imgMap, 'PNG', 15, 35, 181, 100);
  doc.setFontSize(12);
  doc.setTextColor('#245378');
  doc.text('ESTATÍSTICAS', doc.internal.pageSize.width / 2, 142, 'center');
  doc.setFillColor('#f4f8f9');
  doc.rect(16, 145, 28, 28, 'F');
  doc.rect(46, 145, 28, 28, 'F');
  doc.rect(76, 145, 28, 28, 'F');
  doc.rect(106, 145, 28, 28, 'F');
  doc.rect(136, 145, 28, 28, 'F');
  doc.rect(166, 145, 28, 28, 'F');
  doc.setFontSize(15);
  doc.text(statics.max ? statics.max.toFixed(0) : '-', 30, 160, 'center');
  doc.text(
    statics.avg ? parseFloat(statics.avg).toFixed(0) : '-',
    60,
    160,
    'center'
  );
  doc.text(
    statics.distance ? (statics.distance / 1000).toFixed(0) : '-',
    90,
    160,
    'center'
  );
  doc.text(
    statics.liters ? statics.liters.toFixed(2) : '-',
    120,
    160,
    'center'
  );
  doc.text(
    statics.fuelprice ? statics.fuelprice.toFixed(2) : '-',
    150,
    160,
    'center'
  );
  doc.text(statics.total ? statics.total.toFixed(2) : '-', 180, 160, 'center');
  doc.setFontSize(8);
  doc.text('Velocidade Máxima', 30, 165, 'center');
  doc.text('Velocidade Média', 60, 165, 'center');
  doc.text('Distância (aprox)', 90, 165, 'center');
  doc.text('Litros (aprox)', 120, 165, 'center');
  doc.text('Preço por Litro', 150, 165, 'center');
  doc.text('Total Gasto (aprox)', 180, 165, 'center');
  doc.autoTable({
    margin: { top: 180 },
    theme: 'striped',
    styles: {
      font: 'Roboto | helvetica | times | courier',
    },
    headStyles: {
      fillColor: '#245378',
      fontSize: 12,
    },
    head: [['Nome', 'Tempo jornada']],
    body: _.isEmpty(journey)
      ? [
          [
            {
              content: 'Sem registros para exibir',
              colSpan: 5,
              styles: { halign: 'center' },
            },
          ],
        ]
      : journey.map((item) => [item.name, item.total]),
    didDrawPage: (data) => header('JORNADAS', data),
  });
  doc.autoTable({
    theme: 'striped',
    styles: {
      font: 'Roboto | helvetica | times | courier',
    },
    headStyles: {
      fillColor: '#245378',
      fontSize: 12,
    },
    head: [['Momento', 'Distância', 'Endereço']],
    body: _.isEmpty(stopsFormated)
      ? [
          [
            {
              content: 'Sem registros para exibir',
              colSpan: 5,
              styles: { halign: 'center' },
            },
          ],
        ]
      : stopsFormated,
    didDrawPage: (data) => header('PARADAS', data),
  });
  doc.autoTable({
    theme: 'striped',
    styles: {
      font: 'Roboto | helvetica | times | courier',
    },
    headStyles: {
      fillColor: '#245378',
      fontSize: 12,
    },
    head: [['Alerta', 'Quantidade']],
    body: _.isEmpty(alerts)
      ? [
          [
            {
              content: 'Sem registros para exibir',
              colSpan: 5,
              styles: { halign: 'center' },
            },
          ],
        ]
      : alerts.map((item) => [item.type, item.count]),
    didDrawPage: (data) => header('ALERTAS', data),
  });
  doc.addPage();
  doc.autoTable({
    theme: 'striped',
    styles: {
      font: 'Roboto | helvetica | times | courier',
    },
    headStyles: {
      fillColor: '#245378',
      fontSize: 12,
    },
    head: [['Momento', 'Iginição', 'Velocidade', 'Endereço']],
    body: history.map(({ date, ignition, speed, address }) => [
      moment.utc(date).add(-3, 'hours').format('DD/MM HH:mm:ss'),
      ignition ? 'Ligado' : 'Desligado',
      getSpeedText({ speed, ignition }),
      address,
    ]),
    didDrawPage: (data) => header('HISTÓRICO', data),
  });
  doc.save('Diário sobre Mapa');
}
