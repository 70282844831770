import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import api from '~/services/api';

import { SelectStyled, LabelStyled, ErrorMessage } from '../index';
import { LoadingContainer } from './styles';

export default function SelectTracker({
  error,
  parentCallback,
  isDisabled,
  value,
  defaultValue,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getTrackers() {
    const response = await api.fetchTracker('?idPlateOnly=true');
    // const response = await api.fetchTracker();
    setOptions(response.data);
    setLoading(true);
  }

  useEffect(() => {
    getTrackers();
  }, []);

  return (
    <>
      <div>
        <LabelStyled>Veículo</LabelStyled>
        {error ? <ErrorMessage>Campo obrigatório</ErrorMessage> : <></>}
      </div>
      {loading ? (
        <SelectStyled
          onChange={(option) => parentCallback(option)}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.plate}
          defaultValue={
            options[options.findIndex((item) => item.id === defaultValue)]
          }
          options={options}
          placeholder="Selecione o veículo"
          error={error}
          isDisabled={isDisabled}
          value={
            value ? options[options.findIndex((item) => item.id === value)] : ''
          }
        />
      ) : (
        <LoadingContainer>Carregando...</LoadingContainer>
      )}
    </>
  );
}

SelectTracker.propTypes = {
  error: PropTypes.string.isRequired,
  parentCallback: PropTypes.func.isRequired,
  isDisabled: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};
