import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import alertReducer from './alertSauce';
import auditReducer from './auditSauce';
import authReducer from './authSauce';
// import panelReducer from "./panel";
import bufferReducer from './bufferSauce';
import chartReducer from './chartSauce';
import commandReducer from './commandSauce';
import dailyReducer from './dailySauce';
import driverReducer from './driverSauce';
import responsibleReducer from './responsibleSauce';
import fenceReducer from './fenceSauce';
import forgotReducer from './forgotSauce';
import globalReducer from './globalSauce';
import journeyReducer from './journeySauce';
import maintenanceReducer from './maintenanceSauce';
import preventiveReducer from './preventiveSauce';
import reportReducer from './reportSauce';
import routeReducer from './routeSauce';
import schedulesReducer from './schedulesSauce';
import supplyReducer from './supplySauce';
import trackerReducer from './trackerSauce';
import violationReducer from './violationSauce';
import worksheetReducer from './worksheetSauce';
import passengerSauce from './passengerSauce';
import preJourneyReducer from './preJourneySauce';
import journeyRouteReducer from './journeyRouteSauce';

// import history from "../../routes/history";

const rootReducer = (history) =>
  combineReducers({
    authState: authReducer,
    // panelState: panelReducer,
    bufferState: bufferReducer,
    chartState: chartReducer,
    supplyState: supplyReducer,
    maintenanceState: maintenanceReducer,
    preventiveState: preventiveReducer,
    violationState: violationReducer,
    driverState: driverReducer,
    responsibleState: responsibleReducer,
    journeyState: journeyReducer,
    preJourneyState: preJourneyReducer,
    trackerState: trackerReducer,
    commandState: commandReducer,
    fenceState: fenceReducer,
    journeyRouteState: journeyRouteReducer,
    routeState: routeReducer,
    dailyState: dailyReducer,
    reportState: reportReducer,
    worksheetState: worksheetReducer,
    alertState: alertReducer,
    globalState: globalReducer,
    forgotState: forgotReducer,
    auditState: auditReducer,
    passengerState: passengerSauce,
    schedulesState: schedulesReducer,
    router: connectRouter(history),
  });

export default rootReducer;
