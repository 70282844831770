import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import format from 'date-fns/format';
import getUnixTime from 'date-fns/getUnixTime';
import set from 'date-fns/set';
import { useFormik } from 'formik';
import { sortBy } from 'lodash';

import { SimpleButton } from '~/components/buttons';
import { SimpleContainer } from '~/components/containers';
import ReportFields from '~/components/ReportFields';
import { SimpleSelect } from '~/components/selects';
import { Creators as ReportsActions } from '~/store/ducks/reportSauce';
import reportsOptions from '~/util/reportsOptions';

import { Form, ContainerField } from './styles';

function Filters() {
  const dispatch = useDispatch();
  const {
    origin: reportOrigin,
    report: { getUrl, name: NameReport },
  } = useSelector(({ reportState }) => ({
    origin: reportState.report,
    report: reportState.report,
  }));

  const formik = useFormik({
    initialValues: {
      tracker_id: '',
      days: '',
      cars: [],
      typeCars: 'cars',
      group: null,
      start: set(new Date(), { hours: 0, minutes: 0, seconds: 0 }),
      finish: set(new Date(), { hours: 23, minutes: 59, seconds: 59 }),
      fenceId: '',
      userId: '',
      driverId: '',
    },
    onSubmit: ({
      start,
      finish,
      tracker_id,
      days,
      group,
      cars,
      fenceId,
      userId,
      driverId,
    }) => {
      const params = {
        start: getUnixTime(start),
        finish: getUnixTime(finish),
        tracker_id,
        days,
        group,
        cars,
        fenceId,
        userId,
        driverId,
      };
      dispatch(ReportsActions.setParams(params));
      dispatch(ReportsActions.fetchReportRequest(getUrl, params));
    },
  });

  return (
    <SimpleContainer>
      <Form onSubmit={formik.handleSubmit}>
        {!NameReport && (
          <div>
            <h1 style={{ marginBottom: '10px', textAlign: 'center' }}>
              <strong>ESCOLHA UMA OPÇÃO</strong>
            </h1>
            {sortBy(reportsOptions, (i) => i.name)
              // TODO terminar de implementar o relatorio excesso de velocidade e movimentacao final de semana
              .filter(({ key }) => key !== 'movWeekEnd')
              .map((report) => {
                return (
                  <SimpleButton
                    style={{
                      height: 'auto',
                      cursor: 'pointer',
                      padding: '5px 0px 5px 8px',
                      margin: '0px 0px 6px 0px',
                      textAlign: 'left',
                      fontSize: 'medium',
                      backgroundColor: 'rgb(245, 245, 245)',
                      color: 'rgb(51, 51, 51)',
                    }}
                    key={`_report_item_${report.name}`}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      dispatch(ReportsActions.setReport(report));
                    }}
                  >
                    {report.name}
                  </SimpleButton>
                );
              })}
          </div>
        )}

        {NameReport && (
          <>
            <header className="text-xl font-medium text-geo-900 flex items-center justify-center">
              Filtros
            </header>
            <ContainerField>
              <SimpleSelect
                placeholder="Selecione um relatório"
                noOptionsMessage="Nenhum relatório encontrado"
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                options={sortBy(reportsOptions, (i) => i.name).filter(
                  ({ key }) => key !== 'movWeekEnd'
                )}
                onChange={(data) => dispatch(ReportsActions.setReport(data))}
                value={reportOrigin}
              />
              <ReportFields formik={formik} />
            </ContainerField>

            {/* {NameReport && ( */}
            <div className="flex">
              {/* <SimpleButton type="button">Agendar</SimpleButton> */}
              <SimpleButton type="submit">Gerar</SimpleButton>
            </div>
            {/* )} */}
          </>
        )}
      </Form>
    </SimpleContainer>
  );
}

export default Filters;
