import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';

import _ from 'lodash';
import PropTypes from 'prop-types';

import mapStyle from '~/assets/map.json';
import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
} from '~/components/Global';
import processPoints from '~/pages/Tracker/form/FenceData/processPoints';

function Map({ modalView, setModalView, data }) {
  const [map, setMap] = useState('');

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setModalView(true);
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      const geojson = JSON.parse(data.geojson);
      map.data.addGeoJson({
        type: 'Feature',
        geometry: geojson,
      });
      const bounds = new window.google.maps.LatLngBounds();
      map.data.forEach((feature) => {
        processPoints(feature.getGeometry(), bounds.extend, bounds);
      });
      map.fitBounds(bounds);
      map.setZoom(map.getZoom() + 8);
      // map.data.addGeoJson(JSON.parse(data.geojson));
    }
  }, [data]);

  useEffect(() => {
    const mapInit = new window.google.maps.Map(
      document.getElementById('map2'),
      {
        center: { lat: -20.361797, lng: -40.660631 },
        fullscreenControl: false,
        mapTypeControl: true,
        zoomControl: false,
        scaleControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        controlSize: 25,
        styles: mapStyle,
        mapId: 'c0bebca699f25d4b'
      }
    );
    setMap(mapInit);
    setModalView(false);
  }, []);

  return (
    <Modal
      visible={modalView}
      height="380"
      width="500"
      effect="fadeInUp"
      onClickAway={() => setModalView(false)}
      // onRequestClose={() => props.setViewModal()}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTextHeader>Mapa</ModalTextHeader>
        </ModalHeader>
        <div style={{ height: '100%', width: '100%' }} id="map2" />
      </ModalContainer>
    </Modal>
  );
}

Map.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object
  ).isRequired,
  modalView: PropTypes.bool.isRequired,
  setModalView: PropTypes.func.isRequired,
};

export default Map;
