export default function geoJsonFormater(event) {
  let GeoJson = {
    type: 'Feature',
    geometry: {
      coordinates: [[]],
    },
    properties: {},
  };
  if (event.type === 'circle') {
    GeoJson.geometry.type = 'Point';
    const center = event.overlay.getCenter();
    GeoJson.geometry.coordinates[0].push([center.lng(), center.lat()]);
    GeoJson.properties.radius = event.overlay.getRadius();
    return GeoJson;
  }
  if (event.type === 'polygon') {
    GeoJson.geometry.type = event.type;
    event.overlay
      .getPath()
      .getArray()
      .forEach((item, index) => {
        GeoJson.geometry.coordinates[0].push([item.lng(), item.lat()]);
        if (index === event.overlay.getPath().getArray().length - 1) {
          const frist = event.overlay.getPath().getArray()[0];
          GeoJson.geometry.coordinates[0].push([frist.lng(), frist.lat()]);
        }
      });
    return GeoJson;
  }
  if (event.type === 'polyline') {
    GeoJson.geometry.type = 'LineString';
    GeoJson.geometry.coordinates = []
    event.overlay
      .getPath()
      .getArray()
      .forEach((item, index) => {
        GeoJson.geometry.coordinates.push([item.lng(), item.lat()]);
        // if (index === event.overlay.getPath().getArray().length - 1) {
        //   const frist = event.overlay.getPath().getArray()[0];
        //   GeoJson.geometry.coordinates[0].push([frist.lng(), frist.lat()]);
        // }
      });
    return GeoJson;
  }
  if (event.type === 'rectangle') {
    GeoJson.geometry.type = 'polygon';
    const bounds = event.overlay.getBounds();
    const NE = bounds.getNorthEast();
    GeoJson.geometry.coordinates[0].push([NE.lng(), NE.lat()]);
    const SW = bounds.getSouthWest();
    const NW = new window.google.maps.LatLng(NE.lat(), SW.lng());
    GeoJson.geometry.coordinates[0].push([NW.lng(), NW.lat()]);
    GeoJson.geometry.coordinates[0].push([SW.lng(), SW.lat()]);
    const SE = new window.google.maps.LatLng(SW.lat(), NE.lng());
    GeoJson.geometry.coordinates[0].push([SE.lng(), SE.lat()]);
    GeoJson.geometry.coordinates[0].push([NE.lng(), NE.lat()]);
    return GeoJson;
  }
  if (event.type === 'marker') {
    GeoJson.geometry.type = 'Point';
    const bounds = event.overlay.getPosition();
    GeoJson.geometry.coordinates = [bounds.lng(), bounds.lat()];
    return GeoJson;
  }
  return GeoJson;
}
