import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchSchedulesRequest: ['id', 'month'],
  fetchSchedulesSuccess: ['data'],
  fetchSchedulesFailure: [],
  createScheduleRequest: ['params'],
  updateScheduleRequest: ['params'],
  deleteScheduleRequest: ['id'],
  clearSchedule: [],
  setModal: [],
  setSchedule: ['schedule'],
});

const INITIAL_STATE = {
  schedules: [],
  schedule: {},
  modal: false,
};

const fetchSchedulesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
    modal: false,
  };
};

const fetchSchedulesSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  schedules: actions.data,
  schedule: {},
  loading: false,
});

const fetchSchedulesFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  schedules: [],
});

const createScheduleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateScheduleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});
const deleteScheduleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  schedule: {},
});

const clearSchedule = (state = INITIAL_STATE) => ({
  ...state,
  schedule: {},
});

const setModal = (state = INITIAL_STATE) => ({
  ...state,
  modal: !state.modal,
});

const setSchedule = (state = INITIAL_STATE, { schedule }) => ({
  ...state,
  schedule,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_SCHEDULES_REQUEST]: fetchSchedulesRequest,
  [Types.FETCH_SCHEDULES_SUCCESS]: fetchSchedulesSuccess,
  [Types.FETCH_SCHEDULES_FAILURE]: fetchSchedulesFailure,
  [Types.CREATE_SCHEDULE_REQUEST]: createScheduleRequest,
  [Types.UPDATE_SCHEDULE_REQUEST]: updateScheduleRequest,
  [Types.DELETE_SCHEDULE_REQUEST]: deleteScheduleRequest,
  [Types.CLEAR_SCHEDULE]: clearSchedule,
  [Types.SET_MODAL]: setModal,
  [Types.SET_SCHEDULE]: setSchedule,
});
