import React from 'react';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { TrackersSelect } from '~/components/selects';

import { Container } from './styles';

function Select({ label, id = `_select${nanoid()}`, ...props }) {
  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <TrackersSelect id={id} {...props} />
    </Container>
  );
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Select;
