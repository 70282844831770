import { ExportToCsv } from 'export-to-csv';

export default function exportCsv(data, headers, fileTitle, returns = false) {
  const options = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    title: fileTitle,
    filename: fileTitle,
    useTextFile: false,
    useBom: true,
    headers,
  };

  const csvExporter = new ExportToCsv(options);
  if(returns){
    return csvExporter.generateCsv(data, returns);
  }else{
    csvExporter.generateCsv(data);
  }
}