import _ from 'lodash';
//
export default function submitFormat({
  data: {
    id,
    name,
    owner,
    type,
    password,
    schedule: {
      type: typeScheduleData,
      date,
      time,
      week,
      dateLock,
      dateUnlock,
      weekLock,
      timeLock,
      weekUnlock,
      timeUnlock,
    },
    key,
    cars,
    group,
    days,
    tracker_id,
    typeCars,
  },
}) {
  const paramsRequest = {
    id,
    key,
    name,
    password,
    owner: _.isArray(owner)
      ? owner
      : owner.split(',').map((email) => email.trim()),
    type,
    params: {
      tracker_id,
      cars,
      days,
      group,
      typeCars,
    },
    schedule: {
      type: typeScheduleData,
    },
  };

  if (typeScheduleData === 'once' && type !== 'all')
    paramsRequest.schedule.date = new Date(date).toISOString();
  if (typeScheduleData === 'weekly' && type !== 'all') {
    paramsRequest.schedule.week = week;
    paramsRequest.schedule.time = time;
  }
  if (key === 'command') {
    const objectLock = _.cloneDeep(paramsRequest);
    const objectUnlock = _.cloneDeep(paramsRequest);

    objectLock.type = 'lock';
    objectUnlock.type = 'unlock';
    if (type === 'all') {
      if (typeScheduleData === 'once') {
        objectLock.schedule.date = new Date(dateLock).toISOString();
        objectUnlock.schedule.date = new Date(dateUnlock).toISOString();
      }
      if (typeScheduleData === 'weekly') {
        objectLock.schedule.week = weekLock;
        objectLock.schedule.time = timeLock;
        objectUnlock.schedule.week = weekUnlock;
        objectUnlock.schedule.time = timeUnlock;
      }
      if (id === '') {
        return [objectLock, objectUnlock];
      }
    } else if (id) {
      return paramsRequest;
    } else {
      return paramsRequest;
    }
  } else if (key === 'report') {
    if (id) {
      return paramsRequest;
    }
    return paramsRequest;
  }
  return undefined;
}
