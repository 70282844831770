import styled from 'styled-components';

export const Triangle = styled.div`
  width: 18px;
  height: 0;
  border-top: 9px solid #666;
  border-right: 8px solid transparent;
  top: 0;
  left: 0;
  position: absolute;
`;
