import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  grid-area: action;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  padding: 10px;
  > header {
    background: #f4f8f9;
    font-size: 20px;
    color: #245378;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-bottom: 15px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  margin: 5px 0;
  height: 50px;
  border: none;
  border-radius: 3px;
  background: #245378;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  text-transform: uppercase;
  transition: 0.2s;
  :hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background: ${darken(0.1, '#245378')};
  }
  :disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.2);
  }
`;
