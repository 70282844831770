import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../services/api';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as ResponsibleActions } from '~/store/ducks/responsibleSauce';

function Responsible() {
  const dispatch = useDispatch();
  const responsibleState = useSelector(
    (state) => state.responsibleState.responsibles
  );
  const loading = useSelector((state) => state.responsibleState.loading);
  const [responsibles, setResponsibles] = useState(responsibleState);
  useEffect(() => {
    setResponsibles([]);
    dispatch(ResponsibleActions.fetchResponsibleRequest());
  }, []);

  useEffect(() => {
    setResponsibles([...responsibleState]);
    console.log(responsibleState)
  }, [responsibleState]);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={
              responsibles.map((item) =>
                ({ ...item })
              )
        }
        headerBackground="#245378"
        loading={loading}
        labels={['Nome', 'CPF', 'E-mail', 'Telefone']}
        headers={['name', 'cpf', 'email', 'phone']}
        fileTitle="Relatório de responsáveis"
        getData={(data) => history.push(`/responsible/form/${data.id}`)}
      />
    </div>
  );
}

export default Responsible;
