import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';

const Simple = forwardRef(({ children, area }, ref) => {
  return (
    <div
      style={area && { gridArea: area }}
      className="p-2  shadow overflow-auto bg-white dark:bg-gray-600 rounded-lg"
      ref={ref}
    >
      {children}
    </div>
  );
});

Simple.propTypes = {
  children: PropTypes.element,
  area: PropTypes.string,
};

Simple.defaultProps = {
  children: <></>,
  area: null,
};

export default Simple;
