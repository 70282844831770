import { toast } from 'react-toastify';

import { call, put, all, takeEvery } from 'redux-saga/effects';

import Api from '~/services/api';
// import axios from '~/services/axios';

import { Types } from '../ducks/dailySauce';
/*
async function getAddress(lat, lng) {
  console.log(lat, lng);
  if (lat && lng) {
    try {
      const { data } = await axios.get(
        `https://location.georastreamento.com.br/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=16`
      );
      const {
        road,
        city_district: cityDistrict,
        town,
        city,
        state,
        village,
        suburb,
      } = data.address;
      return ` ${road || suburb || village || cityDistrict}, ${
        city || town || cityDistrict
      } - ${state}`;
    } catch (error) {
      return 'Endereço não encontrado...';
    }
  }
  return '';
}
*/
async function getStopData(stops) {
  async function genData({ lat, lng, ...data }) {
    // const address = await getAddress(lat, lng);
    return { ...data, lat, lng };
  }
  const _stops = stops.map(genData);
  const result = await Promise.all(_stops);
  return result;
}

export function* getDaily(payload) {
  try {
    const response = yield call(Api.fetchDaily, payload.data);
    const statistics = yield call(Api.fetchStatistics, payload.data);
    const journey = yield call(Api.fetchDailyJourney, payload.data);
    const alerts = yield call(Api.fetchDailyAlerts, payload.data);
    const history = yield call(Api.fetchHistory, payload.data);
    yield put({
      type: Types.FETCH_DAILY_SUCCESS,
      data: response.data,
      statistics: statistics.data,
      journey: journey.data,
      alerts: alerts.data,
      history: history.data,
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_DAILY_FAILURE });
  }
}

export function* stopsSaga(payload) {
  try {
    const stops = yield call(Api.fetchStops, payload.data);
    const newArr = yield getStopData(stops.data);
    yield put({
      type: Types.FETCH_STOP_SUCCESS,
      stops: newArr,
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_DAILY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_DAILY_REQUEST, getDaily)]);
  yield all([takeEvery(Types.FETCH_STOP_REQUEST, stopsSaga)]);
}
