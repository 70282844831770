import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import history from '~/services/history';

import createRootReducer from './ducks';
import alertSaga from './sagas/alertSaga';
import auditSaga from './sagas/auditSaga';
import authSaga from './sagas/authSaga';
import bufferSaga from './sagas/bufferSaga';
import chartSaga from './sagas/chartSaga';
import commandSaga from './sagas/commandSaga';
import dailySaga from './sagas/dailySaga';
import driverSaga from './sagas/driverSaga';
import responsibleSaga from './sagas/responsibleSaga';
import fenceSaga from './sagas/fenceSaga';
import forgotSaga from './sagas/forgotSaga';
import globalSaga from './sagas/globalSaga';
import journeySaga from './sagas/journeySaga';
import preJourneySaga from './sagas/preJourneySaga';
import maintenanceSaga from './sagas/maintenanceSaga';
import preventiveSaga from './sagas/preventiveSaga';
import reportSaga from './sagas/reportSaga';
import routeSaga from './sagas/routeSaga';
import schedulesSaga from './sagas/schedulesSaga';
import supplySaga from './sagas/supplySaga';
import trackerSaga from './sagas/trackerSaga';
import violationSaga from './sagas/violationSaga';
import worksheetSaga from './sagas/worksheetSaga';
import passengerSaga from './sagas/passengerSaga';
import journeyRouteSaga from './sagas/journeyRouteSaga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = createStore(
  createRootReducer(history),
  compose(applyMiddleware(...middlewares))
);

sagaMiddleware.run(authSaga);
sagaMiddleware.run(bufferSaga);
sagaMiddleware.run(driverSaga);
sagaMiddleware.run(responsibleSaga);
sagaMiddleware.run(chartSaga);
sagaMiddleware.run(supplySaga);
sagaMiddleware.run(maintenanceSaga);
sagaMiddleware.run(preventiveSaga);
sagaMiddleware.run(violationSaga);
sagaMiddleware.run(journeySaga);
sagaMiddleware.run(preJourneySaga);
sagaMiddleware.run(trackerSaga);
sagaMiddleware.run(commandSaga);
sagaMiddleware.run(fenceSaga);
sagaMiddleware.run(routeSaga);
sagaMiddleware.run(dailySaga);
sagaMiddleware.run(worksheetSaga);
sagaMiddleware.run(alertSaga);
sagaMiddleware.run(globalSaga);
sagaMiddleware.run(forgotSaga);
sagaMiddleware.run(auditSaga);
sagaMiddleware.run(schedulesSaga);
sagaMiddleware.run(passengerSaga);
sagaMiddleware.run(journeyRouteSaga);

// sagaMiddleware.run(surveillanceSaga);
sagaMiddleware.run(reportSaga);

export default store;
