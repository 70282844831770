import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/journeyRouteSauce';

export function* createJourneyRouteSaga(payload) {
  try {
    yield call(api.createJourneyRoute, payload.data);
    yield put({
      type: Types.FETCH_JOURNEY_ROUTE_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/journey'));
  } catch (error) {
    yield put({ type: Types.FETCH_JOURNEY_ROUTE_FAILURE });
    toast.error(error.data.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.CREATE_JOURNEY_ROUTE_REQUEST, createJourneyRouteSaga),
  ]);
}
