import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { MdCheck, MdClear } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import AvatarImage from '~/assets/avatar.png';
import categoryCnh from '~/assets/json/cnhCategory.json';
import { IconButton, SimpleButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  FormDataContainerDiv,
  DataContainer,
  DateStyle,
  ContainerTitle,
  Row,
  FieldContainer,
  ErrorMessage,
  Input,
  LabelStyled,
  SelectStyled,
} from '~/components/Global';
import { MaskedInput } from '~/components/inputs';
import Loading from '~/components/Loading';
import history from '~/services/history';
import { Creators as DriverActions } from '~/store/ducks/driverSauce';

import { AvatarContainer, Avatar, ProfileContainer } from './styles';

function Forms({
  match: {
    params: { id: idParams },
  },
}) {
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driverState.driver);
  const loading = useSelector((state) => state.driverState.loading);
  const me = useSelector((state) => state.globalState.me);
  registerLocale('pt-BR', el);
  const [zoom, setZoom] = useState(1.0);
  const [driverImage] = useState(AvatarImage);
  const [editablePermission, setEditablePermission] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      birthdate: '',
      phone: '',
      card: '',
      observation: '',
      cpf: '',
      rg: '',
      category: '',
      cnh: '',
      dateCnh: '',
      image: '',
      password: '',
      status: '',
      registration_number: undefined,
    },
    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (!values.name) errors.name = message;
      if (!values.email) errors.email = message;
      if (!values.cnh) errors.cnh = message;
      if (!values.phone) errors.phone = message;
      if (!values.dateCnh) errors.dateCnh = message;
      if (!values.cpf) {
        errors.cpf = message;
      }
      // Colocar validação de CPF
      return errors;
    },

    name: '',
    email: '',
    birthdate: '',
    phone: '',
    card: '',
    observation: '',
    cpf: '',
    rg: '',
    category: '',
    cnh: '',
    dateCnh: '',
    image: '',
    password: '',
    registration_number: undefined,
    onSubmit: (values) => {
      const formData = new FormData();
      if (values.name) {
        formData.append('name', values.name);
      }
      if (values.email) {
        formData.append('email', values.email);
      }
      if (values.birthdate) {
        formData.append(
          'birthdate',
          moment(values.birthdate).format('YYYY-MM-DD')
        );
      }

      [
        'phone',
        'card',
        'observation',
        'cpf',
        'rg',
        'category',
        'cnh',
        'password',
        'registration_number',
      ].forEach((key) => {
        if (values[key]) {
          formData.append(key, values[key]);
        } else {
          formData.delete(key);
        }
      });
      if (values.status !== '') {
        formData.append('status', values.status);
      }
      if (values.dateCnh) {
        formData.append('dateCnh', moment(values.dateCnh).format('YYYY-MM-DD'));
      }
      if (driverImage) {
        formData.append('image', driverImage);
      }
      if (values.id) {
        dispatch(DriverActions.updateDriverRequest(formData, values.id));
      } else {
        dispatch(DriverActions.createDriverRequest(formData));
      }
    },
  });

  useEffect(() => {
    if (idParams) {
      dispatch(DriverActions.fetchDriverRequest(`/${idParams}`));
    }
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  useEffect(() => {
    if (!_.isEmpty(driver)) {
      const data = {
        ...driver,
        birthdate: driver.birthdate && moment(driver.birthdate).toDate(),
        dateCnh: driver.dateCnh && moment(driver.dateCnh).toDate(),
        password: undefined,
      };
      formik.setValues(data);
    }
  }, [driver]);

  function cancelButton() {
    history.push('/driver');
    dispatch(DriverActions.clearDriver());
  }

  function onChangeZoom(e) {
    if (zoom > 3.5) {
      setZoom(3.5);
    } else if (zoom < 1.5) {
      setZoom(1.5);
    } else if (e.deltaY < 0) {
      setZoom(zoom + 0.5);
    } else {
      setZoom(zoom - 0.5);
    }
  }

  function getHeaderForm() {
    if (editablePermission) {
      if (loading) {
        return (
          <SimpleButton disabled width="w-44" height="h-8" type="button">
            Aguarde
          </SimpleButton>
        );
      }
      return (
        <div className="grid w-60 grid-cols-2 gap-2 h-8">
          <IconButton
            margin="0px"
            background="bg-red-600"
            Icon={MdClear}
            size={15}
            type="button"
            onClick={() => cancelButton()}
          >
            Cancelar
          </IconButton>
          <IconButton
            margin="0px"
            background="bg-gray-600 dark:bg-gray-400"
            Icon={MdCheck}
            size={15}
            type="submit"
          >
            Salvar
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton
        type="button"
        width="w-44"
        height="h-8"
        Icon={MdCheck}
        size={15}
        onClick={() => cancelButton()}
      >
        Voltar
      </IconButton>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de motoristas">
          {getHeaderForm()}
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <FormDataContainerDiv>
              <DataContainer>
                <ProfileContainer>
                  <AvatarContainer>
                    <Avatar
                      borderRadius={10}
                      image={driverImage}
                      width={160}
                      height={160}
                      border={0}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={zoom}
                      onWheel={onChangeZoom}
                    />
                  </AvatarContainer>
                  <div>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Nome</LabelStyled>
                          {formik.touched.name && formik.errors.name ? (
                            <ErrorMessage>{formik.errors.name}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <Input
                          name="name"
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={formik.touched.name && formik.errors.name}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>E-Mail</LabelStyled>
                          {formik.touched.email && formik.errors.email ? (
                            <ErrorMessage>{formik.errors.email}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <Input
                          name="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          disabled={!editablePermission}
                          error={formik.touched.email && formik.errors.email}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Data de Nascimento</LabelStyled>
                          {formik.touched.birthdate &&
                          formik.errors.birthdate ? (
                            <ErrorMessage>
                              {formik.errors.birthdate}
                            </ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <DateStyle
                          error={
                            formik.touched.birthdate && formik.errors.birthdate
                          }
                          dateFormat="dd/MM/yyyy"
                          selected={formik.values.birthdate}
                          onChange={(value) =>
                            formik.setFieldValue(
                              'birthdate',
                              moment(value).toDate()
                            )
                          }
                          locale={pt}
                          customInput={<InputMask mask="99/99/9999" />}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                    </Row>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Telefone Celular</LabelStyled>
                          {formik.touched.phone && formik.errors.phone ? (
                            <ErrorMessage>{formik.errors.phone}</ErrorMessage>
                          ) : (
                            ''
                          )}
                        </div>
                        <MaskedInput
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          id="phone"
                          name="phone"
                          mask="(99) 9 9999-9999"
                          maskChar={null}
                          error={formik.touched.phone && formik.errors.phone}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <LabelStyled>Cartão de Identificação</LabelStyled>
                        <Input
                          name="card"
                          onChange={formik.handleChange}
                          value={formik.values.card}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <LabelStyled>Matrícula</LabelStyled>
                        <Input
                          name="registration_number"
                          onChange={formik.handleChange}
                          value={formik.values.registration_number}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                    </Row>
                    <Row>
                      <FieldContainer>
                        <LabelStyled>Observação</LabelStyled>
                        <Input
                          name="observation"
                          onChange={formik.handleChange}
                          value={formik.values.observation}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <LabelStyled title="senha para acessar o aplicativo ponto do motorista">
                          Senha Ponto
                        </LabelStyled>
                        <Input
                          type="password"
                          name="password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          disabled={!editablePermission}
                          title="senha para acessar o aplicativo ponto do motorista"
                          placeholder="deixe em branco para não alterar a senha"
                        />
                      </FieldContainer>
                    </Row>
                  </div>
                </ProfileContainer>
                <ContainerTitle>DOCUMENTOS</ContainerTitle>
                <div>
                  <Row>
                    <FieldContainer>
                      <div>
                        <LabelStyled>CPF</LabelStyled>
                        {formik.touched.cpf && formik.errors.cpf ? (
                          <ErrorMessage>{formik.errors.cpf}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <MaskedInput
                        onChange={formik.handleChange}
                        value={formik.values.cpf}
                        id="cpf"
                        name="cpf"
                        mask="999.999.999-99"
                        maskChar={null}
                        error={formik.touched.cpf && formik.errors.cpf}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <LabelStyled>RG</LabelStyled>
                      <Input
                        name="rg"
                        onChange={formik.handleChange}
                        value={formik.values.rg}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                    {formik.values.status !== '' && (
                      <FieldContainer>
                        <LabelStyled>Status</LabelStyled>
                        <SelectStyled
                          name="status"
                          defaultValue={{
                            label: formik.values.status ? 'Ativo' : 'Inativo',
                            value: formik.values.status,
                          }}
                          onChange={(option) =>
                            formik.setFieldValue('status', option.value)
                          }
                          value={{
                            label: formik.values.status ? 'Ativo' : 'Inativo',
                            value: formik.values.status,
                          }}
                          options={[
                            { label: 'Ativo', value: true },
                            { label: 'Inativo', value: false },
                          ]}
                          isDisabled={!editablePermission}
                        />
                      </FieldContainer>
                    )}
                  </Row>
                  <Row>
                    <FieldContainer>
                      <LabelStyled>Categoria CNH</LabelStyled>
                      <SelectStyled
                        name="category"
                        defaultValue={{
                          label: formik.values.category,
                          value: formik.values.category,
                        }}
                        onChange={(option) =>
                          formik.setFieldValue('category', option.value)
                        }
                        value={{
                          label: formik.values.category,
                          value: formik.values.category,
                        }}
                        options={categoryCnh}
                        isDisabled={!editablePermission}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <div>
                        <LabelStyled>CNH</LabelStyled>
                        {formik.touched.cnh && formik.errors.cnh ? (
                          <ErrorMessage>{formik.errors.cnh}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <Input
                        name="cnh"
                        onChange={formik.handleChange}
                        error={formik.touched.cnh && formik.errors.cnh}
                        value={formik.values.cnh}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <div>
                        <LabelStyled>Validade CNH</LabelStyled>
                        {formik.touched.dateCnh && formik.errors.dateCnh ? (
                          <ErrorMessage>{formik.errors.dateCnh}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <DateStyle
                        error={formik.touched.dateCnh && formik.errors.dateCnh}
                        dateFormat="dd/MM/yyyy"
                        selected={formik.values.dateCnh}
                        onChange={(value) =>
                          formik.setFieldValue(
                            'dateCnh',
                            moment(value).toDate()
                          )
                        }
                        locale={pt}
                        customInput={<InputMask mask="99/99/9999" />}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                  </Row>
                </div>
              </DataContainer>
            </FormDataContainerDiv>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
