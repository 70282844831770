import React, { useState, useEffect, useRef } from 'react';
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';

import mapStyle from '~/assets/map.json';
import markerFinish from '~/assets/markerFinish.svg';
import markerStart from '~/assets/markerStart.png';
import pdfLogo from '~/components/Table/assets/pdf.svg';
import { Creators as DailyActions } from '~/store/ducks/dailySauce';
import mapPng from '~/util/exportMapPng';

import distance from './distance';
import exportMapPdf from './exportMap';
import { MapContainer, Map, IconContainer, ButtonPdf } from './styles';

function Daily() {
  const dispatch = useDispatch();
  const journey = useSelector((state) => state.dailyState.journey);
  const stops = useSelector((state) => state.dailyState.stops);
  const alerts = useSelector((state) => state.dailyState.alerts);
  const statics = useSelector((state) => state.dailyState.statistics);
  const filters = useSelector((state) => state.dailyState.filters);
  const daily = useSelector((state) => state.dailyState.daily);
  const showDetail = useSelector((state) => state.dailyState.showDetail);
  const coordinateInfo = useSelector((state) => state.dailyState.coordinate);
  const [map, setMap] = useState();
  const [markers] = useState([]);
  const [markerN, setMarkerN] = useState({});
  const history = useSelector((state) => state.dailyState.history);
  const targetRef = useRef();

  useEffect(() => {
    const mapInit = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: true,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 13,
      controlSize: 25,
      styles: mapStyle,
      // mapId: 'e46126f6d335ed53',
    });
    setMap(mapInit);
  }, []);

  async function getAddress(lat, lng) {
    if (lat && lng) {
      try {
        const { data } = await axios.get(
          `https://location.georastreamento.com.br/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=16`
        );
        const { road, city_district, town, city, state } = data.address;
        return `${road || city_district}, ${city || town} - ${state}`;
      } catch (error) {
        return 'Carregando endereço...';
      }
    }
    return '';
  }

  useEffect(() => {
    async function asyncData() {
      if (!_.isEmpty(coordinateInfo)) {
        if (!_.isEmpty(markerN)) {
          markerN.setMap(null);
        }
        const infowindow = await new window.google.maps.InfoWindow({
          content: `<div>
          <div><b>Coordenada:</b> ${coordinateInfo.lat}, ${
            coordinateInfo.lng
          }</div>
          <div><b>Iginição:</b> ${coordinateInfo.ignition}</div>
          <div><b>Momento:</b> ${
            coordinateInfo.date
              ? moment(coordinateInfo.date).format('DD/MM HH:mm:ss')
              : coordinateInfo.time
          }</div>
          <div><b>Endereço:</b> ${await getAddress(
            coordinateInfo.lat,
            coordinateInfo.lng
          )}</div>
          </div>`,
        });

        const markerNew = await new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            coordinateInfo.lat,
            coordinateInfo.lng
          ),
          map,
        });
        await markerNew.setVisible(false);
        await setMarkerN(markerNew);
        await infowindow.open(map, markerNew);
      }
    }
    asyncData();
  }, [coordinateInfo]);

  async function getDaily() {
    if (daily[0] && daily[0].st_asgeojson) {
      const coordinates = JSON.parse(daily[0].st_asgeojson);
      const geojson = {
        type: 'Feature',
        geometry: coordinates,
      };
      map.data.addGeoJson(geojson);
      map.data.setStyle({
        strokeColor: '#3AAAAA',
        strokeOpacity: 0.8,
        strokeWeight: 3,
      });
      let markerH;
      map.data.addListener('click', async function (event) {
        let small = Number.MAX_VALUE;
        let historySpeed;
        if (!_.isEmpty(markerH)) {
          markerH.setMap(null);
        }

        await history.forEach((item) => {
          if (
            distance(
              event.latLng.lat().toFixed(6),
              event.latLng.lng().toFixed(6),
              parseFloat(item.lat),
              parseFloat(item.lng)
            ) < small
          ) {
            small = distance(
              event.latLng.lat().toFixed(6),
              event.latLng.lng().toFixed(6),
              parseFloat(item.lat),
              parseFloat(item.lng)
            );
            historySpeed = item;
          }
        });
        const infowindow = await new window.google.maps.InfoWindow({
          content: `
          <div><b>Coordenada:</b> ${historySpeed.lat}, ${historySpeed.lng}</div>
          <div><b>Iginição:</b> ${
            historySpeed.ignition ? 'Ligada' : 'Desligada'
          }</div>
          <div><b>Velocidade:</b> ${historySpeed.speed} Km/h</div>
          <div><b>Momento:</b> ${moment
            .utc(historySpeed.date)
            .subtract(3, 'hours')
            .format('DD/MM HH:mm:ss')}</div>
          </div>
          <div><b>Endereço:</b> ${await getAddress(
            historySpeed.lat,
            historySpeed.lng
          )}</div>`,
        });

        const markerNew = await new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            event.latLng.lat(),
            event.latLng.lng()
          ),
          map,
        });

        await markerNew.setVisible(false);
        markerH = markerNew;
        await infowindow.open(map, markerNew);
      });
      markers.push(
        new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            coordinates.coordinates[0][1],
            coordinates.coordinates[0][0]
          ),
          icon: {
            url: markerStart,
            origin: new window.google.maps.Point(0, 0),
            scaledSize: new window.google.maps.Size(20, 20),
            anchor: new window.google.maps.Point(0, 20),
          },

          map,
        })
      );

      markers.push(
        new window.google.maps.Marker({
          position: new window.google.maps.LatLng(
            coordinates.coordinates[coordinates.coordinates.length - 1][1],
            coordinates.coordinates[coordinates.coordinates.length - 1][0]
          ),
          icon: {
            url: markerFinish,
            origin: new window.google.maps.Point(0, 0),
            scaledSize: new window.google.maps.Size(20, 20),
            anchor: new window.google.maps.Point(0, 20),
          },

          map,
        })
      );
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.coordinates.map((item) =>
        bounds.extend(new window.google.maps.LatLng(item[1], item[0]))
      );
      map.fitBounds(bounds);
    }
  }

  useEffect(() => {
    if (map) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      markers.map((marker) => marker.setMap(null));

      getDaily();
    }
  }, [daily]);

  async function exportMap() {
    const imgMap = await mapPng(targetRef.current);
    await exportMapPdf({
      imgMap,
      statics,
      stops,
      history,
      alerts,
      journey,
      filters,
    });
  }

  return (
    <MapContainer detail={showDetail}>
      {!_.isEmpty(daily) && (
        <>
          <IconContainer onClick={() => dispatch(DailyActions.setShowDetail())}>
            {showDetail ? <MdFullscreen /> : <MdFullscreenExit />}
          </IconContainer>
          <ButtonPdf onClick={() => exportMap()}>
            <img src={pdfLogo} alt="LOGO" />
          </ButtonPdf>
        </>
      )}

      <Map id="map" ref={targetRef} />
    </MapContainer>
  );
}

export default Daily;
