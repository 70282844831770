import React, { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { IconButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import history from '~/services/history';

import Table from './table';

function Supply() {
  const me = useSelector((state) => state.globalState.me);
  const [editablePermission, setEditablePermission] = useState(false);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  return (
    <FormContainer>
      <HeaderFormContainer title="Abastecimento">
        <IconButton
          Icon={MdAdd}
          size={15}
          height="h-8"
          width="w-44"
          onClick={() => history.push('/supply/form')}
          disabled={!editablePermission}
          type="button"
        >
          Adicionar
        </IconButton>
      </HeaderFormContainer>
      <SimpleContainer>
        <Table />
      </SimpleContainer>
    </FormContainer>
  );
}
export default Supply;
