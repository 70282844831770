import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import MeasureTool from 'measuretool-googlemaps-v3/lib/MeasureTool.min';

import markerAlert from '~/assets/markerAlert.svg';
import markerRun from '~/assets/markerRun.svg';
import markerStop from '~/assets/markerStop.svg';

import markerBlue from '~/assets/markerBlue.svg';
import markerYellow from '~/assets/markerYellow.svg';
import markerOrange from '~/assets/markerOrange.svg';

import { initControls } from './controlsInit';
import geoJson from './geojson';
import { iconMarker } from './marker';
import { InputPlaces } from './styles';

import 'googlemaps-v3-utility-library/richmarker/src/richmarker-compiled';
import 'googlemaps-v3-utility-library/infobox/src/infobox.es6';
import mapStyle from '~/assets/map.json';

import Modal from './Modal';

import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import { saveZone, cancelZone } from './controls';

import FlagMarker from '~/assets/FlagMarker.svg';
import history from '~/services/history';
// import PermissionCheck from "~/components/Global/permissionCheck";

export default function Map({ match }) {
  const dispatch = useDispatch();
  const [map, setMap] = useState();
  const [initial, setInitial] = useState(true);
  const [marker, setMarker] = useState([]);
  const [typeModal, setTypeModal] = useState('');
  const [GeoJson, setGeoJson] = useState('');
  const [hideSearchBox, setHideSearchBox] = useState(false);
  const [measureTool, setMeasureTool] = useState('');
  // const me = useSelector((state) => state.globalState.me);
  const clientSelected = useSelector(
    (state) => state.bufferState.clientSelected
  );
  const buffer = useSelector((state) =>
    state.bufferState.buffer
    // .map((i) => ({ ...i, icon_color: 'orange' }))
  );
  const me = useSelector((state) => state.globalState.me);

  const drawingMode = useSelector((state) => state.bufferState.drawingMode);

  useEffect(() => {
    const mapInit = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 15,
      controlSize: 25,
      styles: mapStyle,
      mapId: '1f7a041e0cae469c',
    });

    // Include measure tools options
    setMeasureTool(
      new MeasureTool(mapInit, {
        showSegmentLength: true,
        contextMenu: false,
        tooltip: true,
        unit: MeasureTool.UnitTypeId.METRIC,
      })
    );

    initControls(
      mapInit,
      measureTool,
      dispatch,
      setTypeModal,
      setGeoJson,
      match
    );

    const input = document.getElementById('pac-input');
    const searchBox = new window.google.maps.places.SearchBox(input);
    mapInit.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(input);

    mapInit.addListener('bounds_changed', function () {
      searchBox.setBounds(mapInit.getBounds());
    });

    let markers = [];
    searchBox.addListener('places_changed', function () {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((markerSet) => {
        markerSet.setMap(null);
      });
      markers = [];
      // For each place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          return;
        }
        const icon = {
          url: FlagMarker,
          size: new window.google.maps.Size(101, 101),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(40, 40),
        };

        // Create a marker for each place.
        markers.push(
          new window.google.maps.Marker({
            mapInit,
            icon,
            title: place.name,
            position: place.geometry.location,
            zIndex: 99999,
          })
        );

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      mapInit.fitBounds(bounds);
    });

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      // drawingControl: editablePermission ? true : false,
      drawingControl: true,

      circleOptions: {
        editable: true,
      },
      polygonOptions: {
        editable: true,
      },
      rectangleOptions: {
        editable: true,
      },
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
        drawingModes: ['marker', 'polygon', 'rectangle'],
      },
    });

    drawingManager.setMap(mapInit);

    window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      function (event) {
        drawingManager.setDrawingMode(null);
        setGeoJson(geoJson(event));
        setTypeModal(event.type);
        if (event.type === 'circle') {
          window.google.maps.event.addListener(
            event.overlay,
            'radius_changed',
            function () {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'polygon') {
          window.google.maps.event.addListener(
            event.overlay.getPath(),
            'set_at',
            function () {
              setGeoJson(geoJson(event));
            }
          );

          window.google.maps.event.addListener(
            event.overlay.getPath(),
            'insert_at',
            function () {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'rectangle') {
          window.google.maps.event.addListener(
            event.overlay,
            'bounds_changed',
            function () {
              setGeoJson(geoJson(event));
            }
          );
        }
        // Change Drawing Mode
        dispatch(BufferActions.setDrawingMode());

        // To hide drawing tools control
        drawingManager.setOptions({
          drawingControl: false,
        });
        // Remove measure tools options
        mapInit.controls[9].clear();

        // Create DIV for save and cancel buttons
        let zoneControlDiv = document.createElement('div');
        const count = zoneControlDiv.getElementsByTagName('div').length;
        if (count === 4) {
          map.controls[window.google.maps.ControlPosition.RIGHT_TOP].removeAt(
            1
          );
          // Destroy the old zoneControlDiv and create a new one if there is more than 2 buttons(save and cancel)
          zoneControlDiv = document.createElement('div');
        }

        saveZone(
          zoneControlDiv,
          mapInit,
          dispatch,
          BufferActions,
          event.overlay,
          drawingManager
        );
        cancelZone(
          zoneControlDiv,
          mapInit,
          dispatch,
          BufferActions,
          event.overlay,
          drawingManager
        );
        zoneControlDiv.index = 1;
        mapInit.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
          zoneControlDiv
        );
      }
    );
    // se começa com o drawingmode ativo, desativa
    if (drawingMode) {
      dispatch(BufferActions.setDrawingMode());
      initControls(map, measureTool, dispatch, setTypeModal, setGeoJson, match);
      setHideSearchBox(false);
      if (drawingMode) {
        mapInit.controls[9].clear(); // Remove ruller tools
        mapInit.controls[6].clear(); // Remove small map in the left
      }
    }
    setMap(mapInit);
    // try {
    //   window.umami.trackEvent(localStorage.getItem('user'), 'map.created');
    // } catch (err) {}
  }, []);

  // useEffect(() => {
  //   try {
  //     // const userName = localStorage.getItem('user');
  //     if (me.clientName) {
  //       window.umami.trackEvent(
  //         // `${me.clientName} # ${userName}`,
  //         `${me.clientName} # ${me.name}`,
  //         'map.created'
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [me]);

  useEffect(() => {
    marker.map((item) => item.setMap(null));
    const point = [];
    const bounds = new window.google.maps.LatLngBounds();
    buffer.forEach((item) => {
      if (me.isDefaulter) {
        return;
      }
      let Icon = markerStop;
      if (item.status === 'DANGER') {
        Icon = markerAlert;
      } else if (item.status === 'OFF') {
        Icon = markerStop;
      } else if (item.ignition) {
        const coloredIcons = {
          blue: markerBlue,
          orange: markerOrange,
          yellow: markerYellow,
        };
        const icon =
          item.icon_color && Object.keys(coloredIcons).includes(item.icon_color)
            ? coloredIcons[item.icon_color]
            : markerRun;
        Icon = icon;
      }

      let alertPlate;
      if (item.status === 'DISCONNECTED') {
        alertPlate = 'DISCONNECTED';
      } else if (item.alert_on) {
        alertPlate = 'ALERT';
      }

      const block = !!item.output1;
      const pointer = new window.RichMarker({
        position: new window.google.maps.LatLng(item.lat, item.lng),
        map,
        draggable: false,
        content: iconMarker(Icon, item.angle, alertPlate, item.label, block),
        flat: true,
        anchor: window.RichMarkerPosition.MIDDLE,
      });
      point.push(pointer);

      window.google.maps.event.addListener(pointer, 'click', () => {
        history.push(`/monitoring?id=${item.tracker_id}`);
      });

      bounds.extend(pointer.getPosition());
      if (map && initial) {
        map.fitBounds(bounds);
        setInitial(false);
      }
    });
    if (!_.isEmpty(clientSelected) && !_.isEmpty(point)) {
      point.forEach((item) => {
        bounds.extend(item.getPosition());
        if (map) {
          map.fitBounds(bounds);
          dispatch(BufferActions.setClientSelected({}));
        }
      });
    }
    setMarker(point);
    if (map !== undefined) {
      map.controls[9].clear(); // Remove ruller tools
      map.controls[6].clear(); // Remove small map in the left
      initControls(map, measureTool, dispatch, setTypeModal, setGeoJson, match);
    }
  }, [map, buffer]);

  useEffect(() => {
    if (drawingMode) {
      setHideSearchBox(drawingMode);
      if (map !== undefined) {
        if (drawingMode) {
          map.controls[9].clear(); // Remove ruller tools
          map.controls[6].clear(); // Remove small map in the left
        } else {
          initControls(
            map,
            measureTool,
            dispatch,
            setTypeModal,
            setGeoJson,
            match
          );
        }
      }
    } else if (map !== undefined) {
      initControls(map, measureTool, dispatch, setTypeModal, setGeoJson, match);
      setHideSearchBox(false);
    }
  }, [drawingMode]);

  return (
    <div className="h-full w-full p-2 bg-white shadow rounded-lg">
      <Modal type={typeModal} geoJson={GeoJson} />
      <InputPlaces
        id="pac-input"
        type="text"
        placeholder="Pesquise o local"
        hide={hideSearchBox}
      />
      <div className="h-full w-full rounded-lg" id="map" />
    </div>
  );
}
