import React from 'react';

import {
  SimpleContainer,
  FormContainer,
  HeaderFormContainer,
} from '~/components/containers';

import Table from './table';

export default function Tracker(props) {
  return (
    <>
      <FormContainer>
        <HeaderFormContainer title="Item de rastreamento" />
        <SimpleContainer>
          <Table {...props} />
        </SimpleContainer>
      </FormContainer>
    </>
  );
}
