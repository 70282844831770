import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';
import translateAudit from '~/util/translateAudit';

import { Types } from '../ducks/auditSauce';

export function* getAudit(payload) {
  try {
    const { data } = yield call(api.fetchAudit, payload.query);
    const formated = data.payload.map((audit) => {
      const { title, method, redirect } = translateAudit({
        url: audit.path,
        body: audit.body,
        method: audit.method,
      });
      return {
        ...audit,
        username: audit.user && (audit.user.name || '-'),
        translateUrl: title,
        methodTranslate: method,
        redirect,
      };
    });
    yield put({
      type: Types.FETCH_AUDIT_SUCCESS,
      data: formated,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_AUDIT_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_AUDIT_REQUEST, getAudit)]);
}
