import styled from 'styled-components';

export const Container = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  padding: 10px;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 600;
    color: #01659e;
  }

  > div {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      color: #01659e;
      font-size: 15px;
      font-weight: 200;
    }

    small {
      color: #666;
      display: flex;
      align-items: flex-end;

      font-size: 14px;
    }
  }

  footer {
    margin: 0 10px 5px;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ShareUrl = styled.div`
  display: flex;
  margin: 20px 5px;
  > div {
    width: 300px;
    max-width: 300px;
    overflow: hidden;
    background: #dbdbdb;
    padding: 5px;
    border-radius: 10px 0 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 50px;
    display: flex;
    align-items: center;
  }
  > button {
    cursor: pointer;
    border: none;
    background: #03669f;
    color: #fff;
    border-radius: 0 10px 10px 0;
    width: 50px;
  }
`;
