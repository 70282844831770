import React, { useState, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import TableComponent from '~/components/Table';
// import history from '~/services/history';

export default function Audit() {
  const audits = useSelector((state) => state.auditState.audits);
  const loading = useSelector((state) => state.auditState.loading);
  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  // function redirect(redirectUrl) {
  //   if (redirectUrl) history.push(redirectUrl);
  // }

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={audits || []}
        headerBackground="#245378"
        loading={loading}
        labels={['Usuário', 'Momento', 'URL', 'Ação']}
        headers={['username', 'moment', 'translateUrl', 'methodTranslate']}
        fileTitle="Relatório de auditoria"
        getData={(data) => console.log({ data })}
      />
    </div>
  );
}
