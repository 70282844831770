import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  height: 100%;
  padding-top: 0.5rem;
  grid-template-rows: ${({ isCompact }) =>
    isCompact ? '2.5rem 2.5rem 1fr' : '2.5rem 1fr'};
  grid-template-columns: ${({ isCompact }) => (isCompact ? '1fr' : '1fr 3fr')};
  grid-gap: 0.5rem;
  grid-template-areas: ${({ isCompact }) =>
    isCompact ? "'input' 'button' 'cars'" : "'input button' 'cars cars'"};
`;
