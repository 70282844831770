import styled from 'styled-components';

export const ConfigGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: 70px 420px;
`;

export const GridContainerFence = styled.div`
  display: grid;
  grid-template-rows: 70px 420px;
  grid-template-columns: 1fr 110px;
  grid-template-areas: 'selectFence buttonFence' 'map map';
`;

export const Map = styled.div`
  border-radius: 5px;
  grid-area: map;
`;

export const ConfigFenceContainer = styled.div`
  display: grid;
  grid-template-rows: 60px 1fr 60px;
  grid-gap: 10px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  background: #f4f8f9;
  padding: 5px;
`;
