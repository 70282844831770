import React from 'react';
import { connect } from 'react-redux';

import {
  ContainerStyled,
  TableContainer,
  ContainerHeader,
} from '~/components/Global';

import Table from './table';

function Worksheet(props) {
  return (
    <ContainerStyled>
      <ContainerHeader>
        <div>Infrações</div>
      </ContainerHeader>
      <TableContainer>
        <Table {...props} />
      </TableContainer>
    </ContainerStyled>
  );
}

export default connect()(Worksheet);
