/* eslint-disable import/no-anonymous-default-export */
import axios from './axios';
import verification from './verification';

export default {
  fetchMe: () => axios.get(`/me`),
  createForgot: (cpf) => axios.post(`/auth/forgot`, cpf),
  fetchBuffer: (id) => axios.get(`/buffer${id || ''}`),
  fetchGroups: (id) => axios.get(`/groups${id ? `/${id}` : ''}`),
  fetchTracker: (query) => axios.get(`/trackers${query ? `${query}` : ''}`),
  updateTracker: (query) => axios.put(`/trackers/${query.id}`, query),
  trackerPoints: ({ trackerId, params }) =>
    axios.post(`/tracker/${trackerId}/points${params ? params : ''}`),
  trackerFences: ({ trackerId }) => axios.post(`/tracker/${trackerId}/fences`),
  trackerAddPoint: ({ trackerId, pointId }) =>
    axios.post(`/tracker/${trackerId}/point/${pointId}`),
  trackerRemovePoint: ({ trackerId, pointId }) =>
    axios.delete(`/tracker/${trackerId}/point/${pointId}`),
  trackerInvertAllPoints: ({ trackerId }) =>
    axios.post(`/tracker/${trackerId}/invertAllPoints`),
  fetchSupply: (query) => axios.get(`/supplies${query ? `${query}` : ''}`),
  createSupply: (data) => axios.post('/supplies', data),
  updateSupply: (data) => axios.put(`/supplies/${data.id}`, data),
  deleteSupply: (id) => axios.delete(`/supplies/${id}`),
  fetchMaintenance: (query) =>
    axios.get(`/maintenances${query ? `${query}` : ''}`),
  createMaintenance: (data) => axios.post('/maintenances', data),
  updateMaintenance: (data) => axios.put(`/maintenances/${data.id}`, data),
  deleteMaintenance: (id) => axios.delete(`/maintenances/${id}`),
  fetchPreventive: (query) =>
    axios.get(`/preventives${query ? `${query}` : ''}`),
  createPreventive: (data) => axios.post('/preventives', data),
  updatePreventive: (data) => axios.put(`/preventives/${data.id}`, data),
  deletePreventive: (id) => axios.delete(`/preventives/${id}`),
  fetchViolation: (query) => axios.get(`/violations${query ? `${query}` : ''}`),
  createViolation: (data) => axios.post('/violations', data),
  updateViolation: (data) => axios.put(`/violations/${data.id}`, data),
  fetchJourney: (query) => axios.get(`/journeys${query ? `${query}` : ''}`),
  createJourney: (data) => axios.post('/journeys', data),
  updateJourney: (data) => axios.put(`/journeys/${data.id}`, data),
  deleteJourney: (id) => axios.delete(`/journeys/${id}`),
  fetchPreJourney: (query) =>
    axios.get(`/prejourneys${query ? `${query}` : ''}`),
  createPreJourney: (data) => axios.post('/prejourneys', data),
  updatePreJourney: (data) => axios.put(`/prejourneys/${data.id}`, data),
  deletePreJourney: (id) => axios.delete(`/prejourneys/${id}`),
  // fetchJourneyRoute: (query) =>
  //   axios.get(`/journeyroute${query ? `${query}` : ''}`),
  createJourneyRoute: (data) => axios.post('/journeyroute', data),
  // updateJourneyRoute: (data) => axios.put(`/journeyroute/${data.id}`, data),
  // deleteJourneyRoute: (id) => axios.delete(`/journeyroute/${id}`),
  fetchGeometries: (query) =>
    axios.get(`/geometries${query ? `${query}` : ''}`),
  createGeometries: (data) => axios.post('/geometries', data),
  updateGeometries: (data) => axios.put(`/geometries/${data.id}`, data),
  deleteGeometry: (id) => axios.delete(`/geometries/${id}`),
  fetchWorksheet: (query) => axios.get(`/worksheet${query ? `${query}` : ''}`),
  createWorksheet: (data) => axios.post('/worksheet', data),
  updateWorksheet: (data) => axios.put(`/worksheet/${data.id}`, data),
  fetchHistory: (query) => axios.get(`history${query}`),
  fetchStatistics: (query) => axios.get(`statistics${query}`),
  fetchDailyJourney: (query) => axios.get(`dailyJourney${query}`),
  fetchDailyAlerts: (query) => axios.get(`countAlerts${query}`),
  fetchStops: (query) => axios.get(`logbook${query}`),
  fetchCommand: (query) => axios.get(`/commands${query || ''}`),
  createCommand: (data) => axios.post('/commands', data),
  updateGroups: (id, data) => axios.put(`/groups/${id}`, data),
  deleteGroups: (id) => axios.delete(`/groups/${id}`),
  login: (user) => axios.post('/auth/login', user),
  fetchDriver: (query) => axios.get(`/drivers${query ? `${query}` : ''}`),
  createDriver: (data) => axios.post('/drivers', data),
  updateDriver: (data, id) => axios.put(`/drivers/${id}`, data),
  fetchResponsible: (query) =>
    axios.get(`/responsibles${query ? `${query}` : ''}`),
  getResponsible: (id) => axios.get(`/responsibles/${id}`),
  createResponsible: (data, id) => axios.post('/responsibles', data),
  updateResponsible: (data, id) => axios.put(`/responsibles/${id}`, data),
  fetchPassenger: (query) => axios.get(`/passengers${query ? `${query}` : ''}`),
  getPassenger: (id) => axios.get(`/passengers/${id}`),
  createPassenger: (data, id) => axios.post('/passengers', data),
  updatePassenger: (data, id) => axios.put(`/passengers/${id}`, data),
  fetchChartSpeed: (id) => axios.get(`/chart/speed/${id}`),
  fetchDriverViolationsChart: () => axios.get(`/driverviolations`),
  fetchDriverSpeedNotificationsChart: () =>
    axios.get(`/driverspeednotifications`),
  fetchTrackerNotificationsChart: () => axios.get(`/trackernotifications`),
  fetchDrivingTimeTrackerChart: () => axios.get(`/drivingTimeTracker`),
  fetchDaily: (query) => axios.get(`/chart/daily${query}`),
  getProfile: () => verification('get', '/profile'),
  getBuffer: () => verification('get', '/buffer'),
  setGroup: (data) => axios.post('/groups', data),
  getGroups: () => axios.get('/groups'),
  notificationByDriver: (data) => axios.post('/notificationByDriver', data),
  // setGroup: data => verification("post", "/groups", data),
  // getGroups: () => verification("get", "/groups"),
  getBufferByGroups: (id) => verification('get', `/buffer/${id}`),
  fetchReport: (url, query) =>
    ['/buffer'].includes(url) ? axios.get(url, query) : axios.post(url, query),
  fetchCommandList: (query) => axios.get(`/listcommands${query || ''}`),
  fetchNotifications: (query) => axios.get(`/notifications${query || ''}`),
  updateNotification: (query) => axios.put(`/notifications`, query),
  changePassword: (data) => axios.put(`/changepassword`, data),
  forgotPassword: (data) => axios.put(`/forgotpassword`, data),
  decryptPassword: (code) => axios.get(`/auth/forgot/${code}`),
  fetchAddress: (lat, lng) => axios.get(`/address?lat=${lat}&lng=${lng}`),
  fetchClients: () => axios.get('/clients'),
  // fetchJourney: query => axios.get(`/journeys${query ? `${query}` : ""}`),

  fetchAlerts: (query) => axios.get(`/alerts${query || ''}`),
  updateAlerts: (data) => axios.put(`/alerts`, data),

  fetchSettings: () => axios.get('/settings'),
  updateSettings: (data) => axios.post('/settings', data),
  fetchAudit: (data) => axios.get(`/logs?date=${data}`),
  trackerReplicateConfig: (data) =>
    axios.post(`/tracker/replicate/${data.trackerId}`, data),
  trackersCount: () => axios.get('/trackers/count'),
  fetchUser: (clientId) =>
    axios.get(`/users?client_id=${clientId ? `${clientId}` : ''}`),
};
