import React from 'react';

import Calendar from './Calendar';
import ModalCalendar from './Modal';
// padding: 0.5rem 0.5rem 0.5rem 1rem;
function Schedules() {
  return (
    <div className="py-2 pl-4 pr-2 ">
      <Calendar />
      <ModalCalendar />
    </div>
  );
}

export default Schedules;
