import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/preJourneySauce';

export function* getPreJourney(payload) {
  try {
    const response = yield call(api.fetchPreJourney, payload.query);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_PRE_JOURNEY_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_PRE_JOURNEY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error(JSON.stringify(error));
    yield put({ type: Types.FETCH_FAILURE });
  }
}
export function* createPreJourneySaga(payload) {
  try {
    yield call(api.createPreJourney, payload.data);
    yield put({
      type: Types.FETCH_PRE_JOURNEY_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/journey'));
  } catch (error) {
    yield put({ type: Types.FETCH_PRE_JOURNEY_FAILURE });
    toast.error(error.data.message);
  }
}

export function* updatePreJourneySaga(payload) {
  try {
    yield call(api.updatePreJourney, payload.data, payload.data.id);
    yield put({
      type: Types.FETCH_PRE_JOURNEY_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/journey'));
  } catch (error) {
    toast.error(error.data.message);
    yield put({ type: Types.FETCH_PRE_JOURNEY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_PRE_JOURNEY_REQUEST, getPreJourney)]);
  yield all([
    takeEvery(Types.CREATE_PRE_JOURNEY_REQUEST, createPreJourneySaga),
  ]);
  yield all([
    takeEvery(Types.UPDATE_PRE_JOURNEY_REQUEST, updatePreJourneySaga),
  ]);
}
