import styled from 'styled-components';

export const Container = styled.div`
  grid-area: side;
  margin: 0.5rem 0 0.5rem 0.5rem;
`;

export const IconStyled = styled.img`
  width: 31px;
  height: 31px;
  padding: 3px;
  border-radius: 5px;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  background: ${(props) => {
    return props.selected ? '#ddd' : '#fff';
  }};
  :hover {
    opacity: 1;
    background: #f3f3f3;
  }
`;

export const Divider = styled.div`
  width: 30px;
  border-bottom: 1px dashed #ddd;
`;
