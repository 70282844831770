import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as PassengerActions } from '~/store/ducks/passengerSauce';
import { getClientId } from '~/util/parseJwt';

function Passenger() {
  const dispatch = useDispatch();
  const passengers = useSelector((state) => state.passengerState.passengers);
  const loading = useSelector((state) => state.passengerState.loading);
  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    dispatch(
      PassengerActions.fetchPassengerRequest(
        filterClient ? `?id_clients=${filterClient}` : undefined
      )
    );
  }, []);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={passengers}
        headerBackground="#245378"
        loading={loading}
        labels={['Nome', 'CPF', 'Email', 'Telefone', 'Tipo']}
        filterTypes={['string', 'string', 'string', 'string', 'string']}
        groupType={['simple', 'simple', 'simple', 'simple', 'simple']}
        headers={['name', 'cpf', 'email', 'phone', 'type']}
        fileTitle="Relatório de passageiro"
        getData={(data) => history.push(`/passenger/form/${data.id}`)}
      />
    </div>
  );
}

export default Passenger;
