import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/supplySauce';

export function* getSupply(payload) {
  try {
    const response = yield call(api.fetchSupply, payload.query, payload.params);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_SUPPLY_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_SUPPLY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_SUPPLY_FAILURE });
  }
}

export function* createSupplySaga(payload) {
  try {
    yield call(api.createSupply, payload.data);
    yield put({
      type: Types.FETCH_SUPPLY_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/supply'));
  } catch (error) {
    yield put({ type: Types.FETCH_SUPPLY_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateSupplySaga(payload) {
  try {
    yield call(api.updateSupply, payload.data);
    yield put({
      type: Types.FETCH_SUPPLY_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/supply'));
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_SUPPLY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_SUPPLY_REQUEST, getSupply)]);
  yield all([takeEvery(Types.CREATE_SUPPLY_REQUEST, createSupplySaga)]);
  yield all([takeEvery(Types.UPDATE_SUPPLY_REQUEST, updateSupplySaga)]);
}
