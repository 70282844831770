import React from 'react';
import ModalContainer from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import BlockModal from './BlockModal';
import OdometerModal from './OdometerModal';
import OrimeterModal from './OrimeterModal';
import TimerModal from './TimerModal';

function Modal() {
  const dispatch = useDispatch();
  const modalVisible = useSelector((state) => state.commandState.viewModal);
  const modalType = useSelector((state) => state.commandState.modalType);
  function closeModal() {
    dispatch(CommandActions.setViewModal(null));
  }
  function setModalType() {
    if (modalType === 'odometer') return <OdometerModal />;
    if (modalType === 'orimeter') return <OrimeterModal />;
    if (modalType === 'timer') return <TimerModal />;
    if (modalType === 'block') return <BlockModal />;
    return <div>Comando não encontrado</div>;
  }

  return (
    <ModalContainer
      visible={modalVisible}
      effect="fadeInUp"
      onClickAway={() => closeModal()}
      width="400px"
      height="366px"
    >
      {setModalType()}
    </ModalContainer>
  );
}

export default Modal;
