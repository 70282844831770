import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';

import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import { ButtonContainer } from '~/components/Global';
import history from '~/services/history';

import Table from './table';

function Trip(props) {
  const [editablePermission, setEditablePermission] = useState(false);
  const me = useSelector((state) => state.globalState.me);
  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  return (
    <FormContainer>
      <HeaderFormContainer title="Rota">
        <ButtonContainer
          onClick={() => history.push('/route/form')}
          disabled={!editablePermission}
        >
          <MdAdd size={15} />
          Adicionar
        </ButtonContainer>
      </HeaderFormContainer>
      <SimpleContainer>
        <Table {...props} />
      </SimpleContainer>
    </FormContainer>
  );
}

export default Trip;
