import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { connect, useSelector } from 'react-redux';

import { IconButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import history from '~/services/history';

import Table from './table';

function Preventive(props) {
  const [editablePermission, setEditablePermission] = useState(false);
  const me = useSelector((state) => state.globalState.me);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  return (
    <FormContainer>
      <HeaderFormContainer title="Manutenção Preventiva">
        <IconButton
          Icon={MdAdd}
          size={15}
          height="h-8"
          width="w-44"
          onClick={() => history.push('/preventive/form')}
          disabled={!editablePermission}
          type="button"
        >
          Adicionar
        </IconButton>
      </HeaderFormContainer>
      <SimpleContainer>
        <Table {...props} />
      </SimpleContainer>
    </FormContainer>
  );
}

const mapStateToProps = (state) => ({
  me: state.globalState.me,
});

export default connect(mapStateToProps, null)(Preventive);
