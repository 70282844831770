import alertIcon from '~/assets/alertIconSemSobra.svg';
import noSignalIcon from '~/assets/noSignalIconSemSobra.svg';
import runIcon from '~/assets/runIconSemSobra.svg';
import sleepIcon from '~/assets/sleepIconSemSobra.svg';
import stopIcon from '~/assets/stopIconSemSobra.svg';
import totalIcon from '~/assets/totalIconSemSobra.svg';

export default function filtersData({ bufferGroup }) {
  const data = [
    {
      tooltip: 'Todos',
      filter: 'ALL',
      name: bufferGroup.length,
      icon: totalIcon,
    },
    {
      tooltip: 'Ligado',
      filter: 'ON',
      name: bufferGroup.filter((item) => item.status === 'ON').length,
      icon: runIcon,
    },
    {
      tooltip: 'Desligado',
      filter: 'OFF',
      name: bufferGroup.filter((item) => item.status === 'OFF').length,
      icon: stopIcon,
    },
    {
      tooltip: 'Acima da velocidade',
      filter: 'DANGER',
      name: bufferGroup.filter((item) => item.status === 'DANGER').length,
      icon: alertIcon,
    },
    {
      tooltip: 'Offline',
      filter: 'DISCONNECTED',
      name: bufferGroup.filter((item) => item.status === 'DISCONNECTED').length,
      icon: noSignalIcon,
    },
    {
      tooltip: 'Sleep',
      filter: 'SLEEP',
      name: bufferGroup.filter((item) => item.status === 'SLEEP').length,
      icon: sleepIcon,
    },
  ];
  return data;
}
