import { MdMoreVert } from 'react-icons/md';
import Select from 'react-select';

import Menu, { Item as MenuItem } from 'rc-menu';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0 10px 10px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
  width: 390px;
  padding: 5px;
  display: grid;
  grid-template-rows: 50px 50px 1fr;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const ContainerDiv = styled.div`
  height: ${(props) => props.heightSet && props.heightSet};

  border-radius: 5px;
  width: 100%;
  padding: 5px;
  > form {
    height: ${(props) => props.heightSet && props.heightSet};
    display: grid;
    grid-template-rows: 50px calc(100% - 132px) 50px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const IconHeader = styled.div`
  display: flex;
  width: 16.6%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  background: ${(props) => (props.selected ? '#ddd' : 'none')};
  border-bottom: ${(props) => (props.selected ? '2px solid #595959' : 'none')};
  img {
    transition: 0.3s;
    @media (max-width: 1300px) {
      width: 20px;
      height: 20px;
    }
    @media (min-width: 1300px) {
      width: 25px;
      height: 25px;
    }
  }
`;

export const IconContainer = styled.div`
  background: #f2f1f0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 0px 0px 0px 0px;
`;

export const GroupContainer = styled.div`
  background: #f2f1f0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 2px;
  padding: 10px;
  font-weight: 700;
  color: #555;

  @media (max-width: 1300px) {
    right: 16px;
  }
`;

export const MoreOptions = styled(MdMoreVert)`
  margin-left: 5px;
  border-radius: 10px;
  transition: all 0.3s;
  :hover {
    background: #dfdfdf !important;
    cursor: pointer;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  .css-yk16xz-control {
    border-radius: 7px;
    border: 0px !important;
  }
  .css-1pahdxg-control {
    box-shadow: none;
    border: none;
  }
  .css-1pahdxg-control:hover {
    border: none;
  }
  .css-tlfecz-indicatorContainer:hover {
    color: #545454;
  }
`;

export const Item = styled(MenuItem)`
  height: 35px;
  width: 100%;
  padding: 5px;
  color: ${(props) =>
    props.bgtheme === 'exit' ? '#fff !important' : '#333 !important'};
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  :hover {
    background: ${(props) =>
      props.bgtheme === 'exit'
        ? 'rgba(168,0,18) !important'
        : 'rgba(0, 0, 0, 0.05) !important'};
  }
  span {
    margin-left: 5px;
  }

  ${(props) =>
    props.bgtheme === 'exit'
      ? `
    background: #c10015;
    border-radius: 0 0 5px 5px;
  `
      : false}
`;

export const MenuStyled = styled(Menu)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  border: none;
  border-radius: 5px;
  margin-top: 8px;
  margin-left: -69px;
  ::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    top: -7px;
    margin-left: 75px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;

    @media (max-width: 600px) {
      margin-left: 120px;
    }
  }
`;

export const CarContainer = styled.div`
  height: ${(props) => props.heightSet && props.heightSet};
  /* height: auto; */
`;

export const CarItem = styled.div`
  background: #f2f1f0;
  margin: 5px 0;
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 30px 1fr;
  grid-template-areas:
    'Detail State'
    'label speed';
`;

export const CarTitle = styled.span`
  font-weight: 600;
  font-size: 17px;
`;

export const CarDetail = styled.span``;

export const CarLabel = styled.div`
  grid-area: label;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const StateContainer = styled.div`
  grid-area: State;
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 600;
  }
  border-radius: 0 7px 0 14px;
`;
export const DetailContainer = styled.div`
  grid-area: Detail;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
`;

export const DetailIcon = styled.div``;

export const DetailAlerts = styled.div`
  img {
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }
  img:last-child {
    margin-right: 10px;
  }
`;

export const NotFound = styled.div`
  background: #f2f1f0;
  margin: 2px 0px 2px 0px;
  color: #333;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
