import { nanoid } from 'nanoid';

export default [
  {
    id: `_reports${nanoid()}`,
    name: 'Carros Offline',
    key: 'offlinetimetracker',
    url: '/offlinetimetracker',
    getUrl: '/offlinetimetracker',
    landscape: true,
    fieldsParams: ['daysOffline'],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 130,
        active: true,
        type: 'string',
      },
      {
        name: 'client_name',
        header: 'Cliente',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'time_off',
        header: 'Tempo Offline',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'brand',
        header: 'Marca',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'model',
        header: 'Modelo',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'year',
        header: 'Ano/Modelo',
        width: 100,
        active: true,
        type: 'number',
      },
      {
        name: 'chassis',
        header: 'Chassi',
        width: 200,
        active: true,
        type: 'string',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Histórico do Veículo',
    key: 'historyDetail',
    url: '/historyDetail',
    getUrl: '/historyDetail',
    landscape: true,
    fieldsParams: ['start', 'finish', 'trackers'],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 130,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'date',
        header: 'Data',
        width: 200,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'ignition',
        header: 'Iginição',
        width: 100,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'lat',
        header: 'Latitude',
        width: 100,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'lng',
        header: 'Longitude',
        width: 200,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'address',
        header: 'Endereço',
        width: 200,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'speed',
        header: 'Velocidade',
        width: 100,
        active: true,
        type: 'number',
        align: 'center',
      },
      {
        name: 'angle',
        header: 'Ângulo',
        width: 100,
        active: true,
        type: 'string',
        align: 'center',
      },
      {
        name: 'name',
        header: 'Motorista',
        width: 100,
        active: true,
        type: 'string',
        align: 'left',
      },
      {
        name: 'id',
        header: 'Jornada',
        width: 100,
        active: true,
        type: 'number',
        align: 'left',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Quilômetros Percorridos',
    key: 'kilometersTraveled',
    url: '/report/traveled',
    getUrl: '/traveled',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'km',
        header: 'Km',
        width: 100,
        active: true,
        type: 'number',
      },
      {
        name: 'model',
        header: 'Modelo',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'type',
        header: 'Tipo',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'color',
        header: 'Cor',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'min',
        header: 'Odômetro inicial',
        width: 100,
        active: true,
        type: 'number',
      },
      {
        name: 'max',
        header: 'Odômetro final',
        width: 100,
        active: true,
        type: 'number',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Notificações por Motorista',
    key: 'countNotification',
    url: '/countNotification',
    getUrl: '/countNotification',
    fieldsParams: ['start', 'finish'],
    fields: [
      {
        name: 'name',
        header: 'Motorista',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'start',
        header: 'Inicio da Jornada',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'end',
        header: 'Fim da Jornada',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'notifications',
        header: 'Notificação',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'flag',
        header: 'Alerta',
        width: 200,
        active: true,
        type: 'string',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Utilização de Horímetro',
    key: 'horimeterReport',
    url: '/horimeterReport',
    getUrl: '/horimeterReport',
    fieldsParams: ['start', 'finish'],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'model',
        header: 'Modelo',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'brand',
        header: 'Marca',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'hours',
        header: 'Horas trabalhadas',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'initial_hour',
        header: 'Horimetro inicial',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'finish_hour',
        header: 'Horimetro Final',
        width: 100,
        active: true,
        type: 'string',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Movimentação Final de Semana',
    key: 'movWeekEnd',
    url: '/movOutHour',
    getUrl: '/movOutHour',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'date',
        header: 'Data',
        width: 100,
        active: true,
        type: 'date',
      },
      {
        name: 'lat',
        header: 'Latitude',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'lng',
        header: 'Longitude',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'speed',
        header: 'Velocidade',
        width: 200,
        active: true,
        type: 'number',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Atendimento de Alertas',
    key: 'alertsCentral',
    url: '/alertsCentral',
    getUrl: '/alertsCentral',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      {
        name: 'protocol',
        header: 'Protocolo',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'moment',
        header: 'Data',
        width: 100,
        active: true,
        type: 'date',
      },
      {
        name: 'flags',
        header: 'Alerta',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'procedure',
        header: 'Procedimento',
        width: 100,
        active: true,
        type: 'string',
      },
    ],
  },

  {
    id: `_reports${nanoid()}`,
    name: 'Comportamento do Motorista',
    key: 'driverAlerts',
    url: '/driverAlerts',
    getUrl: '/driverAlerts',
    fieldsParams: ['start', 'finish'],
    fields: [
      {
        name: 'protocolo',
        header: 'Protocolo',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'placa',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'motorista',
        header: 'Motorista',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'data',
        header: 'Data',
        width: 100,
        active: true,
        type: 'date',
      },
      {
        name: 'alerta',
        header: 'Alerta',
        width: 100,
        active: true,
        type: 'string',
      },
    ],
  },

  {
    id: `_reports${nanoid()}`,
    name: 'Movimentação por Motorista',
    key: 'driverMovimentation',
    url: '/driverMovimentation',
    getUrl: '/driverMovimentation',
    fieldsParams: ['start', 'finish'],
    fields: [
      {
        name: 'motorista',
        header: 'Motorista',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'inicio',
        header: 'Ìnicio',
        width: 100,
        active: true,
        type: 'date',
      },
      {
        name: 'fim',
        header: 'Fim',
        width: 100,
        active: true,
        type: 'date',
      },
      {
        name: 'km_percorrido',
        header: 'KM Percorridos',
        width: 100,
        active: true,
        type: 'string',
      },
    ],
  },

  {
    id: `_reports${nanoid()}`,
    name: 'Movimentação Fora do Horário',
    key: 'movOutHour',
    url: '/movOutHour',
    getUrl: '/movOutHour',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'date',
        header: 'Data',
        width: 100,
        active: true,
        type: 'date',
      },
      {
        name: 'lat',
        header: 'Latitude',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'lng',
        header: 'Longitude',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'speed',
        header: 'Velocidade',
        width: 200,
        active: true,
        type: 'number',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Excesso de Velocidade',
    key: 'speed',
    url: '/report/speedNotifications',
    getUrl: '/report/speedNotifications',
    fieldsParams: ['start', 'finish', 'group'],
    landscape: true,
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'brand',
        header: 'Marca',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'model',
        header: 'Modelo',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'datetime',
        header: 'Data',
        width: 100,
        active: true,
        type: 'date',
      },
      // {
      //   name: 'lat',
      //   header: 'Latitude',
      //   width: 100,
      //   active: true,
      //   type: 'string',
      // },
      // {
      //   name: 'lng',
      //   header: 'Longitude',
      //   width: 200,
      //   active: true,
      //   type: 'string',
      // },
      {
        name: 'speed_max',
        header: 'Veloc. Permitida',
        width: 200,
        active: true,
        type: 'number',
      },
      {
        name: 'speed',
        header: 'Velocidade',
        width: 200,
        active: true,
        type: 'number',
      },
      {
        name: 'address',
        header: 'Endereço',
        width: 200,
        active: true,
        type: 'string',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Quilômetros Percorridos Dentro da Cerca',
    key: 'report/kilometersTraveledWithinFence',
    url: '/report/kilometersTraveledWithinFence',
    getUrl: '/report/kilometersTraveledWithinFence',
    fieldsParams: ['start', 'finish', 'trackers', 'fence'],
    fields: [
      {
        name: 'info',
        header: 'Situação',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'date_min',
        header: 'Data de Entrada',
        width: 100,
        active: true,
        type: 'string',
      },
      {
        name: 'date_max',
        header: 'Data de Saída',
        width: 100,
        active: true,
        type: 'string',
      },

      {
        name: 'date_diff',
        header: 'Tempo na Cerca',
        width: 200,
        active: true,
        type: 'number',
      },
      {
        name: 'odometer_min',
        header: 'Odômetro Entrada',
        width: 200,
        active: true,
        type: 'number',
      },
      {
        name: 'odometer_max',
        header: 'Odômetro Saida',
        width: 200,
        active: true,
        type: 'number',
      },
      {
        name: 'odometer_diff',
        header: 'Percorrido',
        width: 200,
        active: true,
        type: 'number',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Diário de Bordo',
    key: 'diarioBordo',
    url: '/report/driverPoints',
    getUrl: '/report/driverPoints',
    fieldsParams: ['start', 'finish', 'driver'],
    fields: [
      {
        name: 'name',
        header: 'Motorista',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'first_point',
        header: 'Início',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'last_point',
        header: 'Fim',
        width: 100,
        active: true,
        type: 'string',
      },

      {
        name: 'history_count',
        header: 'Quantidade de Ações',
        width: 50,
        active: true,
        type: 'string',
      },
      {
        name: 'duration',
        header: 'Duração',
        width: 50,
        active: true,
        type: 'string',
      },
      {
        name: 'journeyTime',
        header: 'Jornada',
        width: 50,
        active: true,
        type: 'string',
      },
      {
        name: 'extraHours',
        header: 'Horas Extras',
        width: 50,
        active: true,
        type: 'string',
      },
      {
        name: 'exceededHours',
        header: 'Horas Excedentes',
        width: 50,
        active: true,
        type: 'string',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Posição Atual',
    key: 'posicaoAtual',
    url: '/buffer',
    getUrl: '/buffer',
    landscape: true,
    fieldsParams: [],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'brand',
        header: 'Marca',
        width: 600,
        active: true,
        type: 'string',
      },
      {
        name: 'model',
        header: 'Model',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'driver',
        header: 'Motorista',
        width: 200,
        active: true,
        type: 'string',
      },
      {
        name: 'date',
        header: 'Última Posição',
        width: 200,
        active: true,
        type: 'date',
        align: 'left',
      },
      {
        name: 'address',
        width: 600,
        header: 'Endereço',
        active: true,
        type: 'string',
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Atividade do Veículo',
    key: 'atividadeVeiculo',
    url: '/report/vehicleActivity',
    getUrl: '/report/vehicleActivity',
    landscape: true,
    fieldsParams: ['start', 'finish', 'group'],
    getFilterValue: ([row0]) => `Veículo ${row0?.placa}`,
    fields: [
      // { name: 'placa', header: 'Placa', type: 'string', width: 100 },
      { name: 'placa', header: 'Placa', type: 'string', width: 270 },
      { name: 'descricao', header: 'Descrição', type: 'string', width: 270 },
      {
        name: 'tempo',
        header: 'Duração',
        type: 'string',
        align: 'left',
        width: 90,
      },
      {
        name: 'km_percorridos',
        header: 'KM Percorrido',
        type: 'string',
        width: 80,
      },
      {
        name: 'velocidade_media',
        header: 'Vel. Média',
        type: 'string',
        width: 100,
      },
      {
        name: 'velocidade_maxima',
        header: 'Vel. Máxima',
        type: 'string',
        width: 100,
      },
      {
        name: 'velocidade_maxima_datahora',
        header: 'Vel. Máx. Data Hora',
        type: 'string',
        width: 100,
      },
      // {
      //   name: 'velocidade_maxima_lat',
      //   header: 'Vel. Máx. Lat.',
      //   type: 'number',
      //   width: 100,
      // },
      // {
      //   name: 'velocidade_maxima_lng',
      //   header: 'Vel. Máx. Lng.',
      //   type: 'number',
      //   width: 100,
      // },
      {
        name: 'velocidade_maxima_endereco',
        header: 'Vel. Máx. Endereço',
        type: 'string',
        width: 100,
      },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Tempo Ocioso',
    key: 'tempoOcioso',
    url: '/report/idleTime',
    getUrl: '/report/idleTime',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      { name: 'placa', header: 'Placa', type: 'string' },
      { name: 'marca', header: 'Marca', type: 'string' },
      { name: 'modelo', header: 'Modelo', type: 'string' },
      { name: 'tempo', header: 'Duração', type: 'string' },
      { name: 'motorista', header: 'Motorista', type: 'string' },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Custo Manutenção/Abastecimento',
    key: 'manutencaoAbastecimento',
    url: '/report/maintenanceSupply',
    getUrl: '/report/maintenanceSupply',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      { name: 'placa', header: 'Placa', type: 'string' },
      { name: 'marca', header: 'Marca', type: 'string' },
      { name: 'modelo', header: 'Modelo', type: 'string' },
      { name: 'tipo', header: 'Tipo', type: 'string' },
      { name: 'data', header: 'Data', type: 'string' },
      { name: 'preco', header: 'Preço', type: 'string' },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Tempo de Parada (estacionado)',
    key: 'tempoParada',
    url: '/report/stoppedTimes',
    getUrl: '/report/stoppedTimes',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      { name: 'placa', header: 'Placa', type: 'string' },
      { name: 'inicio', header: 'Início', type: 'date' },
      { name: 'fim', header: 'Fim', type: 'date' },
      { name: 'tempo', header: 'Duração', type: 'string' },
      // { name: 'lat', header: 'Latitude', type: 'number' },
      // { name: 'lng', header: 'Longitude', type: 'number' },
      { name: 'endereco', header: 'Endereço', type: 'string' },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Diário de Paradas',
    key: 'diarioParadas',
    url: '/report/dailyStops',
    getUrl: '/report/dailyStops',
    fieldsParams: ['start', 'finish', 'group'],
    fields: [
      { name: 'tracker_plate', header: 'Placa', type: 'string' },
      { name: 'geometry_name', header: 'Local', type: 'string' },
      { name: 'position_date_start', header: 'Hora de Entrada', type: 'date' },
      { name: 'position_date_end', header: 'Hora de Saída', type: 'date' },
      { name: 'time_interval', header: 'Permanência', type: 'interval' },
      // {name: 'geojson', header: 'Mapa', type: 'geojson'}
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Pontos de Referência',
    key: 'pois',
    url: '/report/pois',
    getUrl: '/report/pois',
    fieldsParams: [],
    fields: [
      { name: 'name', header: 'Nome', type: 'string' },
      { name: 'latitude', header: 'Latitude', type: 'string' },
      { name: 'longitude', header: 'Longitude', type: 'string' },
      // { name: 'geojson', header: 'Mapa', type: 'geojson' },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Permissões Diário de Bordo',
    key: 'logbookPermission',
    url: '/report/logbookPermission',
    getUrl: '/report/logbookPermission',
    fieldsParams: ['user'],
    fields: [
      { name: 'name', header: 'Nome', type: 'string' },
      { name: 'cpf', header: 'CPF', type: 'string' },
      { name: 'status', header: 'Status', type: 'string' },
    ],
  },
  {
    id: `_reports${nanoid()}`,
    name: 'Itens Rastreáveis',
    key: 'trackerByClientLogged',
    url: 'report/trackerByClientLogged',
    getUrl: '/report/trackerByClientLogged',
    fieldsParams: [],
    fields: [
      {
        name: 'plate',
        header: 'Placa',
        type: 'string',
        active: true,
        key: 'Placa',
      },
      { name: 'year', header: 'Ano', type: 'string', active: true, key: 'Ano' },
      {
        name: 'device_id',
        header: 'IMEI',
        type: 'string',
        active: true,
        key: 'IMEI',
      },
      {
        name: 'brand',
        header: 'Marca',
        type: 'string',
        active: true,
        key: 'Marca',
      },
      {
        name: 'model',
        header: 'Modelo',
        type: 'string',
        active: true,
        key: 'Modelo',
      },
    ],
  },
];
