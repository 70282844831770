import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Table from '~/components/TableSimple';
import history from '~/services/history';
import { Creators as DriverActions } from '~/store/ducks/driverSauce';

function Worksheet() {
  const dispatch = useDispatch();
  const driver = useSelector((state) => state.driverState.drivers);
  const page = useSelector((state) => state.driverState.page);
  const pageSize = useSelector((state) => state.driverState.pageSize);
  useEffect(() => {
    const params = { page, pageSize };
    dispatch(DriverActions.fetchDriverRequest(params, params));
  }, [page]);

  useEffect(() => {
    const params = { page, pageSize };
    dispatch(DriverActions.fetchDriverRequest(null, params));
  }, []);

  return (
    <>
      <Table
        data={driver.docs || []}
        labelsNames={[
          {
            name: 'name',
            aggregate: ['sum', 'count'],
            detail: 'registros',
          },
          {
            name: 'cpf',
            aggregate: ['sum', 'count'],
            detail: 'registros',
          },
          {
            name: 'email',
            aggregate: ['sum', 'count'],
            detail: 'registros',
          },
          {
            name: 'phone',
            aggregate: ['sum', 'count'],
            detail: 'registros',
          },
        ]}
        header={['nome', 'cpf', 'email', 'telefone']}
        changeValue={(data) => history.push(`/worksheet/form/${data.id}`)}
        name="motoristas"
        page={page}
        pages={driver.pages}
        changePage={(pageChange) => dispatch(DriverActions.setPage(pageChange))}
        urlExport="/drivers"
      />
    </>
  );
}

export default Worksheet;
