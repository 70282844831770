import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchSupplyRequest: ['query', 'params'],
  fetchSupplySuccess: ['data'],
  fetchSupplyIdSuccess: ['data'],
  fetchSupplyFailure: [],
  updateSupplyRequest: ['data'],
  createSupplyRequest: ['data'],
  clearSupply: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  supplies: [],
  supply: {},
  supplyByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearSupply = (state = INITIAL_STATE) => ({
  ...state,
  supply: [],
});

const fetchSupplyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchSupplySuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  supplies: actions.data,
  loading: false,
});

const fetchSupplyIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    supply: actions.data,
    loading: false,
  };
};

const fetchSupplyFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    supplies: [],
  };
};

const createSupplyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateSupplyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_SUPPLY_REQUEST]: fetchSupplyRequest,
  [Types.FETCH_SUPPLY_SUCCESS]: fetchSupplySuccess,
  [Types.FETCH_SUPPLY_ID_SUCCESS]: fetchSupplyIdSuccess,
  [Types.FETCH_SUPPLY_FAILURE]: fetchSupplyFailure,
  [Types.UPDATE_SUPPLY_REQUEST]: updateSupplyRequest,
  [Types.CREATE_SUPPLY_REQUEST]: createSupplyRequest,
  [Types.CLEAR_SUPPLY]: clearSupply,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
