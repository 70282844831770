import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  grid-template-areas: 'title' 'main';
  grid-gap: 10px;
  margin: 0.5rem 0.5rem 0.5rem 1rem;
`;

export const MainContainer = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 350px 350px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'map detail historic' 'map action historic';
`;
