import styled from 'styled-components';

export const Container = styled.div`
  position: ${({ isRelative = true }) => isRelative && 'relative'};
  > div {
    width: 100%;
  }
`;

export const ContainerCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;

  > label {
    margin-left: 5px;
  }
`;
