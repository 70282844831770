import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import { ButtonStyled } from '~/components/Global';
import api from '~/services/api';

import Alerts from './Alerts';
import {
  Container,
  ContainerHeader,
  ContainerDetail,
  ButtonContainer,
} from './styles';

function Settings() {
  const formik = useFormik({
    initialValues: {
      id: '',
      type: 'all',
      ALERT_BATTERY_DISCONNECT: [],
      ALERT_OLW_BATTERY: [],
      ALERT_POWER_VOLTAGE: [],
      ALERT_DRIVING: [],
      ALERT_SPEED: [],
      ALERT_OUTSIDE_FENCE: [],
      IGNITION: [],
      group: null,
      cars: [],
    },
    onSubmit: async (values) => {
      try {
        if (values.type) {
          if (values.type !== 'group') values.group = null;
          if (values.type !== 'cars') values.cars = [];
        }
        await api.updateSettings({ settings: values });
        toast.success('Configurações atualizadas');
      } catch (error) {
        toast.error('Erro ao atualizar as configurações, contate o suporte');
      }
    },
  });

  async function getSettings() {
    try {
      const {
        data: { settings },
      } = await api.fetchSettings();
      if (settings !== null) {
        if (settings.type && typeof settings.type === 'string') {
          formik.setValues(settings);
        } else {
          formik.resetForm();
        }
      }
    } catch (error) {
      console.tron.log({ error });
    }
  }

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Container>
      <ContainerHeader>Configurações</ContainerHeader>
      <ContainerDetail onSubmit={formik.handleSubmit}>
        <Alerts formik={formik} />
        <ButtonContainer>
          <ButtonStyled
            color="#E33939"
            type="button"
            onClick={() => getSettings()}
          >
            Cancelar
          </ButtonStyled>
          <ButtonStyled color="#555" type="submit">
            Salvar
          </ButtonStyled>
        </ButtonContainer>
      </ContainerDetail>
    </Container>
  );
}

export default Settings;
