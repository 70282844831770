import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';

export const Scrollbar = styled(PerfectScrollbar)`
  grid-area: table;
`;

export const TableContainer = styled.div`
  height: 100px;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
  background: #f4f8f9;
`;

export const TableHeader = styled.thead`
  background: #f4f8f9;
  color: #333;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s;
  @media screen and (max-width: 700px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const TableBody = styled.tbody`
  height: 100%;
  width: 100%;
`;

export const TableFooter = styled.tfoot`
  background: #f4f8f9;
  color: #333;
  text-transform: uppercase;
  width: 100%;
`;

export const Th = styled.th`
  cursor: pointer;
  height: 30px;
  padding-left: 5px;
  :first-child {
    border-radius: 5px 0 0 0;
  }
  :last-child {
    border-radius: 0 5px 0 0;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Tr = styled.tr`
  cursor: pointer;
  box-shadow: ${(props) => !props.header && '0 2px 6px rgba(0,0,0,0.1)'};
  border-radius: ${(props) => !props.header && '5px'};
  background: ${(props) => !props.header && '#fff'};
  /* padding: ${(props) => !props.header && '10px 0'}; */

  :hover {
    background: ${(props) => !props.header && 'rgba(0,0,0,0.005)'};
  }
  @media screen and (max-width: 700px) {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }
`;

export const Td = styled.td`
  height: 20px !important;
  /* border: 1px solid #ddd; */
  padding: 5px;
  transition: all 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: ${(props) => props.noData && 'center'};
  @media screen and (max-width: 700px) {
    font-size: 10px;
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    :before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    :last-child {
      border-bottom: 0;
    }
  }
`;

export const TotalSpan = styled.span`
  font-size: 10px;
  padding: 0 5px;
`;

export const TdFooter = styled.td`
  height: 30px;
  :first-child {
    border-radius: 0 0 0 5px;
    vertical-align: center;
    height: 100%;
    padding: 6px 5px;
  }
  :last-child {
    border-radius: 0 0 5px 0;
    align-items: center;
    text-align: right;
    height: 100%;
  }
`;
