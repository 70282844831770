import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/violationSauce';

export function* getViolation(payload) {
  try {
    const response = yield call(
      api.fetchViolation,
      payload.query,
      payload.params
    );
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_VIOLATION_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_VIOLATION_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createViolationSaga(payload) {
  try {
    yield call(api.createViolation, payload.data);
    yield put({
      type: Types.FETCH_VIOLATION_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/violation'));
  } catch (error) {
    yield put({ type: Types.FETCH_VIOLATION_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateViolationSaga(payload) {
  try {
    yield call(api.updateViolation, payload.data);
    yield put({
      type: Types.FETCH_VIOLATION_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/violation'));
  } catch (error) {
    yield put({ type: Types.FETCH_VIOLATION_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_VIOLATION_REQUEST, getViolation)]);
  yield all([takeEvery(Types.CREATE_VIOLATION_REQUEST, createViolationSaga)]);
  yield all([takeEvery(Types.UPDATE_VIOLATION_REQUEST, updateViolationSaga)]);
}
