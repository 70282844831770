import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchDailyRequest: ['data'],
  fetchStopRequest: ['data'],
  fetchDailySuccess: ['data'],
  fetchStopSuccess: ['data'],
  fetchDailyFailure: [],
  setShowDetail: [],
  setCoordinateInfo: ['data'],
  setDailyFilter: ['data'],
});

const INITIAL_STATE = {
  daily: {},
  speed: {},
  history: [],
  stops: [],
  showDetail: true,
  loading: false,
  loadingStops: false,
  coordinate: {},
  statistics: {},
  journey: [],
  alerts: [],
  filters: {},
};

const fetchDailyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchStopRequest = (state = INITIAL_STATE) => ({
  ...state,
  loadingStops: true,
});

const fetchDailySuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  daily: actions.data,
  statistics: actions.statistics,
  journey: actions.journey,
  alerts: actions.alerts,
  history: actions.history,
  loading: false,
});

const fetchStopSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  stops: actions.stops,
  loadingStops: false,
});

const fetchDailyFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    daily: {},
    speed: {},
    history: [],
    stops: [],
    journey: [],
    statistics: {},
    alerts: [],
    filters: {},
  };
};

const setShowDetail = (state = INITIAL_STATE) => {
  return { ...state, showDetail: !state.showDetail };
};

const setCoordinateInfo = (state = INITIAL_STATE, actions) => {
  return { ...state, coordinate: actions.data };
};
const setDailyFilter = (state = INITIAL_STATE, actions) => {
  return { ...state, filters: actions.data };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_DAILY_REQUEST]: fetchDailyRequest,
  [Types.FETCH_STOP_REQUEST]: fetchStopRequest,
  [Types.FETCH_STOP_SUCCESS]: fetchStopSuccess,
  [Types.FETCH_DAILY_SUCCESS]: fetchDailySuccess,
  [Types.FETCH_DAILY_FAILURE]: fetchDailyFailure,
  [Types.SET_SHOW_DETAIL]: setShowDetail,
  [Types.SET_COORDINATE_INFO]: setCoordinateInfo,
  [Types.SET_DAILY_FILTER]: setDailyFilter,
});
