import React from 'react';
import { useSelector } from 'react-redux';

// import NotReport from '~/assets/report/notReport.svg';
import NotReport from '~/assets/report/reportImage.svg';

import { AnimationLoading } from '~/components/animations';

function NoData() {
  const loading = useSelector(({ reportState }) => reportState.loading);
  return (
    <div className="w-full h-full flex flex-col  items-center justify-center">
      {loading ? (
        <AnimationLoading />
      ) : (
        <div>
          <img
            className="w-80"
            src={NotReport}
            alt="Nenhum relatório selecionado"
          />
          {/* <div className="text-xl  text-gray-500">
            Nenhum relatório selecionado
          </div> */}
        </div>
      )}
    </div>
  );
}

export default NoData;
