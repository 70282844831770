import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchTrackerRequest: ['query', 'params'],
  fetchTrackerSuccess: ['data'],
  fetchTrackerIdSuccess: ['data'],
  fetchTrackerFailure: [],
  updateTrackerRequest: ['data'],
  clearTracker: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  trackers: [],
  tracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const fetchTrackerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  trackers: [],
});

const fetchTrackerSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  trackers: actions.data,
  loading: false,
});

const fetchTrackerIdSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  tracker: actions.data,
  loading: false,
});

const fetchTrackerFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    trackers: [],
  };
};

const updateTrackerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const clearTracker = (state = INITIAL_STATE) => ({
  ...state,
  tracker: {},
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_TRACKER_REQUEST]: fetchTrackerRequest,
  [Types.FETCH_TRACKER_SUCCESS]: fetchTrackerSuccess,
  [Types.FETCH_TRACKER_ID_SUCCESS]: fetchTrackerIdSuccess,
  [Types.FETCH_TRACKER_FAILURE]: fetchTrackerFailure,
  [Types.UPDATE_TRACKER_REQUEST]: updateTrackerRequest,
  [Types.CLEAR_TRACKER]: clearTracker,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
