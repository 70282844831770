import { Line } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import InputMask from 'react-input-mask';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Select from 'react-select';

import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { Container, ButtonStyled } from '~/components/Global';

export const Scrollbar = styled(PerfectScrollbar)``;

export const ContainerStyled = styled(Container)`
  display: grid;
  grid-template-rows: 50px 1fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas: 'title' 'main';
  grid-gap: 10px;
  margin: 0.5rem 0.5rem 0.5rem 1rem;
`;

export const MainContainer = styled.div`
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr 1fr 550px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 'map alert points' 'statistics journey points';
  grid-gap: 10px;
  border-radius: 5px;
`;

export const TableContainer = styled.div`
  grid-area: table;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
`;

export const MapContainer = styled.div`
  position: relative;
  z-index: 1;
  grid-area: map;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  ${(props) =>
    !props.detail &&
    css`
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 3;
    `}
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const Map = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
`;

export const ContainerFilters = styled.div`
  height: 100%;
  form {
    display: grid;
    grid-template-rows: 40px 1fr 40px;
    height: 100%;
    grid-template-areas: 'header' 'filters' 'buttons';
  }
`;

export const HeaderFilter = styled.div`
  background: #245378;
  grid-area: header;
  border-radius: 5px 5px 0 0;
  display: flex;
  padding-left: 10px;
  align-items: center;
  font-weight: 600;
  color: #fff;
`;

export const BodyFilter = styled.div`
  grid-area: filters;
  background: #f7f4f7;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 0 0 5px 5px;
`;

export const ButtonsFilter = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonFilter = styled(ButtonStyled)`
  margin: 0;
  border: none;
  padding: 5px;
  background: ${(porps) => porps.color};
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    color: #fff;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  .css-yk16xz-control {
    border-radius: 7px;
    border: none;
    width: 100%;
    border: ${(props) =>
      props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
    background: ${(props) => (props.error ? '#fcd7d7' : '#fff')};
  }
  .css-1pahdxg-control {
    box-shadow: none;
    border: none;
    width: 100%;
    border: ${(props) =>
      props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
    background: ${(props) => (props.error ? '#fcd7d7' : '#fff')};
  }
  .css-1pahdxg-control:hover {
    border: none;
    width: 100%;
    border: ${(props) =>
      props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
    background: ${(props) => (props.error ? '#fcd7d7' : '#fff')};
  }
  .css-tlfecz-indicatorContainer:hover {
    color: #545454;
  }
`;

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;
export const StatisticsContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-area: statistics;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 30px 1fr 1fr;
  grid-gap: 5px;
  > div:first-child {
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #245378;
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

export const ItemStatistics = styled.div`
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > span {
    font-size: 9px;
  }
  > div {
    font-size: 30px;
    font-weight: 600;
    color: #245378;
    > span {
      font-size: 9px;
      font-weight: 300;
    }
  }
`;
export const NotStatistics = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 4;
  display: flex;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
`;

export const JourneyContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-area: journey;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 35px 1fr;
  > div:first-child {
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #245378;
    margin-bottom: 5px;
  }
`;

export const ItemJourney = styled.div`
  > div {
    height: 50px;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    border-radius: 5px;
    margin: 5px 0;
    padding: 5px;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 5px;
    align-items: center;
    > div {
      display: grid;
      grid-template-rows: 1fr 1fr;
      > span {
        font-size: 11px;
      }
    }
    :first-child {
      margin: 0;
    }
    img {
      height: 40px;
      width: 40px;
      border-radius: 100%;
      padding: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    }
  }
`;

export const NotJourney = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const AlertContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  grid-area: alert;
  padding: 5px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5px;
`;
export const Alerts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 30px 1fr;
  grid-gap: 5px;
  > div {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    :first-child {
      grid-column-start: 1;
      grid-column-end: 4;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
      color: #245378;
    }
    > div {
      font-size: 30px;
      font-weight: 600;
      color: #245378;
    }
    > span {
      text-align: center;
      font-size: 9px;
      font-weight: 300;
    }
  }
`;

export const NotAlert = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HistoryContainer = styled.div`
  height: 100%;
  width: 100%;
  grid-area: table;
`;

export const DetailContainer = styled.div`
  padding: 5px;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  grid-area: points;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const TimelineContainer = styled.div``;

export const IconContainer = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  z-index: 3;
  margin-top: -10px;
  margin-left: -4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const ButtonPdf = styled.button`
  position:absolute;
  height: 35px;
  width: 35px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
  z-index: 3;
  margin-top: -20px;
  margin-right: -10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  border: none;
  > img {
    width: 35px;
    height: 35px;
  }
`;

export const ChartPages = styled.div`
  grid-area: pages;
  display: flex;
`;

export const ChartDetail = styled.div`
  background: #f4f8f9;
  border-radius: 5px;
  height: ${(props) => props.heigth && `${props.heigth}px`};
`;

export const PageContainer = styled.div`
  background: #f2f1f0;
  height: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? '#f2f1f0' : '#c4c4c4')};
  font-weight: 600;
  transition: all 0.3s;
  color: ${(props) => (props.active ? '#605F5F' : '#9F9B9B')};
  cursor: pointer;
  :first-child {
    border-radius: 5px 0 0 0;
  }
  :last-child {
    border-radius: 0 5px 0 0;
  }
`;

export const LineStyled = styled(Line)`
  height: 394px;
`;

export const DateStyle = styled(DatePicker)`
  height: 33.2px;
  border: 1px solid #dbdbdb;
  width: 100%;
  border-radius: 3px;
  padding-left: 5px;
  color: #999;
  font-size: 14px;
  position: relative;
  .react-datepicker-popper {
    position: absolute;
    z-index: 99999999;
  }
`;

export const Tr = styled.tr`
  background: #245378;
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s;
  height: 30px;
`;
export const Th = styled.th`
  height: 30px;
  padding-left: 5px;
  border-radius: 5px 5px 0 0;
  width: 350px;
  font-size: 14px;
`;

export const TimelineEventStyled = styled(TimelineEvent)``;

export const TimelineStyled = styled(Timeline)``;

export const InputCustom = styled(InputMask)`
  font-size: 12px;
  border-radius: 3px;
`;

export const NotDate = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
  }
  div {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #9ea1a3;
    margin-top: 10px;
    padding-left: 25px;
  }
`;

export const NotDateContainer = styled.div`
  z-index: 2;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
  }
  div {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #9ea1a3;
    margin-top: 10px;
    padding-left: 25px;
  }
`;
export const NotStops = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  display: flex;
`;

export const ButtonDetail = styled.button`
  width: 100%;
  border: none;
  height: 30px;
  cursor: pointer;
  transition: background 0.2s;

  background: ${(props) =>
    props.selected ? darken(0.05, '#f4f8f9') : '#f4f8f9'};
  :hover {
    background: ${darken(0.05, '#f4f8f9')};
  }
`;

