import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas: 'title' 'detail';
  grid-gap: 10px;
  margin: 10px;
`;

export const ContainerHeader = styled.div`
  height: 100%;
  width: 100%;
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #245378;
  background: #fff;
  padding: 0 5px;
  font-size: 25px;
  font-weight: 600;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  > div {
    display: flex;
  }
`;
export const ContainerDetail = styled.form`
  grid-area: detail;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > button {
    margin: 5px;
    width: 200px;
  }
`;
