import styled from 'styled-components';

export const Container = styled.div`
  grid-area: map;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  padding: 5px;
  > div {
    border-radius: 5px;
    height: 100%;
    width: 100%;
  }
`;
