import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as ViolationActions } from '~/store/ducks/violationSauce';
import { getClientId } from '~/util/parseJwt';

function Table() {
  const dispatch = useDispatch();
  const violations = useSelector((state) => state.violationState.violations);
  const loading = useSelector((state) => state.violationState.loading);

  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    dispatch(
      ViolationActions.fetchViolationRequest(
        filterClient ? `?id_clients=${filterClient}` : undefined
      )
    );
  }, []);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={violations.map((item) => ({
          ...item,
          date: moment(item.date).format('DD/MM/YYYY'),
          expiry: moment(item.date).format('DD/MM/YYYY'),
          tracker_id: item.trackerItem ? item.trackerItem.label : '',
          driver_id: item.driver.name,
        }))}
        headerBackground="#245378"
        loading={loading}
        labels={[
          'veículo',
          'motorista',
          'gravidade',
          'valor',
          'data',
          'vencimento',
        ]}
        headers={[
          'tracker_id',
          'driver_id',
          'level',
          'price',
          'date',
          'expiry',
        ]}
        fileTitle="Relatório de infrações"
        getData={(data) => history.push(`/violation/form/${data.id}`)}
      />
    </div>
  );
}

export default Table;
