import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as SupplyActions } from '~/store/ducks/supplySauce';
import { getClientId } from '~/util/parseJwt';

function Supply() {
  const dispatch = useDispatch();
  const supplies = useSelector((state) => state.supplyState.supplies);
  const loading = useSelector((state) => state.supplyState.loading);
  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    dispatch(
      SupplyActions.fetchSupplyRequest(
        filterClient ? `?id_clients=${filterClient}` : undefined
      )
    );
  }, []);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={supplies.map((item) => ({
          ...item,
          date: moment(item.date).format('DD/MM/YYYY HH:MM:SS'),
          tracker_id: item.trackerItem ? item.trackerItem.label : '',
        }))}
        headerBackground="#245378"
        loading={loading}
        labels={[
          'Item de Rastreamento',
          'Data',
          'Km',
          'Valor por Litro',
          'Preço Total',
        ]}
        filterTypes={['string', 'date', 'number', 'number', 'number']}
        groupType={['simple', 'simple', 'simple', 'avg', 'sum']}
        headers={['tracker_id', 'date', 'marker', 'fuelPrice', 'totalPrice']}
        fileTitle="Relatório de abastecimento"
        getData={(data) => history.push(`/supply/form/${data.id}`)}
      />
    </div>
  );
}

export default Supply;
