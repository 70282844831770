import styled from 'styled-components';

export const ModalHeader = styled.div`
  width: 300px;
  height: 50px;
  background: #d21212;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

export const ModalDetail = styled.div`
  height: 100px;
  font-size: 15px;
  padding: 10px;
`;

export const ModalButtons = styled.div`
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
`;

export const ButtonModal = styled.button`
  width: 50px;
  height: 30px;
  margin: 0;
  font-weight: 600;
  :last-child {
    margin-left: 10px;
  }
`;
