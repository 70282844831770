import React from 'react';
import Modal from 'react-awesome-modal';
import { MdDone, MdClear } from 'react-icons/md';

import PropTypes from 'prop-types';

import { IconButton } from '~/components/buttons';
import { PasswordInput } from '~/components/inputs';

function ModalPassword({ setModalPassword, modalPassword, formik }) {
  return (
    <Modal
      visible={modalPassword}
      onClickAway={() => setModalPassword(false)}
      width="400px"
      height="300px"
    >
      <div className="h-full p-4 ">
        <header className="w-full h-10 flex justify-center items-center text-2xl text-geo-900">
          CONFIRMAÇÃO DE SENHA
        </header>
        <div>
          <div className="text-lg h-32 flex justify-center items-center">
            Digite a senha para confirmar o agendamento.
          </div>
          <PasswordInput
            onChange={({ target: { value } }) =>
              formik.setFieldValue('password', value)
            }
            value={formik.values.password}
          />
        </div>
        <div className="flex justify-center items-center h-10 ">
          <IconButton
            width="w-36"
            type="button"
            Icon={MdClear}
            size={20}
            background="bg-red-500"
            onClick={() => setModalPassword(false)}
          >
            Não
          </IconButton>
          <IconButton
            width="w-36"
            type="button"
            Icon={MdDone}
            size={20}
            onClick={formik.handleSubmit}
            background="bg-geo-900"
          >
            Confirmar
          </IconButton>
        </div>
      </div>
    </Modal>
  );
}

ModalPassword.propTypes = {
  modalPassword: PropTypes.bool.isRequired,
  setModalPassword: PropTypes.func.isRequired,
  formik: PropTypes.objectOf(
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
    PropTypes.number
  ).isRequired,
};

export default ModalPassword;
