import styled from 'styled-components';

export const ContainerModelOptions = styled.div`
  height: 80px;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'headermodel headermodel headermodel' 'inputModel inputModel inputModel';
  grid-gap: 10px;
  margin-bottom: 7px;
  > header {
    grid-area: headermodel;
    font-size: 18px;
  }

  > label {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
    > span {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

export const ContainerModel = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
`;
