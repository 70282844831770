import styled from 'styled-components';

const simpleInput = styled.input`
  width: 100%;
  height: ${({ height }) => height || '40px'};
  margin: ${({ margin }) => margin || '5px auto'};
  padding: 0 10px;
  background: ${({ background, error }) =>
    error ? 'rgba(255,0,0,0.2)' : background || 'rgba(0, 0, 0, 0.01)'};
  color: ${({ color, error }) => (error ? 'rgba(255,0,0,1)' : color || '#333')};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  border: 1px solid #333;
  font-size: 16px;
  border: 1px solid ${({ error }) => (error ? 'rgba(257,0,0, 1)' : '#ddd')};
  text-transform: ${({ plate }) => plate && 'uppercase'};
  grid-area: ${({ area }) => area};
  :disabled {
    background: #dbdbdb;
  }
`;

export default simpleInput;
