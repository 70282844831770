export default function translateWeek(day) {
  let dayTranslate = '';
  switch (day) {
    case 'monday':
      dayTranslate = 'Segunda-feira';
      break;
    case 'tuesday':
      dayTranslate = 'Terça-feira';
      break;
    case 'wednesday':
      dayTranslate = 'Quarta-feira';
      break;
    case 'thursday':
      dayTranslate = 'Quinta-feira';
      break;
    case 'friday':
      dayTranslate = 'Sexta-feira';
      break;
    case 'saturday':
      dayTranslate = 'Sábado';
      break;
    case 'sunday':
      dayTranslate = 'Domingo';
      break;

    default:
      dayTranslate = '';
      break;
  }
  return dayTranslate;
}
