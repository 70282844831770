import styled from 'styled-components';

export const ModalDetail = styled.div`
  height: 100px;
  width: 300px;
  font-size: 15px;
  padding: 10px;
  width: 300px;
`;

export const ModalButtons = styled.div`
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
`;
