import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _, { filter } from 'lodash';

import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import Group from '../Group';
import Context from './Contex';
import 'rc-tooltip/assets/bootstrap.css';
import Filters from './Filters';
import GroupSelect from './GroupSelect';
import Modal from './Modal';
import { CarContainer, ContainerDiv } from './styles';
import Car from './Car';
import { SelectStyled } from '../Global';
// import SimpleField from '../fields/Simple';

export default function CarPanel() {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [criteria, setCriteria] = useState('');
  const [filterType, setFilterType] = useState('Placa/Identificador');

  const [height, setHeight] = useState(20);
  const groupSelected = useSelector(
    ({ bufferState }) => bufferState.groupSelected
  );
  const viewGroup = useSelector((state) => state.bufferState.viewGroup);

  useEffect(() => {
    const heightContainer =
      document.getElementById('container').offsetHeight - 112;
    setHeight(heightContainer);
  }, []);
  window.addEventListener('resize', () => {
    if (!_.isEmpty(document.getElementById('container'))) {
      const heightContainer =
        document.getElementById('container').offsetHeight - 112;
      setHeight(heightContainer);
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(BufferActions.bufferRequest());
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function setViewModal() {
    setModal(!modal);
  }

  return (
    <Context.Provider value={{ modal, setViewModal }}>
      <div
        className="bg-white p-2 h-full w-full rounded-lg shadow"
        id="container"
      >
        {!viewGroup ? (
          <>
            <GroupSelect />
            <Filters />
            <SelectStyled
              name="type"
              defaultValue={{
                label: filterType,
                value: filterType,
              }}
              onChange={(option) => setFilterType(option.value)}
              value={{
                label: filterType,
                value: 'Placa/Identificador',
              }}
              options={[
                { label: 'Velocidade', value: 'Velocidade' },
                {
                  label: 'Tempo Offline/Parado',
                  value: 'Tempo Offline/Parado',
                },
                { label: 'Placa/Identificador', value: 'Placa/Identificador' },
              ]}
              placeholder="Filtrar por..."
            />
            <input
              id="_inputr6z1J6M5F6Doc0N1O440G"
              style={{
                padding: '0px 5px',
                margin: '0px',
                marginTop: '3px',
                marginBottom: '3px',
                height: '30px',
                fontSize: criteria ? 'large' : '',
                fontWeight: criteria ? 600 : 400,
              }}
              data-enpassusermodified="yes"
              placeholder="pesquisar por..."
              type={filterType === 'Velocidade' ? 'number' : 'text'}
              onInput={(e) => setCriteria(e.target.value)}
            />

            {/* <SimpleField style={{ padding: '0px 5px', height: '30px' }} /> */}
            <CarContainer heightSet={`${height - 15}px`}>
              <Car filter={criteria} filterType={filterType} />
            </CarContainer>
            <Modal />
          </>
        ) : (
          <ContainerDiv heightSet={`${height + 90}px`}>
            <Group groupSelected={groupSelected || null} />
          </ContainerDiv>
        )}
      </div>
    </Context.Provider>
  );
}
