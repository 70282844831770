import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as TrackerActions } from '~/store/ducks/trackerSauce';
import { getClientId } from '~/util/parseJwt';

function Table() {
  const dispatch = useDispatch();
  const trackersState = useSelector((state) => state.trackerState.trackers);
  const loading = useSelector((state) => state.trackerState.loading);
  const [trackers, setTrackers] = useState(trackersState);
  useEffect(() => {
    setTrackers([]);
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    dispatch(
      TrackerActions.fetchTrackerRequest(
        filterClient ? `?id_clients=${filterClient}` : undefined
      )
    );
  }, []);

  useEffect(() => {
    setTrackers([...trackersState]);
  }, [trackersState]);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={trackers.map((item) => ({
          ...item,
          active: item.active ? 'Ativo' : 'Inativo',
        }))}
        headerBackground="#245378"
        loading={loading}
        labels={['Placa', 'Identificador', 'Cor', 'Status', 'Ano']}
        headers={['plate', 'label', 'color', 'active', 'year']}
        fileTitle="Relatório de itens de rastreamento"
        getData={(data) => history.push(`/tracker/form/${data.id}`)}
      />
    </div>
  );
}

export default Table;
