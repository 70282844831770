import { toast } from 'react-toastify';

import _ from 'lodash';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/fenceSauce';

export function* getFence({ query }) {
  try {
    // console.log('getFence', query);
    const { data } = yield call(api.fetchGeometries, query);
    if (!_.isArray(data)) {
      yield put({
        type: Types.FETCH_FENCE_ID_SUCCESS,
        data,
      });
    } else {
      yield put({
        type: Types.FETCH_FENCE_SUCCESS,
        data,
      });
    }
  } catch (error) {
    toast.error(JSON.stringify(error));
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createFenceSaga(payload) {
  try {
    yield call(api.createGeometry, payload.data);
    yield put({
      type: Types.FETCH_FENCE_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
  } catch (error) {
    yield put({ type: Types.FETCH_FENCE_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateFenceSaga({ data }) {
  try {
    yield call(api.updateGeometries, data);
    yield put({
      type: Types.FETCH_FENCE_REQUEST,
      query: '?type=fence',
    });
    toast.success('Cerca alterada com sucesso!');
  } catch (error) {
    toast.error('Ocorreu um erro ao alterar a cerca!');
    yield put({ type: Types.FETCH_FENCE_FAILURE });
  }
}

export function* getDataLoadAll({ fences }) {
  try {
    // eslint-disable-next-line no-plusplus
    for (let idx = 0; idx < fences.length; idx++) {
      const { data } = yield call(api.fetchGeometries, `/${fences[idx]}`);
      yield put({
        type: Types.FETCH_FENCE_ID_SUCCESS,
        data,
      });
    }
    yield put({ type: Types.LOAD_ALL_SUCCESS });
  } catch (error) {
    toast.error(JSON.stringify(error));
    yield put({ type: Types.LOAD_ALL_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_FENCE_REQUEST, getFence)]);
  yield all([takeEvery(Types.CREATE_FENCE_REQUEST, createFenceSaga)]);
  yield all([takeEvery(Types.UPDATE_FENCE_REQUEST, updateFenceSaga)]);
  yield all([takeEvery(Types.LOAD_ALL, getDataLoadAll)]);
}
