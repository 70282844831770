import exportCsv from '../csv/index';
import * as Excel from 'exceljs';
import Filesaver from 'file-saver';

const formatCsv = (data, headers, fileTitle) => {
  const csv = exportCsv(data, headers, fileTitle, true).split('\r\n');
  return csv.map((register) => register.split(';'));
};


/**
 * Gera relatorio no formato de planilha Excel (.xlsx)
 * @param {*} data dados a serem tratados
 * @param {*} headers cabecalhos do relatorio
 * @param {*} fileTitle titulo do arquivo
 */
export default async function exportXls(data, headers, fileTitle) {
  // Formata os dados da funcao
  const csv = formatCsv(data, headers, fileTitle);
  let registerLength = csv[0].length + 1

  /**
   * CRIA A PLANILHA DO EXCEL E ADICIONA OS DADOS DENTRO DA WORKSHEET DA PLANILHA
   * 
   * workbook = planilha em si
   * worksheet = "folha" da planilha
   */
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet('Planilha 1');
  worksheet.addRows(csv);
  while(registerLength-- !== 1) {
    const col = worksheet.getColumn(registerLength)
    console.log(col)
    col.width = 30
  }


  /** 
   *  CRIA UM BUFFER DA PLANILHA E CONVERTE ELE PARA UM BLOB
   * 
   * O Blob é usado nesse caso porque é uma maneira eficiente
   * de armazenar e transmitir grandes quantidades de dados
   * binários sem precisar carregar tudo na memória de uma só vez.
   * Ele é especialmente útil quando se trabalha com arquivos muito
   * grandes que não podem ser lidos inteiros de uma vez.
   */
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',   /**protocolo MIME para arquivos .xlsx */
  });

  // salva o arquivo que foi requisitado :)
  Filesaver.saveAs(blob, `${fileTitle}.xlsx`);
}
