import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchPreventiveRequest: ['query', 'params'],
  fetchPreventiveSuccess: ['data'],
  fetchPreventiveIdSuccess: ['data'],
  fetchPreventiveFailure: [],
  updatePreventiveRequest: ['data'],
  createPreventiveRequest: ['data'],
  clearPreventive: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  preventives: [],
  preventive: {},
  preventiveByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearPreventive = (state = INITIAL_STATE) => ({
  ...state,
  preventive: {},
});

const fetchPreventiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchPreventiveSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  preventives: actions.data,
  loading: false,
});

const fetchPreventiveIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    preventive: actions.data,
    loading: false,
  };
};

const fetchPreventiveFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    preventives: [],
  };
};

const createPreventiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updatePreventiveRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_PREVENTIVE_REQUEST]: fetchPreventiveRequest,
  [Types.FETCH_PREVENTIVE_SUCCESS]: fetchPreventiveSuccess,
  [Types.FETCH_PREVENTIVE_ID_SUCCESS]: fetchPreventiveIdSuccess,
  [Types.FETCH_PREVENTIVE_FAILURE]: fetchPreventiveFailure,
  [Types.UPDATE_PREVENTIVE_REQUEST]: updatePreventiveRequest,
  [Types.CREATE_PREVENTIVE_REQUEST]: createPreventiveRequest,
  [Types.CLEAR_PREVENTIVE]: clearPreventive,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
