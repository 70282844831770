import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';

import { ButtonStyled } from '~/components/Global';
import PasswordInput from '~/components/Global/Inputs/passwordInput';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
} from './styles';

function Modal() {
  const dispatch = useDispatch();
  const { device, id } = useSelector((state) => state.commandState.tracker);
  const { output1, output2, output3 } = useSelector(
    (state) => state.commandState.buffer
  );

  function getStatusBLock() {
    if (device.output1 === 'BLOCK') {
      return output1;
    }
    if (device.output2 === 'BLOCK') {
      return output2;
    }
    if (device.output3 === 'BLOCK') {
      return output3;
    }
    return false;
  }

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validate: (value) => {
      const err = {};
      if (!value.password) err.password = 'Campo obrigatório!';
      return err;
    },
    onSubmit: (data) => {
      dispatch(
        CommandActions.createCommand({
          cars: [id],
          type: getStatusBLock() ? 'unlock' : 'lock',
          password: data.password,
        })
      );
    },
  });

  function closeModal() {
    dispatch(CommandActions.setViewModal(null));
  }

  return (
    <ModalContainer onSubmit={formik.handleSubmit}>
      <Title>{getStatusBLock() ? 'DESBLOQUEIO' : 'BLOQUEIO'}</Title>
      <Descripition>
        <PasswordInput
          required
          placeholder="senha"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={!!(formik.touched.password && formik.errors.password)}
        />
      </Descripition>
      <ButtonsContainer>
        <ButtonStyled
          color="#ff4e4e"
          textColor="#eee"
          onClick={() => closeModal()}
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled type="subbmit" color="#01659e" textColor="#eee">
          Enviar
        </ButtonStyled>
      </ButtonsContainer>
    </ModalContainer>
  );
}

export default Modal;
