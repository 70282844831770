import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchResponsibleRequest: ['query', 'params'],
  fetchResponsibleSuccess: ['data'],
  fetchResponsibleIdSuccess: ['data'],
  fetchResponsibleFailure: [],
  updateResponsibleRequest: ['data', 'id'],
  deleteResponsibleRequest: ['id'],
  createResponsibleRequest: ['data'],
  clearResponsible: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  responsibles: [],
  responsible: {},
  responsibleByTracker: {},
  loading: false,
  page: 1,
  pageSize: 2,
  params: '',
};

const clearResponsible = (state = INITIAL_STATE) => ({
  ...state,
  responsible: {},
});

const fetchResponsibleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  responsibles: [],
});

const fetchResponsibleSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  responsibles: actions.data,
  loading: false,
});

const fetchResponsibleIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    responsible: actions.data,
    loading: false,
  };
};

const fetchResponsibleFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    responsibles: [],
  };
};

const createResponsibleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateResponsibleRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_RESPONSIBLE_REQUEST]: fetchResponsibleRequest,
  [Types.FETCH_RESPONSIBLE_SUCCESS]: fetchResponsibleSuccess,
  [Types.FETCH_RESPONSIBLE_ID_SUCCESS]: fetchResponsibleIdSuccess,
  [Types.FETCH_RESPONSIBLE_FAILURE]: fetchResponsibleFailure,
  [Types.UPDATE_RESPONSIBLE_REQUEST]: updateResponsibleRequest,
  [Types.CREATE_RESPONSIBLE_REQUEST]: createResponsibleRequest,
  [Types.CLEAR_RESPONSIBLE]: clearResponsible,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
