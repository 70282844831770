import chroma from 'chroma-js';

export default {
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '10px',
  }),
  option: (styles, { data: { status }, isDisabled, isFocused, isSelected }) => {
    const color = chroma(status ? '#245378' : '#ff0000');
    let backgroundColor = color.alpha(0.5).css();
    let colorText = null;
    if (isSelected) {
      backgroundColor = color.css();
      colorText = '#fff';
    } else if (isFocused) {
      backgroundColor = color.alpha(0.3).css();
      colorText = color.darken(0.3).css();
    } else if (status) {
      backgroundColor = '#fff';
    }
    return {
      ...styles,
      backgroundColor,
      color: colorText,
      cursor: 'pointer',
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? '#245378' : '#ff0000'),
      },
    };
  },
};
