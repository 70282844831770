import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import { MdContentCopy } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { ButtonStyled } from '~/components/Global';
import axios from '~/services/axios';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import { Container, RadioContainer, ShareUrl } from './styles';

export default function ModalShare({ tracker }) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState('');
  const visible = useSelector((state) => state.commandState.modalShare);
  const { label, tracker_id } = tracker;
  const formik = useFormik({
    initialValues: {
      hours: 1,
    },
    onSubmit: async (values) => {
      const { data } = await axios.post('/share', {
        tracker_id,
        hours: Math.floor(values.hours),
      });
      setUrl(data.url);
    },
  });
  async function getUrl() {
    const { data } = await axios.post('/share', {
      tracker_id,
      hours: Math.floor(formik.values.hours),
    });
    setUrl(data.url);
  }

  useEffect(() => {
    formik.setValues({ ...formik.values, tracker_id, password: '' });
  }, [tracker]);

  useEffect(() => {
    if (tracker_id) getUrl();
  }, [formik.values.hours]);

  useEffect(() => {
    if (tracker_id) getUrl();
  }, [tracker_id]);

  function handleClose() {
    dispatch(CommandActions.setModalShare());
  }

  function copyUrl() {
    navigator.clipboard.writeText(url).then(
      () => {
        toast.success('URL copiada para área de transferência');
      },
      () => {
        toast.error('Erro ao copiar URL para área de transferência');
      }
    );
  }

  return (
    <Modal
      visible={visible}
      onClickAway={() => handleClose()}
      effect="fadeInUp"
      width="400px"
      height="366px"
    >
      <Container onSubmit={formik.handleSubmit}>
        <header>Compartilhamento de carro</header>
        <div>
          <strong>Tempo de compartilhamento:</strong>
          <RadioContainer>
            <label htmlFor="umahora">
              <input
                id="umahora"
                type="radio"
                value={1}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 1}
              />
              1 Hora
            </label>
            <label htmlFor="tresHoras">
              <input
                id="tresHoras"
                type="radio"
                value={3}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 3}
              />
              3 Horas
            </label>
            <label htmlFor="seisHoras">
              <input
                id="seisHoras"
                type="radio"
                value={6}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 6}
              />
              6 Horas
            </label>
            <label htmlFor="dozeHoras">
              <input
                id="dozeHoras"
                type="radio"
                value={12}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 12}
              />
              12 Horas
            </label>
            <label htmlFor="vinteQuatroHoras">
              <input
                id="vinteQuatroHoras"
                type="radio"
                value={24}
                name="hours"
                onChange={formik.handleChange}
                defaultChecked={formik.values.hours === 24}
              />
              24 Horas
            </label>
          </RadioContainer>
          <ShareUrl>
            <div>{url}</div>
            <button
              className="flex items-center justify-center"
              type="button"
              onClick={() => copyUrl()}
            >
              <MdContentCopy size="20" />
            </button>
          </ShareUrl>
        </div>
        <footer>
          <ButtonStyled
            color="#E33939"
            textColor="#fff"
            onClick={() => handleClose()}
          >
            Cancelar
          </ButtonStyled>
        </footer>
      </Container>
    </Modal>
  );
}

ModalShare.propTypes = {
  tracker: PropTypes.objectOf.isRequired,
};
