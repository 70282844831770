import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import Actions from './Actions';
import Detail from './Detail';
import Filter from './Filter';
import Historic from './Historic';
import Map from './Map';
import Modal from './Modal';
import NoData from './NoData';
import { Container, MainContainer } from './styles';

function CommandNew() {
  const tracker_id = useSelector((state) => state.commandState.tracker_id);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(CommandActions.clearTrackerId());
  }, []);

  return (
    <Container>
      <Filter />
      {tracker_id ? (
        <MainContainer>
          <Actions />
          <Detail />
          <Historic />
          <Map />
          <Modal />
        </MainContainer>
      ) : (
        <NoData />
      )}
    </Container>
  );
}

export default CommandNew;
