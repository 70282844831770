import React, { useState, useEffect } from 'react';

import { CheckField } from '~/components/fields';
import api from '~/services/api';
import SimpleButton from '~/components/buttons/Simple';

export default function ReferencePoint({ match, formik, editablePermission }) {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('carregando, aguarde...');
  const [searchInput, setSearchInput] = useState('');
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [referencePoints, setReferencePoints] = useState([]);

  useEffect(() => {
    (async () => {
      setLoadingText('carregando, aguarde...');
      setLoading(true);
      const response = await api.fetchGeometries(
        `?type=point&tracker=${match.params.id}`
        // `?type=point`
      );
      if (response.status === 200) {
        setReferencePoints(
          response.data
            .filter((item) => item.type === 'point')
            .map((item) => ({ ...item, name: item.name.toUpperCase() }))
        );
      }
      setLoading(false);
    })();
  }, []);

  function searchReferencePoints() {
    const sortedReferencePoints = referencePoints
      .filter((i) => !showSelectedOnly || (showSelectedOnly && i.checked))
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    if (referencePoints.length && searchInput.trim()) {
      return sortedReferencePoints.filter((item) =>
        item.name.includes(searchInput)
      );
    }
    return sortedReferencePoints;
  }

  async function selectAll() {
    setLoadingText('atualizando, aguarde...');
    setLoading(true);
    try {
      await api.trackerAddPoint({
        trackerId: match.params.id,
        pointId: 'all',
      });
      setReferencePoints(
        referencePoints.map((i) => {
          i.checked = true;
          return i;
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function invertSelection() {
    setLoadingText('atualizando, aguarde...');
    setLoading(true);

    try {
      await api.trackerInvertAllPoints({
        trackerId: match.params.id,
      });
      setReferencePoints(
        referencePoints.map((i) => {
          i.checked = !i.checked;
          return i;
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function cleanSelection() {
    setLoadingText('atualizando, aguarde...');
    setLoading(true);

    try {
      await api.trackerRemovePoint({
        trackerId: match.params.id,
        pointId: 'all',
      });
      setReferencePoints(
        referencePoints.map((i) => {
          i.checked = false;
          return i;
        })
      );
    } finally {
      setLoading(false);
    }
  }

  async function toggleShowSelectedOnly() {
    await setShowSelectedOnly(!showSelectedOnly);
  }

  return (
    <>
      <header className="text-lg">
        Pontos de referência atrelados ao veículo{' '}
        <strong>{formik.values.label}</strong>.
      </header>
      <section>
        <span>
          <strong style={{ marginRight: '4px' }}>
            {referencePoints.length}
          </strong>{' '}
          itens no total
        </span>
        <span style={{ marginLeft: '8px' }}>
          {referencePoints.filter((i) => i.checked).length === 0 &&
            'nenhum item selecionado'}
          {referencePoints.filter((i) => i.checked).length > 0 && (
            <>
              <strong style={{ marginRight: '4px' }}>
                {referencePoints.filter((i) => i.checked).length}
              </strong>
              {`ite${
                referencePoints.filter((i) => i.checked).length > 1 ? 'ns' : 'm'
              } selecionado${
                referencePoints.filter((i) => i.checked).length > 1 ? 's' : ''
              }`}
            </>
          )}
        </span>
      </section>
      <section>
        <input
          onChange={({ target }) => {
            setLoading(true);
            const result = setSearchInput(target.value.toUpperCase());
            setLoading(false);
            return result;
          }}
          type="text"
          placeholder="pesquisar por..."
          style={{
            height: '30px',
            width: '530px',
            margin: 'auto',
            border: 'solid 1px silver',
            borderRadius: '10px',
            padding: '5px 10px',
          }}
        />
      </section>
      <section style={{ marginTop: '10px' }}>
        <SimpleButton width="w-40" height="h-8" onClick={selectAll}>
          Selecionar Todos
        </SimpleButton>
        <SimpleButton width="w-40" height="h-8" onClick={cleanSelection}>
          Limpar Seleção
        </SimpleButton>
        <SimpleButton width="w-40" height="h-8" onClick={invertSelection}>
          Inverter Seleção
        </SimpleButton>
        <div style={{ display: 'inline' }}>
          <input
            id="showSelectedOnly"
            type="checkbox"
            checked={showSelectedOnly}
            onChange={toggleShowSelectedOnly}
            style={{ marginRight: '5px', cursor: 'pointer' }}
          />
          <label
            for="showSelectedOnly"
            title="exibir somente os itens selecionados"
            style={{ cursor: 'pointer' }}
          >
            Exibir somente os selecionados {showSelectedOnly}
          </label>
        </div>
      </section>
      <section
        className="referencePointList"
        style={{ height: '65vh', overflow: 'overlay' }}
      >
        {/* <PerfectScroll style={{ overflow: 'overlay' }}> */}
        {loading ? (
          <div style={{ paddingTop: '10px', fontSize: '20px' }}>
            <span> {loadingText} </span>
          </div>
        ) : (
          searchReferencePoints().map((item, index) => {
            return (
              <div
                key={`container_tracker_point_${item.id}`}
                style={{ paddingTop: '10px', fontSize: '20px' }}
              >
                <CheckField
                  key={`tracker_point_${item.id}`}
                  id={`tracker_point_${item.id}`}
                  disabled={!editablePermission}
                  type="checkbox"
                  value={item.id}
                  label={item.name}
                  checked={item.checked}
                  onClick={({ target }) => {
                    const _changeItem = referencePoints.find(
                      (changeItem) => changeItem.id === item.id
                    );
                    if (_changeItem && _changeItem.id) {
                      target.checked === true
                        ? api.trackerAddPoint({
                            trackerId: match.params.id,
                            pointId: _changeItem.id,
                          })
                        : api.trackerRemovePoint({
                            trackerId: match.params.id,
                            pointId: _changeItem.id,
                          });
                      _changeItem.checked = target.checked;
                      setReferencePoints([...referencePoints]);
                    }
                  }}
                />
              </div>
            );
          })
        )}
        {/* </PerfectScroll> */}
      </section>
    </>
  );
}
