import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { MdCheck, MdClear } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import FineList from '~/assets/json/fineList.json';
import FineLevel from '~/assets/json/level.json';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  FormDataContainerDiv,
  DataContainer,
  ProfileContainer,
  FieldContainer,
  Row,
  LabelStyled,
  ErrorMessage,
  DateStyle,
  SelectStyled,
  NoRow,
  DoubleRow,
  DescContainer,
  TextAreaStyled,
  ButtonContainer,
  Currency,
} from '~/components/Global';
import SelectDriver from '~/components/Global/SelectDriver';
import SelectTracker from '~/components/Global/SelectTracker';
import Loading from '~/components/Loading';
import history from '~/services/history';
import { Creators as ViolationActions } from '~/store/ducks/violationSauce';

function Forms({
  match: {
    params: { id: idParam },
  },
}) {
  const dispatch = useDispatch();
  const violation = useSelector((state) => state.violationState.violation);
  const loading = useSelector((state) => state.violationState.loading);
  const me = useSelector((state) => state.globalState.me);
  registerLocale('pt-BR', el);
  const [editablePermission, setEditablePermission] = useState(false);
  const formik = useFormik({
    initialValues: {
      tracker_id: '',
      driver_id: '',
      date: '',
      reason: '',
      level: '',
      price: '',
      expiry: '',
      description: '',
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.tracker_id) err.tracker_id = message;
      if (!values.driver_id) err.driver_id = message;
      if (!values.date) err.date = message;
      if (!values.reason) err.reason = message;
      if (!values.level) err.level = message;
      if (!values.price) err.price = message;
      if (!values.expiry) err.expiry = message;
      if (values.date !== '' && values.expiry !== '') {
        if (values.date > values.expiry) {
          toast.error(
            'Data da Multa não pode ser superior à Data de Vencimento.'
          );
          err.date = message;
          err.expiry = message;
        }
      }
      return err;
    },
    onSubmit: (data) => {
      if (data.price.toString().includes('R$')) {
        data.price = data.price.toString().replace('R$ ', '');
      }
      if (data.id) {
        dispatch(ViolationActions.updateViolationRequest(data));
      } else {
        dispatch(ViolationActions.createViolationRequest(data));
      }
      dispatch(ViolationActions.clearViolation(data));
    },
  });

  useEffect(() => {
    if (idParam) {
      dispatch(ViolationActions.fetchViolationRequest(`/${idParam}`));
    }
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  function cancelButton() {
    history.push('/violation');
    dispatch(ViolationActions.clearViolation());
  }

  useEffect(() => {
    if (!_.isEmpty(violation)) {
      formik.setValues({
        ...violation,
        date: moment(violation.date).toDate(),
        expiry: moment(violation.expiry).toDate(),
      });
    }
  }, [violation]);

  function getHeaderComponent() {
    if (editablePermission) {
      if (loading) {
        return (
          <ButtonContainer disabled color="#2763c4" type="button">
            Aguarde
          </ButtonContainer>
        );
      }
      return (
        <div className="flex">
          <ButtonContainer
            color="#E33939"
            type="button"
            onClick={() => cancelButton()}
          >
            <MdClear size={15} />
            Cancelar
          </ButtonContainer>
          <ButtonContainer color="#555" type="submit">
            <MdCheck size={15} />
            Salvar
          </ButtonContainer>
        </div>
      );
    }
    return (
      <ButtonContainer type="button" onClick={() => cancelButton()}>
        <MdCheck size={15} />
        Voltar
      </ButtonContainer>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de infrações">
          {getHeaderComponent()}
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <FormDataContainerDiv>
              <DataContainer>
                <ProfileContainer>
                  <Row>
                    <FieldContainer>
                      {(idParam && formik.values.tracker_id) || !idParam ? (
                        <SelectTracker
                          defaultValue={formik.values.tracker_id}
                          parentCallback={(option) =>
                            formik.setFieldValue('tracker_id', option.id)
                          }
                          error={
                            formik.touched.tracker_id &&
                            formik.errors.tracker_id
                          }
                          isDisabled={!editablePermission}
                          value={formik.values.tracker_id}
                        />
                      ) : (
                        <></>
                      )}
                    </FieldContainer>
                    <FieldContainer>
                      {(idParam && formik.values.driver_id) || !idParam ? (
                        <SelectDriver
                          defaultValue={formik.values.driver_id}
                          parentCallback={(option) =>
                            formik.setFieldValue('driver_id', option.id)
                          }
                          error={
                            formik.touched.driver_id && formik.errors.driver_id
                          }
                          isDisabled={!editablePermission}
                          value={formik.values.driver_id}
                        />
                      ) : (
                        <></>
                      )}
                    </FieldContainer>
                    <FieldContainer>
                      <div>
                        <LabelStyled>Gravidade</LabelStyled>
                        {formik.touched.level && formik.errors.level ? (
                          <ErrorMessage>{formik.errors.level}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      {(idParam && formik.values.level) || !idParam ? (
                        <SelectStyled
                          // styles={style}
                          options={FineLevel}
                          placeholder="Selecione a gravidade da violação"
                          noOptionsMessage={() => 'Nenhum gravidade encontrada'}
                          onChange={(option) =>
                            formik.setFieldValue('level', option.label)
                          }
                          defaultValue={
                            FineLevel[
                              FineLevel.findIndex(
                                (item) => item.label === formik.values.level
                              )
                            ]
                          }
                          error={formik.touched.level && formik.errors.level}
                          isDisabled={!editablePermission}
                        />
                      ) : (
                        <></>
                      )}
                    </FieldContainer>
                  </Row>
                  <Row>
                    <FieldContainer>
                      <div>
                        <LabelStyled>Valor</LabelStyled>
                        {formik.touched.price && formik.errors.price ? (
                          <ErrorMessage>{formik.errors.price}</ErrorMessage>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <Currency
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix="R$"
                          precision="2"
                          onChangeEvent={(e, masked, float) =>
                            formik.setFieldValue('price', float)
                          }
                          value={formik.values.price}
                          id="price"
                          name="price"
                          error={formik.errors.price && formik.touched.price}
                          disabled={!editablePermission}
                        />
                      </div>
                    </FieldContainer>
                    <FieldContainer>
                      <div>
                        <LabelStyled>Data da Multa</LabelStyled>
                        {formik.touched.date && formik.errors.date ? (
                          <ErrorMessage>{formik.errors.date}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <DateStyle
                        dateFormat="dd/MM/yyyy"
                        selected={formik.values.date}
                        onChange={(value) =>
                          formik.setFieldValue('date', moment(value).toDate())
                        }
                        locale={pt}
                        customInput={<InputMask mask="99/99/9999" />}
                        error={formik.touched.date && formik.errors.date}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                    <FieldContainer>
                      <div>
                        <LabelStyled>Data de Vencimento</LabelStyled>
                        {formik.touched.expiry && formik.errors.expiry ? (
                          <ErrorMessage>{formik.errors.expiry}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <DateStyle
                        dateFormat="dd/MM/yyyy"
                        selected={formik.values.expiry}
                        onChange={(value) =>
                          formik.setFieldValue('expiry', moment(value).toDate())
                        }
                        locale={pt}
                        customInput={<InputMask mask="99/99/9999" />}
                        error={formik.touched.expiry && formik.errors.expiry}
                        disabled={!editablePermission}
                      />
                    </FieldContainer>
                  </Row>
                  <DoubleRow>
                    <FieldContainer>
                      <div>
                        <LabelStyled>Motivo</LabelStyled>
                        {formik.touched.reason && formik.errors.reason ? (
                          <ErrorMessage>{formik.errors.reason}</ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      {(idParam && formik.values.reason) || !idParam ? (
                        <SelectStyled
                          options={FineList}
                          getOptionLabel={(option) => option.value}
                          placeholder="Selecione o motivo da violação"
                          noOptionsMessage={() => 'Nenhuma opção encontrada'}
                          onChange={(option) =>
                            formik.setFieldValue('reason', option.value)
                          }
                          defaultValue={
                            FineList[
                              FineList.findIndex(
                                (item) => item.value === formik.values.reason
                              )
                            ]
                          }
                          error={formik.touched.reason && formik.errors.reason}
                          isDisabled={!editablePermission}
                        />
                      ) : (
                        <></>
                      )}
                    </FieldContainer>
                  </DoubleRow>
                  <NoRow>
                    <DescContainer full>
                      <div>
                        <LabelStyled>Descrição</LabelStyled>
                        {formik.touched.description &&
                        formik.errors.description ? (
                          <ErrorMessage>
                            {formik.errors.description}
                          </ErrorMessage>
                        ) : (
                          ''
                        )}
                      </div>
                      <TextAreaStyled
                        rows="3"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                        error={
                          formik.touched.description &&
                          formik.errors.description
                        }
                        disabled={!editablePermission}
                      />
                    </DescContainer>
                  </NoRow>
                </ProfileContainer>
              </DataContainer>
            </FormDataContainerDiv>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};

Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
