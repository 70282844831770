import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchMaintenanceRequest: ['query', 'params'],
  fetchMaintenanceSuccess: ['data'],
  fetchMaintenanceIdSuccess: ['data'],
  fetchMaintenanceFailure: [],
  updateMaintenanceRequest: ['data'],
  createMaintenanceRequest: ['data'],
  clearMaintenance: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  maintenances: [],
  maintenance: {},
  maintenanceByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearMaintenance = (state = INITIAL_STATE) => ({
  ...state,
  maintenance: {},
});

const fetchMaintenanceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchMaintenanceSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  maintenances: actions.data,
  loading: false,
});

const fetchMaintenanceIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    maintenance: actions.data,
    loading: false,
  };
};

const fetchMaintenanceFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    maintenances: [],
  };
};

const createMaintenanceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateMaintenanceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_MAINTENANCE_REQUEST]: fetchMaintenanceRequest,
  [Types.FETCH_MAINTENANCE_SUCCESS]: fetchMaintenanceSuccess,
  [Types.FETCH_MAINTENANCE_ID_SUCCESS]: fetchMaintenanceIdSuccess,
  [Types.FETCH_MAINTENANCE_FAILURE]: fetchMaintenanceFailure,
  [Types.UPDATE_MAINTENANCE_REQUEST]: updateMaintenanceRequest,
  [Types.CREATE_MAINTENANCE_REQUEST]: createMaintenanceRequest,
  [Types.CLEAR_MAINTENANCE]: clearMaintenance,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
