import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  TrackersField,
  SelectField,
  DateField,
  MaskedField,
  CurrencyField,
  AreaField,
} from '~/components/fields';
import Loading from '~/components/Loading';
import { Creators as MaintenanceActions } from '~/store/ducks/maintenanceSauce';

import Header from './Header';
import maintenanceTypeOptions from './maintenanceTypeOptions.json';
import typeOptions from './typeOptions.json';

function Forms({
  match: {
    params: { id: idParam },
  },
}) {
  const dispatch = useDispatch();

  const maintenance = useSelector(
    (state) => state.maintenanceState.maintenance
  );
  const loading = useSelector((state) => state.maintenanceState.loading);
  registerLocale('pt-BR', el);
  const [editablePermission] = useState(true);
  const [defaultValueLoading, setDefaultLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      tracker_id: '',
      date_start: '',
      date_end: '',
      price: 0,
      description: '',
      type: '',
      maintenanceType: '',
      marker: 0,
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.tracker_id) err.tracker_id = message;
      if (!values.date_start) err.date_start = message;
      // if (!values.price) err.price = message;
      // if (!values.description) err.description = message;
      if (!values.type) err.type = message;
      if (!values.maintenanceType) err.maintenanceType = message;
      if (values.date_start !== '' && values.date_end !== '') {
        if (values.date_start > values.date_end) {
          toast.error('Data da Início não pode ser superior à Data Final.');
          err.date_start = message;
          err.date_end = message;
        }
      }
      return err;
    },
    onSubmit: (data) => {
      data.price = data.price.toString().replace('R$ ', '');
      if (data.marker === '') {
        data.maker = 0;
      }
      if (data.id) {
        dispatch(MaintenanceActions.updateMaintenanceRequest(data));
      } else {
        dispatch(MaintenanceActions.createMaintenanceRequest(data));
      }
      dispatch(MaintenanceActions.clearMaintenance());
    },
  });

  useEffect(() => {
    if (idParam) {
      dispatch(MaintenanceActions.fetchMaintenanceRequest(`/${idParam}`));
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(maintenance) && idParam) {
      formik.setValues({
        ...maintenance,
        date_start: moment(maintenance.date_start).toDate(),
        date_end: moment(maintenance.date_end).toDate(),
      });
      setDefaultLoading(false);
    }
    if (!idParam) {
      setDefaultLoading(false);
    }
  }, [maintenance]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de manutenção">
          <Header editablePermission={editablePermission} idParam={idParam} />
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <div className="grid grid-cols-3 gap-2">
              {!defaultValueLoading && (
                <TrackersField
                  label="Veículo"
                  onChange={({ value }) =>
                    formik.setFieldValue('tracker_id', value)
                  }
                  error={formik.touched.tracker_id && formik.errors.tracker_id}
                  defaultValue={formik.values.tracker_id}
                  isDisabled={!editablePermission}
                  // value={formik.values.tracker_id}
                />
              )}
              {!defaultValueLoading && (
                <SelectField
                  label="Tipo de Manutenção"
                  placeholder="Selecione o tipo de manutenção"
                  options={maintenanceTypeOptions}
                  noOptionsMessage={() => 'Nenhuma opção encontrada'}
                  onChange={(option) =>
                    formik.setFieldValue('maintenanceType', option.value)
                  }
                  defaultValue={
                    maintenanceTypeOptions[
                      maintenanceTypeOptions.findIndex(
                        (item) => item.value === formik.values.maintenanceType
                      )
                    ]
                  }
                  isDisabled={!editablePermission}
                />
              )}
              {!defaultValueLoading && (
                <SelectField
                  label="Manutenção"
                  options={typeOptions}
                  placeholder="Selecione a manutenção"
                  noOptionsMessage={() => 'Nenhuma opção encontrada'}
                  onChange={(option) =>
                    formik.setFieldValue('type', option.value)
                  }
                  defaultValue={typeOptions.find(
                    ({ value }) => value === formik.values.type
                  )}
                  isDisabled={!editablePermission}
                />
              )}

              <DateField
                label="Data de Início"
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm:ss"
                selected={formik.values.date_start}
                onChange={(value) =>
                  formik.setFieldValue(
                    'date_start',
                    moment(value).toDate('DD/MM/YYYY HH:mm:ss')
                  )
                }
                locale={pt}
                customInput={<InputMask mask="99/99/9999 99:99" />}
                disabled={!editablePermission}
              />
              <DateField
                label="Data Final"
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm:ss"
                selected={formik.values.date_end}
                onChange={(value) =>
                  formik.setFieldValue(
                    'date_end',
                    moment(value).toDate('DD/MM/YYYY HH:mm:ss')
                  )
                }
                locale={pt}
                disabled={!editablePermission}
              />
              <CurrencyField
                label="Custo Total"
                onChange={(e, float) => formik.setFieldValue('price', float)}
                value={formik.values.price}
                disabled={!editablePermission}
              />
              <MaskedField
                label="ODÔMETRO"
                onChange={formik.handleChange}
                value={formik.values.marker}
                mask="99999999"
                id="marker"
                name="marker"
                disabled={!editablePermission}
              />
              <div className="col-span-3">
                <AreaField
                  label="Descrição"
                  type="textarea"
                  rows="3"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  disabled={!editablePermission}
                />
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
