import React from 'react';

// import { Container } from './styles';
const weeks = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];
function WeeksHeader() {
  return (
    <div
      className="
      h-12
      grid
      grid-cols-7
      items-end
      justify-items-center"
    >
      {weeks.map((week) => (
        <div className="text-lg text-geo-900">{week}</div>
      ))}
    </div>
  );
}

export default WeeksHeader;
