import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Cardetail } from './styles';

function Detail() {
  const tracker = useSelector((state) => state.commandState.tracker);

  return (
    <Container>
      <header>Detalhes do veículo</header>
      <div>
        {!_.isEmpty(tracker) && (
          <header>
            <img
              src={`https://filesgeo.s3.us-east-2.amazonaws.com/${tracker.type.image}`}
              alt={tracker.type.name}
            />
            <Cardetail>
              <div>
                <b>Placa: </b> {tracker.plate}
              </div>
              <div>
                <b>Modelo: </b> {`${tracker.model} ${tracker.brand}`}
              </div>
              <div>
                <b>Cor: </b> {tracker.color}
              </div>
              <div>
                <b>Ano: </b> {tracker.year}
              </div>
            </Cardetail>
          </header>
        )}
      </div>
    </Container>
  );
}

export default Detail;
