import React from 'react';

import NotReport from '~/assets/report/reportImage.svg';

import { NotDateContainer } from './styles';

export default function Daily() {
  return (
    <NotDateContainer>
      <img src={NotReport} alt="NotReport" />
      <div>Selecione um veículo para exibir os dados...</div>
    </NotDateContainer>
  );
}
