import styled from 'styled-components';

export const Container = styled.div`
  z-index: 2;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
  }
  div {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #9ea1a3;
    margin-top: 10px;
    padding-left: 25px;
  }
`;
