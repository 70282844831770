import PropTypes from 'prop-types';
import React from 'react';

import { SimpleField, CheckField } from '~/components/fields';

import { Container } from './styles';

function TrackerData({ formik, editablePermission }) {
  return (
    <Container>
      <header>DADOS</header>
      <div>
        <SimpleField
          label="IDENTIFICADOR"
          name="label"
          onChange={formik.handleChange}
          value={formik.values.label}
          disabled={!editablePermission}
        />
        <SimpleField
          label="TIPO ITEM RASTREAMENTO"
          name="type"
          disabled
          value={formik.values.type.name}
        />
        <SimpleField
          label="MARCA"
          name="brand"
          disabled
          value={formik.values.brand}
        />
        <SimpleField
          label="MODELO"
          name="model"
          disabled
          value={formik.values.model}
        />
        <SimpleField
          label="ANO"
          name="model"
          disabled
          value={formik.values.year}
        />
        <SimpleField
          label="COR"
          name="color"
          disabled
          value={formik.values.color}
        />
        <SimpleField
          label="PLACA"
          name="plate"
          disabled
          value={formik.values.plate}
        />
        <SimpleField
          label="CHASSI"
          name="chassis"
          disabled
          value={formik.values.chassis}
        />
        <SimpleField
          label="HODOMETRO/HORIMETRO"
          name="hour_meter"
          value={formik.values.hour_meter}
          disabled
          labelName="HODOMETRO/HORIMETRO"
          inputValue={
            formik.values.hour_meter
              ? { value: 'Hodometro' }
              : { value: 'Horimetro' }
          }
        />
        <SimpleField
          label="ATIVO"
          name="active"
          value={formik.values.active}
          disabled
        />
        <SimpleField
          label="IMEI DO DISPOSITIVO"
          name="chassis"
          disabled
          value={formik.values.device_id}
        />
        {formik.values.secretary_id && formik.values.secretary?.name ? (
          <SimpleField
            label="SECRETARIA"
            name="secretary"
            disabled
            value={formik.values.secretary.name}
          />
        ) : (
          ''
        )}
        <CheckField
          label="TRANSPORTE ESCOLAR"
          name="schoolBus"
          checked={formik.values.school_bus}
        />
      </div>
    </Container>
  );
}

TrackerData.propTypes = {
  formik: PropTypes.objectOf.isRequired,
  editablePermission: PropTypes.bool,
};

TrackerData.defaultProps = {
  editablePermission: false,
};

export default TrackerData;
