import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/routeSauce';

export function* getRoute(payload) {
  try {
    const response = yield call(api.fetchGeometries, payload.query);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_ROUTE_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_ROUTE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createRouteSaga(payload) {
  try {
    yield call(api.createGeometries, payload.data);
    toast.success('cadastrado com sucesso!');
    yield put(push('/route'));
  } catch (error) {
    yield put({ type: Types.FETCH_ROUTE_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateRouteSaga(payload) {
  try {
    yield call(api.updateGeometries, payload.data);
    yield put({
      type: Types.FETCH_ROUTE_REQUEST,
    });
    toast.success('Atualizado com sucesso!');

    yield put(push('/route'));
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_ROUTE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_ROUTE_REQUEST, getRoute)]);
  yield all([takeEvery(Types.CREATE_ROUTE_REQUEST, createRouteSaga)]);
  yield all([takeEvery(Types.UPDATE_ROUTE_REQUEST, updateRouteSaga)]);
}
