import React, { useState, useEffect } from 'react';
import {
  MdExitToApp,
  MdLockOpen,
  MdKeyboardArrowDown,
  MdInfo,
  MdSettings,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import Dropdown from 'rc-dropdown';

import negativeLogo from '~/assets/negativeLogo.svg';
import history from '~/services/history';
import { Creators as AuthActions } from '~/store/ducks/authSauce';
import { Creators as GlobalActions } from '~/store/ducks/globalSauce';

import {
  Container,
  Logo,
  Profile,
  Item,
  MenuStyled,
  ProfileContainer,
} from './styles';

function Navbar() {
  const token = useSelector((state) => state.authState.token);
  const me = useSelector((state) => state.globalState.me);
  const dispatch = useDispatch();
  const [visibleDropdown, setVisibleDropdown] = useState({
    visible: false,
    pristine: true,
  });

  useEffect(() => {
    if (token) {
      if (window.location.href.indexOf('login') < 0) {
        dispatch(GlobalActions.globalRequest());
      }
    }
  }, [token]);
  function setVisible() {
    setVisibleDropdown({ visible: !visibleDropdown.visible, pristine: false });
  }

  function handleLogout() {
    localStorage.setItem('signed', false);
    localStorage.removeItem('token');
    dispatch(AuthActions.signOut());
    history.push('/auth/login');
    dispatch(GlobalActions.clearMe());
    window.localStorage.removeItem('client');
  }

  const menu = (
    <MenuStyled selectable={false}>
      <Item onClick={() => history.push('/releases')}>
        <MdInfo size={15} />
        <span>Atualizações</span>
      </Item>
      <Item onClick={() => history.push('/changepassword')}>
        <MdLockOpen size={15} />
        <span>Mudar senha</span>
      </Item>
      <Item onClick={() => history.push('/settings')}>
        <MdSettings size={15} />
        <span>Configurações</span>
      </Item>
      <Item onClick={() => handleLogout()} bgtheme="exit">
        <MdExitToApp size={15} />
        <span>Sair</span>
      </Item>
    </MenuStyled>
  );

  return (
    <Container>
      <Logo onClick={() => history.push('/')}>
        <img src={negativeLogo} alt="Logotipo georastreamento" />
      </Logo>
      <div>
        <Dropdown
          trigger={['click']}
          overlay={menu}
          animation="slide-up"
          onVisibleChange={setVisible}
        >
          <ProfileContainer>
            <Profile>
              <div>
                <strong>{me ? me.name : ''}</strong>
                <small>{me ? me.clientName : ''}</small>
              </div>
            </Profile>
            <MdKeyboardArrowDown size={15} color="#fff" />
          </ProfileContainer>
        </Dropdown>
      </div>
    </Container>
  );
}

export default Navbar;
