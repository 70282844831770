import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { Creators as DailyActions } from '~/store/ducks/dailySauce';

import Alert from './alert';
import Detail from './detail';
import Filters from './filters';
import Journey from './journey';
import Map from './map';
import NotData from './notData';
import Statistics from './statistics';
import { ContainerStyled, MainContainer } from './styles';

function Daily() {
  const dispatch = useDispatch();
  const daily = useSelector((state) => state.dailyState.daily);
  useEffect(() => {
    dispatch(DailyActions.fetchDailyFailure());
  }, []);

  return (
    <ContainerStyled>
      <Filters />
      <MainContainer>
        {_.isEmpty(daily) && <NotData />}
        <Detail />
        <Map />
        <Statistics />
        <Journey />
        <Alert />
      </MainContainer>
    </ContainerStyled>
  );
}

export default Daily;
