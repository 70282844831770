import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import { SimpleContainer } from '~/components/containers';
import TableComponent from '~/components/Table';

import ModalDailyBoard from './dailyBoard';
import ModalFenceOut from './FenceOut';
import ModalMap from './Map';
import NoData from './NoData';

function Table() {
  const targetRef = useRef();
  const [key, setKey] = useState(0);
  const [height, setHeight] = useState(0);
  const [modalView, setModalView] = useState(false);
  const [mapData, setMapData] = useState({});
  const [modalFenceView, setModalFenceView] = useState(false);
  const [fenceOutData, setFenceOutData] = useState({});
  const [modalDailyBoard, setModalDailyBoard] = useState(false);
  const [dailyBoardData, setDailyBoardData] = useState({});
  const [filterTitle, setFilterTitle] = useState('');
  const {
    data,
    loading,
    report: { fields, name, landscape, getFilterValue },
  } = useSelector(({ reportState }) => reportState);

  useEffect(() => {
    setKey(Math.random());
    // console.log(getFilterValue, data);
    if (getFilterValue) {
      // console.log('getFilterValue', getFilterValue);
      // console.log('getFilterValue(data)', getFilterValue(data));
      setFilterTitle(getFilterValue(data));
    }
  }, [loading, getFilterValue, data]);

  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight - 20}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <SimpleContainer ref={targetRef}>
      {_.isEmpty(data) ? (
        <NoData />
      ) : (
        <TableComponent
          dimensionHeight={height}
          key={key}
          data={data}
          filterTypes={!_.isEmpty(fields) && fields.map(({ type }) => type)}
          headerTypes={
            !_.isEmpty(fields) &&
            fields.map(({ name: nameValue, type }) => ({ [nameValue]: type }))
          }
          align={!_.isEmpty(fields) && fields.map(({ align }) => align)}
          loading={loading}
          headerBackground="#245378"
          labels={!_.isEmpty(fields) && fields.map(({ header }) => header)}
          headers={
            !_.isEmpty(fields) &&
            fields.map(({ name: nameHeader }) => nameHeader)
          }
          fileTitle={
            name === 'Permissões Diário de Bordo'
              ? `${name} \n Usuário: ${data[0].user_name}`
              : name
          }
          ColumnsWidth={!_.isEmpty(fields) && fields.map(({ width }) => width)}
          getData={(dataTable) => {
            if (name === 'Histórico do Veículo') {
              setMapData(dataTable);
            }
            if (name === 'Quilômetros Percorridos Dentro da Cerca') {
              setFenceOutData(dataTable);
            }
            if (name === 'Diário de Bordo') {
              setDailyBoardData(dataTable);
            }
            if (name === 'Pontos de Referência') {
              setFenceOutData(dataTable);
            }
            if (name === 'Diário de Paradas') {
              setFenceOutData(dataTable);
            }
          }}
          filters={filterTitle}
          landscape={landscape}
        />
      )}
      <ModalMap
        modalView={modalView}
        setModalView={setModalView}
        data={mapData}
      />
      <ModalFenceOut
        modalView={modalFenceView}
        setModalView={setModalFenceView}
        data={fenceOutData}
      />
      <ModalDailyBoard
        modalView={modalDailyBoard}
        setModalView={setModalDailyBoard}
        data={dailyBoardData}
      />
    </SimpleContainer>
  );
}

export default Table;
