import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  display: grid;
  grid-template-rows: ${(props) =>
    props.setHeight && `35px ${props.setHeight}px`};

  > header {
    font-size: 20px;
    font-weight: 600;
    color: #245378;
    background: #f4f8f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
  }
  > div {
    display: grid;
    grid-template-columns: ${(props) => (props.visible ? '1fr 1fr' : '1fr')};
  }
`;

export const Clients = styled.div`
  margin: 10px 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  background: #f4f8f9;
  padding: 10px;
  display: grid;
  grid-template-rows: 38px 1fr;
  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #245378;
    font-size: 15px;
    font-weight: 600;
  }
`;

export const Trackers = styled.div`
  margin: 5px 0;
  padding: 0 0 0 5px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.selected ? darken(0.2, '#fff') : '#fff')};
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: background 0.2s;
  :hover {
    background: ${darken(0.1, '#fff')};
  }
  > div {
    color: #333;
  }
  > button {
    height: 30px;
    width: 40px;
    border: none;
    cursor: pointer;
    background: #245378;
    border-radius: 0 5px 5px 0;
    transition: background 0.2s;
    :hover {
      background: ${darken(0.1, '#245378')};
    }
  }
`;

export const Notification = styled.form`
  > ul {
    list-style: none;
  }
  padding: 5px;
  margin: 10px;
  background: #f4f8f9;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  display: grid;
  grid-template-rows: ${(props) =>
    props.setHeight && `50px ${props.setHeight - 160}px 60px 50px`};
`;

export const Li = styled.li`
  background: ${(props) => props.color ? props.color : '#fff'};
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);

  ${(props) =>
    props.checked &&
    css`
      cursor: pointer;
      :hover {
        background: ${darken(0.1, props.color ? props.color : '#fff')};
      }
    `}
`;
