import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Card from '~/assets/login/card.svg';
import IdCard from '~/assets/login/id-card.svg';
import { ButtonStyled } from '~/components/Global';
import Loading from '~/components/Loading';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as AuthActions } from '~/store/ducks/authSauce';
import { Creators as ForgotActions } from '~/store/ducks/forgotSauce';
import { Creators as GlobalActions } from '~/store/ducks/globalSauce';

import {
  Container,
  Form,
  Input,
  InputMask,
  Information,
  ButtonContainer,
  InputContainer,
  Title,
  ImageContainer,
} from './styles';

function Forgot() {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.authState.loading);

  const [code, setCode] = useState(null);
  const [user, setUser] = useState('');

  const formik = useFormik({
    initialValues: {
      cpf: '',
      password: '',
      password_confirmation: '',
    },
    onSubmit: (data) => {
      if (code) {
        if (data.password !== data.password_confirmation) {
          toast.error('Senha e confirmação de senha devem ser iguais.');
        } else {
          data.id = user.id;
          dispatch(ForgotActions.updateRequest(data));
        }
      }
      if (!code) {
        dispatch(ForgotActions.forgotRequest({ cpf: data.cpf }));
      }
    },
  });

  useEffect(() => {
    localStorage.removeItem('token');
    dispatch(AuthActions.loginFailure());

    dispatch(GlobalActions.clearMe());
    window.localStorage.removeItem('client');
    const url = window.location.href;
    if (url.includes('=')) {
      setCode(window.location.href.split('=').pop());
    }
  }, []);

  async function getUser() {
    try {
      const response = await api.decryptPassword(code);
      formik.setFieldValue('cpf', response.data.cpf);
      setUser(response.data);
    } catch (erro) {
      console.tron.log({ erro });
    }
  }
  useEffect(() => {
    if (code) {
      getUser();
    }
  }, [code]);

  function handleBack() {
    history.push('/auth/login');
  }

  return (
    <Container>
      {!loading ? (
        <>
          <Form>
            {!code ? (
              <Title>ESQUECEU A SENHA?</Title>
            ) : (
              <Title>RECUPERAR SENHA</Title>
            )}
            <form onSubmit={formik.handleSubmit}>
              {!code ? (
                <InputContainer>
                  <img src={IdCard} alt="CPF" />
                  <InputMask
                    mask="999.999.999-99"
                    name="cpf"
                    onChange={formik.handleChange}
                    value={formik.values.serial}
                    placeholder="CPF"
                    required
                  />
                </InputContainer>
              ) : (
                <>
                  <InputContainer>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Senha"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                      id="password"
                      required
                    />
                  </InputContainer>
                  <InputContainer>
                    <Input
                      type="password"
                      id="password_confirmation"
                      name="password_confirmation"
                      onChange={formik.handleChange}
                      value={formik.values.password_confirmation}
                      placeholder="Confirmação de senha"
                      required
                    />
                  </InputContainer>
                </>
              )}
              <ButtonContainer>
                <ButtonStyled color="#245378" type="submit">
                  Enviar
                </ButtonStyled>
                <ButtonStyled
                  color="#999"
                  type="button"
                  onClick={() => handleBack()}
                >
                  Cancelar
                </ButtonStyled>
              </ButtonContainer>
            </form>
          </Form>
          <Information>
            <ImageContainer>
              <img src={Card} alt="Card" />
            </ImageContainer>
          </Information>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}

export default Forgot;
