import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { SelectStyledFilter } from '../index';

export default function SelectTracker({ parentCallback }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getTrackers() {
    try {
      const { data } = await api.fetchTracker('/?idPlateOnly=true');
      const newData = data.map((item) => ({
        value: item.id,
        label: item.plate,
      }));
      setOptions([...newData]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getTrackers();
  }, []);

  function onchangeTracker(item) {
    parentCallback({ id: item.value, label: item.label });
  }

  return (
    <>
      <SelectStyledFilter
        noOptionsMessage={() => 'Nenhum veículo encontrado.'}
        onChange={(option) => onchangeTracker(option || {})}
        placeholder={loading ? 'Carregando...' : 'Selecione um veículo'}
        isClearable
        isSearchable
        name="color"
        options={options}
      />
    </>
  );
}

SelectTracker.propTypes = {
  parentCallback: PropTypes.func.isRequired,
};
