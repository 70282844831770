import styled from 'styled-components';

import { InputStyled } from '..';

import Mask from '~/util/Mask';

export const LabelStyled = styled.label`
  font-weight: 600;
  text-transform: uppercase;
`;

export const ErroMessage = styled.div`
  color: #e33939;
  font-weight: 600;
  font-size: 11px;
  float: right;
`;

export const ContainerLabel = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Input = styled(InputStyled)`
  width: 100%;
  height: 33.2px;
  border: 1px solid #dbdbdb;
  text-transform: ${(props) => props.upper && 'uppercase'};
  border: ${(props) => props.error && '1px solid #E33939'};
  background: ${(props) => props.errorBackground && '#fcd7d7'};
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
`;

export const MaskStyle = styled(Mask)`
  width: 100%;
  height: 33.2px;
  border: 1px solid #dbdbdb;
  text-transform: ${(props) => props.upper && 'uppercase'};
  border: ${(props) => (props.error ? '1px solid #E33939' : '0')};
  /* background: ${(props) => (props.error ? '#fcd7d7' : '#fff')}; */
`;
