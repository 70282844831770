import React from 'react';

import Action from './Action';
import Daily from './Daily';
import Map from './Map';
import history from '~/services/history';

export default function Dashboard() {
  return (
    <>
      <div className="h-full w-full grid grid-cols-1 grid-rows-3 xl:grid-cols-2 xl:grid-rows-2 gap-2">
        <Map />
        {history.location.pathname !== '/monitoringFullscreen' && (
          <>
            <Action />
            <Daily />
          </>
        )}
      </div>
    </>
  );
}
