/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { MdCheck, MdClear } from 'react-icons/md';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { decode } from 'polyline';

import {
  ContainerStyled,
  TableContainer,
  FieldContainer,
  LabelStyled,
  ContainerFilters,
  HeaderFilter,
  ButtonsFilter,
  BodyFilter,
  FilterButton,
  FilterContainer,
  Input,
  ContainerStyledTrip,
  ContainerTripFilters,
  ButtonContainer,
} from '~/components/Global';

let myOrigin;
let myDestination;
// let originAutocomplete = '';
// let destinationAutocomplete= ''
let placesAutocomplete = '';

const directionsService = new window.google.maps.DirectionsService();
const directionsDisplay = new window.google.maps.DirectionsRenderer();
import { Creators as RouteActions } from '~/store/ducks/routeSauce';

import mapStyle from '~/assets/map.json';
import { Map } from './styles';

function RouteMap(props) {
  const [name, setName] = useState('');
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [map, setMap] = useState('');
  const [duration, setDuration] = useState('');
  const [distance, setDistance] = useState('');
  const [type, setType] = useState('trip');
  const [geometries, setGeometries] = useState('');
  const [placesInput, setPlacesInput] = useState('');
  const [id, setId] = useState('');
  const [newPosition, setNewPosition] = useState(''); // Adding one more position
  const [waypoints, setWaypoints] = useState([]); // Current positions
  const [routeNotFound, setRouteNotFound] = useState(false); // Current positions
  const [originInput, setOriginInput] = useState('');
  const [destinationInput, setDestinationInput] = useState('');

  // let _autocomplete;

  const [editablePermission, setEditablePermission] = useState(false);

  let originAutocomplete = '';

  useEffect(() => {
    if (props.match.params.id) {
      props.fetchRouteRequest(`/${props.match.params.id}`);
    }
  }, []);

  useEffect(() => {
    if (props.me) {
      if (props.me.role == 'master') {
        setEditablePermission(true);
      }
    }
  }, [props.me]);

  useEffect(() => {
    setName('');
    setOrigin('');
    setDestination('');
    setDuration('');
    setDistance('');
    setMap('');
    setType('trip');
    setGeometries('');
    setId('');
    setNewPosition('');
    setWaypoints([]);
    setRouteNotFound(false);
    setOriginInput('');
    if (props.route.google_json !== undefined) {
      // Set all data that backend returns
      myOrigin = new google.maps.Marker({
        position: props.route.google_json.origin,
        map: null,
        label: 'A',
        title: 'Origem',
      });
      myDestination = new google.maps.Marker({
        position: props.route.google_json.destination,
        map: null,
        label: 'B',
        title: 'Destino',
      });
      setName(props.route.name);
      setOrigin(props.route.google_json.origin);
      setDestination(props.route.google_json.destination);
      setId(props.route.id);
      setGeometries(props.route.google_json);
      setDuration(props.route.google_json.routes[0].legs[0].duration.text);
      setDistance(props.route.google_json.routes[0].legs[0].distance.text);
      directionsDisplay.setMap(map);
      directionsDisplay.setDirections(props.route.google_json);
    }
  }, [props.route]);

  useEffect(() => {
    if (!!destination) {
      myOrigin = true;
      myDestination = true;
    } else {
      myOrigin = false;
      myDestination = false;
    }
  }, [destination]);

  useEffect(() => {
    setName('');
    setOrigin('');
    setDestination('');
    setDuration('');
    setDistance('');
    setMap('');
    setType('trip');
    setGeometries('');
    setNewPosition('');
    setWaypoints([]);
    setRouteNotFound(false);
    setOriginInput('');
    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 15,
      controlSize: 25,
      styles: mapStyle,
      mapId: '1b55642b375a5240',
    });
    // _autocomplete = new AutocompleteDirectionsHandler(map, origin, destination)
    map.addListener('click', ({ latLng }) => {
      if (!myOrigin || !myDestination) {
        if (!myOrigin) {
          myOrigin = new google.maps.Marker({
            position: latLng,
            map,
            label: 'A',
            title: 'Origem',
          });
          myOrigin.addListener('click', () => {
            myOrigin.setMap(null);
            myOrigin = null;
          });
        } else {
          myDestination = new google.maps.Marker({
            position: latLng,
            map,
            label: 'B',
            title: 'Destino',
          });
          myDestination.addListener('click', () => {
            myDestination.setMap(null);
            myDestination = null;
          });
          directionsService.route(
            {
              origin: myOrigin.position,
              destination: myDestination.position,
              travelMode: 'DRIVING',
            },
            function (response, status) {
              if (status === 'OK') {
                myOrigin.setMap(null);
                myDestination.setMap(null);
                directionsDisplay.setMap(map);
                directionsDisplay.setDirections(response);
                setDuration(response.routes[0].legs[0].duration.text);
                setDistance(response.routes[0].legs[0].distance.text);
                setGeometries(response);
                setOrigin(response.routes[0].legs[0].start_address);
                setDestination(response.routes[0].legs[0].end_address);
                setRouteNotFound(false);
              } else {
                toast.warn('Rota não encontrada.');
                setRouteNotFound(true);
                clearRoute();
              }
            }
          );
        }
      }
    });
    setMap(map);
    placesAutocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('places-input')
    );
    placesAutocomplete.setFields(['formatted_address', 'geometry', 'name']);
    placesAutocomplete.bindTo('bounds', map);
    placesAutocomplete.addListener('place_changed', () => {
      const place = placesAutocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
      }
    });
  }, []);
  // class AutocompleteDirectionsHandler {
  //   constructor(map, origin, destination) {
  //     this.map = map;
  //     this.originPlaceId = null;
  //     this.destinationPlaceId = null;
  //     this.travelMode = 'DRIVING';
  //     this.directionsService = new window.google.maps.DirectionsService();
  //     this.directionsRenderer = new window.google.maps.DirectionsRenderer();
  //     this.directionsRenderer.setMap(map);

  //     originAutocomplete = new window.google.maps.places.Autocomplete(
  //       document.getElementById('origin-input')
  //     );

  //     originAutocomplete.setFields(['place_id']);

  //     destinationAutocomplete =
  //       new window.google.maps.places.Autocomplete(
  //         document.getElementById('destination-input')
  //       );
  //     destinationAutocomplete.setFields(['place_id']);

  //     this.setupPlaceChangedListener(originAutocomplete, 'ORIG', destination);
  //     this.setupPlaceChangedListener(destinationAutocomplete, 'DEST', origin);
  //   }
  //   setupPlaceChangedListener(autocomplete, mode, placeId) {
  //     const me = this;
  //     autocomplete.bindTo('bounds', this.map);

  //     autocomplete.addListener('place_changed', function () {
  //       const place = autocomplete.getPlace();

  //       if (!place.place_id) {
  //         toast.warn('Please select an option from the dropdown list.');
  //         return;
  //       }

  //       if (mode === 'ORIG') {
  //         // If updating ORIG, receive DESTINY value from props
  //         me.originPlaceId = place.place_id;
  //         setOrigin(autocomplete.gm_accessors_.place.jj.formattedPrediction);
  //         if (props.match.params.id) {
  //           if (me.destinationPlaceId == null) {
  //             me.destinationPlaceId = placeId;
  //           }
  //         }
  //       } else {
  //         // If updating DESTINATION, receive ORIG value from props
  //         me.destinationPlaceId = place.place_id;
  //         setDestination(
  //           autocomplete.gm_accessors_.place.jj.formattedPrediction
  //         );
  //         if (props.match.params.id) {
  //           if (me.originPlaceId == null) {
  //             me.originPlaceId = placeId;
  //           }
  //         }
  //       }
  //       me.route();
  //     });
  //   }
  //   route() {
  //     if (!this.originPlaceId || !this.destinationPlaceId) {
  //       return;
  //     }
  //     const me = this;
  //     this.directionsService.route(
  //       {
  //         origin: { placeId: this.originPlaceId },
  //         destination: { placeId: this.destinationPlaceId },
  //         provideRouteAlternatives: true,
  //         // waypoints: { placeId: "ChIJOWUb4ha1uQARcZlbYQ19F28" },
  //         // optimizeWaypoints: true,
  //         travelMode: 'DRIVING',
  //       },
  //       function (response, status) {
  //         if (status === 'OK') {
  //           me.directionsRenderer.setDirections(response);
  //           setDuration(response.routes[0].legs[0].duration.text);
  //           setDistance(response.routes[0].legs[0].distance.text);
  //           setGeometries(response);
  //           directionsDisplay.setMap(null);
  //           setRouteNotFound(false);
  //         } else {
  //           toast.warn('Rota não encontrada.');
  //           setRouteNotFound(true);
  //         }
  //       }
  //     );
  //   }
  //   clear() {
  //     setDestination('')
  //     setOrigin('')
  //     this.directionsService.setMap(null)
  //     this.directionsRenderer.setDirections(null)
  //     directionsDisplay.setDirections(null);
  //     directionsService.setMap(null)
  //   }
  // }

  const handleSubmit = (evt) => {
    if (evt !== undefined) {
      evt.preventDefault();
      if (geometries === '') {
      }
      if (name === '') {
        toast.error('Nome obrigatório');
      }
      if (origin === '') {
        toast.error('Origem obrigatório');
      }
      if (destination === '') {
        toast.error('Destino obrigatório');
      } else {
        const newGeometries = {
          ...geometries,
          origin,
          destination,
        };
        const data = { name, type, id, geojson: newGeometries };
        if (!routeNotFound) {
          if (data.name && data.geojson) {
            // Get route coordinates of data that google maps returns
            const routeCoordinates = decode(
              data.geojson.routes[0].overview_polyline
            ).map((coord) => coord.reverse());

            // Creating an object in geojson format using the coordinates that was get before
            const routeFormattedToGeojson = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: routeCoordinates,
              },
              properties: {},
            };

            // Difining geojons with route in GeoJson format and defining googleMapsUiJson with the value that google maps returns
            data.google_json = data.geojson;
            data.geojson = routeFormattedToGeojson;

            if (data.id) {
              props.updateRouteRequest(data);
            } else {
              props.createRouteRequest(data);
            }
          } else {
          }
        } else {
          toast.error('Rota não encontrada.');
        }
      }
      clearVariables();
    }
  };

  // const generateRoute = (e) => {
  //   e.preventDefault()
  //   if(myOrigin && myDestination){
  //     directionsService.route(
  //     {
  //       origin: myOrigin.position,
  //       destination: myDestination.position,
  //       travelMode: 'DRIVING',
  //     },
  //     function (response, status) {
  //       if (status === 'OK') {
  //         myOrigin.setMap(null);
  //         myDestination.setMap(null);
  //         directionsDisplay.setMap(map);
  //         directionsDisplay.setDirections(response);
  //         setDuration(response.routes[0].legs[0].duration.text);
  //         setDistance(response.routes[0].legs[0].distance.text);
  //         setGeometries(response);
  //         setOrigin(response.routes[0].legs[0].start_address);
  //         setDestination(response.routes[0].legs[0].end_address);
  //         setRouteNotFound(false);
  //       } else {
  //         toast.warn('Rota não encontrada.');
  //         setRouteNotFound(true);
  //       }
  //     }
  //   );
  // }
  // }
  function clearRoute() {
    if (myOrigin !== true) {
      myOrigin.setMap(null);
    }
    myOrigin = undefined;
    if (myDestination !== true) {
      myDestination.setMap(null);
    }
    myDestination = undefined;
    setDestination('');
    setOrigin('');
    directionsDisplay.setMap(null);
    // if (_autocomplete) {
    //   _autocomplete.clear();
    // }
  }

  function handleDelete(position) {
    setWaypoints(waypoints.filter((item) => item !== position));
  }

  function handleInclude(position) {
    setWaypoints([...waypoints, { location: position.value, stopover: true }]);
    const s = document.getElementById('destination-input');
    s.value = '';
  }

  function backButton() {
    clearVariables();
    props.history.push('/route/');
  }

  function clearVariables() {
    props.clearRoute();
    // setName('');
    setOrigin('');
    setDestination('');
    setId('');
    setOriginInput('');
    setDestinationInput('');
    myOrigin = null;
    myDestination = null;
    // if (_autocomplete) {
    //   _autocomplete.clear();
    // }
  }

  return (
    <ContainerStyledTrip>
      <FilterContainer onSubmit={handleSubmit}>
        {/* <form onSubmit={handleSubmit}> */}
        <HeaderFilter>ROTAS</HeaderFilter>
        <BodyFilter>
          <FieldContainer>
            <LabelStyled>Pesquisar local</LabelStyled>

            {/* <AddButtonStyled
                    onClick={() =>
                      handleInclude(
                        document.getElementById("destination-input")
                      )
                    }
                    type="button"
                  >
                    <FaPlus />
                  </AddButtonStyled> */}
            <Input
              id="places-input"
              className="controls"
              type="text"
              defaultValue={placesInput}
            />
          </FieldContainer>
          <FieldContainer>
            <LabelStyled>Nome</LabelStyled>
            <Input
              className="controls"
              type="text"
              value={name}
              name="name"
              onChange={(e) => setName(e.target.value)}
              disabled={!editablePermission}
            />
          </FieldContainer>

          <FieldContainer>
            <LabelStyled>Origem</LabelStyled>

            <Input
              id="origin-input"
              className="controls"
              type="text"
              defaultValue={origin}
              disabled={true}
            />
          </FieldContainer>
          {/* {waypoints && waypoints.length > 0 ? (
                <LabelStyled>Paradas</LabelStyled>
              ) : (
                <></>
              )} */}
          {/* <>
                {waypoints.length > 0 ? (
                  waypoints.map(item => (
                    <PositionContainer>
                      <div>
                        <Input key={item} defaultValue={item.location} />
                        <AddButtonStyled
                          onClick={() => handleDelete(item)}
                          type="button"
                        >
                          <FaMinus />
                        </AddButtonStyled>
                      </div>
                    </PositionContainer>
                  ))
                ) : (
                  <></>
                )}
              </> */}
          <FieldContainer>
            <LabelStyled>Destino</LabelStyled>

            {/* <AddButtonStyled
                    onClick={() =>
                      handleInclude(
                        document.getElementById("destination-input")
                      )
                    }
                    type="button"
                  >
                    <FaPlus />
                  </AddButtonStyled> */}
            <Input
              id="destination-input"
              className="controls"
              type="text"
              defaultValue={destination}
              disabled={true}
            />
          </FieldContainer>
          <ButtonsFilter>
            {/* <ButtonContainer
            color="#00CC77"
            type="button"
            onClick={generateRoute}
          >
            Gerar Rota
          </ButtonContainer> */}
            <ButtonContainer
              color="#63BCF8"
              type="button"
              onClick={() => {
                clearRoute();
                clearVariables();
              }}
              disabled={!myOrigin && !myDestination}
            >
              Limpar
            </ButtonContainer>
          </ButtonsFilter>
          {duration && distance ? (
            <FieldContainer>
              <LabelStyled>Distância : {distance}</LabelStyled>
              <LabelStyled>Duração: {duration}</LabelStyled>
            </FieldContainer>
          ) : (
            ''
          )}
          <FieldContainer>
            <LabelStyled>
              Clique sobre o mapa para marcar os pontos de origem e destino.
            </LabelStyled>
          </FieldContainer>
        </BodyFilter>
        <ButtonsFilter>
          <ButtonContainer
            color="#E33939"
            type="button"
            onClick={() => {
              clearVariables();
              backButton();
            }}
          >
            <MdClear size={15} />
            Sair
          </ButtonContainer>
          {editablePermission ? (
            <ButtonContainer
              color="#555"
              type="submit"
              onclick={handleSubmit()}
            >
              <MdCheck size={15} />
              Salvar
            </ButtonContainer>
          ) : (
            ''
          )}
        </ButtonsFilter>
        {/* </form> */}
      </FilterContainer>
      <TableContainer>
        <Map id="map" />
      </TableContainer>
    </ContainerStyledTrip>
  );
}

const mapStateToProps = (state) => ({
  routes: state.routeState.routes,
  route: state.routeState.route,
  loading: state.routeState.loading,
  me: state.globalState.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(RouteActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RouteMap);
