import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import { SimpleSelect } from '~/components/selects';
import { Creators as FenceActions } from '~/store/ducks/fenceSauce';

function Fence() {
  const dispatch = useDispatch();
  const [selectKey, setSelectKey] = useState(0);

  const fences = useSelector(({ fenceState }) => fenceState.fences);
  const loading = useSelector(({ fenceState }) => fenceState.loading);

  useEffect(() => {
    if (_.isEmpty(fences)) {
      setSelectKey(Math.random());
    }
  }, [fences]);

  useEffect(() => {
    dispatch(FenceActions.fetchFenceRequest('?type=fence&type=safezone'));
  }, []);

  function onChangeFence({ id }) {
    if (Number(id) === 0) {
      dispatch(FenceActions.loadAll(fences.map((f) => f.id)));
    } else if (id) {
      dispatch(FenceActions.fetchFenceRequest(`/${id}`));
    } else {
      dispatch(FenceActions.clearFence());
    }
  }
  return (
    <div className="flex items-center justify-between px-2 shadow rounded-lg bg-white">
      <div className="text-xl font-bold text-geo-900">Cadastro de área</div>
      <SimpleSelect
        key={selectKey}
        noOptionsMessage={() => 'Nenhuma área encontrada.'}
        onChange={(option) => onChangeFence(option || {})}
        placeholder={loading ? 'Carregando...' : 'Selecione uma área'}
        isClearable
        isSearchable
        getOptionValue={({ id }) => id}
        getOptionLabel={({ name }) => name}
        name="color"
        options={[{ id: '0', name: 'Todas' }].concat(
          fences?.map((fence) => ({
            ...fence,
            name:
              fence.type === 'fence'
                ? fence.name
                : `${fence.name} (ZONA DE SEGURANÇA)`,
          }))
        )}
        width="300px"
      />
    </div>
  );
}

export default Fence;
