import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  loginRequest: ['userForm'],
  loginSuccess: [],
  loginFailure: [],
  signOut: [],
});

const INITIAL_STATE = {
  loading: false,
  token: localStorage.getItem('token'),
  signed: localStorage.getItem('signed'),
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  token: action.payload.token,
  signed: true,
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  token: null,
  signed: false,
});

const signOut = (state = INITIAL_STATE) => ({
  ...state,
  token: null,
  signed: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,
  [Types.SIGN_OUT]: signOut,
});
