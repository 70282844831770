import moment from 'moment';
import { toast } from 'react-toastify';

export default function setException({ formik }) {
  if (formik.values.exception_date_start && formik.values.exception_date_end) {
    const {
      exception_date_start: start,
      exception_date_end: end,
    } = formik.values;
    formik.setFieldValue('exceptions', [
      ...formik.values.exceptions,
      {
        start: moment(start).format('YYYY-MM-DD HH:mm'),
        end: moment(end).format('YYYY-MM-DD HH:mm'),
      },
    ]);
  } else {
    toast.error('Verifique se os dados estão corretos');
  }
}
