import React, { useState, useRef, useEffect } from 'react';
import PerfectScroll from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { SimpleInput } from '~/components/inputs';
import { Creators as TrackersActions } from '~/store/ducks/trackerSauce';

import clearSelect from './clearSelect';
import inverterSelect from './inverterSelect';
import selectAll from './selectAll';
import { Container } from './styles';

function Cars({ formik, isCompact, className }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const { trackers, loading } = useSelector(({ trackerState }) => trackerState);

  const [trackersFiltered, setTrackersFiltered] = useState(trackers);
  const [height, setHeight] = useState(0);
  const validValues = (carId) => trackers.some((t) => t.id === carId);
  function trackersSearch(plateFilter) {
    if (!plateFilter) setTrackersFiltered(trackers);

    setTrackersFiltered(
      trackers.filter(({ plate }) =>
        plate
          .replace(/[-]+/, '')
          .toUpperCase()
          .includes(plateFilter.replace(/[-]+/, '').toUpperCase())
      )
    );
  }

  useEffect(() => {
    setTrackersFiltered(trackers);
  }, [trackers]);

  useEffect(() => {
    dispatch(TrackersActions.fetchTrackerRequest('?idPlateOnly=true'));
    setHeight(ref.current.offsetHeight - (isCompact ? 106 : 56));
  }, []);

  return (
    <Container ref={ref} isCompact={isCompact}>
      <SimpleInput
        area="input"
        height="100%"
        margin="0"
        type="text"
        placeholder="Pequise pela placa"
        onChange={({ target: { value } }) => trackersSearch(value)}
      />
      <div style={{ gridArea: 'button' }} className="grid grid-cols-3 gap-2">
        <button
          className="bg-gray-200 shadow rounded-lg cursor-pointer hover:bg-gray-300"
          type="button"
          onClick={() => selectAll({ formik, trackers })}
        >
          Selecionar Todos
        </button>
        <button
          className="bg-gray-200 shadow rounded-lg cursor-pointer hover:bg-gray-300"
          type="button"
          onClick={() => inverterSelect({ formik, trackers })}
        >
          Inverter seleção
        </button>
        <button
          className="bg-gray-200 shadow rounded-lg cursor-pointer hover:bg-gray-300"
          type="button"
          onClick={() => clearSelect({ formik })}
        >
          Limpar seleção
        </button>
      </div>
      <div
        className="col-span-2"
        style={{ height: `${height}px`, gridArea: 'cars' }}
      >
        <PerfectScroll>
          <div className={className}>
            {loading
              ? 'Carregando'
              : trackersFiltered.map(({ plate, id }) => (
                  <label
                    className="
                    rounded-lg
                    shadow
                  bg-gray-50
                  hover:bg-gray-100
                    transition-all
                    cursor-pointer
                    h-10
                    flex
                    items-center px-2"
                    htmlFor={id}
                    key={id}
                  >
                    <input
                      checked={
                        (Array.isArray(formik.values.cars) &&
                          formik.values.cars.some((carId) => carId === id)) ||
                        false
                      }
                      onChange={({ target: { checked } }) => {
                        formik.values.group = null;
                        if (!Array.isArray(formik.values.cars)) {
                          formik.values.cars = [];
                        }
                        let carsValues = formik.values.cars.filter(validValues);
                        if (checked) {
                          carsValues.push(id);
                        } else {
                          carsValues = carsValues.filter(
                            (carId) => carId !== id
                          );
                        }
                        formik.setFieldValue('cars', carsValues);
                      }}
                      id={id}
                      type="checkbox"
                    />
                    <span className="mx-2">{plate}</span>
                  </label>
                ))}
          </div>
        </PerfectScroll>
      </div>
    </Container>
  );
}

Cars.propTypes = {
  formik: PropTypes.objectOf(PropTypes.object).isRequired,
  isCompact: PropTypes.bool,
  className: PropTypes.string,
};

Cars.defaultProps = {
  isCompact: false,
  className: 'grid grid-cols-4 gap-2',
};

export default Cars;
