import React, { useState } from 'react';
import { MdDone, MdDelete, MdCreate, MdClear } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { IconButton } from '~/components/buttons';

import ModalDelete from './ModalDelete';

function Buttons({ closeModal }) {
  const schedule = useSelector(({ schedulesState }) => schedulesState.schedule);
  const [modalDelete, setModalDelete] = useState(false);

  return (
    <>
      <div className="col-span-2 flex justify-end">
        <IconButton
          type="button"
          Icon={MdClear}
          size={20}
          background="bg-gray-600"
          width="w-40"
          onClick={() => closeModal()}
        >
          Cancelar
        </IconButton>

        {schedule.id ? (
          <>
            <IconButton
              type="button"
              Icon={MdDelete}
              size={20}
              background="bg-red-600"
              width="w-40"
              onClick={() => setModalDelete(true)}
            >
              Deletar
            </IconButton>
            <IconButton type="submit" Icon={MdCreate} size={20} width="w-40">
              Editar
            </IconButton>
          </>
        ) : (
          <IconButton type="submit" Icon={MdDone} size={20} width="w-40">
            Salvar
          </IconButton>
        )}
      </div>
      <ModalDelete
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        id={schedule.id}
      />
    </>
  );
}

Buttons.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default Buttons;
