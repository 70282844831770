export default function translateWeek(day) {
  let dayTranslate = '';
  switch (day) {
    case 2:
      dayTranslate = 'Segunda-feira';
      break;
    case 3:
      dayTranslate = 'Terça-feira';
      break;
    case 4:
      dayTranslate = 'Quarta-feira';
      break;
    case 5:
      dayTranslate = 'Quinta-feira';
      break;
    case 6:
      dayTranslate = 'Sexta-feira';
      break;
    case 7:
      dayTranslate = 'Sábado';
      break;
    case 1:
      dayTranslate = 'Domingo';
      break;

    default:
      dayTranslate = '';
      break;
  }
  return dayTranslate;
}
