// import { Container } from './styles';

import React, { useState, useEffect } from 'react';
import { MdPhone, MdShare, MdDirections } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// import axios from 'axios';
import moment from 'moment';
import Tooltip from 'rc-tooltip';

import AvatarImageNone from '~/assets/avatar.png';
import addressIcon from '~/assets/infobox/address.svg';
import batteryIcon from '~/assets/infobox/battery.svg';
import directionsIcon from '~/assets/infobox/directions.svg';
import ignitionIcon from '~/assets/infobox/ignition.svg';
import lastComunicationIcon from '~/assets/infobox/lastComunication.svg';
import odometerIcon from '~/assets/infobox/odometer.svg';
import carIcon from '~/assets/side/car.svg';
import { ItemList } from '~/components/Global';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import {
  ListContainer,
  DriverContainer,
  AvatarImage,
  DriverTitle,
  DriverDetail,
} from '../styles';
import Actionitem from './ActionItem';
import ModalShare from './ModalShare';

function ActionComponent() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const buffer = useSelector(({ bufferState }) => bufferState.buffer);
  const dispatch = useDispatch();
  const query = useQuery();
  const idTracker = query.get('id');
  const [tracker, setTracker] = useState({ odometer: 0 });
  const [angleName, setAngle] = useState('Norte');
  // const [address, setAddress] = useState('...');

  // async function getAddress(lat, lng) {
  //   if (lat && lng) {
  //     try {
  //       const { data } = await axios.get(
  //         `https://location.georastreamento.com.br/reverse.php?format=json&lat=${lat}&lon=${lng}&zoom=16`
  //       );
  //       const {
  //         road,
  //         city_district: cityDistrict,
  //         town,
  //         city,
  //         state,
  //         village,
  //         suburb,
  //       } = data.address;
  //       setAddress(
  //         ` ${road || suburb || village || cityDistrict}, ${
  //           city || town || cityDistrict
  //         } - ${state}`
  //       );
  //     } catch (error) {
  //       setAddress('...');
  //     }
  //   }
  // }

  function getDirections(angle) {
    let nameAngle;
    if (angle > 337.5 && angle <= 22.5) nameAngle = 'Norte';
    if (angle > 22.5 && angle <= 67.5) nameAngle = 'Nordeste';
    if (angle > 67.5 && angle <= 112.5) nameAngle = 'Leste ';
    if (angle > 112.5 && angle <= 157.5) nameAngle = 'Sudeste';
    if (angle > 157.5 && angle <= 202.5) nameAngle = 'Sul';
    if (angle > 202.5 && angle <= 247.5) nameAngle = 'Sudoeste';
    if (angle > 247.5 && angle <= 292.5) nameAngle = 'Oeste';
    if (angle > 292.5 && angle <= 337.5) nameAngle = 'Noroeste';
    return setAngle(nameAngle);
  }

  useEffect(() => {
    if (buffer.length !== 0) {
      const data = buffer.find(
        (item) => Number(item.tracker_id) === Number(idTracker)
      );
      if (data) {
        data.odometer = data.odometer ? data.odometer : 0;
        setTracker(data);
        getDirections(data.angle);
        // getAddress(data.lat, data.lng);
      }
    }
  }, [buffer]);

  useEffect(() => {
    if (buffer.length !== 0) {
      const data = buffer.find(
        (item) => Number(item.tracker_id) === Number(idTracker)
      );
      setTracker(data);
      getDirections(data.angle);
      // getAddress(data.lat, data.lng);
    }
  }, [idTracker]);

  function undefinedRemove(text) {
    if (text === undefined) {
      return '';
    }
    return text;
  }

  function directionsRedirect(lat, lng) {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
    );
  }

  function handleShare() {
    dispatch(CommandActions.setModalShare());
  }
  return (
    <div className="h-full w-full bg-white rounded-lg shadow p-2">
      <ListContainer>
        <DriverContainer>
          <header>
            <AvatarImage src={AvatarImageNone} alt="Avatar" />
            <div>
              <DriverTitle>
                {tracker.driver ? tracker.driver : 'Sem motorista vinculado'}
              </DriverTitle>
              <DriverDetail>
                <div>
                  <MdPhone size={15} />
                  <span>
                    {tracker.driver_phone ? tracker.driver_phone : '---'}
                  </span>
                </div>
              </DriverDetail>
            </div>
          </header>
          <div className="flex ">
            <button
              className="flex h-10 w-10 rounded-xl hover:bg-gray-200 transition mx-2 items-center justify-center"
              type="button"
              onClick={() => handleShare()}
              title="compartilhar a localização do veículo"
            >
              <MdShare size={20} color="#333" />
            </button>
            <button
              className="flex h-10 w-10 rounded-xl hover:bg-gray-200 transition mx-2 items-center justify-center"
              type="button"
              onClick={() => directionsRedirect(tracker.lat, tracker.lng)}
              title="ir até o veículo"
            >
              <MdDirections size={20} color="#333" />
            </button>
          </div>
        </DriverContainer>
        <div>
          <Actionitem
            icon={carIcon}
            text={`${undefinedRemove(tracker.plate)} - ${undefinedRemove(
              tracker.brand
            )} ${undefinedRemove(tracker.model)}`}
            tooltip="Carro"
          />
          {/* onClick={() => getAddress(tracker.lat, tracker.lng)} */}
          <ItemList>
            <Tooltip
              placement="right"
              overlay="Endereço"
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              arrowContent={<div className="rc-tooltip-arrow-inner" />}
            >
              <img src={addressIcon} alt="" />
            </Tooltip>
            {/* <span>{address}</span> */}
            <span>{tracker.address}</span>
          </ItemList>

          <Actionitem
            icon={batteryIcon}
            text={`${undefinedRemove(tracker.power_voltage)} V`}
            tooltip="Bateria"
          />
          <Actionitem
            icon={ignitionIcon}
            text={undefinedRemove(tracker.ignition) ? 'Ligada' : 'Desligada'}
            tooltip="Ignição"
          />
          <Actionitem
            icon={lastComunicationIcon}
            text={moment(tracker.date).format('DD/MM/YYYY HH:mm:ss')}
            tooltip="Última comunicação"
          />
          <Actionitem
            icon={odometerIcon}
            text={
              tracker.hour_meter
                ? `${undefinedRemove((tracker.h_meter / 60).toFixed(0))} H`
                : `${undefinedRemove((tracker.odometer / 1000).toFixed(0))} Km`
            }
            tooltip="Odômetro"
          />
          <Actionitem
            icon={directionsIcon}
            rotate={tracker.angle}
            text={`${undefinedRemove(tracker.speed)}Km/h - ${
              tracker.rpm ? `${tracker.rpm} RPM - ` : ''
            } ${undefinedRemove(angleName)} - (${undefinedRemove(
              tracker.lat
            )}, ${undefinedRemove(tracker.lng)})`}
            tooltip="Direção"
          />
        </div>
      </ListContainer>
      <ModalShare tracker={tracker} />
    </div>
  );
}

export default ActionComponent;
