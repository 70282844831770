import pt from 'date-fns/locale/pt';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';

import {
  ButtonStyled,
  FieldContainer,
  LabelStyled,
  DateStyle,
  SelectStyled,
} from '~/components/Global';
import SelectTracker from '~/components/Global/SelectTracker';
import { Creators as AlertActions } from '~/store/ducks/alertSauce';
import { mountParams } from '~/util/Mountparams';

import { Container } from './styles';

function Filter() {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      checked: false,
      tracker_id: '',
      initialDate: moment().subtract(1, 'week').toDate(),
      finalDate: moment().toDate(),
    },
    onSubmit: async (data) => {
      const params = { checked: data.checked };
      if (data.initialDate !== '')
        params.initialDate = moment
          .utc(data.initialDate)
          .format('YYYY-MM-DD HH:mm');
      if (data.finalDate !== '')
        params.finalDate = moment
          .utc(data.finalDate)
          .format('YYYY-MM-DD HH:mm');
      if (data.tracker_id !== '') params.tracker_id = data.tracker_id;
      const query = mountParams(params);
      await dispatch(AlertActions.setQueryParams(data));
      await dispatch(AlertActions.fetchAlertRequest(query));
    },
  });

  useEffect(() => {
    const params = { checked: false };

    params.initialDate = moment
      .utc()
      .subtract(1, 'week')
      .format('YYYY-MM-DD HH:mm')
      .valueOf();

    params.finalDate = moment.utc().format('YYYY-MM-DD HH:mm').valueOf();
    const query = mountParams(params);
    dispatch(AlertActions.setQueryParams(params));
    dispatch(AlertActions.fetchAlertRequest(query));
  }, []);

  return (
    <Container onSubmit={formik.handleSubmit}>
      <header>Filtros</header>
      <div>
        <div>
          <SelectTracker
            parentCallback={(tracker) =>
              formik.setFieldValue('tracker_id', tracker.id)
            }
            value={formik.values.tracker_id}
          />
        </div>
        <FieldContainer>
          <LabelStyled>Alertas Finalizados</LabelStyled>
          <SelectStyled
            name="category"
            defaultValue={{
              label: formik.values.checked ? 'Sim' : 'Não',
              value: formik.values.checked,
            }}
            onChange={(option) => formik.setFieldValue('checked', option.value)}
            value={{
              label: formik.values.checked ? 'Sim' : 'Não',
              value: formik.values.checked,
            }}
            options={[
              { label: 'Sim', value: true },
              { label: 'Não', value: false },
            ]}
          />
        </FieldContainer>
        <FieldContainer>
          <LabelStyled>Data de inicial</LabelStyled>
          <DateStyle
            showTimeSelect
            error={formik.touched.initialDate && formik.errors.initialDate}
            dateFormat="dd/MM/yyyy HH:mm:ss"
            selected={formik.values.initialDate}
            onChange={(value) =>
              formik.setFieldValue('initialDate', moment(value).toDate())
            }
            locale={pt}
            customInput={<InputMask mask="99/99/9999 99:99" />}
          />
        </FieldContainer>
        <FieldContainer>
          <LabelStyled>Data de Final</LabelStyled>
          <DateStyle
            showTimeSelect
            error={formik.touched.finalDate && formik.errors.finalDate}
            dateFormat="dd/MM/yyyy HH:mm:ss"
            selected={formik.values.finalDate}
            onChange={(value) =>
              formik.setFieldValue('finalDate', moment(value).toDate())
            }
            locale={pt}
            customInput={<InputMask mask="99/99/9999 99:99" />}
          />
        </FieldContainer>
      </div>
      <footer>
        <ButtonStyled
          color="#eee"
          textColor="#333"
          type="button"
          onClick={() => formik.resetForm()}
        >
          Limpar
        </ButtonStyled>
        <ButtonStyled color="#245378" type="submit">
          Pesquisar
        </ButtonStyled>
      </footer>
    </Container>
  );
}

export default Filter;
