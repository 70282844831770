import styled from 'styled-components';
import InputMask from 'react-input-mask';
import 'react-datepicker/dist/react-datepicker.css';

export const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 3px;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  resize: none;
  background: #fff;
  margin-bottom: 10px;
`;

export const HeaderTitle = styled.div`
  grid-area: header;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const FilterHeader = styled.div`
  border-radius: 5px 5px 0 0;
  background: #245378;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-left: 5px;
  font-weight: 600;
  color: #fff;
  height: 100%;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  @media screen and (max-width: 700px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const OptionPlaceholder = styled.option`
  background-color: #ccc;
`;

export const Map = styled.div`
  height: 500px;
  width: 100%;
`;

export const InputCustom = styled(InputMask)`
  font-size: 12px;
  border-radius: 500px;
`;
