import styled from 'styled-components';

export const LoadingContainer = styled.div`
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  height: 33.2px;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 10px;
`;
