import styled from 'styled-components';

export const Container = styled.div`
  padding: 5px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    padding-left: 5px;
    max-width: 350px;
    text-align: end;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  img {
    transform: ${(props) => props.rotate && `rotate(${props.rotate}deg)`};
    height: 15px;
    width: 15px;
    opacity: 0.8;
    transition: opacity 0.3s;
    :hover {
      opacity: 1;
    }
  }
`;
