import React, { useState, useEffect } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import AvatarImage from '~/assets/avatar.png';

import { JourneyContainer, ItemJourney, NotJourney } from './styles';

export default function Daily() {
  const [heigthChart, setHeigthChart] = useState(0);
  const journey = useSelector((state) => state.dailyState.journey);
  useEffect(() => {
    setHeigthChart(document.getElementById('journey').offsetHeight - 37);
  }, []);

  return (
    <JourneyContainer id="journey">
      <div>Jornadas</div>
      <ScrollBar style={{ height: `${heigthChart}px` }}>
        <ItemJourney>
          {_.isEmpty(journey) ? (
            <NotJourney>Sem jornada vinculada</NotJourney>
          ) : (
            journey.map((item) => (
              <div>
                <img
                  src={
                    item.image
                      ? `${process.env.REACT_APP_SERVER_FILE}${item.image}`
                      : AvatarImage
                  }
                  alt="Motorista"
                />
                <div>
                  <h4>{item.name}</h4>
                  <span>{item.total}</span>
                </div>
              </div>
            ))
          )}
        </ItemJourney>
      </ScrollBar>
    </JourneyContainer>
  );
}
