import { useFormik } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as WorksheetActions } from '~/store/ducks/worksheetSauce';

import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
  ConfirmBox,
  ConfirmDataContainer,
  ConfirmButtonsContainer,
  CommandNameStyled,
  BodyFilterConfirm,
  CardInfo,
  CommandConfirmButton,
  DivFlex,
  Input,
} from './styles';

function WorksheetModal({ events }) {
  const dispatch = useDispatch();
  const viewModal = useSelector((state) => state.worksheetState.viewModal);

  const [day, setDay] = useState([]);

  const formik = useFormik({
    initialValues: {
      newworksheet: [],
    },
    validate: (values) => {
      const err = {};
      const message = 'Hora inválida';
      values.newworksheet.map((item) => {
        if (item && item !== undefined) {
          if (item.start.includes('_')) {
            err.item = message;
          }
          return '';
        }
        return false;
      });
      return err;
    },
    onSubmit: async (values) => {
      const data = [];
      if (values.newworksheet.length > 0) {
        values.newworksheet.map((item, index) =>
          item !== undefined
            ? data.push({
                id: index,
                start: moment(`${day} ${item.start}`, 'DD/MM/YYYY HH:mm:ss'),
                end: moment(`${day} ${item.start}`, 'DD/MM/YYYY HH:mm:ss'),
              })
            : ''
        );
      }

      data.map((item) =>
        dispatch(WorksheetActions.updateWorksheetRequest(item))
      );
    },
  });

  function WindowClose() {
    dispatch(WorksheetActions.setViewModal());
  }

  useEffect(() => {
    if (events) {
      if (events[0] !== undefined) {
        setDay(events[0].start.format('DD/MM/YYYY'));
      }
    }
  }, [events]);

  return (
    <Modal
      visible={viewModal}
      effect="fadeInUp"
      onClickAway={() => WindowClose()}
      width="330px"
      height="500px"
    >
      <ModalContainer onSubmit={formik.handleSubmit}>
        <ModalHeader>
          <ModalTextHeader>Pontos de Trabalho</ModalTextHeader>
        </ModalHeader>
        <ConfirmBox>
          <ConfirmDataContainer>
            <BodyFilterConfirm>
              <CardInfo>
                {events.map((item) => (
                  <DivFlex>
                    <Input
                      mask="99:99:99"
                      defaultValue={item.start.format('HH:mm:SS')}
                      // onChange={e => timeUpdate(item.id, e.target.value)}
                      onChange={(event) =>
                        formik.setFieldValue(
                          `newworksheet[${item.id}].start`,
                          event.target.value
                        )
                      }
                    />
                  </DivFlex>
                ))}
              </CardInfo>
              {formik.errors.item ? <div>erro</div> : <div>a</div>}
            </BodyFilterConfirm>
            <hr />
          </ConfirmDataContainer>
        </ConfirmBox>
        <ConfirmButtonsContainer>
          <CommandConfirmButton color="#ff0015" onClick={() => WindowClose()}>
            <CommandNameStyled>&nbsp;Cancelar</CommandNameStyled>
          </CommandConfirmButton>
          <CommandConfirmButton front="#000000" back="#245378" type="submit">
            <CommandNameStyled>&nbsp;Salvar</CommandNameStyled>
          </CommandConfirmButton>
        </ConfirmButtonsContainer>
      </ModalContainer>
    </Modal>
  );
}

WorksheetModal.propTypes = {
  events: PropTypes.arrayOf().isRequired,
};

export default WorksheetModal;
