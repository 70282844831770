import styled from 'styled-components';

const AreaInput = styled.textarea`
  width: 100%;
  margin: ${({ margin }) => margin || '5px auto'};
  padding: 0 10px;
  background: ${({ background }) => background || 'rgba(0, 0, 0, 0.01)'};
  color: ${({ color }) => color || '#333'};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  border: 1px solid #333;
  font-size: 16px;
  border: 1px solid ${({ error }) => (error ? 'rgba(257,0,0, 1)' : '#ddd')};
  text-transform: ${({ plate }) => plate && 'uppercase'};

  :disabled {
    background: #dbdbdb;
  }
`;

export default AreaInput;
