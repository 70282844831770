import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonStyled } from '~/components/Global';
import MaskedInput from '~/components/Global/Inputs/maskedInput';
import PasswordInput from '~/components/Global/Inputs/passwordInput';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
} from './styles';

function Modal() {
  const dispatch = useDispatch();
  const { device, id } = useSelector((state) => state.commandState.tracker);
  const formik = useFormik({
    initialValues: {
      orimeter: '',
      password: '',
    },
    validate: (value) => {
      const err = {};
      if (!value.orimeter) err.orimeter = 'Campo obrigatório!';
      if (!value.password) err.password = 'Campo obrigatório!';
      return err;
    },
    onSubmit: (data) => {
      const orimeterSplit = data.orimeter.split('.');
      const orimeterSum = orimeterSplit[0] * 60 + orimeterSplit[1] * 6;
      let msg;
      if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
        msg = `CMD;${device.imei};05;05;${orimeterSum}`;
      } else if (device.model === 'SUNTECH') {
        msg = `ST300CMD;${device.imei};02;SetHMeter=${orimeterSum}`;
      }
      dispatch(
        CommandActions.createCommand({
          phone: device.phone,
          command: msg,
          value: 'orimeterUpdate',
          tracker_id: id,
          type: 'ORIMETER',
          password: data.password,
        })
      );
    },
  });
  function closeModal() {
    dispatch(CommandActions.setViewModal(null));
  }

  return (
    <ModalContainer onSubmit={formik.handleSubmit}>
      <Title>Horímetro</Title>
      <Descripition>
        <MaskedInput
          placeholder="Digite o horímetro"
          mask="99999.9"
          name="orimeter"
          type="tel"
          onChange={formik.handleChange}
          value={formik.values.orimeter}
          error={!!(formik.touched.orimeter && formik.errors.orimeter)}
        />
        <PasswordInput
          required
          placeholder="senha"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={!!(formik.touched.password && formik.errors.password)}
        />
      </Descripition>
      <ButtonsContainer>
        <ButtonStyled
          color="#ff4e4e"
          textColor="#eee"
          onClick={() => closeModal()}
        >
          Cancelar
        </ButtonStyled>
        <ButtonStyled type="subbmit" color="#01659e" textColor="#eee">
          Enviar
        </ButtonStyled>
      </ButtonsContainer>
    </ModalContainer>
  );
}

export default Modal;
