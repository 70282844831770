import JsPDF from 'jspdf';

import 'jspdf-autotable';
import logoImage from '../assets/base64Logo';

export default function exportPdf(
  data,
  headers,
  labels,
  title,
  background,
  color,
  moment,
  isGrouped,
  filters,
  landscape
) {
  const doc = new JsPDF(landscape ? 'l' : 'p');
  doc.autoTable({
    head: [labels],
    body: data,
    theme: isGrouped ? 'grid' : 'striped',
    styles: {
      fontSize: 8,
    },
    headStyles: {
      fillColor: background,
      fontSize: 8,
    },
    margin: {
      top: filters ? 35 : 27,
    },
    didDrawPage() {
      // Header
      doc.addImage(logoImage(), 'JPEG', 15, 10, 30, 15);
      doc.setFontSize(12);
      doc.text(title, !landscape ? 196 : 282, 15, 'right');
      doc.setFontSize(8);
      doc.text(moment, !landscape ? 196 : 280, 25, 'right');
      doc.line(15, 26, !landscape ? 196 : 282, 26);
      if (filters) {
        doc.setFontSize(10);
        doc.text(`Filtros: ${filters}`, 15, 31);
        doc.line(15, 33, !landscape ? 196 : 282, 33);
      }

      // footer
      const { pageSize } = doc.internal;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.line(15, pageHeight - 15, !landscape ? 196 : 282, pageHeight - 15);
      const str = `Página ${doc.internal.getNumberOfPages()}`;
      doc.setFontSize(10);
      doc.text(str, !landscape ? 180 : 262, pageHeight - 10);
    },
  });
  doc.save(`${title} ${moment}.pdf`);
}
