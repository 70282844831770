import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/journeySauce';

export function* getJourney(payload) {
  try {
    const response = yield call(api.fetchJourney, payload.query);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_JOURNEY_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_JOURNEY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error(JSON.stringify(error));
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createJourneySaga(payload) {
  try {
    yield call(api.createJourney, payload.data);
    yield put({
      type: Types.FETCH_JOURNEY_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/journey'));
  } catch (error) {
    yield put({ type: Types.FETCH_JOURNEY_FAILURE });
    toast.error(error.data.message);
  }
}

export function* updateJourneySaga(payload) {
  try {
    yield call(api.updateJourney, payload.data);
    yield put({
      type: Types.FETCH_JOURNEY_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/journey'));
  } catch (error) {
    toast.error(error.data.message);
    yield put({ type: Types.FETCH_JOURNEY_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_JOURNEY_REQUEST, getJourney)]);
  yield all([takeEvery(Types.CREATE_JOURNEY_REQUEST, createJourneySaga)]);
  yield all([takeEvery(Types.UPDATE_JOURNEY_REQUEST, updateJourneySaga)]);
}
