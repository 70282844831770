import React, { useRef, useLayoutEffect, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import Loading from '~/components/Loading';

import {
  TableContainer,
  HeaderTable,
  DetailTable,
  ItemTable,
  CommandTitle,
  CommandPlate,
  CommandDate,
  CommandDriver,
  NoData,
} from './styles';

function Historic() {
  const commands = useSelector((state) => state.commandState.commands);
  const loading = useSelector((state) => state.commandState.loading);
  const targetRef = useRef();
  const [height, setHeight] = useState('0px');
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight - 40}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight('0px');
    dimension();
  }, []);

  function getCommandType(command) {
    let commandString;
    switch (command.type) {
      case 'BLOCK':
        if (command.value === 'true') commandString = 'BLOQUEIO';
        else commandString = 'DESBLOQUEIO';
        break;
      case 'SIREN':
        if (command.value === 'true') commandString = 'DESLIGOU SIRENE';
        else commandString = 'LIGOU SIRENE';
        break;
      case 'ODOMETER':
        commandString = 'ODÔMETRO';
        break;
      case 'ORIMETER':
        commandString = 'HORÍMETRO';
        break;
      case 'TIMER':
        commandString = 'TEMPO DE TRANSMISSÃO';
        break;
      default:
        commandString = 'OUTROS';
        break;
    }
    return commandString;
  }

  return (
    <TableContainer ref={targetRef}>
      <HeaderTable>Histórico de comandos</HeaderTable>
      <DetailTable>
        <ScrollBar style={{ height }}>
          {loading && <Loading />}
          {_.isEmpty(commands) ? (
            <NoData>Não possui histórico de comandos</NoData>
          ) : (
            commands.map((item) => (
              <ItemTable key={item.id}>
                <CommandTitle>{getCommandType(item)}</CommandTitle>
                <CommandPlate>{item.trackerItem.plate}</CommandPlate>
                <CommandDate>
                  {moment(item.date).format('DD/MM/YYYY HH:mm')}
                </CommandDate>
                <CommandDriver>
                  {item.user ? item.user.name : 'Usuário não encontrado'}
                </CommandDriver>
              </ItemTable>
            ))
          )}
        </ScrollBar>
      </DetailTable>
    </TableContainer>
  );
}

export default Historic;
