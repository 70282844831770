import {
  safezoneControl,
  trafficControl,
  mapControl,
  referencePointControl,
  zoneControl,
  rulerControl,
  fullscreenControl,
} from './controls';

export function initControls(
  map,
  measureTool,
  dispatch,
  setTypeModal,
  setGeoJson,
  params,
  history
) {
  const centerControlDiv = document.createElement('div');
  mapControl(centerControlDiv, map);

  centerControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
    centerControlDiv
  );

  const trackerId = params && params.get && Number(params.get('id'));
  if (trackerId) {
    const fullscreenControlDiv = document.createElement('div');
    fullscreenControl(
      trackerId,
      fullscreenControlDiv,
      map,
      dispatch,
      setTypeModal,
      setGeoJson,
      params,
      history
    );
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
      fullscreenControlDiv
    );
  }

  const trafficControlDiv = document.createElement('div');
  trafficControl(trafficControlDiv, map);

  trafficControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
    trafficControlDiv
  );

  const referencePointControlDiv = document.createElement('div');
  referencePointControl(
    referencePointControlDiv,
    map,
    dispatch,
    setTypeModal,
    setGeoJson,
    params
  );

  referencePointControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
    referencePointControlDiv
  );

  const safezoneControlDiv = document.createElement('div');
  safezoneControl(safezoneControlDiv, map, params);

  safezoneControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
    safezoneControlDiv
  );

  const zoneControlDiv = document.createElement('div');
  zoneControl(zoneControlDiv, map, params);

  zoneControlDiv.index = 1;
  map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
    zoneControlDiv
  );

  if (measureTool) {
    const rulerControlDiv = document.createElement('div');
    rulerControl(rulerControlDiv, map, measureTool);

    rulerControlDiv.index = 1;
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      rulerControlDiv
    );
  }
}
