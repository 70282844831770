import { toast } from 'react-toastify';

import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '~/services/api';
import history from '~/services/history';

import { Types } from '../ducks/authSauce';

export function* loginSaga(payload) {
  try {
    const { data } = yield call(Api.login, payload.userForm);

    console.log('#14', { data });

    localStorage.setItem('token', data.token);
    localStorage.setItem('signed', true);

    let roleType = '';
    // código "aleatório" para dificultar que o usuário modifique o local storage no console
    if (data.role === 'basic') {
      roleType = '3822';
    }
    if (data.role === 'master') {
      roleType = '4521';
    }
    localStorage.setItem('elor', roleType);
    localStorage.setItem('user', data.user);

    yield put({ type: Types.LOGIN_SUCCESS, payload: data });
    setTimeout(() => history.push('/monitoring'), 1000);
  } catch (error) {
    // console.tron.log({ error });
    toast.error(error.data.error);
    yield put({ type: Types.LOGIN_FAILURE });
    // localStorage.removeItem('token');
    localStorage.setItem('signed', false);
  }
}

export default function* rootSaga() {
  yield takeLatest(Types.LOGIN_REQUEST, loginSaga);
}
