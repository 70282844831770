export default function translate({ url, body, method }) {
  const translateString = {
    '/me': 'Informações do usuário',
    '/groups': 'Grupo de veículos',
    '/share': `Compartilhou o veículo por ${body.hours} hora${
      body.hours > 1 ? 's' : ''
    }`,
    '/chart/daily': 'Diário sobre mapa',
    '/clients': 'Clientes',
    '/logs': 'Auditoria',
    '/alerts': 'Alertas',
    '/devices': 'Dispositivos',
    '/trackers': 'Item de rastreamento',
    '/profile': 'Perfil',
    '/types': 'Icones',
    '/commands': 'Comandos',
    '/listcommands': 'Histórico de comandos',
    '/users': 'Usuários',
    '/hasCommand': 'Possui comando',
    '/drivers': 'Motoristas',
    '/maintenances': 'Manutenção',
    '/permissions': 'Permissões',
    '/journeys': 'Jornadas',
    '/violations': 'Violações',
    '/supplies': 'Abastecimento',
  };

  const methodTranslate = {
    GET: 'Visualizou',
    POST: 'Criou',
    PUT: 'Atualizou',
    DELETE: 'Deletou',
  };

  const redirectDefault = url.replace(/^\/([\w]+)\/([\d]+)$/, '/$1/form/$2');
  const redirectWithoutPlural = url
    .replace(/^\/([\w]+)\/([\d]+)$/, '/$1#/form/$2')
    .replace('s#/', '/');
  const redirectTranslate = {
    '/me': false,
    '/clients': false,
    '/share': false,
    '/group': false,
    '/logs': false,
    '/types': false,
    '/listCommand': false,
    '/command': false,
    '/permissions': false,
    '/devices': false,
    '/profile': false,
    '/chart/daily': false,
    '/users': false,
    '/drivers/': redirectWithoutPlural,
    '/drivers': false,
    '/supplies/': redirectWithoutPlural,
    '/supplies': false,
    '/violations/': redirectWithoutPlural,
    '/violations': false,
    '/journeys/': redirectWithoutPlural,
    '/journeys': false,
    '/maintenances/': redirectWithoutPlural,
    '/maintenances': false,
  };

  const idx = Object.keys(translateString).find((p) => url.startsWith(p));
  const idxRedirect = Object.keys(redirectTranslate).find((p) =>
    url.startsWith(p)
  );

  return {
    title: idx ? translateString[idx] : url,
    method: methodTranslate[method] || method,
    redirect: idxRedirect ? redirectTranslate[idxRedirect] : redirectDefault,
  };
}
