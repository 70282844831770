import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import api from '~/services/api';

import SimpleSelect from './Simple';

export default function SelectGroups({ defaultValue, ...props }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [valueDefault, setValueDefault] = useState({});

  async function getGroups() {
    try {
      const { data } = await api.fetchGroups();
      const newData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(newData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOptions([]);
    }
  }

  useEffect(() => {
    setLoading(true);
    getGroups();
  }, []);

  useEffect(() => {
    if (defaultValue && !_.isEmpty(options)) {
      setValueDefault(
        options.find(({ value }) => Number(value) === Number(defaultValue))
      );
      setKey(Math.random());
    }
  }, [defaultValue, options]);

  return (
    <div>
      {loading ? (
        <div
          style={{ margin: '5px auto' }}
          className="h-10 flex px-2 items-center w-full rounded-lg bg-gray-100"
        >
          Carregando...
        </div>
      ) : (
        <SimpleSelect
          key={key}
          noOptionsMessage={() => 'Nenhum grupo encontrado.'}
          placeholder={loading ? 'Carregando' : 'Selecione um grupo'}
          options={options}
          defaultValue={defaultValue && valueDefault}
          {...props}
        />
      )}
    </div>
  );
}

SelectGroups.propTypes = {
  defaultValue: PropTypes.number,
};

SelectGroups.defaultProps = {
  defaultValue: null,
};
