import React, { useEffect, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import moment from 'moment';
import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { DateStyle } from '~/components/Global';

import { IconButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import history from '~/services/history';

import { InputCustom } from './styles';
import Table from './table';
import { FieldContainer, SelectStyled } from '~/components/Global';

function Journey() {
  const me = useSelector((state) => state.globalState.me);
  const [filterDate, setFilterDate] = useState(new Date());
  const [journeyType, setJourneyType] = useState('journey');
  const [editablePermission, setEditablePermission] = useState(false);
  registerLocale('pt-BR', el);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
      setJourneyType('journey');
    }
  }, [me]);

  return (
    <FormContainer>
      <HeaderFormContainer
        title={
          { journey: 'Jornada', preJourney: 'Pré Jornada' }[journeyType] || ''
        }
      >
        <div className="w-1/3 flex items-center justify-between gap-2 px-2">
          <FieldContainer style={{ height: 'auto' }}>
            {/* <span>Tipo</span> */}
            <SelectStyled
              name="type"
              defaultValue={{
                label:
                  {
                    journey: 'Jornada',
                    routeJourney: 'Jornada Rota',
                    preJourney: 'Pré Jornada',
                  }[journeyType] || '',
                value: journeyType,
              }}
              onChange={(option) => setJourneyType(option.value)}
              value={{
                label:
                  {
                    journey: 'Jornada',
                    routeJourney: 'Jornada Rota',
                    preJourney: 'Pré Jornada',
                  }[journeyType] || '',
                value: journeyType,
              }}
              options={[
                { label: 'Jornada', value: 'journey' },
                { label: 'Jornada Rota', value: 'routeJourney' },
                { label: 'Pré Jornada', value: 'preJourney' },
              ]}
              isDisabled={!editablePermission}
            />
          </FieldContainer>
          <DateStyle
            width='110px'
            borderRadius="8px"
            height="2.5rem"
            dateFormat="dd/MM/yyyy"
            selected={filterDate}
            onChange={(value) => setFilterDate(moment(value, 'DD/MM/YYYY').toDate())}
            locale={pt}
            customInput={<InputCustom style={{ width: '110px' }} mask="99/99/9999" />}
          />
          <IconButton
            Icon={MdAdd}
            style={{ 'min-width': `100px` }}
            size={15}
            margin="0"
            height="h-10"
            onClick={() => history.push('/journey/form')}
            disabled={!editablePermission}
            type="button"
          >
            Adicionar
          </IconButton>
        </div>
      </HeaderFormContainer>
      <SimpleContainer>
        <Table filterDate={filterDate} type={journeyType} />
      </SimpleContainer>
    </FormContainer>
  );
}

export default Journey;
