import pt from 'date-fns/locale/pt';
import _ from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import PropType from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import { DateStyle, FieldContainer, LabelStyled } from '~/components/Global';

import { InputContainerWeek, WeekList } from '../../styles';
import translateWeek from '../../translateDayWeek';
import { WeekContainer } from './styles';

function ConfigurationFence({
  configurationFence,
  week,
  formik,
  editablePermission,
  index,
}) {
  function getConfigurationFence() {
    if (configurationFence === 'always') {
      return <></>;
    }
    if (configurationFence === 'once') {
      return (
        <WeekList>
          <FieldContainer style={{ margin: '0 10px' }}>
            <LabelStyled>Início</LabelStyled>
            <DateStyle
              style={{ height: '42px' }}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              selected={formik.values.fences[index].once.start}
              onChange={(value) =>
                formik.setFieldValue(
                  `fences[${index}].once.start`,
                  moment(value).toDate()
                )
              }
              locale={pt}
              customInput={<InputMask mask="99/99/9999 99:99" />}
              disabled={!editablePermission}
            />
          </FieldContainer>
          <FieldContainer style={{ margin: '0 10px' }}>
            <LabelStyled>Fim</LabelStyled>
            <DateStyle
              style={{ height: '42px' }}
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              selected={formik.values.fences[index].once.end}
              onChange={(value) =>
                formik.setFieldValue(
                  `fences[${index}].once.end`,
                  moment(value).toDate()
                )
              }
              locale={pt}
              customInput={<InputMask mask="99/99/9999 99:99" />}
              disabled={!editablePermission}
            />
          </FieldContainer>
        </WeekList>
      );
    }

    return (
      <WeekContainer>
        {!_.isEmpty(week) &&
          week.map((w, subIndex, id = `_label${nanoid()}`) => {
            return (
              <WeekList key={w}>
                <div className="pretty p-default p-curve p-smooth">
                  <input
                    id={id}
                    type="checkbox"
                    checked={week.check}
                    onChange={(event) =>
                      formik.setFieldValue(
                        `fences[${index}].week[${subIndex}].check`,
                        event.target.checked
                      )
                    }
                    value={formik.values.fences[index].week[subIndex].check}
                  />
                  <div className="state p-success">
                    <label htmlFor={id}>{translateWeek(w.name)}</label>
                  </div>
                </div>
                <InputContainerWeek>
                  <DateStyle
                    style={{ height: '42px' }}
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    selected={formik.values.fences[index].week[subIndex].start}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `fences[${index}].week[${subIndex}].start`,
                        moment(value).toDate()
                      )
                    }
                    locale={pt}
                    customInput={<InputMask mask="99:99" />}
                    disabled={!editablePermission}
                  />
                  <DateStyle
                    style={{ height: '42px' }}
                    dateFormat="HH:mm"
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    selected={formik.values.fences[index].week[subIndex].end}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `fences[${index}].week[${subIndex}].end`,
                        moment(value).toDate()
                      )
                    }
                    locale={pt}
                    customInput={<InputMask mask="99:99" />}
                    disabled={!editablePermission}
                  />
                </InputContainerWeek>
              </WeekList>
            );
          })}
      </WeekContainer>
    );
  }

  return <>{getConfigurationFence()}</>;
}

ConfigurationFence.propTypes = {
  formik: PropType.objectOf.isRequired,
  week: PropType.objectOf.isRequired,
  configurationFence: PropType.string.isRequired,
  editablePermission: PropType.bool,
  index: PropType.number,
};

ConfigurationFence.defaultProps = {
  editablePermission: false,
  index: 0,
};

export default ConfigurationFence;
