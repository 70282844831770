import React, { useState, useEffect } from 'react';
import { registerLocale } from 'react-datepicker';
import { MdCheck, MdClear, MdDelete } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { IconButton, SimpleButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  DateStyle,
  FormData,
  DataContainer,
  ProfileContainer,
  Row,
  ErrorMessage,
  FieldContainer,
  LabelStyled,
  Input,
  SelectStyled,
  Currency,
} from '~/components/Global';
import SelectTracker from '~/components/Global/SelectTracker';
import Loading from '~/components/Loading';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as SupplyActions } from '~/store/ducks/supplySauce';

function Forms({
  match: {
    params: { id: idParam },
  },
}) {
  const dispatch = useDispatch();
  const supply = useSelector((state) => state.supplyState.supply);
  const loading = useSelector((state) => state.supplyState.loading);
  const me = useSelector((state) => state.globalState.me);
  const [editablePermission, setEditablePermission] = useState(false);
  const [liters, setLiters] = useState(false);

  registerLocale('pt-BR', el);

  useEffect(() => {
    if (idParam) {
      dispatch(SupplyActions.fetchSupplyRequest(`/${idParam}`));
    }
  }, []);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  const formik = useFormik({
    initialValues: {
      tracker_id: '',
      date: moment().toDate(),
      fuelPrice: 0,
      totalPrice: '',
      fuelType: 0,
      marker: 0,
    },

    validate: (values) => {
      const errors = {};
      const message = 'Campo obrigatório';
      if (values.tracker_id === '') errors.tracker_id = message;
      if (values.fuelType === '') errors.fuelType = message;
      if (!values.date) errors.date = message;
      if (values.fuelPrice === '') errors.fuelPrice = message;
      if (values.totalPrice === '') errors.totalPrice = message;
      return errors;
    },

    onSubmit: (data) => {
      if (data.id) {
        dispatch(SupplyActions.updateSupplyRequest(data));
      } else {
        dispatch(SupplyActions.createSupplyRequest(data));
      }
      dispatch(SupplyActions.clearSupply());
    },
  });

  useEffect(() => {
    if (!_.isEmpty(supply)) {
      formik.setValues({
        ...supply,
        date: moment(supply.date).toDate(),
      });
    }
  }, [supply]);

  useEffect(() => {
    const { fuelPrice, totalPrice } = formik.values;
    if (fuelPrice && totalPrice) {
      setLiters(totalPrice / fuelPrice);
    }
  }, [formik.values.fuelPrice, formik.values.totalPrice]);

  function cancelButton() {
    history.push('/supply');
    dispatch(SupplyActions.clearSupply());
  }

  const fuelTypes = [
    { value: '1', label: 'Gasolina comum' },
    { value: '2', label: 'Gasolina aditivada' },
    { value: '3', label: 'Etanol' },
    { value: '4', label: 'Diesel' },
    { value: '5', label: 'Gás Natural Veicular' },
  ];

  async function deleteButton() {
    try {
      await api.deleteSupply(idParam);
      dispatch(SupplyActions.clearSupply());

      history.push('/supply');
    } catch (error) {
      console.tron.log('error');
    }
  }

  function getHeaderComponent() {
    if (editablePermission) {
      if (loading) {
        return (
          <SimpleButton width="w-44" height="h-8" disabled type="button">
            Aguarde
          </SimpleButton>
        );
      }
      return (
        <div
          className={`grid ${
            idParam ? 'w-96 grid-cols-3' : 'w-64 grid-cols-2'
          } gap-2 h-8`}
        >
          <IconButton
            background="bg-red-600"
            margin="0px"
            Icon={MdClear}
            size={15}
            type="button"
            onClick={() => cancelButton()}
          >
            Cancelar
          </IconButton>
          {idParam && (
            <IconButton
              margin="0px"
              background="bg-red-600"
              Icon={MdDelete}
              size={15}
              onClick={deleteButton}
            >
              Excluir
            </IconButton>
          )}
          <IconButton
            background="bg-gray-600"
            margin="0px"
            Icon={MdCheck}
            size={15}
            type="submit"
          >
            Salvar
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton
        Icon={MdCheck}
        size={15}
        type="button"
        onClick={() => cancelButton()}
        width="w-44"
        height="h-8"
      >
        Voltar
      </IconButton>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de abastecimento">
          {getHeaderComponent()}
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <FormData>
              <DataContainer>
                <ProfileContainer>
                  <div>
                    <Row>
                      <FieldContainer>
                        {(idParam && formik.values.tracker_id) || !idParam ? (
                          <SelectTracker
                            defaultValue={formik.values.tracker_id}
                            parentCallback={(option) =>
                              formik.setFieldValue('tracker_id', option.id)
                            }
                            error={
                              formik.errors.tracker_id &&
                              formik.touched.tracker_id
                            }
                            isDisabled={!editablePermission}
                            value={formik.values.tracker_id}
                          />
                        ) : (
                          <></>
                        )}
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>DATA DE ABASTECIMENTO</LabelStyled>
                        </div>
                        <DateStyle
                          showTimeSelect
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          selected={formik.values.date}
                          onChange={(value) =>
                            formik.setFieldValue('date', moment(value).toDate())
                          }
                          locale={pt}
                          customInput={<InputMask mask="99/99/9999 99:99" />}
                          disabled={!editablePermission}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>TIPO DE COMBUSTIVEL</LabelStyled>
                          {formik.errors.fuelType && formik.touched.fuelType ? (
                            <ErrorMessage>
                              {formik.errors.fuelType}
                            </ErrorMessage>
                          ) : (
                            <></>
                          )}
                        </div>
                        <SelectStyled
                          // styles={style}
                          defaultValue={
                            fuelTypes[
                              fuelTypes.findIndex(
                                (item) => item.value === formik.values.fuelType
                              )
                            ]
                          }
                          options={fuelTypes}
                          placeholder="Selecione o tipo de combustível"
                          noOptionsMessage={() =>
                            'Nenhum tipo de combustível encontrado'
                          }
                          onChange={(option) =>
                            formik.setFieldValue('fuelType', option.value)
                          }
                          error={
                            formik.errors.fuelType && formik.touched.fuelType
                          }
                          isDisabled={!editablePermission}
                          value={
                            formik.values.fuelType
                              ? fuelTypes[
                                  fuelTypes.findIndex(
                                    (item) =>
                                      item.value === formik.values.fuelType
                                  )
                                ]
                              : ''
                          }
                        />
                      </FieldContainer>
                    </Row>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>VALOR/LITRO</LabelStyled>
                          {formik.errors.fuelPrice &&
                          formik.touched.fuelPrice ? (
                            <ErrorMessage>Campo obrigatório</ErrorMessage>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <Currency
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix="R$"
                            precision="3"
                            onChangeEvent={(e, masked, float) =>
                              formik.setFieldValue('fuelPrice', float)
                            }
                            value={formik.values.fuelPrice}
                            id="fuelPrice"
                            name="fuelPrice"
                            error={
                              formik.errors.fuelPrice &&
                              formik.touched.fuelPrice
                            }
                            disabled={!editablePermission}
                          />
                        </div>
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>VALOR TOTAL</LabelStyled>
                          {formik.errors.totalPrice &&
                          formik.touched.totalPrice ? (
                            <ErrorMessage>Campo obrigatório</ErrorMessage>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div>
                          <Currency
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix="R$"
                            precision="3"
                            onChangeEvent={(e, masked, float) =>
                              formik.setFieldValue('totalPrice', float)
                            }
                            value={formik.values.totalPrice}
                            id="totalPrice"
                            name="totalPrice"
                            error={
                              formik.errors.totalPrice &&
                              formik.touched.totalPrice
                            }
                            disabled={!editablePermission}
                          />
                        </div>
                      </FieldContainer>
                      <FieldContainer>
                        <div>
                          <LabelStyled>LITROS</LabelStyled>
                        </div>

                        <Input
                          disabled
                          value={liters ? liters.toFixed(3) : '...'}
                        />
                      </FieldContainer>
                    </Row>
                    <Row>
                      <FieldContainer>
                        <div>
                          <LabelStyled>Odômetro</LabelStyled>
                        </div>
                        <div>
                          <Input
                            type="number"
                            onChange={formik.handleChange}
                            value={formik.values.marker}
                            id="marker"
                            name="marker"
                            disabled={!editablePermission}
                          />
                        </div>
                      </FieldContainer>
                    </Row>
                  </div>
                </ProfileContainer>
              </DataContainer>
            </FormData>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
