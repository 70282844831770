import React from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import { ConnectedRouter } from 'connected-react-router';

import * as pages from '~/pages';
import axios from '~/services/axios';
import history from '~/services/history';
import { Creators as AuthActions } from '~/store/ducks/authSauce';

import Route from './Route';
import routes from './routes';

export default function Routes() {
  const dispatch = useDispatch();
  const theme = localStorage.getItem('geoTheme');
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          dispatch(AuthActions.signOut());
          localStorage.setItem('signed', false);
          localStorage.removeItem('token');
        }
      }
      return Promise.reject(error.response);
    }
  );

  if (theme === 'dark') {
    document.querySelector('html').classList.add('dark');
  } else {
    document.querySelector('html').classList.remove('dark');
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Redirect exact from="/" to="auth/login" />
        <Route path="/auth/login" component={pages.Auth} />
        <Route path="/auth/forgot" component={pages.Forgot} />
        <Route path="/password/:token" component={pages.Password} />
        {routes.map((i) => (
          <Route
            {...i}
            key={`route_${i.path}`}
            isPrivate
            component={i.component}
          />
        ))}
      </Switch>
    </ConnectedRouter>
  );
}
