import React, { useEffect, useState } from 'react';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import ScrollBar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import Loading from '~/components/Loading';
import { Creators as DailyActions } from '~/store/ducks/dailySauce';

import History from './history';
import {
  DetailContainer,
  TimelineContainer,
  NotStops,
  Header,
  ButtonDetail,
} from './styles';

function Daily() {
  const [heigthChart, setHeigthChart] = useState(0);
  const [showDetail, setShowDetail] = useState(true);
  const dispatch = useDispatch();
  const stops = useSelector((state) => state.dailyState.stops);
  const loadingStops = useSelector((state) => state.dailyState.loadingStops);
  useEffect(() => {
    setHeigthChart(document.getElementById('points').offsetHeight - 41);
  }, []);

  function getShowDetailComponent() {
    function getTitle({ odometer, index }) {
      if (!_.isEmpty(stops)) {
        if (!odometer) {
          return 'N/D';
        }
        if (stops[index + 1] && stops[index + 1].odometer) {
          return `percorreu ${(odometer - stops[index + 1].odometer).toFixed(
            1
          )}`;
        }
        return 'N/D';
      }
      return 'N/D';
    }
    if (_.isEmpty(stops)) {
      return <NotStops>Sem paradas para exibir</NotStops>;
    }
    return (
      <TimelineContainer>
        <Timeline>
          {stops.map((item, index) =>
            item.event === 'ON' ? (
              <TimelineEvent
                style={{
                  backgroundColor: '#e8f4f0',
                  cursor: 'pointer',
                  padding: '5px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.07)',
                }}
                key={item}
                bubbleStyle={{
                  borderColor: '#245378',
                  backgroundColor: '#e8f4f0',
                }}
                onIconClick={() =>
                  dispatch(
                    DailyActions.setCoordinateInfo({
                      ...item,
                      ignition: 'Ligado',
                    })
                  )
                }
                title={item.address}
                createdAt={`${moment(
                  item.date,
                  'YYYY-MM-DDTHH:mm:ss.SSSZ'
                ).format('HH:mm:ss')} Ligou o carro`}
                onClick={() =>
                  dispatch(
                    DailyActions.setCoordinateInfo({
                      ...item,
                      ignition: 'Ligado',
                    })
                  )
                }
              />
            ) : (
              <TimelineEvent
                key={item}
                style={{
                  backgroundColor: '#f4f8f9',
                  cursor: 'pointer',
                  padding: '5px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.07)',
                }}
                bubbleStyle={{
                  borderColor: '#E3EDF0',
                  backgroundColor: '#f4f8f9',
                }}
                title={item.address}
                createdAt={`${moment(
                  item.date,
                  'YYYY-MM-DDTHH:mm:ss.SSSZ'
                ).format('HH:mm:ss')} Desligou o carro e ${getTitle({
                  index,
                  odometer: item.odometer,
                })} km`}
                onIconClick={() =>
                  dispatch(
                    DailyActions.setCoordinateInfo({
                      ...item,
                      ignition: 'Desligado',
                    })
                  )
                }
                onClick={() =>
                  dispatch(
                    DailyActions.setCoordinateInfo({
                      ...item,
                      ignition: 'Desligado',
                    })
                  )
                }
              />
            )
          )}
        </Timeline>
      </TimelineContainer>
    );
  }

  return (
    <DetailContainer id="points">
      <Header>
        <ButtonDetail
          selected={showDetail}
          onClick={() => setShowDetail(true)}
          type="button"
        >
          Paradas
        </ButtonDetail>
        <ButtonDetail
          selected={!showDetail}
          onClick={() => setShowDetail(false)}
          type="button"
        >
          Pontos
        </ButtonDetail>
      </Header>
      {loadingStops ? (
        <Loading />
      ) : (
        <ScrollBar style={{ height: `${heigthChart}px` }}>
          {showDetail ? (
            getShowDetailComponent()
          ) : (
            <History height={`${heigthChart}px`} />
          )}
        </ScrollBar>
      )}
    </DetailContainer>
  );
}

export default Daily;
