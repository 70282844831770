import styled from 'styled-components';
import AvatarEditor from 'react-avatar-editor';
import { InputStyled, Container, ButtonStyled } from '~/components/Global';

export const Avatar = styled(AvatarEditor)`
  border: 2px solid rgb(255, 255, 255);
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px;
  margin: 10px;
`;

export const FileUpload = styled.label`
  input[type='file'] {
    display: none;
  }
  margin-left: -65px;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  border: 1px solid #fff;
  border-radius: 100%;
  background: #999;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  z-index: 2;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileContainer = styled.div`
  display: flex;
`;

export const StyledContainer = styled(Container)`
  display: grid;
  grid-template-rows: 200px 1fr;
  .header {
    background: #227ad7;
    border-radius: 5px 5px 0 0;
  }
`;

export const Side = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 5px 0 0 5px;
  margin-top: -145px;
`;

export const Title = styled.span`
  color: #333;
  text-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px;
  font-weight: 600;
  font-size: 25px;
`;

export const Subtitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Input = styled(InputStyled)`
  border: none;
  background: transparent;
  color: #666;
  font-size: 18px;
  border-bottom: 1px solid rgba(102, 6, 0, 0.1);
  border-radius: 0;
  padding-left: 5px;
  padding-top: 5px;
  height: 30px;
  :focus {
    border-bottom: 1px solid #666;
  }
`;

export const Button = styled(ButtonStyled)`
  border: none;
  font-weight: 600;
  transition: all 0.3s;
  :hover {
    background: #0e5fb5;
  }
  span {
    margin-left: 5px;
  }
`;
