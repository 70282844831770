import alerts from '~/assets/side/alerts.svg';
import audit from '~/assets/side/audit.svg';
import car from '~/assets/side/car.svg';
import command from '~/assets/side/command.svg';
import daily from '~/assets/side/daily.svg';
import driver from '~/assets/side/driver.svg';
import gear from '~/assets/side/gear.svg';
import globe from '~/assets/side/globe.svg';
import journey from '~/assets/side/journey.svg';
import maintenance from '~/assets/side/maintenance.svg';
import news from '~/assets/side/news.svg';
import report from '~/assets/side/report.svg';
import route from '~/assets/side/route.svg';
import schedules from '~/assets/side/schedules.svg';
import supply from '~/assets/side/supply.svg';
import violation from '~/assets/side/violation.svg';
import zone from '~/assets/side/zone.svg';

export default function itemsData({ editablePermission }) {
  const visible = true;
  return [
    {
      icon: globe,
      tooltip: 'Veículos',
      to: '/monitoring',
      selected: window.location.href.indexOf('monitoring') > -1,
      visible,
    },
    {
      icon: alerts,
      tooltip: 'Central de Alertas',
      to: '/alert',
      selected: window.location.href.indexOf('alert') > -1,
      visible: editablePermission,
    },
    {
      icon: car,
      tooltip: 'Veículos',
      to: '/tracker',
      selected: window.location.href.indexOf('tracker') > -1,
      visible,
    },
    {
      icon: driver,
      tooltip: 'Pessoas',
      to: '/driver',
      selected: window.location.href.indexOf('driver') > -1,
      visible,
    },
    {
      icon: maintenance,
      tooltip: 'Manutenção',
      to: '/maintenance',
      selected: window.location.href.indexOf('maintenance') > -1,
      visible,
    },
    {
      icon: maintenance,
      tooltip: 'Manutenção Preventiva',
      to: '/preventive',
      selected: window.location.href.indexOf('preventive') > -1,
      visible,
    },
    {
      icon: supply,
      tooltip: 'Abastecimento',
      to: '/supply',
      selected: window.location.href.indexOf('supply') > -1,
      visible,
    },
    {
      icon: journey,
      tooltip: 'Jornada',
      to: '/journey',
      selected: window.location.href.indexOf('journey') > -1,
      visible,
    },
    {
      icon: violation,
      tooltip: 'Infrações',
      to: '/violation',
      selected: window.location.href.indexOf('violation') > -1,
      visible,
    },
    {
      icon: command,
      tooltip: 'Gestão de Comandos',
      to: '/command',
      selected: window.location.href.indexOf('command') > -1,
      visible,
    },
    {
      icon: zone,
      tooltip: 'Cadastro de área',
      to: '/fence',
      selected: window.location.href.indexOf('fence') > -1,
      visible,
    },
    {
      icon: route,
      tooltip: 'Rotas',
      to: '/route',
      selected: window.location.href.indexOf('route') > -1,
      visible: true,
    },
    {
      icon: daily,
      tooltip: 'Diário sobre Mapa',
      to: '/daily',
      selected: window.location.href.indexOf('daily') > -1,
      visible,
    },
    {
      icon: report,
      tooltip: 'Relatórios',
      to: '/reports',
      selected: window.location.href.indexOf('reports') > -1,
      visible,
    },
    {
      icon: schedules,
      tooltip: 'Agendamentos',
      to: '/schedules',
      selected: window.location.href.indexOf('schedules') > -1,
      visible,
    },
    {
      icon: audit,
      tooltip: 'Auditoria',
      to: '/audit',
      selected: window.location.href.indexOf('audit') > -1,
      visible,
    },
    {
      icon: news,
      tooltip: 'Atualização',
      to: '/releases',
      selected: window.location.href.indexOf('releases') > -1,
      visible,
    },
    {
      icon: gear,
      tooltip: 'Configurações',
      to: '/settings',
      selected: window.location.href.indexOf('settings') > -1,
      visible,
    },
  ];
}
