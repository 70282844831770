import styled from 'styled-components';

export const IconHeader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  background: ${(props) => (props.selected ? '#ddd' : 'none')};
  border-bottom: ${(props) => (props.selected ? '2px solid #595959' : 'none')};
  img {
    transition: 0.3s;
    @media (max-width: 1300px) {
      width: 20px;
      height: 20px;
    }
    @media (min-width: 1300px) {
      width: 25px;
      height: 25px;
    }
  }
`;

export const Badge = styled.div`
  background: #fff;
  border-radius: 6px;
  font-weight: 700;
  font-size: 11px;
  width: 21px;
  height: 13px;
  text-align: center;
  position: absolute;
  top: 3px;
  right: 0;
`;
