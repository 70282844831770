import styled from 'styled-components';

export const TableContainer = styled.div`
  grid-area: historic;
  display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-areas: 'headerTable' 'detailTable';
  grid-gap: 10px;
`;

export const HeaderTable = styled.div`
  grid-area: headerTable;
  display: flex;
  align-items: center;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  font-size: 20px;
  font-weight: 600;
  color: #245378;
`;

export const DetailTable = styled.div`
  grid-area: detailTable;
`;

export const NoData = styled.div`
  height: 50px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemTable = styled.div`
  height: 50px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-template-rows: 2fr 1fr;
  :first-child {
    margin: 0;
  }
`;

export const CommandTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: #245378;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommandPlate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #245378;
`;

export const CommandDate = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommandDriver = styled.div`
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
