import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/preventiveSauce';

export function* getPreventive(payload) {
  try {
    const response = yield call(api.fetchPreventive, payload.query);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_PREVENTIVE_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_PREVENTIVE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Erro ao buscar dados');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createPreventiveSaga(payload) {
  try {
    const clientId = localStorage.getItem('client') || '';
    yield call(api.createPreventive, { ...payload.data, client_id: clientId });
    yield put({
      type: Types.FETCH_PREVENTIVE_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/preventive'));
  } catch (error) {
    yield put({ type: Types.FETCH_PREVENTIVE_FAILURE });
    toast.error(error?.data.message);
  }
}

export function* updatePreventiveSaga(payload) {
  try {
    yield call(api.updatePreventive, payload.data);
    yield put({
      type: Types.FETCH_PREVENTIVE_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/preventive'));
  } catch (error) {
    toast.error(error.data.message);
    yield put({ type: Types.FETCH_PREVENTIVE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_PREVENTIVE_REQUEST, getPreventive)]);
  yield all([takeEvery(Types.CREATE_PREVENTIVE_REQUEST, createPreventiveSaga)]);
  yield all([takeEvery(Types.UPDATE_PREVENTIVE_REQUEST, updatePreventiveSaga)]);
}
