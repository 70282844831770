import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import PropTypes from 'prop-types';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function ViewPublication({ publication }) {
  const [descriptionFormated, setDescriptionFormated] = useState(
    EditorState.createEmpty()
  );
  useEffect(() => {
    const contentBlock = htmlToDraft(publication.description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);

      setDescriptionFormated(editorState);
    } else {
      setDescriptionFormated(EditorState.createEmpty());
    }
  }, [publication]);
  return (
    <div className="overflow-auto bg-white p-2 rounded-lg shadow">
      <Editor
        editorState={descriptionFormated}
        toolbarClassName="toolbarClassName"
        toolbarHidden
        readOnly
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </div>
  );
}

ViewPublication.propTypes = {
  publication: PropTypes.objectOf(PropTypes.string, PropTypes.bool).isRequired,
};

export default ViewPublication;
