export default function verifyStatus({ filterActive }) {
  if (filterActive === 'ON') {
    return 'ligado';
  }
  if (filterActive === 'OFF') {
    return 'desligado';
  }
  if (filterActive === 'DANGER') {
    return 'acima da velocidade';
  }
  if (filterActive === 'DISCONNECTED') {
    return 'offline';
  }
  return 'encontrado';
}
