import React from 'react';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { SimpleInput } from '~/components/inputs';

import { Container } from './styles';

function Simple({
  label,
  error,
  errorMessage,
  id = `_input${nanoid()}`,
  ...props
}) {
  return (
    <Container>
      <label className={error ? 'text-red-700 font-bold' : ''} htmlFor={id}>
        {label}
      </label>

      {error && (
        <span className="absolute top-2 right-0 text-xs text-red-600 ">
          {errorMessage}
        </span>
      )}
      <SimpleInput id={id} error={error} {...props} />
    </Container>
  );
}

Simple.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Simple.defaultProps = {
  error: false,
  errorMessage: '',
};

export default Simple;
