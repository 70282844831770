import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as RouteActions } from '~/store/ducks/routeSauce';

function Route() {
  const routes = useSelector((state) => state.routeState.routes);
  const loading = useSelector((state) => state.routeState.loading);
  const dispatch = useDispatch();
  async function getRoutes() {
    dispatch(RouteActions.fetchRouteRequest('?type=trip'));
  }

  useEffect(() => {
    getRoutes();
  }, []);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={routes.map((item) => ({
          ...item,
          origin: item.google_json?.origin,
          destination: item.google_json?.destination,
        }))}
        headerBackground="#245378"
        loading={loading}
        labels={['Nome', 'Origem', 'Destino']}
        headers={['name', 'origin', 'destination']}
        fileTitle="Relatório de rotas"
        getData={(data) => history.push(`/route/form/${data.id}`)}
      />
    </div>
  );
}

export default Route;
