import PropTypes from 'prop-types';
import React from 'react';

import { MaskedInput } from '~/components/inputs';

import { LabelStyled, Input, ContainerLabel } from './styles';

export default function Field({
  labelName,
  name,
  error,
  disabled,
  mask,
  parentCallback,
  value
}) {
  return (
    <>
      <ContainerLabel>
        <LabelStyled>{labelName}</LabelStyled>
      </ContainerLabel>
      {!mask ? (
        <Input
          disabled={!!disabled}
          name={name}
          value={value}
          onChange={parentCallback}
          error={error}
          errorBackground={error}
        />
      ) : (
        <MaskedInput
          maskChar={null}
          mask={mask}
          disabled={!!disabled}
          name={name}
          value={value}
          onChange={parentCallback}
        />
      )}
    </>
  );
}

Field.propTypes = {
  parentCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
};

Field.defaultProps = {
  error: '',
  disabled: false,
  mask: '',
};
