import pt from 'date-fns/locale/pt';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { MdAdd } from 'react-icons/md';
import InputMask from 'react-input-mask';

import { DateField } from '~/components/fields';
import {
  ContainerTitle,
  FieldContainer,
  LabelStyled,
  ButtonContainer,
} from '~/components/Global';

import { ConfigRow } from '../styles';
import setException from './setException';

function ExceptionConfig({ formik, editablePermission }) {
  return (
    <>
      <ContainerTitle>EXCEÇÃO</ContainerTitle>
      <ConfigRow>
        <DateField
          label="DATA INÍCIO"
          dateFormat="dd/MM/yyyy HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          selected={formik.values.exception_date_start}
          onChange={(value) =>
            formik.setFieldValue('exception_date_start', moment(value).toDate())
          }
          locale={pt}
          customInput={<InputMask mask="99/99/9999 99:99" />}
          error={
            formik.touched.exception_date_start &&
            formik.errors.exception_date_start
          }
          disabled={!editablePermission}
        />
        <DateField
          label="DATA FIM"
          dateFormat="dd/MM/yyyy HH:mm"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          selected={formik.values.exception_date_end}
          onChange={(value) =>
            formik.setFieldValue('exception_date_end', moment(value).toDate())
          }
          locale={pt}
          customInput={<InputMask mask="99/99/9999 99:99" />}
          error={
            formik.touched.exception_date_end &&
            formik.errors.exception_date_end
          }
          disabled={!editablePermission}
        />
        <FieldContainer style={{ width: '110px' }}>
          <LabelStyled>&nbsp;</LabelStyled>
          <ButtonContainer
            disabled={formik.values.fence_id === null}
            color="#555"
            type="button"
            onClick={() => setException({ formik })}
          >
            <MdAdd size={15} />
            Adicionar
          </ButtonContainer>
        </FieldContainer>
      </ConfigRow>
    </>
  );
}

ExceptionConfig.propTypes = {
  formik: PropTypes.objectOf.isRequired,
  editablePermission: PropTypes.bool,
};

ExceptionConfig.defaultProps = {
  editablePermission: false,
};

export default ExceptionConfig;
