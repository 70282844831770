import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  height: 99vh;
  width: 100vw;
  grid-template-rows: 50px 1fr;
  grid-template-columns: 40px 1fr;
  grid-template-areas:
    'header header'
    'side main';

  @media (max-width: 600px) {
    grid-template-rows: 40px 1fr;
  }
`;

export const Container = styled.div``;
