import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

export const DivBlockChart = styled.div`
  height: 90%;
  display: block;
  canvas {
    min-height: 200px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
  grid-template-areas: 'Map  Map' 'Action  Board';
  margin: 10px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: 'Map Board ' ' Action Action' ' Data Data';
  }
`;

export const MapContainer = styled.div`
  grid-area: Map;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const BoardContainer = styled.div`
  grid-area: Board;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const DataContainer = styled.div`
  grid-area: Data;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const ActionContainer = styled.div`
  grid-area: Action;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const ActionGrid = styled.div`
  height: 100%;
  width: 100%;
  background: #f4f8f9;
  border-radius: 5px;
`;

export const ListContainer = styled.div`
  grid-area: List;
`;

export const ActionsContainer = styled.div`
  grid-area: Actions;
  background: #dfdfdf;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const DriverContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  > header {
    display: flex;
  }
`;

export const AvatarImage = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 10px;
  border: 2px solid #fff;
  margin-right: 10px;
`;

export const DriverTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
`;

export const DriverDetail = styled.div`
  div {
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
`;

export const ActionImage = styled.img`
  width: 30px;
  height: 30px;
  opacity: 0.5;
  padding: 5px;

  border-radius: 8px;
  transition: all 0.3s;
  :hover {
    opacity: 1;
    background: #d8d8d8;
  }
`;

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
`;

export const ChartPages = styled.div`
  grid-area: pages;
  display: flex;
`;

export const PageContainer = styled.div`
  background: #f2f1f0;
  height: 20px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? '#f2f1f0' : '#c4c4c4')};
  font-weight: 600;
  transition: all 0.3s;
  color: ${(props) => (props.active ? '#605F5F' : '#9F9B9B')};
  cursor: pointer;
  :first-child {
    border-radius: 5px 0 0 0;
  }
  :last-child {
    border-radius: 0 5px 0 0;
  }
`;

export const ChartDetail = styled.div`
  background: #f4f8f9;
  border-radius: 5px;
  width: 100%;
  height: 100%;
`;

export const LineStyled = styled(Line)``;

export const ButtonContainer = styled.div`
  padding: 10px;

  > button {
    margin: 0 5px;
    cursor: pointer;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    transition: 0.2s;
    background: transparent;
    :hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;
