import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import Card from '~/assets/login/card.svg';
import IdCard from '~/assets/login/id-card.svg';
import Lock from '~/assets/login/lock.svg';
import { ButtonStyled, WhiteLabel } from '~/components/Global';
import Loading from '~/components/Loading';
import history from '~/services/history';
import { Creators as AuthActions } from '~/store/ducks/authSauce';

import {
  Container,
  Form,
  Input,
  InputMask,
  Information,
  ButtonContainer,
  InputContainer,
  Title,
  ImageContainer,
  Link,
} from './styles';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';

function Login() {
  localStorage.clear();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const loading = useSelector((state) => state.authState.loading);
  const formik = useFormik({
    initialValues: {
      cpf: '',
      password: '',
    },
    onSubmit: (data) => {
      dispatch(AuthActions.loginRequest(data));
      if (checked) {
        localStorage.setItem('login', data.cpf);
      } else {
        localStorage.setItem('login', '');
      }
    },
  });

  const handleClick = () => setChecked(!checked);

  function handleForgot() {
    history.push('/auth/forgot');
  }

  useEffect(() => {
    const login = localStorage.getItem('login');
    if (login) {
      formik.setFieldValue('cpf', login);
      setChecked(true);
    }
  }, []);

  return (
    <Container>
      {!loading ? (
        <>
          <Form>
            <Title>BEM-VINDO</Title>
            <form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <img src={IdCard} alt="cpf" />
                <InputMask
                  mask="999.999.999-99"
                  name="cpf"
                  onChange={formik.handleChange}
                  value={formik.values.cpf}
                  placeholder="CPF"
                  required
                />
              </InputContainer>
              <InputContainer>
                <img src={Lock} alt="senha" />
                <Input
                  placeholder="Senha"
                  name="password"
                  required
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
              </InputContainer>
              <div className="pretty p-default p-curve p-smooth">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleClick}
                />
                <div className="state p-success">
                  <WhiteLabel>Lembrar-me</WhiteLabel>
                </div>
              </div>
              <ButtonContainer>
                <ButtonStyled color="#245378" type="submit">
                  Entrar
                </ButtonStyled>
                <Link href onClick={() => handleForgot()}>
                  Esqueceu a senha?
                </Link>
              </ButtonContainer>
            </form>
          </Form>

          <Information>
            <ImageContainer>
              <img src={Card} alt="Card" />
            </ImageContainer>
          </Information>
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
}

export default Login;
