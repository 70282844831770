import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchFenceRequest: ['query'],
  fetchFenceSuccess: ['data'],
  fetchFenceIdSuccess: ['data'],
  fetchFenceFailure: [],
  updateFenceRequest: ['data'],
  createFenceRequest: ['data'],
  clearFence: [],
  setModalDelete: [],
  loadAll: ['fences'],
  loadAllSuccess: [],
  loadAllFailure: [],
});

const INITIAL_STATE = {
  fences: [],
  fence: {},
  fenceByTracker: {},
  loading: false,
  modalDelete: false,
  loadAll: false,
};

const changeModal = (state = INITIAL_STATE) => ({
  ...state,
  modalDelete: !state.modalDelete,
});

const clearFence = (state = INITIAL_STATE) => ({
  ...state,
  fence: {},
});

const fetchFenceRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchFenceSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    fences: actions.data,
    loading: false,
  };
};

const fetchFenceIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    fence: actions.data,
    loading: false,
  };
};

const fetchFenceFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  fences: [],
  fence: {},
});

const loadAll = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
    loadAll: true,
  };
};

const loadAllSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    loadAll: false,
  };
};

const loadAllFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  loadAll: false,
  fences: [],
  fence: {},
});

const createFenceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateFenceRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_FENCE_REQUEST]: fetchFenceRequest,
  [Types.FETCH_FENCE_SUCCESS]: fetchFenceSuccess,
  [Types.FETCH_FENCE_ID_SUCCESS]: fetchFenceIdSuccess,
  [Types.FETCH_FENCE_FAILURE]: fetchFenceFailure,
  [Types.UPDATE_FENCE_REQUEST]: updateFenceRequest,
  [Types.CREATE_FENCE_REQUEST]: createFenceRequest,
  [Types.SET_MODAL_DELETE]: changeModal,
  [Types.CLEAR_FENCE]: clearFence,
  [Types.LOAD_ALL]: loadAll,
  [Types.LOAD_ALL_SUCCESS]: loadAllSuccess,
  [Types.LOAD_ALL_FAILURE]: loadAllFailure,
});
