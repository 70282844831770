import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import mapStyle from '~/assets/map.json';
import markerAlert from '~/assets/markerAlert.svg';
import markerRun from '~/assets/markerRun.svg';
import markerStop from '~/assets/markerStop.svg';
import { iconMarker } from '~/components/Map/marker';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import { Container } from './styles';

function Map() {
  const tracker_id = useSelector((state) => state.commandState.tracker_id);
  const buffer = useSelector((state) => state.commandState.buffer);
  const dispatch = useDispatch();
  const [map, setMap] = useState();
  const [marker, setMarker] = useState({});

  useEffect(() => {
    const mapView = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: true,
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 15,
      controlSize: 25,
      styles: mapStyle,
      mapId: 'b2695ee62f09f89'
    });
    setMap(mapView);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(marker)) {
      marker.setMap(null);
    }

    let point = {};

    let icon;
    let alertPlate;
    if (buffer.status === 'DANGER') icon = markerAlert;
    else if (buffer.status === 'OFF') icon = markerStop;
    else if (buffer.ignition) icon = markerRun;
    else icon = markerStop;

    if (buffer.status === 'DISCONNECTED') alertPlate = 'DISCONNECTED';
    else if (buffer.alert_on) alertPlate = 'ALERT';

    const block = !!(buffer.output1 || buffer.output2 || buffer.output3);

    const pointer = new window.RichMarker({
      position: new window.google.maps.LatLng(buffer.lat, buffer.lng),
      map,
      draggable: false,
      content: iconMarker(icon, buffer.angle, alertPlate, buffer.plate, block),
      flat: true,
      anchor: window.RichMarkerPosition.MIDDLE,
    });
    point = pointer;
    if (map)
      map.setCenter(new window.google.maps.LatLng(buffer.lat, buffer.lng));

    setMarker(point);
  }, [buffer]);

  useEffect(() => {
    let interval;
    if (tracker_id) {
      interval = setInterval(() => {
        dispatch(CommandActions.fetchCommandBufferRequest(tracker_id));
      }, 30000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [tracker_id]);

  return (
    <Container>
      <div id="map" />
    </Container>
  );
}

export default Map;
