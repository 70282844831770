import React, { useEffect, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { CSVLink } from 'react-csv';
import { FaFileDownload } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import _ from 'lodash';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';

import Loading from '~/components/Loading';

import {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  Th,
  Tr,
  Td,
  TableContainer,
  Scrollbar,
  TotalSpan,
  TdFooter,
} from './styles';

import 'rc-tooltip/assets/bootstrap.css';

export default function TableComponent({
  loading,
  changeValue,
  data: dataTable,
  header,
  labelsNames,
}) {
  function getData(data) {
    changeValue(data);
  }
  const [data, setData] = useState([]);
  const [list, setList] = useState(dataTable);
  const [, setForceUpdate] = useState(0);
  const [labelOrder, setLabelOrder] = useState({ label: '', order: false });

  useEffect(() => {
    setData();
    const teste = [header];

    Array.prototype.push.apply(
      teste,
      list.map((dataMap) =>
        labelsNames.map((labels) => (dataMap[labels] ? dataMap[labels] : ''))
      )
    );
    setData(teste);
  }, [list]);
  useEffect(() => {
    setList(dataTable);
  }, [dataTable]);
  function onSort(e, sortKey) {
    if (labelOrder.order || labelOrder.label !== sortKey) {
      dataTable.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
      setLabelOrder({ label: sortKey, order: false });
    } else {
      dataTable.sort((a, b) => a[sortKey].localeCompare(b[sortKey])).reverse();
      setLabelOrder({ label: sortKey, order: true });
    }
    setForceUpdate((teste) => teste + 1);
    setList(dataTable);
  }

  function getDataComponent() {
    if (_.isEmpty(dataTable)) {
      <Tr>
        <Td colSpan={header.length} noData>
          Sem dados para exibir
        </Td>
      </Tr>;
    } else {
      list.map((item) => {
        return (
          <Tr key={item} onClick={() => getData(item)}>
            {labelsNames.map((teste, i) => {
              return (
                <Td key={item} data-label={header[i]}>
                  {item[teste] ? item[teste].toString() : ''}
                </Td>
              );
            })}
          </Tr>
        );
      });
    }
  }

  return (
    <Scrollbar>
      <TableContainer>
        <Table>
          <TableHeader>
            <tr>
              {header.map((item, index) => (
                <Th
                  scope="col"
                  key={item}
                  onClick={(e) => onSort(e, labelsNames[index])}
                >
                  <div>
                    <span>{item}</span>
                    {labelOrder.label === labelsNames[index] &&
                      (labelOrder.order ? (
                        <MdKeyboardArrowDown size={20} />
                      ) : (
                        <MdKeyboardArrowUp size={20} />
                      ))}
                  </div>
                </Th>
              ))}
            </tr>
          </TableHeader>
          <TableBody>
            {loading ? (
              <Tr>
                <Td colSpan={header.length} noData>
                  <Loading />
                </Td>
              </Tr>
            ) : (
              getDataComponent()
            )}
          </TableBody>
          <TableFooter>
            <tr>
              {labelsNames.map((item, index) => (
                <TdFooter>
                  {index === labelsNames.length - 1 && (
                    <TotalSpan>
                      {`Total de Registros : ${dataTable.length}`}
                    </TotalSpan>
                  )}
                  {index === 0 && (
                    <Tooltip
                      placement="right"
                      overlay="Download da planilha"
                      mouseEnterDelay={0}
                      mouseLeaveDelay={0}
                      arrowContent={<div className="rc-tooltip-arrow-inner" />}
                    >
                      <CSVLink data={data} filename="Tabela de ****.csv">
                        <FaFileDownload color="#333" size={17} />
                      </CSVLink>
                    </Tooltip>
                  )}
                </TdFooter>
              ))}
            </tr>
          </TableFooter>
        </Table>
      </TableContainer>
    </Scrollbar>
  );
}

TableComponent.propTypes = {
  loading: PropTypes.bool,
  changeValue: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(),
  header: PropTypes.arrayOf(),
  labelsNames: PropTypes.arrayOf(),
};

TableComponent.defaultProps = {
  loading: false,
  data: [],
  header: [],
  labelsNames: [],
};
