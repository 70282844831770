import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import moment from 'moment';
import api from '~/services/api';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as JourneyActions } from '~/store/ducks/journeySauce';
// import { Creators as PreJourneyActions } from '~/store/ducks/preJourneySauce';
import { getClientId } from '~/util/parseJwt';

function Journey({ filterDate, type }) {
  const dispatch = useDispatch();
  const journeys = useSelector((state) => state.journeyState.journeys);
  // const preJourneys = useSelector((state) => state.preJourneyState.preJourneys);
  const loading = useSelector((state) => state.journeyState.loading);
  const [data, setData] = useState([]);
  const [_loading, set_loading] = useState(false);

  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }

    // dispatch(
    //   PreJourneyActions.fetchPreJourneyRequest(
    //     filterClient
    //       ? `?id_clients=${filterClient}&filterDate=${new Date(
    //           filterDate
    //         ).toISOString()}`
    //       : undefined
    //   )
    // );
  }, []);

  useEffect(() => {
    setData(journeys);
  }, [journeys]);

  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    if (type === 'journey') {
      dispatch(
        JourneyActions.fetchJourneyRequest(
          filterClient
            ? `?id_clients=${filterClient}&filterDate=${new Date(
                filterDate
              ).toISOString()}`
            : undefined
        )
      );
    } else if (type === 'preJourney') {
      (async () => {
        // console.log('fetch preJourney');
        try {
          set_loading(true);
          const result = await api.fetchPreJourney(
            filterClient
              ? `?id_clients=${filterClient}&filterDate=${new Date(
                  filterDate
                ).toISOString()}`
              : undefined
          );
          // console.log(result);
          if (result.status === 200) {
            setData(result.data);
          }
        } catch (err) {
          console.log('#72 fetch preJourney', err?.message);
        } finally {
          set_loading(false);
        }
      })();
    } else if (type === 'routeJourney') {
    }
  }, [type, filterDate]);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      {type === 'journey' ? (
        <TableComponent
          dimensionHeight={height}
          data={data.map((item) => ({
            ...item,
            start: moment(item.start).format('DD/MM/YYYY - HH:mm:ss '),
            end: item.end
              ? moment(item.end).format('DD/MM/YYYY - HH:mm:ss ')
              : '',
            tracker_id: item.trackerItem ? item.trackerItem.label : '',
            driver_id: item.driver
              ? item.driver.name
              : 'Motorista não identificado',
            automatic: item.automatic ? 'Cartão identificador' : 'Manual',
          }))}
          headerBackground="#245378"
          loading={loading}
          labels={['Veículo', 'Motorista', 'Início', 'Fim', 'Cadastro']}
          headers={[
            'tracker_id',
            'driver_id',
            'start',
            'end',
            'automatic',
          ]}
          fileTitle="Relatório de itens de rastreamento"
          getData={(data) =>
            data.automatic && !data.end
              ? toast.warning('Aguarde jornada ser finalizada para editar')
              : history.push(`/journey/form/${data.id}`)
          }
        />
      ) : (
        ''
      )}

      {type === 'preJourney' ? (
        <TableComponent
          dimensionHeight={height}
          data={data.map((item) => ({
            ...item,
            tracker_id: item.trackerItem ? item.trackerItem.label : '',
            driver_id: item.driver ? item.driver.name : 'Sem Motorista',
          }))}
          headerBackground="#245378"
          loading={_loading}
          // labels={['Veículo', 'Motorista']}
          // headers={['tracker_id', 'driver_id']}

          labels={['Nome', 'Veículo', 'Motorista', 'Início', 'Fim', 'Km Total']}
          headers={[
            'name',
            'tracker_id',
            'driver_id',
            'start_hour',
            'end_hour',
            'kilometer_total',
          ]}
          fileTitle="Relatório de pré jornadas"
          getData={(data) =>
            data.automatic && !data.end
              ? toast.warning('Aguarde jornada ser finalizada para editar')
              : history.push(`/prejourney/form/${data.id}`)
          }
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Journey;
