import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as DriverActions } from '~/store/ducks/driverSauce';

function Driver() {
  const dispatch = useDispatch();
  const driversState = useSelector((state) => state.driverState.drivers);
  const loading = useSelector((state) => state.driverState.loading);
  const [drivers, setDrivers] = useState(driversState);
  useEffect(() => {
    setDrivers([]);
    dispatch(DriverActions.fetchDriverRequest());
  }, []);

  useEffect(() => {
    setDrivers([...driversState]);
  }, [driversState]);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={drivers.map((item) => ({
          ...item,
        }))}
        headerBackground="#245378"
        loading={loading}
        labels={[
          'Nome',
          'CPF',
          'E-mail',
          'Telefone',
          'Cartão de identificação',
        ]}
        headers={['name', 'cpf', 'email', 'phone', 'card']}
        fileTitle="Relatório de motoristas"
        getData={(data) => history.push(`/driver/form/${data.id}`)}
      />
    </div>
  );
}

export default Driver;
