import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ButtonStyled } from '~/components/Global';
import MaskedInput from '~/components/Global/Inputs/maskedInput';
import PasswordInput from '~/components/Global/Inputs/passwordInput';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import {
  ModalContainer,
  Title,
  Descripition,
  ButtonsContainer,
  Field,
} from './styles';

function Modal() {
  const dispatch = useDispatch();
  const { device, id } = useSelector((state) => state.commandState.tracker);

  const formik = useFormik({
    initialValues: {
      stoped: '480',
      running: '30',
      password: '',
      angle: '75', 
    },
    validate: (value) => {
      const err = {};
      if (!value.stoped) err.stoped = 'Campo obrigatório!';
      if (!value.running) err.running = 'Campo obrigatório!';
      if (!value.password) err.password = 'Campo obrigatório!';
      if (!value.angle) err.angle = 'Campo obrigatório!';
      return err;
    },
    onSubmit: (data) => {
      let msg;
      if (device.model === 'SUNTECH' && device.type === 'ST4315U') {
        const ignitiomOff = data.stoped
        const stopIgnitiomOn = data.running
        const ignitiomOn = data.running
        const angle = data.angle
        msg = `PRG;${device.imei};16;70#${ignitiomOff};71#0;72#0;73#${stopIgnitiomOn};74#0;75#${angle};76#0;77#0;78#0;79#${ignitiomOn};80#0;81#${angle};82#60;83#0;84#0;85#60;86#0;87#0`;
      } else if (device.model === 'SUNTECH') {
        msg = `ST300RPT;${device.imei};02;${data.stoped};${data.running};${data.running};3;0;200;0;0;0`;
      } else if (device.model === 'QUECLINK') {
        msg = `AT+GTFRI=gv75,1,1,,1,0000,0000,,${data.running},1000,1000,,0,${data.stoped},0,,,,FFFF$`;
      }
      if (data.stoped < '480') {
        toast.warn('O valor minimo do tempo parado é de 480s');
      } else if (data.running < '30') {
        toast.warn('O valor minimo do tempo em movimento é de 30s');
      } else if (data.angle < '60') {
        toast.warn('O valor minimo da mudança de ângulo é de 60°');
      } else if (data.angle > '90') {
        toast.warn('O valor máximo da mudança de ângulo é de 90°');
      } else {
        dispatch(
          CommandActions.createCommand({
            phone: device.phone,
            command: msg,
            value: 'timerUpdate',
            tracker_id: id,
            type: 'TIMER',
            password: data.password,
          })
        );
      }
    },
  });

  function closeModal() {
    dispatch(CommandActions.setViewModal(null));
  }

  return (
    <ModalContainer onSubmit={formik.handleSubmit}>
      <Title>Tempo de transmissão</Title>
      <Descripition>
        <Field>
          <div>Tempo parado</div>
          <MaskedInput
            placeholder="Digite o tempo Parado"
            mask="9999"
            name="stoped"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.stoped}
            error={!!(formik.touched.stoped && formik.errors.stoped)}
          />
        </Field>
        <Field>
          <div>Tempo em movimento</div>
          <MaskedInput
            placeholder="Digite o tempo em movimento"
            mask="9999"
            name="running"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.running}
            error={!!(formik.touched.running && formik.errors.running)}
          />
        </Field>
        <PasswordInput
          required
          placeholder="senha"
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          error={!!(formik.touched.password && formik.errors.password)}
        />
      </Descripition>
      <ButtonsContainer>
        <ButtonStyled
          color="#ff4e4e"
          textColor="#eee"
          onClick={() => closeModal()}
        >
          Cancelar{' '}
        </ButtonStyled>
        <ButtonStyled type="subbmit" color="#01659e" textColor="#eee">
          Enviar{' '}
        </ButtonStyled>
      </ButtonsContainer>
    </ModalContainer>
  );
}

export default Modal;
