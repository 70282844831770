import React from 'react';
import { useDispatch } from 'react-redux';

import SelectTracker from '~/components/Global/selectTrackerFilter';
import { Creators as CommandActions } from '~/store/ducks/commandSauce';

import { Container } from './styles';

function Filter() {
  const dispatch = useDispatch();
  function getValues(car) {
    dispatch(CommandActions.fetchCommandRequest(car.id));
  }

  return (
    <Container>
      <header>Gestão de Comandos</header>
      <SelectTracker parentCallback={(car) => getValues(car)} />
    </Container>
  );
}

export default Filter;
