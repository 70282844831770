import React from 'react';

import { ContainerStyled } from '~/components/Global';

import Filters from './filters';
import Map from './Map';

function Fence() {
  return (
    <ContainerStyled>
      <Filters />
      <Map />
    </ContainerStyled>
  );
}

export default Fence;
