import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as PreventiveActions } from '~/store/ducks/preventiveSauce';
import { getClientId } from '~/util/parseJwt';

function Preventive() {
  const dispatch = useDispatch();
  const preventives = useSelector((state) => state.preventiveState.preventives);
  const loading = useSelector((state) => state.preventiveState.loading);

  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    console.log(filterClient);
    dispatch(
      PreventiveActions.fetchPreventiveRequest(
        filterClient ? `?id_clients=${filterClient}` : undefined
      )
    );
  }, []);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={preventives.map((item) => ({
          // date_start: moment(date_start).format('DD/MM/YYYY HH:MM:SS'),
          // date_end: moment(date_end).format('DD/MM/YYYY HH:MM:SS'),
          plateFormated: item.trackerItem
            ? `${item.trackerItem.label} - ${item.trackerItem.plate}`
            : '',
          ...item,
        }))}
        headerBackground="#245378"
        loading={loading}
        labels={['Tipo', 'Km', 'Veículo', 'Dias', 'Aviso Prévio']}
        headers={[
          'type',
          'kilometer',
          'plateFormated',
          'days',
          'advanced_notice',
        ]}
        groupType={['simple', 'simple', 'simple', 'simple', 'simple', 'sum']}
        fileTitle="Relatório de manutenção preventiva"
        getData={(data) => history.push(`/preventive/form/${data.id}`)}
      />
    </div>
  );
}

export default Preventive;
