import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/chartSauce';

export function* speedSaga(payload) {
  try {
    const speed = yield call(api.fetchChartSpeed, payload.id);
    yield put({
      type: Types.FETCH_CHART_SUCCESS,
      data: speed.data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_CHART_FAILURE });
    toast.error('Ocorreu um error!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_CHART_SPEED_REQUEST, speedSaga)]);
}
