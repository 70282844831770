import { toast } from 'react-toastify';

import { put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/axios';

import { Types } from '../ducks/schedulesSauce';

export function* getSchedules({ id, month }) {
  try {
    const { data } = yield api.get(
      month ? `/tasks?month=${month}` : `/tasks${id ? `/${id}` : ''}`
    );
    // console.log({ data });
    yield put({
      type: Types.FETCH_SCHEDULES_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_SCHEDULES_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export function* createSchedule({ params }) {
  try {
    yield api.post('/tasks', params);
    yield put({
      type: Types.FETCH_SCHEDULES_REQUEST,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_SCHEDULES_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export function* updateSchedule({ params }) {
  try {
    yield api.put(`/tasks/${params.id}`, params);
    yield put({
      type: Types.FETCH_SCHEDULES_REQUEST,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_SCHEDULES_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}
export function* deleteSchedule({ id }) {
  try {
    yield api.delete(`/tasks/${id}`);
    yield put({
      type: Types.FETCH_SCHEDULES_REQUEST,
    });
  } catch (error) {
    yield put({ type: Types.FETCH_SCHEDULES_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.FETCH_SCHEDULES_REQUEST, getSchedules),
    takeEvery(Types.CREATE_SCHEDULE_REQUEST, createSchedule),
    takeEvery(Types.UPDATE_SCHEDULE_REQUEST, updateSchedule),
    takeEvery(Types.DELETE_SCHEDULE_REQUEST, deleteSchedule),
  ]);
}
