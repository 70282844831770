import React from 'react';
import { MdToday } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import { format, getMonth } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';

import { Creators as schedulesActions } from '~/store/ducks/schedulesSauce';

function Header({ monthNow, setMonth, month }) {
  const dispatch = useDispatch();
  return (
    <div className="grid grid-cols-3 w-full">
      <div className="w-80 flex">
        <div className="w-32">
          <header className="text-xl font-bold text-geo-900">
            {format(monthNow, 'MMMM', { locale: ptBr }).toUpperCase()}
          </header>
          <div
            style={{ marginTop: '-9px' }}
            className="text-base h-5 font-light text-geo-900"
          >
            {format(monthNow, 'yyyy', { locale: ptBr })}
          </div>
        </div>
        <div className="flex ml-2">
          <div className="w-36 grid grid-cols-2 gap-2">
            <button
              type="button"
              className="h-full bg-geo-900 text-gray-100  rounded-lg  shadow"
              onClick={() => setMonth(month - 1)}
            >
              Anterior
            </button>

            <button
              type="button"
              className="h-full bg-geo-900 text-gray-100  rounded-lg  shadow"
              onClick={() => setMonth(month + 1)}
            >
              Proximo
            </button>
          </div>
          <button
            type="button"
            className="h-full w-10 flex items-center justify-center mx-2 bg-geo-900 text-gray-100  rounded-lg  shadow"
            onClick={() => setMonth(Number(getMonth(new Date())))}
          >
            <MdToday size={30} />
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center text-3xl font-bold text-geo-900">
        Agendamento
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => dispatch(schedulesActions.setModal())}
          type="button"
          className="
      h-full
      rounded-lg
      w-48
      bg-geo-900
      text-gray-100
    "
        >
          + Agendamento
        </button>
      </div>
    </div>
  );
}

Header.propTypes = {
  setMonth: PropTypes.func.isRequired,
  month: PropTypes.number.isRequired,
  monthNow: PropTypes.number.isRequired,
};

export default Header;
