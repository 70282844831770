import React from 'react';
import { useDispatch } from 'react-redux';

import { getMonth, format, isSameDay, set } from 'date-fns';
import PropTypes from 'prop-types';

import { Creators as schedulesActions } from '~/store/ducks/schedulesSauce';

function OnceType({ data, monthNow, day }) {
  const dispatch = useDispatch();
  if (data.schedule.type === 'once') {
    if (
      isSameDay(
        set(new Date(), {
          month: getMonth(monthNow),
          date: day,
        }),
        new Date(data.schedule.date)
      )
    ) {
      let bgColor;
      if (data.key === 'command') {
        if (data.type === 'lock') {
          bgColor = 'bg-red-800';
        } else {
          bgColor = 'bg-red-600';
        }
      } else {
        bgColor = 'bg-blue-800';
      }
      return (
        <button
          type="button"
          className={`
        ${bgColor}
        w-full
        h-10
        text-xs
        flex
        flex-col
        justify-center
        text-white
        px-2
        my-1
        rounded
        shadow
        `}
          onClick={() => {
            // console.log({ data });
            dispatch(schedulesActions.setSchedule(data));
            dispatch(schedulesActions.setModal());
          }}
        >
          <div className="text-sm font-bold">{data.name}</div>
          <div>{format(new Date(data.schedule.date), 'kk:mm')}</div>
        </button>
      );
    }
  }
  return <></>;
}

OnceType.propTypes = {
  data: PropTypes.objectOf(PropTypes.object, PropTypes.string, PropTypes.number)
    .isRequired,
  monthNow: PropTypes.number.isRequired,
  day: PropTypes.number.isRequired,
};

export default OnceType;
