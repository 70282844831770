import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';

export const Container = styled.div`
  margin: 10px 10px;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const TableChartContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas:
    'table'
    'chart';
  grid-row-gap: 10px;
`;

export const ChartContainer = styled.div`
  display: chart;
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  border-radius: 3px;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  resize: none;
  background: #fff;
  margin-bottom: 10px;
`;

export const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-areas: 'avatar data';
`;

export const AvatarContainer = styled.div`
  grid-area: avatar;
  display: flex;
`;

export const Avatar = styled(AvatarEditor)`
  border: 2px solid rgb(255, 255, 255);
  border-radius: 10px;
  margin: 10px;
`;

export const FileUpload = styled.label`
  input[type='file'] {
    display: none;
  }
  margin-left: -32px;
  width: 40px;
  height: 40px;
  margin-top: -6px;
  border: 1px solid #fff;
  border-radius: 100%;
  background: #999;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px;
  z-index: 2;
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
