import React from 'react';
// import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';

import { FormContainer, HeaderFormContainer } from '~/components/containers';
// import { Creators as ReportActions } from '~/store/ducks/reportSauce';

import Filters from './Filters';
import { Container } from './styles';
import Table from './Table';

function Report() {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(ReportActions.fetchReportFailure());
  // }, []);

  return (
    <FormContainer>
      <HeaderFormContainer title="Relatórios" />
      <Container>
        <Filters />
        <Table />
      </Container>
    </FormContainer>
  );
}

export default Report;
