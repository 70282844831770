import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import Api from '~/services/api';

import { Types } from '../ducks/forgotSauce';

export function* createForgot(payload) {
  try {
    const response = yield call(Api.createForgot, payload.data);
    yield put({ type: Types.FORGOT_SUCCESS, payload: response.data });
    toast.success('E-mail enviado com sucesso.');
    yield put(push('/'));
  } catch (error) {
    toast.error(error.data.error);
    yield put({ type: Types.FORGOT_FAILURE });
  }
}

export function* updatePasswordSaga(payload) {
  try {
    const response = yield call(Api.forgotPassword, payload.data);
    yield put({
      type: Types.UPDATE_SUCCESS,
      payload: response.data,
    });
    toast.success('Senha alterada com sucesso.');
    yield put(push('/'));
  } catch (error) {
    yield put({ type: Types.FORGOT_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export default function* rootSaga() {
  yield takeLatest(Types.FORGOT_REQUEST, createForgot);
  yield takeLatest(Types.UPDATE_REQUEST, updatePasswordSaga);
}
