import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import { registerLocale } from 'react-datepicker';
import { MdAdd, MdCheck, MdClear, MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import mapStyle from '~/assets/map.json';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import {
  FormData,
  DataContainer,
  ProfileContainer,
  Row,
  FieldContainer,
  LabelStyled,
  ButtonContainer,
  ErrorMessage,
  DateStyle,
  SelectStyled,
  Input,
} from '~/components/Global';
import SelectDriver from '~/components/Global/SelectDriver';
import SelectTracker from '~/components/Global/SelectTracker';
import Loading from '~/components/Loading';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as JourneyActions } from '~/store/ducks/journeySauce';
import { Creators as PreJourneyActions } from '~/store/ducks/preJourneySauce';
import { Creators as JourneyRouteActions } from '~/store/ducks/journeyRouteSauce';

import { Map } from './styles';
import SelectPassenger from '~/components/Global/SelectPassenger';
import SelectReferencePoint from '~/components/Global/SelectReferencePoint';
import { IconButton } from '~/components/buttons';
import { MaskedInput } from '~/components/inputs';
import SelectPreJourney from '~/components/Global/SelectPreJourney';
import SelectRoute from '~/components/Global/SelectRoute';
import { DateInput } from '~/components/inputs';

function Forms({
  match: {
    params: { id: idParams },
  },
}) {
  const dispatch = useDispatch();
  const journey = useSelector((state) => state.journeyState.journey);
  const preJourney = useSelector((state) => state.preJourneyState.preJourney);
  const [journeyType, setJourneyType] = useState();
  const loading = useSelector((state) => state.journeyState.loading);
  const me = useSelector((state) => state.globalState.me);
  registerLocale('pt-BR', el);
  const [editablePermission, setEditablePermission] = useState(false);
  const [map, setMap] = useState();

  const [flag, setFlag] = useState();
  const [geometry, setGeometry] = useState();
  const [passenger, setPassenger] = useState();
  const [uiPois, setUiPois] = useState([]);

  const formik = useFormik({
    initialValues: {
      // Jouney simples
      tracker_id: null,
      driver_id: null,
      start: null,
      end: null,
      geometry_id: null,

      // Pre journey
      start_hour: null,
      end_hour: null,
      name: null,
      pois: [],
      kilometer_total: 0,
      time_total: null,
      // Journey route
      pre_journey: '',
      passengers_quantity: '',
      pois_quantity: '',
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.tracker_id) err.tracker_id = message;
      if (!values.driver_id) err.driver_id = message;
      if (!values.pre_journey) err.pre_journey = message;
      if (!values.start_hour) err.start_hour = message;
      if (!values.end_hour) err.end_hour = message;
      if (!values.name) err.name = message;
      if (!values.geometry_id) err.geometry_id = message;
      // if (!values.geometry_id) err.geometry_id = message;
      if (!values.start) err.start = message;
      // ? Fim da jornada não é obrigatório.
      // if (!values.end) err.end = message;
      if (
        journeyType === 'preJourney' &&
        values.start_hour !== '' &&
        values.start_hour !== null
      ) {
        const [hour, minutes, seconds] = values.start_hour.split(':');
        if (
          hour > 24 ||
          minutes > 59 ||
          seconds > 59 ||
          values.start_hour.length < 8
        ) {
          err.start_hour = 'Horário inválido';
        }
      }
      if (
        journeyType === 'preJourney' &&
        values.end_hour !== '' &&
        values.end_hour !== null
      ) {
        const [hour, minutes, seconds] = values.end_hour.split(':');
        if (
          hour > 24 ||
          minutes > 59 ||
          seconds > 59 ||
          values.end_hour.length < 8
        ) {
          err.end_hour = 'Horário inválido';
        }
      }
      if (values.start !== '' && values.end !== '') {
        if (!values.start) {
          err.start = message;
        }

        // ? Fim da jornada não é obrigatório.
        // if (!values.end) {
        //   err.end = message;
        // }

        if (values.start > values.end && values.end !== null) {
          err.start = 'Data inicial não pode ser maior que a data final!';
          err.end = 'Data final não pode ser menor que a data inicial!';
        }
      }
      return err;
    },
    onSubmit: (value) => {
      // console.log('data', value);
    },
  });

  function cancelButton() {
    history.push('/journey');
    dispatch(JourneyActions.clearJourney());
  }

  useEffect(() => {
    if (
      formik.values.start_hour?.length === 8 &&
      formik.values.end_hour?.length === 8
    ) {
      const date = new Date().toLocaleString().split(' ')[0];
      const start = date + formik.values.start_hour;
      const end = date + formik.values.end_hour;

      const milliseconds = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(start, 'DD/MM/YYYY HH:mm:ss')
      );
      const durationTotal = moment.duration(milliseconds);
      const timeTotal =
        Math.floor(durationTotal.asHours()) +
        moment.utc(milliseconds).format(':mm:ss');
      console.log(timeTotal);
      formik.setFieldValue('time_total', timeTotal, false);
    }
  }, [formik.values.end_hour]);

  useEffect(() => {
    // console.log({ idParams });
    if (idParams) {
      dispatch(JourneyActions.fetchJourneyRequest(`/${idParams}`));
      // dispatch(PreJourneyActions.fetchPreJourneyRequest(`/${idParams}`));
    }
  }, [idParams]);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
      setJourneyType('journey');
    }
  }, [me]);

  async function getDaily(query) {
    const response = await api.fetchDaily(query);
    if (response.data[0].st_asgeojson) {
      const coordinates = JSON.parse(response.data[0].st_asgeojson);

      const geojson = {
        type: 'Feature',
        geometry: coordinates,
      };
      map.data.addGeoJson(geojson);
      map.data.setStyle({
        strokeColor: '#3AAAAA',
        strokeOpacity: 0.8,
        strokeWeight: 3,
      });

      const bounds = new window.google.maps.LatLngBounds();
      coordinates.coordinates.map((item) =>
        bounds.extend(new window.google.maps.LatLng(item[1], item[0]))
      );
      map.fitBounds(bounds);
    }
  }

  useEffect(() => {
    if (!_.isEmpty(journey)) {
      const mapinit = new window.google.maps.Map(
        document.getElementById('map2'),
        {
          center: { lat: -20.361797, lng: -40.660631 },
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
          scaleControl: false,
          streetViewControl: false,
          streetViewControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          zoom: 13,
          controlSize: 25,
          styles: mapStyle,
          mapId: 'fae8cf043fff18e4',
        }
      );
      setMap(mapinit);
      formik.setValues({
        ...journey,
        start: moment(journey.start).toDate(),
        end: moment(journey.end).toDate(),
      });
    }
  }, [journey]);

  useEffect(() => {
    if (idParams) {
      console.log(preJourney);
    }
  }, [preJourney]);

  useEffect(() => {
    formik.resetForm();
  }, [journeyType]);

  useEffect(() => {
    // TODO Agrupar pessoas por ponto de referência
    // console.log(formik.values.pre_journey);
    formik.setFieldValue(
      'kilometer_total',
      formik.values.pre_journey?.kilometer_total
    );
    formik.setFieldValue('time_total', formik.values.pre_journey?.time_total);
    formik.setFieldValue(
      'passengers_quantity',
      formik.values.pre_journey?.pois?.length
    );
    // console.log(formik.values.pre_journey?.pois?.length);
    // console.log(
    //   formik.values.pre_journey?.pois?.reduce((a, i) => {
    //     if (!a.includes(i.geometry.id)) {
    //       a.push(i.geometry.id);
    //     }
    //     return a;
    //   }, []).length
    // );
    formik.setFieldValue(
      'pois_quantity',
      formik.values.pre_journey?.pois?.reduce((a, i) => {
        if (!a.includes(i.geometry.id)) {
          a.push(i.geometry.id);
        }
        return a;
      }, []).length
    );
  }, [formik.values.pre_journey]);

  useEffect(() => {
    if (map) {
      const data = `?id=${journey.tracker_id}&start=${moment(
        journey.start
      ).format('YYYY-MM-DDTHH:mm:ssZ')}&end=${moment(journey.end).format(
        'YYYY-MM-DDTHH:mm:ssZ'
      )}`;
      getDaily(data);
    }
  }, [map]);

  // moment.defaultFormat = 'DD/MM/YYYY HH:mm:ss';

  async function deleteButton() {
    try {
      await api.deleteJourney(idParams);
      history.push('/journey');
      dispatch(JourneyActions.clearJourney());
    } catch (error) {
      console.tron.log('error');
    }
  }

  function addDetail() {
    if (passenger && flag && geometry) {
      const id = nanoid();
      const detailUi = {
        passenger,
        geometry,
        flag,
        id,
      };
      const detail = {
        passenger_id: passenger.id,
        geometry_id: geometry.id,
        flag,
        id,
      };

      formik.values.pois.push(detail);
      setUiPois([...uiPois, detailUi]);

      setGeometry(null);
      setFlag(null);
      setPassenger(null);
    } else {
    }
  }

  function removeDetail(id) {
    if (id) {
      const filteredUiPois = uiPois.filter((poi) => poi.id !== id);
      const filteredPois = formik.values.pois
        .filter((poi) => poi.id !== id)
        .map((item) => ({
          passenger: item.passenger,
          geometry: item.geometry,
          flag: item.flag,
        }));
      formik.setFieldValue('pois', [...filteredPois]);
      setUiPois(filteredUiPois);
    }
  }

  function saveJourney() {
    if (
      journeyType === 'routeJourney' &&
      formik.values.tracker_id &&
      formik.values.driver_id &&
      formik.values.pre_journey?.id
    ) {
      const data = {
        tracker_id: formik.values.tracker_id,
        driver_id: formik.values.driver_id,
        pre_journey_id: formik.values.pre_journey?.id,
        kilometer_total: String(formik.values.kilometer_total),
        time_total: formik.values.time_total.split('+')[0],
        start: new Date(formik.values.start).toISOString(),
        end: new Date(formik.values.end).toISOString(),
        id: idParams,
      };
      dispatch(JourneyRouteActions.createJourneyRouteRequest(data));
    }
    if (
      journeyType === 'journey' &&
      formik.values.tracker_id &&
      formik.values.driver_id &&
      formik.values.start
    ) {
      const data = {
        tracker_id: formik.values.tracker_id,
        driver_id: formik.values.driver_id,
        start: formik.values.start,
        end: formik.values.end,
        id: idParams,
      };

      if (data.id) {
        dispatch(JourneyActions.updateJourneyRequest(data, data.id));
      } else {
        dispatch(JourneyActions.createJourneyRequest(data));
      }
      dispatch(JourneyActions.clearJourney());
    }
    if (
      journeyType === 'preJourney' &&
      formik.values.tracker_id &&
      formik.values.driver_id
    ) {
      const data = {
        tracker_id: formik.values.tracker_id,
        driver_id: formik.values.driver_id,
        start_hour: formik.values.start_hour,
        end_hour: formik.values.end_hour,
        kilometer_total: Number(formik.values.kilometer_total),
        time_total: formik.values.time_total,
        name: formik.values.name,
        geometry_id: formik.values.geometry_id,
        pois: formik.values.pois,
        id: idParams,
      };
      if (data.id) {
        dispatch(PreJourneyActions.updatePreJourneyRequest(data, data.id));
      } else {
        dispatch(PreJourneyActions.createPreJourneyRequest(data));
      }
      dispatch(PreJourneyActions.clearPreJourney());
    }
  }

  function finishJourney() {
    if (
      journeyType === 'journey' &&
      formik.values.tracker_id &&
      formik.values.driver_id &&
      formik.values.start &&
      formik.values.end &&
      idParams
    ) {
      formik.setFieldValue('end', new Date());
      setTimeout(() => {
        const data = {
          tracker_id: formik.values.tracker_id,
          driver_id: formik.values.driver_id,
          start: formik.values.start,
          end: new Date(),
          id: idParams,
        };
        dispatch(JourneyActions.updateJourneyRequest(data, data.id));
        dispatch(JourneyActions.clearJourney());
      }, 500);
    }
  }

  function getHeaderComponent() {
    if (editablePermission) {
      if (loading) {
        return (
          <ButtonContainer disabled color="#2763c4" type="button">
            Aguarde
          </ButtonContainer>
        );
      }
      return (
        <div className="flex">
          <div className="h-full w-full flex items-center justify-between px-2">
            {!idParams ? (
              <FieldContainer
                style={{ flexDirection: 'row', height: 'auto', width: '180px' }}
              >
                <SelectStyled
                  name="type"
                  defaultValue={{
                    label:
                      {
                        journey: 'Jornada',
                        routeJourney: 'Jornada Rota',
                        preJourney: 'Pré Jornada',
                      }[journeyType] || '',
                    value: journeyType,
                  }}
                  onChange={(option) => setJourneyType(option.value)}
                  value={{
                    label:
                      {
                        journey: 'Jornada',
                        routeJourney: 'Jornada Rota',
                        preJourney: 'Pré Jornada',
                      }[journeyType] || '',
                    value: journeyType,
                  }}
                  options={[
                    { label: 'Jornada', value: 'journey' },
                    { label: 'Jornada Rota', value: 'routeJourney' },
                    { label: 'Pré Jornada', value: 'preJourney' },
                  ]}
                  isDisabled={!editablePermission}
                />
              </FieldContainer>
            ) : (
              ''
            )}
            {/* </div>

          <div className="pt-3"> */}

            {journeyType === 'journey' && !formik.values.end_hour ? (
              <ButtonContainer
                color="#336CD6"
                onClick={finishJourney}
                title="Finalizar jornada com a data atual"
              >
                <MdCheck size={15} />
                Finalizar
              </ButtonContainer>
            ) : (
              ''
            )}

            <ButtonContainer
              color="#E33939"
              type="button"
              onClick={() => cancelButton()}
            >
              <MdClear size={15} />
              Cancelar
            </ButtonContainer>
            {/* </div>
          <div className="pt-3"> */}
            {idParams && (
              <ButtonContainer
                color="#E33939"
                type="button"
                onClick={() => deleteButton()}
              >
                <MdDelete size={15} />
                Excluir
              </ButtonContainer>
            )}
            {/* </div>
          <div className="pt-3"> */}
            <ButtonContainer color="#555" onClick={saveJourney}>
              <MdCheck size={15} />
              Salvar
            </ButtonContainer>
          </div>
        </div>
      );
    }
    return (
      <ButtonContainer type="button" onClick={() => cancelButton()}>
        <MdCheck size={15} />
        Voltar
      </ButtonContainer>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormContainer>
        <HeaderFormContainer title="Formulário de Jornada">
          {getHeaderComponent()}
        </HeaderFormContainer>
        <SimpleContainer>
          {!loading ? (
            <FormData>
              <DataContainer>
                <ProfileContainer>
                  <div>
                    <Row>
                      {journeyType === 'preJourney' ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Nome</LabelStyled>
                            {formik.touched.name && formik.errors.name ? (
                              <ErrorMessage>{formik.errors.name}</ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <Input
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            error={formik.touched.name && formik.errors.name}
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}
                      <FieldContainer>
                        <SelectTracker
                          defaultValue={formik.values.tracker_id}
                          parentCallback={(option) =>
                            formik.setFieldValue('tracker_id', option.id)
                          }
                          error={
                            formik.touched.tracker_id &&
                            formik.errors.tracker_id
                          }
                          isDisabled={!editablePermission}
                          value={formik.values.tracker_id}
                        />
                      </FieldContainer>
                      <FieldContainer>
                        <SelectDriver
                          defaultValue={formik.values.driver_id}
                          parentCallback={(option) =>
                            formik.setFieldValue('driver_id', option.id)
                          }
                          error={
                            formik.touched.driver_id && formik.errors.driver_id
                          }
                          isDisabled={!editablePermission}
                          value={formik.values.driver_id}
                        />
                      </FieldContainer>
                      {['journey', 'routeJourney'].includes(journeyType) ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Início</LabelStyled>
                            {formik.touched.start && formik.errors.start ? (
                              <ErrorMessage>{formik.errors.start}</ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <DateStyle
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            selected={formik.values.start}
                            onChange={(value) =>
                              formik.setFieldValue(
                                'start',
                                moment(value, 'DD/MM/YYYY HH:mm:ss').toDate()
                              )
                            }
                            locale={pt}
                            customInput={
                              <InputMask mask="99/99/9999 99:99:99" />
                            }
                            error={formik.touched.start && formik.errors.start}
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}

                      {['journey', 'routeJourney'].includes(journeyType) ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Final</LabelStyled>
                            {formik.touched.end && formik.errors.end ? (
                              <ErrorMessage>{formik.errors.end}</ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <DateStyle
                            showTimeSelect
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            selected={formik.values.end}
                            onChange={(value) =>
                              formik.setFieldValue(
                                'end',
                                moment(value, 'DD/MM/YYYY HH:mm:ss').toDate()
                              )
                            }
                            locale={pt}
                            customInput={
                              <InputMask mask="99/99/9999 99:99:99" />
                            }
                            error={formik.touched.end && formik.errors.end}
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}

                      {journeyType === 'preJourney' ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Início da jornada</LabelStyled>
                            {formik.touched.start_hour &&
                            formik.errors.start_hour ? (
                              <ErrorMessage>
                                {formik.errors.start_hour}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <MaskedInput
                            onChange={formik.handleChange}
                            value={formik.values.start_hour}
                            id="start_hour"
                            name="start_hour"
                            mask="99:99:99"
                            maskChar={null}
                            error={
                              formik.touched.start_hour &&
                              formik.errors.start_hour
                            }
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}
                      {journeyType === 'preJourney' ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Final da jornada</LabelStyled>
                            {formik.touched.end_hour &&
                            formik.errors.end_hour ? (
                              <ErrorMessage>
                                {formik.errors.end_hour}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <MaskedInput
                            onChange={formik.handleChange}
                            value={formik.values.end_hour}
                            id="end_hour"
                            name="end_hour"
                            mask="99:99:99"
                            maskChar={null}
                            error={
                              formik.touched.end_hour && formik.errors.end_hour
                            }
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}

                      {journeyType === 'preJourney' ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Duração</LabelStyled>
                            {formik.touched.time_total &&
                            formik.errors.time_total ? (
                              <ErrorMessage>
                                {formik.errors.time_total}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <Input
                            name="time_total"
                            onChange={formik.handleChange}
                            value={formik.values.time_total}
                            mask="99:99:99"
                            error={
                              formik.touched.time_total &&
                              formik.errors.time_total
                            }
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}

                      {journeyType === 'preJourney' ? (
                        <FieldContainer>
                          <div>
                            <LabelStyled>Quilômetros</LabelStyled>
                            {formik.touched.kilometer_total &&
                            formik.errors.kilometer_total ? (
                              <ErrorMessage>
                                {formik.errors.kilometer_total}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <MaskedInput
                            onChange={formik.handleChange}
                            value={formik.values.kilometer_total}
                            id="kilometer_total"
                            name="kilometer_total"
                            mask="999999"
                            maskChar={null}
                            error={
                              formik.touched.kilometer_total &&
                              formik.errors.kilometer_total
                            }
                            disabled={!editablePermission}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}

                      {journeyType === 'preJourney' ? (
                        <FieldContainer>
                          <SelectRoute
                            defaultValue={formik.values.geometry_id}
                            parentCallback={(option) =>
                              formik.setFieldValue('geometry_id', option.id)
                            }
                            error={
                              formik.touched.geometry_id &&
                              formik.errors.geometry_id
                            }
                            isDisabled={!editablePermission}
                            value={formik.values.geometry_id}
                          />
                        </FieldContainer>
                      ) : (
                        ''
                      )}
                    </Row>

                    {journeyType === 'routeJourney' ? (
                      <Row>
                        <FieldContainer>
                          <SelectPreJourney
                            defaultValue={null}
                            parentCallback={(option) =>
                              formik.setFieldValue('pre_journey', option)
                            }
                            error={
                              formik.touched.pre_journey &&
                              formik.errors.pre_journey
                            }
                            isDisabled={!editablePermission}
                            value={formik.values.pre_journey}
                          />
                        </FieldContainer>

                        <FieldContainer>
                          <div>
                            <LabelStyled>Quilômetros</LabelStyled>
                            {formik.touched.kilometer_total &&
                            formik.errors.kilometer_total ? (
                              <ErrorMessage>
                                {formik.errors.kilometer_total}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <MaskedInput
                            onChange={formik.handleChange}
                            value={formik.values.kilometer_total}
                            id="kilometer_total"
                            name="kilometer_total"
                            mask="999999"
                            maskChar={null}
                            error={
                              formik.touched.kilometer_total &&
                              formik.errors.kilometer_total
                            }
                            disabled={true}
                          />
                        </FieldContainer>

                        <FieldContainer>
                          <div>
                            <LabelStyled>Duração</LabelStyled>
                            {formik.touched.time_total &&
                            formik.errors.time_total ? (
                              <ErrorMessage>
                                {formik.errors.time_total}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>

                          <Input
                            name="time_total"
                            onChange={formik.handleChange}
                            value={formik.values.time_total}
                            error={
                              formik.touched.time_total &&
                              formik.errors.time_total
                            }
                            disabled={true}
                          />
                        </FieldContainer>

                        <FieldContainer>
                          <div>
                            <LabelStyled>Quantidade de Passageiros</LabelStyled>
                            {formik.touched.passengersQuantity &&
                            formik.errors.passengersQuantity ? (
                              <ErrorMessage>
                                {formik.errors.passengersQuantity}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <MaskedInput
                            onChange={formik.handleChange}
                            value={formik.values.passengers_quantity}
                            id="passengers_quantity"
                            name="passengers_quantity"
                            mask="999999"
                            maskChar={null}
                            error={
                              formik.touched.passengers_quantity &&
                              formik.errors.passengers_quantity
                            }
                            disabled={true}
                          />
                        </FieldContainer>

                        <FieldContainer>
                          <div>
                            <LabelStyled>
                              Quantidade de Pontos de Referência
                            </LabelStyled>
                            {formik.touched.poisQuantity &&
                            formik.errors.poisQuantity ? (
                              <ErrorMessage>
                                {formik.errors.poisQuantity}
                              </ErrorMessage>
                            ) : (
                              ''
                            )}
                          </div>
                          <MaskedInput
                            onChange={formik.handleChange}
                            value={formik.values.pois_quantity}
                            id="pois_quantity"
                            name="pois_quantity"
                            mask="999999"
                            maskChar={null}
                            error={
                              formik.touched.pois_quantity &&
                              formik.errors.pois_quantity
                            }
                            disabled={true}
                          />
                        </FieldContainer>
                      </Row>
                    ) : (
                      ''
                    )}

                    {journeyType === 'preJourney' ? (
                      <div style={{ display: 'flex' }}>
                        <FieldContainer style={{ width: '300px' }}>
                          <LabelStyled>Tipo</LabelStyled>
                          <SelectStyled
                            name="type"
                            defaultValue={{
                              label: flag,
                              value: flag,
                            }}
                            onChange={(option) => setFlag(option.value)}
                            value={{
                              label: flag,
                              value: flag,
                            }}
                            options={[
                              { label: 'Embarque', value: 'EMBARQUE' },
                              { label: 'Desembarque', value: 'DESEMBARQUE' },
                            ]}
                            isDisabled={!editablePermission}
                          />
                        </FieldContainer>
                        <FieldContainer style={{ width: '400px' }}>
                          <SelectPassenger
                            defaultValue={passenger}
                            parentCallback={(option) =>
                              setPassenger({
                                id: option.id,
                                name: option.name,
                              })
                            }
                            isDisabled={!editablePermission}
                            value={passenger}
                          />
                        </FieldContainer>
                        <FieldContainer style={{ width: '400px' }}>
                          <SelectReferencePoint
                            defaultValue={geometry}
                            parentCallback={(option) =>
                              setGeometry({
                                id: option.id,
                                name: option.name,
                              })
                            }
                            isDisabled={!editablePermission}
                            value={geometry}
                          />
                        </FieldContainer>
                        <ButtonContainer
                          style={{ marginTop: '18px' }}
                          color="#E33939"
                          type="button"
                          onClick={addDetail}
                        >
                          <MdAdd size={15} />
                        </ButtonContainer>
                      </div>
                    ) : (
                      ''
                    )}

                    {uiPois.length && journeyType === 'preJourney' ? (
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th style={{ width: '100px' }}>Tipo</th>
                            <th style={{ width: '250px' }}>Passageiro</th>
                            <th style={{ width: '200px' }}>
                              Ponto de Referência
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {uiPois.map((poi) => (
                            <tr>
                              <td>{poi.flag}</td>
                              <td style={{ paddingLeft: '90px' }}>
                                {' '}
                                {poi.passenger.name}
                              </td>
                              <td style={{ paddingLeft: '40px' }}>
                                {poi.geometry.name}
                              </td>
                              <td>
                                <IconButton
                                  Icon={MdDelete}
                                  style={{
                                    'max-width': `200px`,
                                    margin: '10px',
                                  }}
                                  size={15}
                                  margin="0"
                                  height="h-10"
                                  onClick={() => removeDetail(poi.id)}
                                  disabled={!editablePermission}
                                  type="button"
                                >
                                  Remover
                                </IconButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      ''
                    )}
                  </div>
                </ProfileContainer>
              </DataContainer>
              {!_.isEmpty(journey) && <Map id="map2" />}
            </FormData>
          ) : (
            <Loading />
          )}
        </SimpleContainer>
      </FormContainer>
    </form>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};

Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
