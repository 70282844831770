import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url('https://fonts.googleapis.com/css?family=Bungee|Fredoka+One&display=swap');

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    html, border-style, #root {
        height: 100%
    }
    tr{
        padding: 0
    }
    td{
        font-size: 14px;
    }
    tr:nth-child(even) {
            background-color: #f0f0f0;
            color: #000000
        }
    body {
        font: 14px "Montserrat", sans-serif;
        color: #333;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased !important;
        background: #F4F8F9;

    a {
        text-decoration: none;
        color: #333;
    }
    button {
      cursor: pointer;
      :focus {
        border: none;
        outline: 0;
      }
    }
    
`;
