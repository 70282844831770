import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import SimpleInput from './simpleInput';

function Mask({
  mask,
  borderRadius,
  onChange,
  value,
  disabled,
  plate,
  ...props
}) {
  return (
    <>
      <InputMask
        mask={mask}
        onChange={onChange}
        maskChar={null}
        value={value}
        {...props}
        disabled={disabled}
      >
        {(inputProps) => (
          <SimpleInput
            disabled={disabled}
            borderRadius={borderRadius}
            plate={plate}
            {...inputProps}
          />
        )}
      </InputMask>
    </>
  );
}

Mask.propTypes = {
  mask: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  plate: PropTypes.bool,
  disabled: PropTypes.bool,
  borderRadius: PropTypes.string,
};

Mask.defaultProps = {
  plate: false,
  disabled: false,
  borderRadius: '3px',
};

export default Mask;
