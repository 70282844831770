import { toast } from 'react-toastify';

import _ from 'lodash';
import { all, takeEvery, put, call } from 'redux-saga/effects';

import getApi from '~/services/api';
import api from '~/services/axios';

import { Types } from '../ducks/reportSauce';

const getBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = function getBase64OnLoad() {
      resolve(this.result);
    };
    reader.readAsDataURL(blob);
  });

export function* getReport({ url, params }) {
  try {
    let { data } = yield call(getApi.fetchReport, url, params);
    if (url === '/buffer') {
      data = _.sortBy(data, ['plate']);
      // data = data.sort(
      //   (a, b) => (a.plate === b.plate && 0) || (a.plate > b.plate ? 1 : -1)
      // );
    }
    yield put({
      type: Types.FETCH_REPORT_SUCCESS,
      data,
    });
    if (_.isEmpty(data)) {
      toast.warn('O relatório não possui dados.');
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_REPORT_FAILURE });
  }
}

export function* createReport({ url, params }) {
  try {
    const { data: blob } = yield api.post(url, params, {
      responseType: 'blob',
    });

    const dataBase64 = yield getBase64(blob);
    yield put({
      type: Types.CREATE_REPORT_SUCCESS,
      data: dataBase64,
    });
  } catch (error) {
    console.log({ error });
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_REPORT_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(Types.FETCH_REPORT_REQUEST, getReport),
    takeEvery(Types.CREATE_REPORT_REQUEST, createReport),
  ]);
}
