import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tooltip from 'rc-tooltip';

// import sleepIcon from '~/assets/sleepIconSemSobra.svg';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import filtersData from './filtersData';
import { IconHeader, Badge } from './styles';

function Filters() {
  const dispatch = useDispatch();
  const { bufferGroup, filterActive, groupSelected } = useSelector(
    ({ bufferState }) => bufferState
  );
  const me = useSelector((state) => state.globalState.me);

  return (
    <div className="grid grid-cols-6 h-10 bg-gray-100">
      {filtersData({ bufferGroup }).map(({ tooltip, filter, name, icon }) => (
        <Tooltip
          key={`toolTip_${tooltip}`}
          placement="right"
          overlay={tooltip}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          arrowContent={<div className="rc-tooltip-arrow-inner" />}
        >
          <IconHeader
            selected={filterActive === filter}
            onClick={() =>
              dispatch(
                BufferActions.filterBuffer(filter, groupSelected.id_trackers)
              )
            }
          >
            <img src={icon} alt="" />
            <Badge>{me.isDefaulter ? 0 : name}</Badge>
          </IconHeader>
        </Tooltip>
      ))}
      {/* <IconHeader>
        <img src={sleepIcon} alt="" />
        <Badge>0</Badge>
      </IconHeader> */}
    </div>
  );
}

export default Filters;
