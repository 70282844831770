import styled from 'styled-components';

export const Form = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr 40px;
`;

export const ContainerField = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr;
`;
