import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/responsibleSauce';

export function* getResponsible(payload) {
  try {
    const response = yield call(api.fetchResponsible, payload.query, payload.params);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_RESPONSIBLE_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_RESPONSIBLE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createResponsibleSaga(payload) {
  try {
    yield call(api.createResponsible, payload.data);
    yield put({
      type: Types.FETCH_RESPONSIBLE_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/responsible'));
  } catch (error) {
    yield put({ type: Types.FETCH_RESPONSIBLE_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateResponsibleSaga(payload) {
  try {
    yield call(api.updateResponsible, payload.data, payload.id);
    yield put({
      type: Types.FETCH_RESPONSIBLE_REQUEST,
    });
    toast.success('Motorista atualizado com sucesso!');
    yield put(push('/responsible'));
  } catch (error) {
    yield put({ type: Types.FETCH_RESPONSIBLE_FAILURE });
    toast.error('Algo deu errado! Tente novamente em alguns instantes.');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_RESPONSIBLE_REQUEST, getResponsible)]);
  yield all([takeEvery(Types.CREATE_RESPONSIBLE_REQUEST, createResponsibleSaga)]);
  yield all([takeEvery(Types.UPDATE_RESPONSIBLE_REQUEST, updateResponsibleSaga)]);
}
