import React, { useState, useEffect } from 'react';
import { MdAdd, MdDelete } from 'react-icons/md';

import _ from 'lodash';
import PropTypes from 'prop-types';

import mapStyle from '~/assets/map.json';
import { FencesField } from '~/components/fields';
import {
  FieldContainer,
  LabelStyled,
  TabContainer,
  ButtonContainer,
  TabView,
} from '~/components/Global';

import ConfigHeader from './ConfigHeader';
import ConfigurationFence from './ConfigurationFence';
import processPoints from './processPoints';
import setArrayFence from './setArrayFence';
import {
  ConfigGrid,
  GridContainer,
  GridContainerFence,
  Map,
  ConfigFenceContainer,
} from './styles';
import weekDays from './weekDays.json';

function FenceData({ formik, editablePermission, tab }) {
  const [fenceSelected, setFenceSelected] = useState({});
  const [configFence, setConfigFence] = useState({});
  const [map, setMap] = useState({});

  useEffect(() => {
    let fenceConfig;
    if (!_.isEmpty(formik.values.fences)) {
      if (formik.values.fences.length > 1) {
        fenceConfig = formik.values.fences[formik.values.fences.length - 1];
      } else {
        [fenceConfig] = formik.values.fences;
      }
    } else {
      fenceConfig = {};
    }
    setConfigFence(fenceConfig);
  }, [formik.values.fences]);

  const bounds = new window.google.maps.LatLngBounds();

  useEffect(() => {
    if (!_.isEmpty(fenceSelected.geojson)) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
      map.data.addGeoJson(fenceSelected.geojson);
      map.data.forEach((feature) => {
        processPoints(feature.getGeometry(), bounds.extend, bounds);
      });
      map.fitBounds(bounds);
    }
  }, [fenceSelected.geojson]);

  useEffect(() => {
    if (tab === 3) {
      const mapInitial = new window.google.maps.Map(
        document.getElementById('map'),
        {
          center: { lat: -20.361797, lng: -40.660631 },
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: true,
          scaleControl: true,
          streetViewControl: true,
          streetViewControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
          },
          zoom: 15,
          controlSize: 25,
          styles: mapStyle,
          mapId: 'd76bbff7924763d9',
        }
      );
      setMap(mapInitial);
    }
  }, [tab]);

  function setFence(fence) {
    setFenceSelected(fence);
    formik.setFieldValue('fence_id', fence.id);
  }

  function deleteFence(index) {
    const newFences = formik.values.fences.filter(
      (fence, idx) => idx !== index
    );
    formik.setFieldValue('fences', newFences);
  }

  return (
    <>
      <ConfigGrid>
        <GridContainerFence>
          <FencesField
            label="NOME DA CERCA"
            onChange={(option) => setFence(option)}
            isDisabled={!editablePermission}
            defaultValue={formik.values.fence_id}
          />
          <FieldContainer style={{ width: '110px' }}>
            <LabelStyled>&nbsp;</LabelStyled>
            <ButtonContainer
              disabled={formik.values.fence_id === null}
              color="#555"
              type="button"
              onClick={() => setArrayFence({ formik, fenceSelected, weekDays })}
            >
              <MdAdd size={15} />
              Adicionar
            </ButtonContainer>
          </FieldContainer>
          <Map id="map" />
        </GridContainerFence>
        <GridContainer>
          <FieldContainer>
            <LabelStyled>Cercas Adicionadas</LabelStyled>
            <TabContainer>
              {formik.values.fences.map((item) => (
                <>
                  <TabView
                    key={item.id}
                    active={item.name === configFence.name}
                    onClick={() => setConfigFence(item)}
                  >
                    {item.name}
                  </TabView>
                </>
              ))}
            </TabContainer>
          </FieldContainer>
          {formik.values.fences.length !== 0 &&
            formik.values.fences.map((item, index) => {
              return (
                item.name === configFence.name && (
                  <ConfigFenceContainer key={item.id}>
                    <ConfigHeader
                      data={item}
                      index={index}
                      formik={formik}
                      editablePermission={editablePermission}
                    />
                    <ConfigurationFence
                      configurationFence={item.configurationFence}
                      week={item.week}
                      index={index}
                      formik={formik}
                      editablePermission={editablePermission}
                    />

                    <ButtonContainer
                      color="#E33939"
                      type="button"
                      onClick={() => deleteFence(index)}
                    >
                      <MdDelete size={15} />
                      Apagar
                    </ButtonContainer>
                  </ConfigFenceContainer>
                )
              );
            })}
        </GridContainer>
      </ConfigGrid>
    </>
  );
}

FenceData.propTypes = {
  formik: PropTypes.objectOf(PropTypes.object).isRequired,
  editablePermission: PropTypes.bool,
  tab: PropTypes.string.isRequired,
};

FenceData.defaultProps = {
  editablePermission: false,
};

export default FenceData;
