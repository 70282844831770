import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';

import mapStyle from '~/assets/map.json';
import {
  saveZone as saveZoneMap,
  cancelZone as cancelZoneMap,
} from '~/components/Map/controls';
import geoJson from '~/components/Map/geojson';
import ModalSave from '~/components/Map/Modal';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';
import { Creators as FenceActions } from '~/store/ducks/fenceSauce';

import { deleteZoneFence, saveZoneFence, cancelZone } from '../controls';
import { DeleteModal } from '../Modal';
import processPoints from './processPoints';

function Map() {
  const dispatch = useDispatch();
  const fence = useSelector((state) => state.fenceState.fence);
  const loadAll = useSelector((state) => state.fenceState.loadAll);

  const [editablePermission] = useState(true);
  const [map, setMap] = useState(null);
  const [GeoJson, setGeoJson] = useState('');
  const [typeModal, setTypeModal] = useState('');
  const [drawingManagerController, setDrawingManagerController] =
    useState(null);

  useEffect(() => {
    const mapInit = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 13,
      controlSize: 25,
      styles: mapStyle,
      mapId: '67821eba7b5f566',
    });

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingControl: true,

      circleOptions: {
        editable: true,
      },
      polygonOptions: {
        editable: true,
      },
      rectangleOptions: {
        editable: true,
      },
      polylineOptions: {
        editable: true,
      },
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
        drawingModes: ['polygon', 'rectangle', 'polyline'],
      },
    });

    drawingManager.setMap(mapInit);

    window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (event) => {
        drawingManager.setDrawingMode(null);
        setGeoJson(geoJson(event));
        setTypeModal(event.type);
        if (event.type === 'circle') {
          window.google.maps.event.addListener(
            event.overlay,
            'radius_changed',
            () => {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'polygon') {
          window.google.maps.event.addListener(
            event.overlay.getPath(),
            'set_at',
            () => {
              setGeoJson(geoJson(event));
            }
          );

          window.google.maps.event.addListener(
            event.overlay.getPath(),
            'insert_at',
            () => {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'rectangle') {
          window.google.maps.event.addListener(
            event.overlay,
            'bounds_changed',
            () => {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'polyline') {
          // console.log(event.overlay.getPath().getArray());
          // console.log(geoJson(event));
          setGeoJson(geoJson(event));
        }
        dispatch(BufferActions.setDrawingMode());

        drawingManager.setOptions({
          drawingControl: false,
        });
        mapInit.controls[9].clear();

        const zoneControlDiv = document.createElement('div');
        const count = zoneControlDiv.getElementsByTagName('div').length;
        if (count === 4) {
          mapInit.controls[
            window.google.maps.ControlPosition.RIGHT_TOP
          ].removeAt(1);
        }

        saveZoneMap(
          zoneControlDiv,
          mapInit,
          dispatch,
          BufferActions,
          event.overlay,
          drawingManager
        );
        cancelZoneMap(
          zoneControlDiv,
          mapInit,
          dispatch,
          BufferActions,
          event.overlay,
          drawingManager
        );
        zoneControlDiv.index = 1;
        mapInit.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
          zoneControlDiv
        );
      }
    );

    setDrawingManagerController(drawingManager);
    setMap(mapInit);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(fence) && map) {
      // console.log('loadAll', loadAll);
      if (!loadAll) {
        map.data.forEach((feature) => {
          map.data.remove(feature);
        });
      }
      if (drawingManagerController !== null) {
        drawingManagerController.setMap(null);
      }

      const { geojson } = fence;
      let coords = '';
      const bounds = new window.google.maps.LatLngBounds();

      if (geojson !== undefined) {
        coords = map.data.addGeoJson(geojson);
        map.data.setStyle({
          editable: true,
        });
        map.data.forEach((feature) => {
          processPoints(feature.getGeometry(), bounds.extend, bounds);
        });
        map.fitBounds(bounds);
      }
      // DELETE permission verifier
      if (!loadAll && !_.isEmpty(fence)) {
        if (editablePermission) {
          map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].clear();
          const zoneControlDeleteDiv = document.createElement('div');
          deleteZoneFence(dispatch, zoneControlDeleteDiv, fence.id);
          map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
            zoneControlDeleteDiv
          );
        }
        map.controls[window.google.maps.ControlPosition.RIGHT_TOP].clear();
        const zoneControlDiv = document.createElement('div');
        saveZoneFence(zoneControlDiv, map, { fence }, dispatch, coords);
        cancelZone(zoneControlDiv, map, { fence });
        zoneControlDiv.index = 1;
        map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
          zoneControlDiv
        );
      }
    } else if (map) {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].clear();
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].clear();
      drawingManagerController.setMap(map);
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    }
  }, [fence]);

  useEffect(() => {
    dispatch(FenceActions.clearFence());
  }, []);

  return (
    <div className="h-full w-full p-2 rounded-lg shadow bg-white">
      <div className="h-full w-full rounded-lg" id="map" />
      <DeleteModal />
      <ModalSave type={typeModal} geoJson={GeoJson} />
    </div>
  );
}

export default Map;
