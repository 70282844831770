import api from '~/services/api';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';

import referencePointOff from '~/assets/referencePointOff.svg';
import referencePointOn from '~/assets/referencePointOn.svg';
import rulerOff from '~/assets/rulerOff.svg';
import rulerOn from '~/assets/rulerOn.svg';
import trafficOff from '~/assets/trafficOff.png';
import trafficOn from '~/assets/trafficOn.png';
import widgetMinimap from '~/assets/widgetMinimap.svg';
import widgetStreetMap from '~/assets/widgetStreetMap.svg';
import zoneOff from '~/assets/zoneOff.svg';
import zoneOn from '~/assets/zoneOn.svg';
import safezoneOff from '~/assets/safezoneOff.svg';
import safezoneOn from '~/assets/safezoneOn.svg';
import fullScreen from '~/assets/16px_ui_icon_fullScreen.png';
import fullScreenExit from '~/assets/16px_ui_ison_fullscreenexit.png';

const infowindow = new window.google.maps.InfoWindow();

// ? Quais botões estão ativos no momento
// ? Manter apenas um ativo.
const active = {
  zone: false,
  ruler: false,
  safezone: false,
  referencePoint: false,
};

const offImages = {
  zoneOff,
  rulerOff,
  safezoneOff,
  referencePointOff,
};

export function mapControl(controlDiv, map) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.border = '2px solid #fff';
  controlUI.style.marginLeft = '6px';
  controlUI.style.borderRadius = '10px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlUI.style.marginBottom = '10px';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '62px';
  controlUI.style.width = '70px';
  controlUI.title = 'Clique para mudar o tipo de mapa';
  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '58px';
  controlImage.style.width = '66px';
  controlImage.style.borderRadius = '10px';
  controlImage.src = widgetMinimap;
  controlUI.appendChild(controlImage);

  const controlText = document.createElement('div');
  controlText.style.color = '#FFF';
  controlText.style.fontFamily = 'Roboto,Arial,sans-serif';
  controlText.style.fontSize = '13px';
  controlText.style.lineHeight = '62px';
  controlText.style.paddingLeft = '5px';
  controlText.style.paddingRight = '5px';
  controlText.style.marginTop = '-42px';
  controlText.innerHTML = 'Satélite';
  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    controlImage.src =
      map.mapTypeId === 'roadmap' ? widgetStreetMap : widgetMinimap;
    controlUI.style.borderColor = map.mapTypeId === 'roadmap' ? '#000' : '#fff';
    controlText.innerHTML = map.mapTypeId === 'roadmap' ? 'Mapa' : 'Satélite';
    controlText.style.color = map.mapTypeId === 'roadmap' ? '#000' : '#fff';
    if (map.mapTypeId === 'roadmap') {
      map.setMapTypeId('hybrid');
    } else {
      map.setMapTypeId('roadmap');
    }
  });
}

export function trafficControl(controlDiv, map) {
  const trafficLayer = new window.google.maps.TrafficLayer();
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '25px';
  controlUI.style.width = '25px';
  controlUI.style.marginRight = '6px';
  controlUI.title = 'Clique para mostrar o trânsito';
  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '25px';
  controlImage.style.padding = '2px 4px';
  controlImage.src = trafficOff;

  controlUI.appendChild(controlImage);

  controlUI.addEventListener('click', () => {
    if (trafficLayer.getMap() == null) {
      trafficLayer.setMap(map);
      controlImage.src = trafficOn;
    } else {
      trafficLayer.setMap(null);
      controlImage.src = trafficOff;
    }
  });
}

export function fullscreenControl(
  trackerId,
  controlDiv,
  map,
  dispatch,
  setTypeModal,
  setGeoJson,
  params,
  history
) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '25px';
  controlUI.style.width = '25px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.style.marginTop = '6px';
  controlUI.title = 'Clique para ampliar o mapa';

  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.style.height = '24px';
  controlImage.style.padding = '3px 3px';
  controlImage.src =
    history.location.pathname !== '/monitoringFullscreen'
      ? fullScreen
      : fullScreenExit;

  controlUI.appendChild(controlImage);

  controlUI.addEventListener('click', async () => {
    const _params = new URLSearchParams(window.location.search);
    let trackerId = _params && _params.get && Number(_params.get('id'));
    if (!trackerId) {
      trackerId = params && params.get && Number(params.get('id'));
    }
    if (history.location.pathname !== '/monitoringFullscreen') {
      history.push(`/monitoringFullscreen?id=${trackerId}`);
    } else {
      history.push(`/monitoring?id=${trackerId}`);
    }
  });
}

export function referencePointControl(
  controlDiv,
  map,
  dispatch,
  setTypeModal,
  setGeoJson,
  params
) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '25px';
  controlUI.style.width = '25px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.title = 'Clique para mostrar os pontos de referência';

  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.setAttribute('id', 'referencePoint');
  controlImage.style.height = '24px';
  controlImage.style.padding = '3px 3px';
  controlImage.src = referencePointOff;

  controlUI.appendChild(controlImage);

  controlUI.addEventListener('click', async () => {
    map.data.forEach((feature) => {
      map.data.remove(feature);
    });

    active.referencePoint = !active.referencePoint;
    if (active.referencePoint) {
      disableOthersButtons('referencePoint');
      controlImage.src = referencePointOn;

      let fetchGeometriesParams = '?type=point';
      let trackerPointsParams = '';
      const clientId = localStorage.getItem('client') || '';
      if (clientId) {
        fetchGeometriesParams += `&client_id=${clientId}`;
        trackerPointsParams = `?client_id=${clientId}`;
      }
      const _params = new URLSearchParams(window.location.search);
      let trackerId = _params && _params.get && Number(_params.get('id'));
      if (!trackerId) {
        trackerId = params && params.get && Number(params.get('id'));
      }
      const response = !trackerId
        ? await api.fetchGeometries(fetchGeometriesParams)
        : await api.trackerPoints({ trackerId, params: trackerPointsParams });
      const { data } = response;
      data
        .filter(
          ({ geojson }) =>
            geojson.geometry &&
            geojson.geometry.type &&
            geojson.geometry.type.toUpperCase() === 'POINT'
        )
        .forEach((query) => {
          const { geojson } = query;
          const { id, name, hasPassed, detail } = query;
          geojson.type = 'Feature';
          geojson.properties = {
            id,
            title: name && name.toUpperCase(),
            detail: detail,
            hasPassed: hasPassed,
            latitude: geojson.geometry.coordinates[1],
            longitude: geojson.geometry.coordinates[0],
          };
          try {
            var color = hasPassed === true ? 'green' : 'red';
            const coisa = map.data.addGeoJson(geojson);
            map.data.overrideStyle(coisa[0], {
              icon: `http://maps.google.com/mapfiles/ms/micons/${color}-dot.png`,
            });
          } catch (err) {
            console.log('#138', err.name, err.message, { geojson });
          }
        });

      if (setTypeModal && setGeoJson) {
        map.data.addListener('dblclick', (event) => {
          // console.log(event.feature.j);
          try {
            infowindow.close();
            controlImage.src = referencePointOff;
            active.referencePoint = false;
            map.data.forEach((feature) => {
              map.data.remove(feature);
            });
            window.google.maps.event.clearInstanceListeners(map.data);
          } catch (err) {
            console.log('#151', err.name, err.message);
          }
          setGeoJson({
            type: 'Feature',
            properties: {
              id: event.feature.j.id,
              name: event.feature.j.title,
              latitude: event.feature.j.latitude,
              longitude: event.feature.j.longitude,
            },
            geometry: {
              type: 'Point',
              coordinates: [
                event.feature.j.longitude,
                event.feature.j.latitude,
              ],
            },
          });
          setTypeModal('marker');
          dispatch(BufferActions.setViewModal(true));
        });
      }

      map.data.addListener('click', (event) => {
        const feat = event.feature;
        let detail = feat.getProperty('detail');
        if (detail) {
          detail = detail
            .split('\n')
            .map((v) => `<p>${v}</p>`)
            .join('');
        } else {
          detail = '';
        }
        const html = `<div><strong>${feat.getProperty(
          'title'
        )}</strong></div>${detail}`;
        infowindow.setContent(html);
        infowindow.setPosition(event.latLng);
        infowindow.setOptions({
          pixelOffset: new window.google.maps.Size(0, -34),
        });
        infowindow.open(map);
      });
    } else {
      // map.data.forEach((feature) => {
      //   map.data.remove(feature);
      // });
      controlImage.src = referencePointOff;
    }
  });
}

export function zoneControl(controlDiv, map, params) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '25px';
  controlUI.style.width = '25px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.title = 'Clique para mostrar as cercas';

  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.setAttribute('id', 'zone');
  controlImage.style.height = '24px';
  controlImage.style.padding = '3px 3px';
  controlImage.src = zoneOff;

  controlUI.appendChild(controlImage);

  // let visibleZone = false;
  controlUI.addEventListener('click', async () => {
    // visibleZone = !visibleZone;
    active.zone = !active.zone;
    if (active.zone) {
      disableOthersButtons('zone');
      removeElements(map);
      const _params = new URLSearchParams(window.location.search);
      let trackerId = _params && _params.get && Number(_params.get('id'));
      if (!trackerId) {
        trackerId = params && params.get && Number(params.get('id'));
      }
      const response = !trackerId
        ? await api.fetchGeometries('?type=fence')
        : await api.trackerFences({ trackerId });
      const { data } = response;
      data.forEach((query) => {
        const { geojson } = query;
        if (
          geojson.geometry &&
          geojson.geometry.type &&
          geojson.geometry.type.toUpperCase() === 'POINT'
        ) {
          geojson.geometry.coordinates.splice(0, 1);
        }
        geojson.properties = { id: query.id, title: query.name };
        map.data.addGeoJson(geojson);
        map.data.setStyle({ fillColor: '#3a3a3a', strokeColor: '#000000' });
        const html = `<div>${query.name}</div>`;
        infowindow.setContent(html);
        // infowindow.setPosition(event.latLng);
        infowindow.setOptions({
          pixelOffset: new window.google.maps.Size(0, -34),
        });
        infowindow.open(map);
      });

      controlImage.src = zoneOn;
    } else {
      removeElements(map);
      controlImage.src = zoneOff;
    }
  });
}

export function safezoneControl(controlDiv, map, params) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#fff';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '25px';
  controlUI.style.width = '25px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlUI.title = 'Clique para mostrar as zonas de segurança';

  controlDiv.appendChild(controlUI);

  const controlImage = document.createElement('img');
  controlImage.setAttribute('id', 'safezone');
  controlImage.style.height = '24px';
  controlImage.style.padding = '3px 3px';
  controlImage.src = safezoneOff;

  controlUI.appendChild(controlImage);

  // let visibleZone = false;
  controlUI.addEventListener('click', async () => {
    // visibleZone = !visibleZone;
    active.safezone = !active.safezone;
    if (active.safezone) {
      disableOthersButtons('safezone');
      removeElements(map);
      const response = await api.fetchGeometries('?type=safezone');
      const { data } = response;
      data.forEach((query) => {
        const { geojson } = query;
        geojson.properties = {
          id: query.id,
          title: query.name,
        };
        map.data.addGeoJson(geojson);
        map.data.setStyle({ fillColor: '#69FF70', strokeColor: '#000' });
        const html = `<div>${query.name}</div>`;
        infowindow.setContent(html);
        // infowindow.setPosition(event.latLng);
        infowindow.setOptions({
          pixelOffset: new window.google.maps.Size(0, -34),
        });
        infowindow.open(map);
      });

      controlImage.src = safezoneOn;
    } else {
      removeElements(map);
      controlImage.src = safezoneOff;
    }
  });
}

export function rulerControl(controlDiv, map, measureTool) {
  if (measureTool) {
    const controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.height = '25px';
    controlUI.style.width = '25px';
    controlUI.style.marginRight = '6px';
    controlUI.style.marginBottom = '6px';
    controlUI.title = 'Clique para iniciar a régua';

    controlDiv.appendChild(controlUI);

    const controlImage = document.createElement('img');
    controlImage.setAttribute('id', 'ruler');
    controlImage.style.height = '24px';
    controlImage.style.padding = '3px 3px';
    controlImage.src = rulerOff;

    controlUI.appendChild(controlImage);
    controlUI.addEventListener('click', () => {
      active.ruler = !active.ruler;
      if (active.ruler) {
        disableOthersButtons('ruler');
        removeElements(map);
        measureTool.start();
        controlImage.src = rulerOn;
      } else {
        measureTool.end();
        controlImage.src = rulerOff;
      }
    });
  }
}

export function saveZone(
  controlDiv,
  map,
  dispatch,
  BufferActions,
  event,
  drawingManager,
  params
) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#555555';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'SALVAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    dispatch(BufferActions.setDrawingMode());
    dispatch(BufferActions.setViewModal(false));
    event.setMap(null);
    if (!params) {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].clear();
    } else {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].removeAt(1);
    }
    drawingManager.setOptions({
      drawingControl: true,
    });
  });
}

export function cancelZone(
  controlDiv,
  map,
  dispatch,
  BufferActions,
  event,
  drawingManager,
  params
) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#E33939';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'CANCELAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    dispatch(BufferActions.setDrawingMode());
    event.setMap(null);
    if (!params) {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].clear();
    } else {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].removeAt(1);
    }
    drawingManager.setOptions({
      drawingControl: true,
    });
  });
}

function removeElements(map) {
  map.data.forEach((feature) => {
    map.data.remove(feature);
  });
}

// Mudar a imagem dos outros botôes (cercas e pontos de referencia)
function disableOthersButtons(sholdBeActive) {
  for (const key of Object.keys(active)) {
    if (key !== sholdBeActive) {
      active[key] = false;
      const button = document.getElementById(key);
      button.src = offImages[`${key}Off`];
    }
  }
}
