import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { format } from 'date-fns';
import PropTypes from 'prop-types';

import TableComponent from '~/components/Table';
import { Creators as DailyActions } from '~/store/ducks/dailySauce';

import { HistoryContainer } from './styles';

function Daily({ height }) {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.dailyState.history);
  const loading = useSelector((state) => state.dailyState.loading);
  function getSpeed({ speed, ignition }) {
    if (speed) {
      return speed.toFixed(0);
    }
    if (ignition) {
      return 0;
    }
    return '-';
  }
  return (
    <HistoryContainer>
      <TableComponent
        dimensionHeight={height}
        data={history.map(({ date, ignition, speed, ...item }) => ({
          ...item,
          time: format(new Date(date), 'dd/MM HH:mm:ss'),
          ignition: ignition ? 'Ligado' : 'Desligado',
          speed: getSpeed({ ignition, speed }),
        }))}
        headerBackground="#E3EDF0"
        headerColor="#333"
        loading={loading}
        labels={['Momento', 'Iginição', 'Velocidade', 'Endereço']}
        headers={['time', 'ignition', 'speed', 'address']}
        fileTitle="Relatório de pontos do diário"
        getData={(data) => dispatch(DailyActions.setCoordinateInfo(data))}
      />
    </HistoryContainer>
  );
}

Daily.propTypes = {
  height: PropTypes.string,
};

Daily.defaultProps = {
  height: '300px',
};

export default Daily;
