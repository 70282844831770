import styled from 'styled-components';
import 'rc-dropdown/assets/index.css';

import Menu, { Item as MenuItem } from 'rc-menu';

export const Container = styled.div`
  grid-area: header;
  background: #245378;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  transition: height 0.5s;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Logo = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding-left: 5px;
  font-family: 'Fredoka One', cursive;

  span {
    color: #fefefe;
    font-size: 17px;
    letter-spacing: 0.1em;
  }

  img {
    width: 75px;
  }
`;

export const Profile = styled.div`
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    > strong {
      font-size: 12px;
      color: #f1f2f1;
    }
    > small {
      font-size: 9px;
      color: #ddd;
    }
  }
  > img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  /* span:first-of-type {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 3px;
  }
  span {
    color: #fff;
    font-weight: 700;
    font-size: 11px;
  } */
`;

export const Item = styled(MenuItem)`
  height: 35px;
  width: 100%;
  padding: 5px;
  color: ${(props) =>
    props.bgtheme === 'exit' ? '#fff !important' : '#333 !important'};
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  :hover {
    background: ${(props) =>
      props.bgtheme === 'exit'
        ? 'rgba(168,0,18) !important'
        : 'rgba(0, 0, 0, 0.05) !important'};
  }
  span {
    margin-left: 5px;
  }

  ${(props) =>
    props.bgtheme === 'exit'
      ? `
    background: #c10015;
    border-radius: 0 0 5px 5px;
  `
      : false}
`;

export const MenuStyled = styled(Menu)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  border: none;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  ::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    top: -7px;
    margin-left: 117px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;

    @media (max-width: 600px) {
      margin-left: 120px;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;
