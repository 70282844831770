import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import api from '~/services/api';

import { SelectStyled, LabelStyled, ErrorMessage } from '../index';

export default function SelectReferencePoint({
  error,
  parentCallback,
  isDisabled,
  value,
  defaultValue,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);

  async function getReferencePoints() {
    const response = await api.fetchGeometries('?type=point');
    setOptions(response.data);
    setKey(Math.random());
    setLoading(true);
  }

  useEffect(() => {
    getReferencePoints();
  }, []);

  return (
    <>
      <div>
        <LabelStyled>Ponto de Referência</LabelStyled>
        {error ? <ErrorMessage>Campo obrigatório</ErrorMessage> : <></>}
      </div>
      {loading ? (
        <SelectStyled
          key={key}
          // styles={style}
          onChange={(option) => parentCallback(option)}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          defaultValue={
            options[options.findIndex((item) => item.id === defaultValue)]
          }
          options={options}
          placeholder="Selecione o pont de referência"
          error={error}
          isDisabled={isDisabled}
          value={
            value ? options[options.findIndex((item) => item.id === value)] : ''
          }
        />
      ) : (
        <>Carregando</>
      )}
    </>
  );
}

SelectReferencePoint.propTypes = {
  error: PropTypes.string.isRequired,
  parentCallback: PropTypes.func.isRequired,
  isDisabled: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
};
