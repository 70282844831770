import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import { IconButton } from '~/components/buttons';
import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import history from '~/services/history';

import Table from './table';
import { FieldContainer, SelectStyled } from '~/components/Global';

function Responsible({
  match: {
    params: { id: idParams },
  },
}) {
  const [personType, setPersonType] = useState();
  // const dispatch = useDispatch();
  const [editablePermission, setEditablePermission] = useState(false);
  const me = useSelector((state) => state.globalState.me);
  // useEffect(() => {
  // if (idParams) {
  //   dispatch(ResponsibleActions.fetchResponsibleRequest(`/${idParams}`));
  // }
  // }, []);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
      setPersonType('Responsável');
    }
  }, [me]);
  useEffect(() => {
    if (personType === 'Passageiro') {
      history.push('/passenger');
    }
    if (personType === 'Responsável') {
      history.push('/responsible');
    }
    if (personType === 'Motorista') {
      history.push('/driver');
    }
  }, [personType]);
  return (
    <FormContainer>
      <HeaderFormContainer title="Responsáveis">
        <FieldContainer style={{ width: '250px', height: 'auto', padding: 0 }}>
          {/* <label>Tipo de Pessoa</label> */}
          <SelectStyled
            name="type"
            defaultValue={{
              label: personType,
              value: personType,
            }}
            onChange={(option) => setPersonType(option.value)}
            value={{
              label: personType,
              value: personType,
            }}
            options={[
              { label: 'Motorista', value: 'Motorista' },
              { label: 'Responsável', value: 'Responsável' },
              { label: 'Passageiro', value: 'Passageiro' },
            ]}
            isDisabled={!editablePermission}
          />
        </FieldContainer>
        <IconButton
          Icon={MdAdd}
          size={15}
          background="bg-geo-900 dark:bg-lightBlue-600"
          height="h-8"
          width="w-44"
          onClick={() => history.push('/responsible/form')}
          disabled={!editablePermission}
          type="button"
        >
          Adicionar
        </IconButton>
      </HeaderFormContainer>
      <SimpleContainer>
        <Table />
      </SimpleContainer>
    </FormContainer>
  );
}

Responsible.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};

Responsible.defaultProps = {
  match: { params: {} },
};

export default Responsible;
