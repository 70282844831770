export default function translateConfigurationFence({ configurationFence }) {
  let translateString;
  switch (configurationFence) {
    case 'always':
      translateString = 'Sempre Ativa';
      break;
    case 'once':
      translateString = 'Apenas uma vez';
      break;
    case 'weekly':
      translateString = 'Semanalmente';
      break;

    default:
      translateString = '';
      break;
  }
  return translateString;
}
