import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchJourneyRouteRequest: ['query', 'params'],
  fetchJourneyRouteSuccess: ['data'],
  fetchJourneyRouteIdSuccess: ['data'],
  fetchJourneyRouteFailure: [],
  updateJourneyRouteRequest: ['data'],
  createJourneyRouteRequest: ['data'],
  clearJourneyRoute: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  journeyRoutes: [],
  journeyRoute: {},
  journeyRouteByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearJourneyRoute = (state = INITIAL_STATE) => ({
  ...state,
  journeyRoute: {},
});

const fetchJourneyRouteRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchJourneyRouteSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  journeyRoutes: actions.data,
  loading: false,
});

const fetchJourneyRouteIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    journeyRoute: actions.data,
    loading: false,
  };
};

const fetchJourneyRouteFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    journeyRoutes: [],
  };
};

const createJourneyRouteRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateJourneyRouteRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_JOURNEY_ROUTE_REQUEST]: fetchJourneyRouteRequest,
  [Types.FETCH_JOURNEY_ROUTE_SUCCESS]: fetchJourneyRouteSuccess,
  [Types.FETCH_JOURNEY_ROUTE_ID_SUCCESS]: fetchJourneyRouteIdSuccess,
  [Types.FETCH_JOURNEY_ROUTE_FAILURE]: fetchJourneyRouteFailure,
  [Types.UPDATE_JOURNEY_ROUTE_REQUEST]: updateJourneyRouteRequest,
  [Types.CREATE_JOURNEY_ROUTE_REQUEST]: createJourneyRouteRequest,
  [Types.CLEAR_JOURNEY_ROUTE]: clearJourneyRoute,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
