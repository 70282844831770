import React, { useState, useEffect } from 'react';

import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';

import { ButtonStyled, InputStyled } from '~/components/Global';
import Loading from '~/components/Loading';
import axios from '~/services/axios';
import history from '~/services/history';

import { Container, Main, Error } from './styles';

export default function Password({
  match: {
    params: { token },
  },
}) {
  const [linkExpired, setLinkExpired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    axios.get(`/auth/forgot/${token}`).catch(() => {
      setLinkExpired(true);
    });
  }, [token]);

  async function handleSubmit(data) {
    setIsSubmitting(true);
    try {
      const response = await axios.post('/auth/login', data);
      localStorage.setItem('token', response.data.token);
      setIsSubmitting(false);
      history.push('/');
    } catch ({ response: { data: DataError } }) {
      setIsSubmitting(false);
      setError(DataError.error);
    }
  }

  // function handleForgot() {
  //   props.history.push("/auth/forgot");
  // }

  return (
    <>
      <Container>
        {linkExpired ? (
          <>Link expirado!</>
        ) : (
          <>
            {!isSubmitting ? (
              <Main>
                <h3>Cadastre a nova senha</h3>
                <Form onSubmit={handleSubmit}>
                  <InputStyled
                    type="password"
                    required
                    name="password"
                    placeholder="Senha"
                  />
                  <InputStyled
                    type="password"
                    required
                    name="password"
                    placeholder="Repita a senha"
                  />
                  <ButtonStyled color="#227AD7">Salvar</ButtonStyled>
                  <Error>{error || ''}</Error>
                </Form>
              </Main>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Container>
    </>
  );
}

Password.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};

Password.defaultProps = {
  match: { params: {} },
};
