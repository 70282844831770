import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  bufferRequest: ['id'],
  groupRequest: ['id'],
  bufferSuccess: ['data', 'groups'],
  groupSuccess: ['groups'],
  bufferFailure: [],
  groupFailure: [],
  setViewGroup: [],
  setViewModal: [],
  updateBuffer: ['data'],
  filterBuffer: ['filter'],
  setDrawingMode: [],
  filterGroupBuffer: ['tracker_id'],
  clearGroupBuffer: [],
  setLastId: ['id'],
  setGroupSelected: ['group'],
  setClientSelected: ['client'],
});

const INITIAL_STATE = {
  loading: false,
  buffer: [],
  bufferImutable: [],
  bufferGroup: [],
  viewGroup: false,
  viewModal: false,
  groups: [],
  drawingMode: false,
  filterActive: 'ALL',
  lastId: '',
  groupSelected: {
    label: '',
    value: '',
    id_trackers: [],
    isAdm: false,
  },
  clientSelected: {},
};

const setGroupSelected = (state = INITIAL_STATE, action) => ({
  ...state,
  groupSelected: action.group,
});

const setLastId = (state = INITIAL_STATE, action) => ({
  ...state,
  lastId: action.id,
});

const requestBuffer = (state = INITIAL_STATE) => ({
  ...state,
  // loading: true,
});
const requestGroup = (state = INITIAL_STATE) => ({
  ...state,
  // loading: true,
});

// no preenchimento da buffer é verificado se possui filtro de status e/ou grupo selecionado (action.filtered no saga)
const successBuffer = (state = INITIAL_STATE, action) => {
  let buffer;
  if (action.filtered) {
    if (state.filterActive === 'ALL') {
      buffer = action.filtered;
    } else {
      buffer = action.filtered.filter(
        (item) => state.filterActive === item.status
      );
    }
  } else if (state.filterActive === 'ALL') {
    buffer = action.data;
  } else {
    buffer = action.data.filter((item) => state.filterActive === item.status);
  }
  return {
    ...state,
    buffer,
    bufferImutable: action.data,
    bufferGroup: action.filtered ? action.filtered : action.data,
    loading: false,
  };
};
const successGroup = (state = INITIAL_STATE, action) => ({
  ...state,
  groups: action.groups,
});

const failureBuffer = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  buffer: [],
  bufferImutable: [],
});
const failureGroup = (state = INITIAL_STATE) => ({
  ...state,
  group: [],
});

const changeDrawingMode = (state = INITIAL_STATE) => {
  return {
    ...state,
    drawingMode: !state.drawingMode,
  };
};

const changeView = (state = INITIAL_STATE) => ({
  ...state,
  viewGroup: !state.viewGroup,
});

const changeModal = (state = INITIAL_STATE, action) => ({
  ...state,
  viewModal: !state.viewModal,
});

const filterGroupBuffer = (state = INITIAL_STATE, action) => ({
  ...state,
  buffer: state.bufferImutable.filter((item) =>
    action.tracker_id.find(
      (tracker) => Number(tracker) === Number(item.tracker_id)
    )
  ),
  bufferGroup: state.bufferImutable.filter((item) =>
    action.tracker_id.find(
      (tracker) => Number(tracker) === Number(item.tracker_id)
    )
  ),
});

const clearGroupBuffer = (state = INITIAL_STATE) => ({
  ...state,
  buffer: state.bufferImutable,
  bufferGroup: state.bufferImutable,
});

const changeBuffer = (state = INITIAL_STATE, action) => ({
  ...state,
  buffer: state.buffer.map((item) => {
    if (item.tracker_id === action.data.tracker_id) {
      return action.data;
    }
    return item;
  }),
  bufferImutable: state.bufferImutable.map((item) => {
    if (item.tracker_id === action.data.tracker_id) {
      return action.data;
    }
    return item;
  }),
});

const filterBuffer = (state = INITIAL_STATE, action) => ({
  ...state,
  filterActive: action.filter,
  buffer:
    action.filter === 'ALL'
      ? state.bufferGroup
      : state.bufferGroup.filter((item) => action.filter === item.status),
});

const setClientSelected = (state = INITIAL_STATE, { client }) => ({
  ...state,
  clientSelected: client,
});

export default createReducer(INITIAL_STATE, {
  [Types.BUFFER_REQUEST]: requestBuffer,
  [Types.GROUP_REQUEST]: requestGroup,
  [Types.BUFFER_SUCCESS]: successBuffer,
  [Types.GROUP_SUCCESS]: successGroup,
  [Types.BUFFER_FAILURE]: failureBuffer,
  [Types.GROUP_FAILURE]: failureGroup,
  [Types.SET_VIEW_GROUP]: changeView,
  [Types.SET_VIEW_MODAL]: changeModal,
  [Types.SET_DRAWING_MODE]: changeDrawingMode,
  [Types.UPDATE_BUFFER]: changeBuffer,
  [Types.FILTER_BUFFER]: filterBuffer,
  [Types.FILTER_GROUP_BUFFER]: filterGroupBuffer,
  [Types.CLEAR_GROUP_BUFFER]: clearGroupBuffer,
  [Types.SET_LAST_ID]: setLastId,
  [Types.SET_GROUP_SELECTED]: setGroupSelected,
  [Types.SET_CLIENT_SELECTED]: setClientSelected,
});
