import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';

import TableComponent from '~/components/Table';
import history from '~/services/history';
import { Creators as MaintenanceActions } from '~/store/ducks/maintenanceSauce';
import { getClientId } from '~/util/parseJwt';

function Maintenance() {
  const dispatch = useDispatch();
  const maintenances = useSelector(
    (state) => state.maintenanceState.maintenances
  );
  const loading = useSelector((state) => state.maintenanceState.loading);

  useEffect(() => {
    let filterClient;
    const clientId = getClientId();
    if (clientId === 2) {
      filterClient = localStorage.getItem('client');
    }
    dispatch(
      MaintenanceActions.fetchMaintenanceRequest(
        filterClient ? `?id_clients=${filterClient}` : undefined
      )
    );
  }, []);

  const targetRef = useRef();
  const [height, setHeight] = useState(0);
  function dimension() {
    if (targetRef.current) {
      setHeight(`${targetRef.current.offsetHeight}px `);
    }
  }

  useLayoutEffect(() => {
    setHeight(0);
    dimension();
  }, []);

  function getMaintenanceType({ maintenanceType }) {
    if (maintenanceType === 'corrective') {
      return 'Corretiva';
    }
    if (maintenanceType === 'preventive') {
      return 'Preventiva';
    }
    if (maintenanceType === 'detective') {
      return 'Detectiva';
    }
    return 'Aguardando...';
  }

  return (
    <div style={{ height: '100%' }} ref={targetRef}>
      <TableComponent
        dimensionHeight={height}
        data={maintenances.map(
          ({
            date_start,
            date_end,
            trackerItem,
            maintenanceType,
            ...item
          }) => ({
            date_start: moment(date_start).format('DD/MM/YYYY HH:MM:SS'),
            date_end: moment(date_end).format('DD/MM/YYYY HH:MM:SS'),
            plateFormated: trackerItem
              ? `${trackerItem.label} - ${trackerItem.plate}`
              : '',
            maintenanceType: getMaintenanceType({ maintenanceType }),
            ...item,
          })
        )}
        headerBackground="#245378"
        loading={loading}
        labels={[
          'Veículo',
          'Km',
          'Tipo de Manutenção',
          'Data de Início',
          'Data Final',
          'Custo Total',
        ]}
        headers={[
          'plateFormated',
          'marker',
          'maintenanceType',
          'date_start',
          'date_end',
          'price',
        ]}
        groupType={['simple', 'simple', 'simple', 'simple', 'simple', 'sum']}
        fileTitle="Relatório de manutenção"
        getData={(data) => history.push(`/maintenance/form/${data.id}`)}
      />
    </div>
  );
}

export default Maintenance;
