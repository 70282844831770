import React from 'react';
import { connect } from 'react-redux';

import {
  ContainerStyled,
  TableContainer,
  ContainerHeader,
} from '~/components/Global';

import Form from './form';

function ChangePassword(props) {
  return (
    <ContainerStyled>
      <ContainerHeader>
        <div>Alterar Senha</div>
      </ContainerHeader>
      <TableContainer>
        <Form {...props} />
      </TableContainer>
    </ContainerStyled>
  );
}

export default connect()(ChangePassword);
