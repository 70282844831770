import React from 'react';

import { ContainerCheck } from './styles';

function fields({ label, id, ...props }) {
  return (
    <ContainerCheck>
      <input id={id} type="checkbox" {...props} style={{ cursor: 'pointer' }} />
      <label htmlFor={id} style={{ cursor: 'pointer' }}>
        {label}
      </label>
    </ContainerCheck>
  );
}

export default fields;
