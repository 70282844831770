import React from 'react';

// import { Container } from './styles';
import moment from 'moment';
import PropTypes from 'prop-types';

import carBatteryRed from '~/assets/carPanel/car-battery-red.png';
import noSignal from '~/assets/carPanel/noSignalNew.svg';
import speedometer from '~/assets/carPanel/noun_Speedometer_2171226.svg';
import sleepIcon from '~/assets/sleepIconSemSobra.svg';
import stopIcon from '~/assets/stopIconSemSobra.svg';

import { Disconnected } from './styles';

function Status({ status, ignition, last_time_on, speed, rpm, date, battery }) {
  const totalMinutes = moment().diff(moment(last_time_on), 'minutes');
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  /**
   * Calculo tempo sem sinal
   */
  const totalMinutesNoSignal = moment().diff(moment(date), 'minutes');
  const hoursNosignal = Math.floor(totalMinutesNoSignal / 60);
  const minutesNoSignal = totalMinutesNoSignal % 60;

  if (battery === 0) {
    return (
      <>
        <img
          src={carBatteryRed}
          alt=""
          style={{
            backgroundColor: 'red',
            minHeight: `24px`,
            marginTop: `4px`,
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '4px',
            paddingRight: '4px',
            borderRadius: `4px`,
          }}
        />
        {last_time_on ? `${hours}h:${minutes}m` : '-'}
      </>
    );
  }

  if (status === 'SLEEP') {
    return (
      <>
        <img src={sleepIcon} alt="" />
        {last_time_on ? `${hours}h:${minutes}m` : '-'}
      </>
    );
  }

  // AS OUTRAS SITUACOES
  if (status !== 'DISCONNECTED') {
    if (ignition) {
      return (
        <>
          <i mg src={speedometer} alt="" />
          {rpm && rpm < 9999 ? (
            <div>
              <span>{`${Math.floor(speed)} km/h`}</span>
              <span>{rpm} RPM</span>
            </div>
          ) : (
            <>
              <span>{`${Math.floor(speed)} km/h`}</span>
              {/* WIP <span>{last_time_on ? `${hours}h:${minutes}m` : '-'}</span> */}
            </>
          )}
        </>
      );
    }
    return (
      <>
        <img src={stopIcon} alt="" />
        {last_time_on ? `${hours}h:${minutes}m` : '-'}
      </>
    );
  }

  return (
    <Disconnected>
      <img src={noSignal} alt="" />
      {`${hoursNosignal}h:${minutesNoSignal}m`}
    </Disconnected>
  );
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
  last_time_on: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  ignition: PropTypes.bool.isRequired,
  rpm: PropTypes.number,
  battery: PropTypes.number,
  speed: PropTypes.number.isRequired,
};

Status.defaultProps = {
  rpm: 0,
  battery: 0,
};

export default Status;
