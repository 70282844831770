import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/worksheetSauce';

export function* getWorksheet(payload) {
  try {
    const response = yield call(
      api.fetchWorksheet,
      payload.query,
      payload.params
    );
    if (!_.isArray(response.data.docs)) {
      yield put({
        type: Types.FETCH_WORKSHEET_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_WORKSHEET_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createWorksheetSaga(payload) {
  try {
    yield call(api.createWorksheet, payload.data);
    yield put({
      type: Types.FETCH_WORKSHEET_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/worksheet'));
  } catch (error) {
    yield put({ type: Types.FETCH_WORKSHEET_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateWorksheetSaga(payload) {
  try {
    yield call(api.updateWorksheet, payload.data);
    yield put({
      type: Types.FETCH_WORKSHEET_REQUEST,
    });
    toast.success('Atualizado com sucesso!');
    yield put(push('/worksheet'));
  } catch (error) {
    yield put({ type: Types.FETCH_WORKSHEET_FAILURE });
    toast.error('Ocorreu um erro!');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_WORKSHEET_REQUEST, getWorksheet)]);
  yield all([takeEvery(Types.CREATE_WORKSHEET_REQUEST, createWorksheetSaga)]);
  yield all([takeEvery(Types.UPDATE_WORKSHEET_REQUEST, updateWorksheetSaga)]);
}
