import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { useDispatch, useSelector } from 'react-redux';

import { isPast, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import _ from 'lodash';

import { SimpleField, SelectField } from '~/components/fields';
import { TrackersFilter } from '~/components/filters';
import ReportFields from '~/components/ReportFields';
import { Creators as ReportsActions } from '~/store/ducks/reportSauce';
import { Creators as schedulesActions } from '~/store/ducks/schedulesSauce';
import reportsOptions from '~/util/reportsOptions';

import Buttons from './Buttons';
import Command from './Command';
import initialValues from './initialValues';
import ModalPassword from './ModalPassword';
import Reports from './Reports';
import { Container } from './styles';
import submitFormat from './submitFormat';

function ModalCalendar() {
  const dispatch = useDispatch();
  const { modal: modalVisible, schedule } = useSelector(
    ({ schedulesState }) => schedulesState
  );
  const [typeSchedule] = useState([
    { value: 'report', label: 'Relatório' },
    { value: 'command', label: 'Comando' },
  ]);
  const [scheduleType] = useState([
    { value: 'once', label: 'Apenas uma vez' },
    { value: 'weekly', label: 'Semanalmente' },
  ]);
  const [modalPassword, setModalPassword] = useState(false);

  const [keyUpdate, setKeyUpdate] = useState(0);
  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const err = {};
      const msg = 'Este campo é obrigatório!';
      // console.log(
      //   values,
      //   values.schedule.type === 'once',
      //   isPast(values.schedule.date)
      // );
      if (!values.name) err.name = msg;
      if (!values.type) err.type = msg;
      if (values.schedule.type === 'once') {
        if (values.type === 'all') {
          delete err.data;
        } else if (isPast(values.schedule.date))
          err.date = 'A data não deverá ser menor que a atual!';
      }
      if (values.key === 'command') {
        if (values.type === 'all') {
          if (isPast(values.schedule.dateLock))
            err.dateLock = 'A data de bloqueio ser menor que a atual!';
          if (isPast(values.schedule.dateUnlock))
            err.dateUnlock = 'A data de desbloqueio ser menor que a atual!';
          if (isBefore(values.schedule.dateUnlock, values.schedule.dateLock))
            err.datePast = 'O desbloqueio tem que ser após o bloqueio';
        }
      }
      return err;
    },
    onSubmit: (data) => {
      const values = submitFormat({ data });
      if (data.id) {
        dispatch(schedulesActions.updateScheduleRequest(values));
      } else if (data.key === 'command' && data.password === '') {
        setModalPassword(true);
      } else {
        dispatch(schedulesActions.createScheduleRequest(values));
      }
    },
  });

  useEffect(() => {
    if (!_.isEmpty(schedule)) {
      setModalPassword(false);
      formik.resetForm();

      if (schedule.schedule.type === 'once') {
        schedule.schedule.date = new Date(schedule.schedule.date);
      }
      formik.setValues({
        ...schedule,
        typeCars: schedule.params.typeCars,
        group: schedule.params.typeCars,
        cars: schedule.params.cars,
      });
      dispatch(
        ReportsActions.setReport(
          reportsOptions.find(({ key }) => key === schedule.type)
        )
      );

      setKeyUpdate(Math.random());
    }
  }, [schedule]);

  useEffect(() => {
    if (modalVisible && !schedule.id) {
      formik.resetForm();
      setModalPassword(false);
      setKeyUpdate(Math.random());
      dispatch(ReportsActions.setReport({ fieldsParams: [], fields: [] }));
    }
  }, [modalVisible, schedule]);

  useEffect(() => {
    setModalPassword(false);
  }, []);

  function closeModal() {
    dispatch(schedulesActions.setModal());
    dispatch(schedulesActions.setSchedule({}));
  }
  return (
    <Modal
      visible={modalVisible}
      width="70%"
      height="90%"
      onClickAway={() => closeModal()}
    >
      <Container
        onSubmit={formik.handleSubmit}
        className="h-full w-full overflow-auto"
      >
        <div>
          <div className="text-xl w-full text-center font-bold text-geo-900">
            Filtros
          </div>
          <SimpleField
            label="Nome do agendamento"
            error={formik.touched.name && formik.errors.name}
            errorMessage={formik.errors.name}
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <SelectField
            key={keyUpdate}
            label="Tipo de agendamento"
            options={typeSchedule}
            isDisabled={formik.values.id}
            onChange={({ value }) => {
              formik.setFieldValue('key', value);
              formik.setFieldValue('type', '');
              dispatch(
                ReportsActions.setReport({ fieldsParams: [], fields: [] })
              );
            }}
            defaultValue={typeSchedule.find(
              ({ value }) => value === formik.values.key
            )}
            placeholder="Selecione o tipo de agendamento"
            noOptionsValue="Nenhum tipo de agendamento encontrado"
          />
          <SelectField
            key={formik.values.schedule.type}
            label="Recorrência"
            options={scheduleType}
            onChange={({ value }) =>
              formik.setFieldValue('schedule.type', value)
            }
            placeholder="Selecione o tipo de agendamento"
            noOptionsValue="Nenhum tipo de agendamento encontrado"
            defaultValue={scheduleType.find(
              ({ value }) => value === formik.values.schedule.type
            )}
          />
          {formik.values.key === 'report' && (
            <Reports keyUpdate={keyUpdate} formik={formik} />
          )}
          {formik.values.key === 'command' && (
            <Command keyUpdate={keyUpdate} formik={formik} />
          )}
        </div>
        <div>
          <div className="text-xl w-full text-center font-bold text-geo-900">
            Carros
          </div>
          {formik.values.key === 'command' && (
            <TrackersFilter
              formik={formik}
              title="Carros que receberam o comando"
              param="typeCars"
            />
          )}
          {formik.values.key === 'report' && (
            <ReportFields formik={formik} isSchedule />
          )}
        </div>
        <Buttons closeModal={closeModal} key={formik.values.key} />
      </Container>
      <ModalPassword
        formik={formik}
        modalPassword={modalPassword}
        setModalPassword={setModalPassword}
      />
    </Modal>
  );
}

export default ModalCalendar;
