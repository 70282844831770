import lock from '~/assets/lock.svg';

export function iconMarker(icon, rotate, alertType, plate, block) {
  const div = document.createElement('div');
  div.style.cursor = 'pointer';

  const ImageIcon = document.createElement('img');
  ImageIcon.src = icon;

  ImageIcon.style.height = '30px';
  ImageIcon.style.opacity = alertType === 'DISCONNECTED' ? '0.5' : '1';
  ImageIcon.style.width = '30px';
  ImageIcon.style.transform = `rotate(${rotate}deg)`;

  div.appendChild(ImageIcon);
  if (block) {
    const ImageAlert = document.createElement('img');
    ImageAlert.src = lock;
    ImageAlert.style.height = '20px';
    ImageAlert.style.width = '20px';
    ImageAlert.style.marginTop = '-39px';
    ImageAlert.style.marginLeft = '3px';
    ImageAlert.style.zIndex = '3';
    ImageAlert.style.position = 'relative';

    div.appendChild(ImageAlert);
  }

  const PlateCar = document.createElement('div');

  PlateCar.style.height = '17px';
  PlateCar.style.width = `${plate ? plate.length * 8 : 100}px `;

  if (alertType === 'DISCONNECTED') {
    PlateCar.style.background = 'rgba(0, 0, 0, 0.2)';
  } else if (alertType === 'ALERT') {
    PlateCar.style.background = 'rgba(255, 20,0, 0.41)';
  } else {
    PlateCar.style.background = 'rgba(0, 0, 0, 0.41)';
  }

  PlateCar.style.top = '-25px';
  PlateCar.style.left = `-${plate ? plate.length * 4 - 15 : 51}px`;
  PlateCar.style.display = 'flex';
  PlateCar.style.alignItems = 'center';
  PlateCar.style.justifyContent = 'center';
  PlateCar.style.borderRadius = '5px';
  PlateCar.style.color = '#fff';
  PlateCar.style.fontWeight = '400';
  PlateCar.style.position = 'absolute';

  PlateCar.innerHTML = plate;
  if (alertType === 'ALERT') {
    PlateCar.animate(
      [
        { boxShadow: '0 0 0 0 rgba(255, 0 ,0 , 0.4)' },
        { boxShadow: '0 0 0 10px rgba(255, 0 ,0 , 0)' },
        { boxShadow: '0 0 0 0 rgba(255, 0 ,0 , 0)' },
      ],
      {
        duration: 2000,
        iterations: Infinity,
      }
    );
  }

  div.appendChild(PlateCar);

  return div;
}
