import styled from 'styled-components';

export const ConfigRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 60px; */
  grid-gap: 20px;
  /* @media (max-width: 900px) {
    grid-template-columns: 1fr;
  } */
`;

export const TableContainer = styled.div`
  height: 200px;
  width: 100%;
`;
