import { push } from 'connected-react-router';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/driverSauce';

export function* getDriver(payload) {
  try {
    const response = yield call(api.fetchDriver, payload.query, payload.params);
    if (!_.isArray(response.data)) {
      yield put({
        type: Types.FETCH_DRIVER_ID_SUCCESS,
        data: response.data,
      });
    } else {
      yield put({
        type: Types.FETCH_DRIVER_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_FAILURE });
  }
}

export function* createDriverSaga(payload) {
  try {
    yield call(api.createDriver, payload.data);
    yield put({
      type: Types.FETCH_DRIVER_REQUEST,
    });
    toast.success('cadastrado com sucesso!');
    yield put(push('/driver'));
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_FAILURE });
    toast.error('Ocorreu um erro');
  }
}

export function* updateDriverSaga(payload) {
  try {
    yield call(api.updateDriver, payload.data, payload.id);
    yield put({
      type: Types.FETCH_DRIVER_REQUEST,
    });
    toast.success('Motorista atualizado com sucesso!');
    yield put(push('/driver'));
  } catch (error) {
    yield put({ type: Types.FETCH_DRIVER_FAILURE });
    toast.error('Algo deu errado! Tente novamente em alguns instantes.');
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_DRIVER_REQUEST, getDriver)]);
  yield all([takeEvery(Types.CREATE_DRIVER_REQUEST, createDriverSaga)]);
  yield all([takeEvery(Types.UPDATE_DRIVER_REQUEST, updateDriverSaga)]);
}
