import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { TrackersFilter } from '~/components/filters';
import { TabContainer, TabView } from '~/components/Global';

import _alertsData from './alerts.json';
import { Container, ContainerModelOptions } from './styles';

const WHATSAPP = 'whatsapp';
const EMAIL = 'email';
// const SMS = 'sms';
const alertsData = _alertsData.filter((i) => i.label !== 'ALERT_WEEKEND');

function Alerts({ formik }) {

  const methodOptions = [
    { label: 'Whatsapp', value: WHATSAPP },
    { label: 'Email', value: EMAIL },
    // { label: 'sms', value: SMS },
  ];

  const [activeAction, setActiveAction] = useState(0);
  function applyToAll(value) {
    if (value === null) {
      alertsData.forEach(({ label }) => formik.setFieldValue(label, []));
    } else {
      alertsData.forEach(({ label }) => formik.setFieldValue(label, [value]));
    }
  }

  return (
    <Container>
      <header>Configuração de alertas</header>
      <TrackersFilter formik={formik} title="Modo de alerta" />

      <div>
        <div>
          <span> Aplicar a todas as opções abaixo: </span>
          <TabContainer>
            <TabView
              onMouseLeave={() => setActiveAction(0)}
              onMouseEnter={() => setActiveAction(1)}
              active={activeAction !== 1}
              onClick={() => applyToAll(WHATSAPP)}
            >
              WHATSAPP
            </TabView>
            <TabView
              onMouseLeave={() => setActiveAction(0)}
              onMouseEnter={() => setActiveAction(3)}
              active={activeAction !== 3}
              onClick={() => applyToAll(EMAIL)}
            >
              EMAIL
            </TabView>
            {/* <TabView
              onMouseLeave={() => setActiveAction(0)}
              onMouseEnter={() => setActiveAction(2)}
              active={activeAction !== 2}
              onClick={() => applyToAll(SMS)}
            >
              SMS
            </TabView>
             */
            }
            <TabView
              onMouseLeave={() => setActiveAction(0)}
              onMouseEnter={() => setActiveAction(4)}
              active={activeAction !== 4}
              onClick={() => applyToAll(null)}
            >
              LIMPAR
            </TabView>
          </TabContainer>
        </div>
        {alertsData.map(({ name, label }) => (
          <ContainerModelOptions>
            <header>{name}</header>
            {methodOptions.map(({ label: methodLabel, value: methodValue }) => (
              <label htmlFor={`${label}_${methodValue}`}>
                <input
                  onChange={({ target: { checked } }) => {
                    let values = formik.values[label] || [];
                    if (checked) {
                      values = values.filter((item) => item !== methodValue);
                      values.push(methodValue);
                    } else {
                      values = values.filter((item) => item !== methodValue);
                    }
                    formik.setFieldValue(label, values);
                  }}
                  id={`${label}_${methodValue}`}
                  value={methodValue}
                  checked={(formik.values[label] || []).includes(methodValue)}
                  type="checkbox"
                />
                <span>{methodLabel}</span>
              </label>
            ))}
          </ContainerModelOptions>
        ))}
      </div>
    </Container>
  );
}

Alerts.propTypes = {
  formik: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Alerts;
