import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import itemsData from './itemsData';
import ItemSide from './ItemSide';
import { Container } from './styles';

function Side() {
  const [editablePermission, setEditablePermission] = useState(false);
  const token = useSelector((state) => state.authState.token);
  const me = useSelector((state) => state.globalState.me);
  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  return (
    <>
      {token ? (
        <Container className="w-full bg-white dark:bg-gray-600 flex flex-col items-center  rounded-lg shadow">
          {itemsData({ editablePermission })
            .filter(({ visible }) => visible)
            .map(({ icon, tooltip, to, selected }) => (
              <ItemSide
                key={`itemSide_${to}`}
                icon={icon}
                tooltip={tooltip}
                to={to}
                selected={selected}
              />
            ))}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}

export default Side;
