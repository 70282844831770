import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchAuditRequest: ['query'],
  fetchAuditSuccess: ['data'],
  fetchAuditFailure: [],
});

const INITIAL_STATE = {
  audits: [],
  loading: false,
};

const fetchAuditRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchAuditSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  audits: actions.data,
  loading: false,
});

const fetchAuditFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    audits: { payload: [] },
  };
};

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_AUDIT_REQUEST]: fetchAuditRequest,
  [Types.FETCH_AUDIT_SUCCESS]: fetchAuditSuccess,
  [Types.FETCH_AUDIT_FAILURE]: fetchAuditFailure,
});
