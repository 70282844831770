import React from 'react';

import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';

import history from '~/services/history';

import 'rc-tooltip/assets/bootstrap.css';

export default function Side({ to, tooltip, selected, icon }) {
  return (
    <Tooltip
      placement="right"
      overlay={tooltip}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      arrowContent={<div className="rc-tooltip-arrow-inner" />}
    >
      <button
        type="button"
        className={`w-8 h-8 my-1 rounded-lg hover:bg-gray-200 ${
          selected ? 'bg-gray-300' : 'opacity-40'
        } flex items-center justify-center`}
        onClick={() => history.push(to)}
      >
        <img className="h-6 w-6" src={icon} alt="Icones" />
      </button>
    </Tooltip>
  );
}

Side.propTypes = {
  to: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};
