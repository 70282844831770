import React from 'react';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { MaskedInput } from '~/components/inputs';

import { Container } from './styles';

function Masked({ label, id = `_input${nanoid()}`, ...props }) {
  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <MaskedInput id={id} {...props} />
    </Container>
  );
}

Masked.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Masked;
