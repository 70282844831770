import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchPreJourneyRequest: ['query', 'params'],
  fetchPreJourneySuccess: ['data'],
  fetchPreJourneyIdSuccess: ['data'],
  fetchPreJourneyFailure: [],
  updatePreJourneyRequest: ['data'],
  createPreJourneyRequest: ['data'],
  clearPreJourney: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  preJourneys: [],
  preJourney: {},
  preJourneyByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearPreJourney = (state = INITIAL_STATE) => ({
  ...state,
  preJourney: {},
});

const fetchPreJourneyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchPreJourneySuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  preJourneys: actions.data,
  loading: false,
});

const fetchPreJourneyIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    preJourney: actions.data,
    loading: false,
  };
};

const fetchPreJourneyFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    preJourneys: [],
  };
};

const createPreJourneyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updatePreJourneyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_PRE_JOURNEY_REQUEST]: fetchPreJourneyRequest,
  [Types.FETCH_PRE_JOURNEY_SUCCESS]: fetchPreJourneySuccess,
  [Types.FETCH_PRE_JOURNEY_ID_SUCCESS]: fetchPreJourneyIdSuccess,
  [Types.FETCH_PRE_JOURNEY_FAILURE]: fetchPreJourneyFailure,
  [Types.UPDATE_PRE_JOURNEY_REQUEST]: updatePreJourneyRequest,
  [Types.CREATE_PRE_JOURNEY_REQUEST]: createPreJourneyRequest,
  [Types.CLEAR_PRE_JOURNEY]: clearPreJourney,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
