import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { Creators as FenceActions } from '~/store/ducks/fenceSauce';

export function saveZoneFence(controlDiv, map, { fence }, dispatch) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#555555';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginTop = '6px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'SALVAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    map.data.toGeoJson((obj) => {
      const data = {
        id: fence.id,
        name: fence.name,
        geojson: obj.features[0],
      };
      dispatch(FenceActions.updateFenceRequest(data));
    });
  });
}

export function cancelZone(controlDiv, map, { fence }) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#E33939';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'CANCELAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    map.data.forEach((feature) => {
      map.data.remove(feature);
    });
    map.data.addGeoJson(fence.geojson);
  });
}

export function deleteZoneFence(dispatch, controlDiv) {
  const controlUI = document.createElement('div');
  controlUI.style.backgroundColor = '#E33939';
  controlUI.style.borderRadius = '3px';
  controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.1)';
  controlUI.style.cursor = 'pointer';
  controlUI.style.height = '35px';
  controlUI.style.width = '100px';
  controlUI.style.marginRight = '6px';
  controlUI.style.marginBottom = '6px';
  controlDiv.appendChild(controlUI);

  const controlText = document.createElement('span');
  controlText.innerHTML = 'DELETAR';
  controlText.style.fontWeight = 300;
  controlText.style.color = '#fff';
  controlText.style.display = 'flex';
  controlText.style.justifyContent = 'center';
  controlText.style.alignItems = 'center';
  controlText.style.height = '100%';
  controlText.style.fontSize = '13px';

  controlUI.appendChild(controlText);

  controlUI.addEventListener('click', () => {
    dispatch(FenceActions.setModalDelete());
  });
}

const mapStateToProps = (state) => ({
  fences: state.fenceState.fences,
  fence: state.fenceState.fence,
  loading: state.fenceState.loading,
  viewModal: state.fenceState.viewModal,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FenceActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(deleteZoneFence);
