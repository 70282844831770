import styled from 'styled-components';

export const Container = styled.div`
  grid-area: detail;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  padding: 10px;
  > header {
    background: #f4f8f9;
    font-size: 20px;
    color: #245378;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      > img {
        width: 200px;
      }
    }
  }
`;

export const Cardetail = styled.div`
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid;
  padding-top: 10px;
`;
