import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-rows: 3rem 1fr;
  height: 100%;
`;
function Form({ children }) {
  return <Container>{children}</Container>;
}

Form.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Form;
