import React from 'react';
import Select from 'react-select';

import { darken, lighten } from 'polished';
import PropTypes from 'prop-types';

function Simple({
  borderRadius,
  background,
  height,
  width,
  error,
  margin,
  color,
  ...props
}) {
  const customStyles = {
    container: (styles) => ({
      ...styles,
      width,
      margin,
    }),
    control: (styles) => ({
      ...styles,
      minWidth: '100px',
      width,
      height,
      borderRadius,
      background: error ? 'rgba(255,0,0,0.2)' : background,
      color: error ? 'rgba(255,0,0,1)' : color,
      border: error ? '1px solid red' : '1px solid #ddd',
      display: 'flex',
      position: 'relative',
    }),
    placeholder: (styles) => ({
      ...styles,
      color: error ? 'rgba(255,0,0,1)' : color,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: error ? 'rgba(255,0,0,1)' : color,
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      background: error ? 'rgba(255,0,0,1)' : color,
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: '5px',
      position: 'absolute',
      zIndex: '99999',
      width: '100%',
      background,
      borderRadius: '3px',
      border: '1px solid #ddd',
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      let backgroundColor = background;
      let colorText = color;
      let cursor = 'pointer';
      if (isDisabled) {
        backgroundColor = lighten(0.2, background);
        cursor = 'not-allowed';
        colorText = lighten(0.1, background);
      }
      if (isFocused) {
        backgroundColor = darken(0.1, background);
      }
      if (isSelected) {
        backgroundColor = darken(0.2, background);
      }
      return {
        ...styles,
        fontSize: '12px',
        color: colorText,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '5px',
        cursor,
        width: '100%',
        height: '30px',
        transition: 'all 0.2s',
        background: backgroundColor,
      };
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  return <Select {...props} styles={customStyles} />;
}

Simple.propTypes = {
  borderRadius: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  error: PropTypes.bool,
};

Simple.defaultProps = {
  borderRadius: '10px',
  background: '#fff',
  color: '#333',
  height: '40px',
  width: '100%',
  margin: '5px 0',
  error: false,
};

export default Simple;
