import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchReportRequest: ['url', 'params'],
  createReportRequest: ['url', 'params'],
  fetchReportSuccess: ['data'],
  createReportSuccess: ['dataBlob'],
  fetchReportFailure: [],
  setReport: ['report'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  loading: false,
  dataBlob: '',
  data: [],
  params: {},
  report: { fieldsParams: [], fields: [] },
};

const fetchReportRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: true,
  };
};
const createReportRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchReportSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
});
const createReportSuccess = (state = INITIAL_STATE, { dataBlob }) => ({
  ...state,
  dataBlob,
  loading: false,
});

const fetchReportFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  data: [],
  // report: { fieldsParams: [], fields: [] },
});

const setReport = (state = INITIAL_STATE, { report }) => ({
  ...state,
  report,
});

const setParams = (state = INITIAL_STATE, { params }) => ({
  ...state,
  params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_REPORT_REQUEST]: fetchReportRequest,
  [Types.CREATE_REPORT_REQUEST]: createReportRequest,
  [Types.FETCH_REPORT_SUCCESS]: fetchReportSuccess,
  [Types.CREATE_REPORT_SUCCESS]: createReportSuccess,
  [Types.FETCH_REPORT_FAILURE]: fetchReportFailure,
  [Types.SET_REPORT]: setReport,
  [Types.SET_PARAMS]: setParams,
});
