import React, { useEffect, useState } from 'react';
import Modal from 'react-awesome-modal';

import _ from 'lodash';
import PropTypes from 'prop-types';

import mapStyle from '~/assets/map.json';
import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
} from '~/components/Global';

function Map({ modalView, setModalView, data }) {
  const [map, setMap] = useState('');
  const [markerN, setMarkerN] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setModalView(true);
      if (!_.isEmpty(markerN)) {
        markerN.setMap(null);
      }
      const infowindow = new window.google.maps.InfoWindow({
        content: `<div>
        <div><b>Coordenada:</b> ${data.lat}, ${data.lng}</div>
        <div><b>Iginição:</b> ${data.ignition}</div>
        <div><b>Velocidade:</b> ${data.speed}</div>
        <div><b>Momento:</b> ${data.date}</div>
        <div><b>Momento:</b> ${data.address}</div>
        </div>`,
      });

      const markerNew = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data.lat, data.lng),
        map,
      });
      setMarkerN(markerNew);
      infowindow.open(map, markerNew);
    }
  }, [data]);

  useEffect(() => {
    const mapInit = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: true,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 13,
      controlSize: 25,
      styles: mapStyle,
      mapId: 'c1a7381ec3f56b88'
    });
    setMap(mapInit);
    setModalView(false);
  }, []);

  return (
    <Modal
      visible={modalView}
      height="380"
      width="500"
      effect="fadeInUp"
      onClickAway={() => setModalView(false)}
      // onRequestClose={() => props.setViewModal()}
    >
      <ModalContainer>
        <ModalHeader>
          <ModalTextHeader>Mapa</ModalTextHeader>
        </ModalHeader>
        <div style={{ height: '100%', width: '100%' }} id="map" />
      </ModalContainer>
    </Modal>
  );
}

Map.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object
  ).isRequired,
  modalView: PropTypes.bool.isRequired,
  setModalView: PropTypes.func.isRequired,
};

export default Map;
