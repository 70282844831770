import { call, put, all, takeEvery, select } from 'redux-saga/effects';

import Api from '~/services/api';

import { Types } from '../ducks/bufferSauce';
import { getGroupSelected, getFilterActive } from '../selectors';

export function* getBuffer() {
  try {
    const id = localStorage.getItem('client');
    const buffer = yield call(Api.fetchBuffer, id && `?client_id=${id}`);

    const groupSelected = yield select(getGroupSelected);
    const filterActive = yield select(getFilterActive);
    if (groupSelected && groupSelected.value) {
      yield put({
        type: Types.BUFFER_SUCCESS,
        data: buffer.data,
        filtered: buffer.data.filter((item) =>
          groupSelected.id_trackers.find(
            (tracker) => tracker === item.tracker_id
          )
        ),
        filterActive,
      });
    } else {
      yield put({
        type: Types.BUFFER_SUCCESS,
        data: buffer.data,
        filterActive,
      });
    }
  } catch (error) {
    yield put({ type: Types.BUFFER_FAILURE });
  }
}

export function* groupSaga() {
  try {
    const groups = yield call(Api.fetchGroups);
    yield put({
      type: Types.GROUP_SUCCESS,
      groups: groups.data,
    });
  } catch (error) {
    yield put({ type: Types.GROUP_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.BUFFER_REQUEST, getBuffer)]);
  yield all([takeEvery(Types.GROUP_REQUEST, groupSaga)]);
}
