import React from 'react';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { UsersSelect } from '~/components/selects';

import { Container } from './styles';

function Fences({ label, id = `_select${nanoid()}`, ...props }) {
  return (
    <Container>
      <label htmlFor={id}>{label}</label>
      <UsersSelect id={id} {...props} />
    </Container>
  );
}

Fences.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Fences;
