import PropTypes from 'prop-types';
import React from 'react';

import { MaskedField } from '~/components/fields';

import { Container } from './styles';
import { SimpleSelect } from '~/components/selects';

const colors = [
  { value: 'blue', title: 'AZUL' },
  { value: 'yellow', title: 'AMARELO' },
  { value: 'orange', title: 'LARANJA' },
];
function TrackerConfig({ formik, editablePermission }) {
  return (
    <Container>
      <div>
        <MaskedField
          label={`VELOCIDADE MÁXIMA ${
            (formik.values.speed_max && '( Km/h )') || ''
          }`}
          mask="999"
          name="speed_max"
          onChange={formik.handleChange}
          value={formik.values.speed_max}
          inputValue={formik.values.speed_max}
          disabled={!editablePermission}
          placeholder="em Km/h"
        />
        <MaskedField
          label={`ALERTA DE VEÍCULO OCIOSO ${
            (formik.values.idle_time && '( minutos )') || ''
          }`}
          mask="999"
          name="idle_time"
          onChange={formik.handleChange}
          value={formik.values.idle_time}
          inputValue={formik.values.idle_time}
          disabled={!editablePermission}
          placeholder="em minutos"
        />
        <SimpleSelect
          error={formik.touched.icon_color && formik.errors.icon_color}
          defaultValue={colors.find(
            (color) => color.value === formik.values.icon_color
          )}
          onChange={(e) => formik.setFieldValue('icon_color', e?.value || '')}
          placeholder={'Selecione uma cor para o ícone do veículo'}
          getOptionValue={({ value }) => value}
          getOptionLabel={({ title }) => title}
          isClearable
          options={colors}
        />
      </div>
    </Container>
  );
}

TrackerConfig.propTypes = {
  formik: PropTypes.objectOf.isRequired,
  editablePermission: PropTypes.bool,
};

TrackerConfig.defaultProps = {
  editablePermission: false,
};

export default TrackerConfig;
