export default {
  id: '',
  tracker_id: '',
  key: 'report',
  name: '',
  owner: [],
  type: '',
  params: {},
  typeCars: 'cars',
  days: '',
  group: null,
  cars: [],
  schedule: {
    type: 'once',
    date: new Date(),
    dateLock: '',
    dateUnlock: '',
    time: '',
    timeLock: '',
    timeUnlock: '',
    week: '',
    weekLock: '',
    weekUnlock: '',
  },
  password: '',
};
