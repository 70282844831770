import * as pages from '~/pages';

const exact = true;

export default [
  { path: '/monitoring', component: pages.Monitoring },
  { path: '/monitoringFullscreen', component: pages.Monitoring },

  /// DRIVER
  { path: '/driver/form/:id', component: pages.FormDriver },
  { path: '/driver/form', component: pages.FormDriver },
  { path: '/driver', component: pages.Driver },

  /// RESPONSIBLE
  { path: '/responsible/form/:id', component: pages.FormResponsible },
  { path: '/responsible/form', component: pages.FormResponsible },
  { path: '/responsible', component: pages.Responsible },

  { path: '/passenger/form/:id', component: pages.FormPassenger },
  { path: '/passenger/form', component: pages.FormPassenger },
  { path: '/passenger', component: pages.Passenger },
  /// MAINTENANCE
  { path: '/maintenance/form/:id', component: pages.FormMaintenance },
  { path: '/maintenance/form', component: pages.FormMaintenance },
  { path: '/maintenance', component: pages.Maintenance, exact }, // ##
  /// PREVENTIVE
  { path: '/preventive/form/:id', component: pages.FormPreventive },
  { path: '/preventive/form', component: pages.FormPreventive },
  { path: '/preventive', component: pages.Preventive }, // ##
  /// SUPLY
  { path: '/supply/form/:id', component: pages.FormSupply },
  { path: '/supply/form', component: pages.FormSupply },
  { path: '/supply', component: pages.Supply, exact },
  /// TRACKER
  { path: '/tracker/form/:id', component: pages.FormTracker, exact },
  { path: '/tracker', component: pages.Tracker, exact },
  /// VIOLATION
  { path: '/violation/form/:id', component: pages.FormViolation },
  { path: '/violation/form', component: pages.FormViolation },
  { path: '/violation', component: pages.Violation },
  /// JOURNEY
  { path: '/journey/form/:id', component: pages.FormJourney },
  { path: '/prejourney/form/:id', component: pages.FormPreJourney },
  { path: '/journey/form', component: pages.FormJourney },
  { path: '/journey', component: pages.Journey },
  /// ROUTE
  { path: '/route/form/:id', component: pages.MapTrip }, // ##
  { path: '/route/form', component: pages.MapTrip, exact }, // ##
  { path: '/route', component: pages.Trip, exact },
  /// WORKSHEET
  { path: '/worksheet/form/:id', component: pages.FormWorksheet },
  { path: '/worksheet/form', component: pages.FormWorksheet },
  { path: '/worksheet', component: pages.Worksheet }, // ##

  { path: '/alert', component: pages.Alert, exact },
  { path: '/changepassword', component: pages.ChangePassword }, // ##
  { path: '/daily', component: pages.Daily, exact },
  { path: '/command', component: pages.Command, exact },
  { path: '/reports', component: pages.Reports },
  { path: '/preferences', component: pages.Preferences, exact },
  { path: '/profile', component: pages.Profile },
  { path: '/fence', component: pages.Fence },
  { path: '/audit', component: pages.Audit },
  { path: '/settings', component: pages.Settings },
  { path: '/releases', component: pages.Releases },
  { path: '/schedules', component: pages.Schedules },
];
