import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import Modal from 'react-awesome-modal';

import format from 'date-fns/format';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
} from '~/components/Global';
import TableComponent from '~/components/Table';

function Map({ modalView, setModalView, data: { history, name } }) {
  const [dataTable, setDataTable] = useState([]);
  const [height, setHeight] = useState(300);
  const ref = useRef();
  useEffect(() => {
    if (!_.isEmpty(history)) {
      // console.log({ history });
      setModalView(true);
      setDataTable(history);
    }
  }, [history]);

  useLayoutEffect(() => {
    if (modalView) setHeight(ref.current.offsetHeight - 72);
  }, [modalView]);

  return (
    <Modal
      visible={modalView}
      height="80%"
      width="80%"
      effect="fadeInUp"
      onClickAway={() => setModalView(false)}
    >
      <ModalContainer className="p-4" ref={ref}>
        <ModalHeader>
          <ModalTextHeader>Diáro de bordo Detalhado</ModalTextHeader>
        </ModalHeader>
        <ModalHeader>
          <ModalTextHeader>Motorista: {name}</ModalTextHeader>
        </ModalHeader>
        <TableComponent
          dimensionHeight={height}
          data={dataTable.map(({ inicio, fim, ...item }) => ({
            inicio: format(new Date(inicio), 'dd/MM/yyyy HH:mm:ss'),
            fim: fim ? format(new Date(fim), 'dd/MM/yyyy HH:mm:ss') : '-',
            ...item,
          }))}
          headerBackground="#245378"
          labels={['Descrição', 'Início', 'Fim', 'Duração']}
          headers={['descricao', 'inicio', 'fim', 'tempo']}
          groupType={['simple', 'simple', 'simple', 'simple']}
          fileTitle={
            'Relatório detalhado diário de bordo \n Motorista: ' + name
          }
          getData={(data) => console.log(data)}
        />
      </ModalContainer>
    </Modal>
  );
}

Map.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  modalView: PropTypes.bool.isRequired,
  setModalView: PropTypes.func.isRequired,
};

export default Map;
