import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import SimpleSelect from './Simple';
import { LoadingContainer } from './styles';

function Users({ ...props }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getUsers() {
    setLoading(true);

    try {
      const client = localStorage.getItem('client');
      const { data } = await api.fetchUser(client);
      setOptions(data);
      setLoading(false);
    } catch (erro) {
      setLoading(false);
      toast.error('Erro ao consultar os usuários! Tente novamente mais tarde.');
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingContainer {...props}>Loading</LoadingContainer>
      ) : (
        <SimpleSelect
          getOptionValue={({ id }) => id}
          getOptionLabel={({ name }) => name}
          emptyMessage="Nenhum registro encontrado"
          options={options}
          placeholder="Selecione o usuário"
          {...props}
        />
      )}
    </>
  );
}

export default Users;
