import React from 'react';
import { MdClear, MdCheck, MdChecklist } from 'react-icons/md';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { IconButton, SimpleButton } from '~/components/buttons';
import history from '~/services/history';
import { Creators as TrackerActions } from '~/store/ducks/trackerSauce';

export default function Header({ editablePermission, loading, actions }) {
  const dispatch = useDispatch();
  function cancelButton() {
    history.push('/tracker');
    dispatch(TrackerActions.clearTracker());
  }
  if (editablePermission) {
    if (loading) {
      return (
        <SimpleButton disabled width="w-46" height="h-8" type="button">
          Aguarde
        </SimpleButton>
      );
    }
    return (
      <div className="grid w-90 grid-cols-3 gap-2 h-8">
        <IconButton
          margin="0px"
          style={{ backgroundColor: '#f2f1f0', color: '#605F5F' }}
          colorIcon="#605F5F"
          Icon={MdChecklist}
          size={15}
          type="button"
          onClick={() => actions.showModal()}
          title="replicar as configurações deste veículos para um grupo de veículos"
        >
          Replicar Configuração
        </IconButton>
        <IconButton
          margin="0px"
          background="bg-red-600"
          Icon={MdClear}
          size={15}
          type="button"
          onClick={() => cancelButton()}
        >
          Cancelar
        </IconButton>
        <IconButton
          margin="0px"
          background="bg-gray-600"
          Icon={MdCheck}
          size={15}
          type="submit"
        >
          Salvar
        </IconButton>
      </div>
    );
  }
  return (
    <IconButton
      type="button"
      width="w-46"
      height="h-8"
      Icon={MdCheck}
      size={15}
      onClick={() => cancelButton()}
    >
      Voltar
    </IconButton>
  );
}

Header.propTypes = {
  editablePermission: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: null,
};

Header.defaultProps = {
  actions: {},
};
