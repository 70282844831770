import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchPassengerRequest: ['query', 'params'],
  fetchPassengerSuccess: ['data'],
  fetchPassengerIdSuccess: ['data'],
  fetchPassengerFailure: [],
  updatePassengerRequest: ['data', 'id'],
  deletePassengerRequest: ['id'],
  createPassengerRequest: ['data'],
  clearPassenger: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  passengers: [],
  passenger: {},
  passengerByTracker: {},
  loading: false,
  page: 1,
  pageSize: 2,
  params: '',
};

const clearPassenger = (state = INITIAL_STATE) => ({
  ...state,
  passenger: {},
});

const fetchPassengerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  passengers: [],
});

const fetchPassengerSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  passengers: actions.data,
  loading: false,
});

const fetchPassengerIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    passenger: actions.data,
    loading: false,
  };
};

const fetchPassengerFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    passengers: [],
  };
};

const createPassengerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updatePassengerRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_PASSENGER_REQUEST]: fetchPassengerRequest,
  [Types.FETCH_PASSENGER_SUCCESS]: fetchPassengerSuccess,
  [Types.FETCH_PASSENGER_ID_SUCCESS]: fetchPassengerIdSuccess,
  [Types.FETCH_PASSENGER_FAILURE]: fetchPassengerFailure,
  [Types.UPDATE_PASSENGER_REQUEST]: updatePassengerRequest,
  [Types.CREATE_PASSENGER_REQUEST]: createPassengerRequest,
  [Types.CLEAR_PASSENGER]: clearPassenger,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
