import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Creators as AlertActions } from '~/store/ducks/alertSauce';

import Filter from './Filter';
import List from './List';
import { Container } from './styles';

function AlertNew() {
  const dispatch = useDispatch();
  const queryParams = useSelector((state) => state.alertState.params);
  useEffect(() => {
    setInterval(() => {
      if (queryParams) {
        dispatch(AlertActions.fetchAlertRequest(queryParams));
      }
    }, 60000);
  }, [queryParams]);

  return (
    <Container>
      <Filter />
      <List />
    </Container>
  );
}

export default AlertNew;
