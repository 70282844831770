import styled, { css } from 'styled-components';

export const CarContainer = styled.div`
  background: #f2f1f0;
  margin: 2px 0px 2px 0px;
  color: #333;
  cursor: pointer;
  display: grid;
  grid-template:
    'vehicle drive' 1fr
    / 1fr 2fr;
  grid-template-columns: 3fr 1fr;
  ${({ isDashboard }) =>
    isDashboard &&
    css`
      opacity: ${({ selected }) => (selected ? '1' : '0.3')};
    `}
`;

export const Vehicle = styled.div`
  grid-area: vehicle;
  display: grid;
  grid-template-columns: 60px 1fr;
  justify-content: center;
  align-items: center;
  padding: 5px;
  > div {
    margin-left: 5px;
  }
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Drive = styled.div`
  grid-area: drive;
  display: grid;
  grid-template:
    'monitor' 1fr
    / 1fr;
  background: #fafafa;
  font-size: 13px;
  padding-right: 2px;
  img {
    height: 35px;
  }
`;

export const Label = styled.div`
  font-size: 13px;
  font-weight: bold;
  img {
    height: 15px;
    margin-right: 5px;
  }
`;

export const Driver = styled.div`
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 132px;
`;

export const Triangle = styled.div`
  width: 18px;
  height: 0;
  border-top: 9px solid ${({ color }) => color || '#666'};
  border-right: 8px solid transparent;
  position: absolute;
`;

export const MonitorContainer = styled.div`
  grid-area: monitor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  > img {
    max-height: 20px;
  }
  > div {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 10px;
    }
  }
`;

export const Block = styled.img`
  width: 20px;
  position: absolute;
  height: 15px;
  right: 0;
  margin-top: -6px;
  margin-right: -4px;
`;
