import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';

export const Container = styled.div``;

export const InputCustom = styled(InputMask)`
  border-radius: 500px !important;
  height: 35px !important;
`;

export const DateStyle = styled(DatePicker)`
  height: 33.2px;
  border: 1px solid #dbdbdb;
  width: 100%;
  border-radius: 3px;
  padding-left: 5px;
  color: #999;
  font-size: 14px;
`;

export const Form = styled.form`
  display: flex;
  /* width: 300px; */
`;
