import styled from 'styled-components';

import { ButtonStyled, InputStyled } from '~/components/Global';

export const Container = styled.form`
  height: 100%;
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-gap: 7px;
`;

export const ContainerButtons = styled.div`
  grid-area: footer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const Button = styled(ButtonStyled)`
  border: none;
  height: 20px;
  margin: 5px;
  padding: 5px;
  background: ${(porps) => porps.color};
  text-transform: uppercase;
  border-radius: 5px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    color: #fff;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const ContainerGroup = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerCars = styled.div`
  border-radius: 7px;
  grid-area: main;
`;

export const Inputstyle = styled(InputStyled)`
  width: 100%;
  margin-bottom: 0;
  height: 30px;
  border: ${(props) => (props.error ? '1px solid #E33939' : '0')};
  background: ${(props) => (props.error ? '#fcd7d7' : '#fff')};
`;

export const ContainerInput = styled.div`
  background: #f2f1f0;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  grid-area: header;
`;

export const Formstyle = styled.form`
  height: 100%;
`;

export const ListHeader = styled.div`
  background: #c4c4c4;
  font-size: 15px;
  font-weight: 600;
  padding: 5px;
`;

export const CheckContainer = styled.div`
  padding: 5px;
`;
