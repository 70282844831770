import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { decode } from 'jwt-simple';

import PropTypes from 'prop-types';

// import { BsGearWide } from 'react-icons/ri';
// import { MdLockOpen, MdCog } from 'react-icons/md';
import { BsGearWide } from 'react-icons/bs';
import { BiLockOpenAlt } from 'react-icons/bi';

import lock from '~/assets/lock.svg';
import history from '~/services/history';

import 'moment/locale/pt-br';
import colorCar from './colorCar';
import Status from './Status';
import {
  Block,
  CarContainer,
  Drive,
  Driver,
  Label,
  MonitorContainer,
  Triangle,
  Vehicle,
} from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function CarItem({
  tracker_id,
  status,
  last_time_on,
  date,
  output1,
  label,
  driver,
  ignition,
  rpm,
  speed,
  image,
  battery,
  device,
}) {
  const [color, setColor] = useState('#222');
  const [block] = useState(!!output1);

  const query = useQuery();

  function setDashboard() {
    if (Number(tracker_id) === Number(query.get('id'))) {
      history.push('/monitoring');
    } else {
      history.push(`/monitoring?id=${tracker_id}`);
    }
  }
  useEffect(() => {
    setColor(colorCar({ status, ignition }));
  }, [status, ignition]);

  const token = localStorage.getItem('token');
  const isGeo = token && decode(token, '', true).cli === 2;

  return (
    <CarContainer
      onClick={() => setDashboard()}
      isDashboard={!!query.get('id')}
      selected={Number(tracker_id) === Number(query.get('id'))}
    >
      <Triangle color={color} />
      {block && <Block src={lock} />}
      <div>
        <BsGearWide
          title="veículo com bloqueio instalado"
          size="22"
          color="#c2c2c2"
          style={{
            display: isGeo && device?.output1 ? 'block' : 'none',
            position: 'absolute',
            left: '202px',
            minWidth: '22px',
            // filter: 'drop-shadow(0px 0px 3px #000)',
            background: 'radial-gradient(#8a8a8a 46%, #FFF0 46%)',
          }}
        />
        <BiLockOpenAlt
          title="veículo com bloqueio instalado"
          size="12"
          color="#ededed"
          style={{
            display: isGeo && device?.output1 ? 'block' : 'none',
            position: 'absolute',
            left: '207px',
            marginTop: '4.5px',
            minWidth: '12px',
            filter: 'drop-shadow(0px 0px 3px #FFFF)',
            // background: 'radial-gradient(#F00F, #0000 55%)',
          }}
        />

        <Vehicle style={{ position: 'relative' }}>
          <span>
            <img
              src={`${process.env.REACT_APP_SERVER_FILE}/${image}`}
              className="h-5"
              alt="Veículo"
            />
          </span>
          <div>
            <Label>{label}</Label>
            <Driver>{driver || 'Sem motorista vinculado'}</Driver>
          </div>
        </Vehicle>
      </div>
      <Drive title={!battery ? 'Bateria Principal Desconectada' : ''}>
        <MonitorContainer>
          <Status
            status={status}
            ignition={ignition}
            last_time_on={last_time_on}
            speed={speed}
            rpm={rpm}
            date={date}
            battery={battery}
          />
        </MonitorContainer>
      </Drive>
    </CarContainer>
  );
}

CarItem.propTypes = {
  tracker_id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  last_time_on: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  output1: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  driver: PropTypes.string.isRequired,
  ignition: PropTypes.bool.isRequired,
  rpm: PropTypes.number,
  speed: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  battery: PropTypes.number,
};

CarItem.defaultProps = {
  rpm: 0,
  battery: 0,
};
