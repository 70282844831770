import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchViolationRequest: ['query', 'params'],
  fetchViolationSuccess: ['data'],
  fetchViolationIdSuccess: ['data'],
  fetchViolationFailure: [],
  updateViolationRequest: ['data'],
  createViolationRequest: ['data'],
  clearViolation: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  violations: [],
  violation: {},
  violationByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearViolation = (state = INITIAL_STATE) => ({
  ...state,
  violation: [],
});

const fetchViolationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchViolationSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  violations: actions.data,
  loading: false,
});

const fetchViolationIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    violation: actions.data,
    loading: false,
  };
};

const fetchViolationFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    violations: [],
  };
};

const createViolationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateViolationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_VIOLATION_REQUEST]: fetchViolationRequest,
  [Types.FETCH_VIOLATION_SUCCESS]: fetchViolationSuccess,
  [Types.FETCH_VIOLATION_ID_SUCCESS]: fetchViolationIdSuccess,
  [Types.FETCH_VIOLATION_FAILURE]: fetchViolationFailure,
  [Types.UPDATE_VIOLATION_REQUEST]: updateViolationRequest,
  [Types.CREATE_VIOLATION_REQUEST]: createViolationRequest,
  [Types.CLEAR_VIOLATION]: clearViolation,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
