import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import pt from 'date-fns/locale/pt';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { CarsCheckBox } from '~/components/checkBox';
import {
  MaskedField,
  TrackersField,
  DateField,
  FencesField,
  DriverField,
  UserField,
} from '~/components/fields';
import { MaskedInput } from '~/components/inputs';
import { GroupRadio } from '~/components/radio';

import modeOptions from './modeOptions.json';

function ReportFields({ formik, isSchedule }) {
  const className = isSchedule
    ? 'grid grid-cols-4 gap-2'
    : 'grid grid-cols-2 gap-2';
  const ref = useRef();
  const [height, setHeight] = useState(300);
  const { fieldsParams } = useSelector(({ reportState }) => reportState.report);

  useEffect(() => {
    if (!_.isEmpty(fieldsParams)) {
      setHeight(0);
      const fields = fieldsParams.filter((field) => field !== 'group').length;
      const dimension = ref.current.offsetHeight - 40 - Number(fields) * 71;
      setHeight(dimension);
    }
  }, [fieldsParams]);

  return (
    <div className="h-full" id={`_fields${nanoid()}`} ref={ref}>
      {fieldsParams.find((field) => field === 'daysOffline') && (
        <MaskedField
          label="Dias offline"
          mask="9999"
          onChange={(e) => formik.setFieldValue('days', Number(e.target.value))}
          id="days"
          value={formik.values.days}
        />
      )}
      {fieldsParams.find((field) => field === 'trackers') && (
        <TrackersField
          label="Veículos"
          onChange={({ value }) => formik.setFieldValue('tracker_id', value)}
          error={formik.touched.tracker_id && formik.errors.tracker_id}
        />
      )}
      {fieldsParams.find((field) => field === 'driver') && (
        <DriverField
          label="Motorista"
          onChange={({ id }) => formik.setFieldValue('driverId', id)}
          error={formik.touched.fenceId && formik.errors.fenceId}
        />
      )}
      {fieldsParams.find((field) => field === 'user') && (
        <UserField
          label="Usuário"
          onChange={({ id }) => formik.setFieldValue('userId', id)}
          error={formik.touched.userId && formik.errors.userId}
        />
      )}
      {fieldsParams.find((field) => field === 'fence') && (
        <FencesField
          label="Cerca"
          onChange={({ id }) => formik.setFieldValue('fenceId', id)}
          error={formik.touched.fenceId && formik.errors.fenceId}
        />
      )}
      {fieldsParams.find((field) => field === 'start') && !isSchedule && (
        <DateField
          label="Data início"
          showTimeSelect
          dateFormat="dd/MM/yyyy HH:mm:ss"
          locale={pt}
          isRelative={false}
          selected={formik.values.start}
          onChange={(value) => formik.setFieldValue('start', value)}
          customInput={<MaskedInput mask="99/99/9999 99:99" />}
        />
      )}
      {fieldsParams.find((field) => field === 'finish') && !isSchedule && (
        <DateField
          isRelative={false}
          label="Data fim"
          showTimeSelect
          dateFormat="dd/MM/yyyy HH:mm:ss"
          selected={formik.values.finish}
          onChange={(value) => formik.setFieldValue('finish', value)}
          locale={pt}
          customInput={<MaskedInput mask="99/99/9999 99:99" />}
        />
      )}
      {fieldsParams.find((field) => field === 'group') && (
        <>
          <div className="grid grid-cols-3 gap-2">
            {modeOptions.map(({ label, value }) => (
              <label
                htmlFor={value}
                className="
            bg-gray-50
              h-10
              shadow
              rounded-lg
              flex
              items-center
              justify-center
              cursor-pointer
              hover:bg-gray-100
              "
              >
                <input
                  onChange={({ target: { value: valueType } }) => {
                    if (valueType !== 'group') {
                      formik.setFieldValue('group', null);
                    }
                    if (valueType !== 'cars') {
                      formik.setFieldValue('cars', null);
                    }
                    formik.setFieldValue('typeCars', valueType);
                  }}
                  id={value}
                  value={value}
                  checked={formik.values.typeCars === value}
                  name="modeOptions"
                  type="radio"
                />
                <span>{label}</span>
              </label>
            ))}
          </div>
          <div style={{ height: `${height}px` }}>
            {formik.values.typeCars === 'group' && (
              <GroupRadio className={className} formik={formik} />
            )}
            {formik.values.typeCars === 'cars' && (
              <CarsCheckBox
                isCompact={!isSchedule}
                className={className}
                formik={formik}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

ReportFields.propTypes = {
  formik: PropTypes.objectOf(
    PropTypes.object,
    PropTypes.string,
    PropTypes.number
  ).isRequired,
  isSchedule: PropTypes.bool,
};

ReportFields.defaultProps = {
  isSchedule: false,
};

export default ReportFields;
