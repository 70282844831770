import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchJourneyRequest: ['query', 'params'],
  fetchJourneySuccess: ['data'],
  fetchJourneyIdSuccess: ['data'],
  fetchJourneyFailure: [],
  updateJourneyRequest: ['data'],
  createJourneyRequest: ['data'],
  clearJourney: [],
  setPage: ['page', 'pageSize'],
  setParams: ['params'],
});

const INITIAL_STATE = {
  journeys: [],
  journey: {},
  journeyByTracker: {},
  loading: false,
  page: 1,
  pageSize: 20,
  params: '',
};

const clearJourney = (state = INITIAL_STATE) => ({
  ...state,
  journey: {},
});

const fetchJourneyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchJourneySuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  journeys: actions.data,
  loading: false,
});

const fetchJourneyIdSuccess = (state = INITIAL_STATE, actions) => {
  return {
    ...state,
    journey: actions.data,
    loading: false,
  };
};

const fetchJourneyFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    journeys: [],
  };
};

const createJourneyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const updateJourneyRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const setPage = (state = INITIAL_STATE, actions) => ({
  ...state,
  page: actions.page,
});

const setParams = (state = INITIAL_STATE, actions) => ({
  ...state,
  params: actions.params,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_JOURNEY_REQUEST]: fetchJourneyRequest,
  [Types.FETCH_JOURNEY_SUCCESS]: fetchJourneySuccess,
  [Types.FETCH_JOURNEY_ID_SUCCESS]: fetchJourneyIdSuccess,
  [Types.FETCH_JOURNEY_FAILURE]: fetchJourneyFailure,
  [Types.UPDATE_JOURNEY_REQUEST]: updateJourneyRequest,
  [Types.CREATE_JOURNEY_REQUEST]: createJourneyRequest,
  [Types.CLEAR_JOURNEY]: clearJourney,
  [Types.SET_PAGE]: setPage,
  [Types.SET_PARAMS]: setParams,
});
