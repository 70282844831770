import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import { registerLocale } from 'react-datepicker';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import el from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';

import {
  ModalContainer,
  ModalHeader,
  ModalTextHeader,
  ConfirmBox,
  ConfirmButtonsContainer,
  CommandConfirmButton,
  FormData,
  DataContainer,
  TabContainer,
  TabView,
} from '~/components/Global';

import Loading from '~/components/Loading';
import api from '~/services/api';
import { Creators as TrackerActions } from '~/store/ducks/trackerSauce';

import ConfigurationData from './ConfigurationData';
import FenceData from './FenceData';
import Header from './Header';
import MovimentationData from './MovimentationData';
import TrackerData from './TrackerData';
import weekDaysMovimentation from './weekDaysMovimentation.json';
import ReferencePoint from './ReferencePoint';

let saveAndRedirect = true;

function Tracker({ match }) {
  const dispatch = useDispatch();
  // const { groups } = useSelector(({ bufferState }) => bufferState);

  registerLocale('pt-BR', el);
  const tracker = useSelector((state) => state.trackerState.tracker);
  const loading = useSelector((state) => state.trackerState.loading);
  const me = useSelector((state) => state.globalState.me);
  const [loadingReplicate, setLoadingReplicate] = useState(false);
  const [tab, setTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [enableApplyAll, setEnableApplyAll] = useState(false);
  const [optionsApplyAll, setOptionsApplyAll] = useState({
    speed: false,
    idleTime: false,
    moveHour: false,
    type: '',
  });
  const [editablePermission, setEditablePermission] = useState(false);
  const [groups, setGroups] = useState([]);
  const [trackersCount, setTrackersCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      label: '',
      type: '',
      brand: '',
      model: '',
      year: '',
      color: '',
      plate: '',
      chassis: '',
      hour_meter: '',
      active: '',
      school_bus: false,
      device_id: '',
      client: {},
      speed_max: 110,
      idle_time: 30,
      driving_time_start: '',
      driving_time_end: '',
      fence_id: '',
      fence: [],
      configuration: { movimentation: [] },
      typeFence: true,
      configurationFence: '',
      weekend: false,
      exception_date_start: '',
      exception_start_end: '',
      exceptions: [],
      icon_color: '',
    },
    validate: (values) => {
      const error = {};
      const message = 'Campo obrigatório';
      if (!values.label) error.label = message;
      if (!_.isEmpty(values.fences)) {
        const weeks = values.fences.map((item) => item.week);
        weeks.forEach((item, index) => {
          item.forEach((week, subIndex) => {
            if (week.check) {
              if (!week.start) error[`weeksStart${index}${subIndex}`] = message;
              if (!week.end) error[`weeksEnd${index}${subIndex}`] = message;
            }
          });
        });
      }
      return error;
    },
    onSubmit: (values) => {
      if (values.active === 'Ativo') {
        values.active = true;
      } else {
        values.active = false;
      }
      if (values.hour_meter === 'Odômetro') {
        values.hour_meter = false;
      } else {
        values.hour_meter = true;
      }
      dispatch(
        TrackerActions.updateTrackerRequest({ ...values, saveAndRedirect })
      );
    },
  });

  useEffect(() => {
    if (match.params.id) {
      dispatch(TrackerActions.fetchTrackerRequest(`/${match.params.id}`));
      api.getGroups().then(({ data }) =>
        setGroups(
          data.map((i) => {
            i.selected = false;
            return i;
          })
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(tracker)) {
      formik.setValues({
        ...tracker,
        fences: !_.isEmpty(tracker.fences)
          ? tracker.fences.map((item) => ({
              ...item,
              once: item.once
                ? {
                    ...item.once,
                    start: moment(item.once.start).toDate(),
                    end: moment(item.once.end).toDate(),
                  }
                : {},
              week: item.week.map((day) => ({
                ...day,
                start: !_.isEmpty(day.start) ? moment(day.start).toDate() : '',
                end: !_.isEmpty(day.end) ? moment(day.end).toDate() : '',
              })),
            }))
          : [],
        exceptions: tracker.exceptions ? tracker.exceptions : [],
        school_bus: tracker.school_bus ? tracker.school_bus : false,
        active: tracker.active ? 'Ativo' : 'Inativo',
        hour_meter: tracker.hour_meter ? 'Horímetro' : 'Odômetro',
        weekend: tracker.weekend,
        configuration: {
          movimentation:
            _.isObject(tracker.configuration) &&
            _.isArray(tracker.configuration.movimentation)
              ? tracker.configuration.movimentation
              : weekDaysMovimentation,
        },
      });
    }
  }, [tracker]);

  useEffect(() => {}, [tab]);

  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  useEffect(() => {
    if (optionsApplyAll.type === 'allcars') {
      api.trackersCount().then(({ data }) => setTrackersCount(data.count));
    }
  }, [optionsApplyAll]);

  useEffect(() => {
    // api.getGroups().then(({ data }) => setGroups(data));
    const enableButton =
      ['group', 'allcars'].includes(optionsApplyAll.type) &&
      (optionsApplyAll.type !== 'group' ||
        (optionsApplyAll.type === 'group' && groups.some((i) => i.selected))) &&
      (optionsApplyAll.speed ||
        optionsApplyAll.idleTime ||
        optionsApplyAll.moveHour);
    setEnableApplyAll(enableButton);
  }, [
    optionsApplyAll.type,
    optionsApplyAll.speed,
    optionsApplyAll.idleTime,
    optionsApplyAll.moveHour,
    groups,
  ]);

  function getTab() {
    if (tab === 1) {
      return (
        <TrackerData formik={formik} editablePermission={editablePermission} />
      );
    }
    if (tab === 2) {
      return (
        <ConfigurationData
          formik={formik}
          editablePermission={editablePermission}
        />
      );
    }
    if (tab === 3) {
      return (
        <FenceData
          tab={tab}
          formik={formik}
          editablePermission={editablePermission}
        />
      );
    }

    if (tab === 4) {
      return (
        <MovimentationData
          formik={formik}
          editablePermission={editablePermission}
        />
      );
    }
    if (tab === 5) {
      return (
        <ReferencePoint
          formik={formik}
          editablePermission={editablePermission}
          match={match}
        />
      );
    }
    return <></>;
  }
  // TODO velocidade maxima, obrigatorio, numero maior que zero
  // TODO alerta de veiculo ocioso, obrigatorio e nao pode ser menor que 5 minutos

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormContainer>
          <HeaderFormContainer title="Item de Rastreamento">
            <Header
              editablePermission={editablePermission}
              loading={loading}
              actions={{
                showModal: () => {
                  if (me.role !== 'master') {
                    alert(
                      `Somente usuário administrador por replicar a configução!`
                    );
                  } else if (!(Number(formik.values.speed_max) > 0)) {
                    // eslint-disable-next-line no-alert
                    alert(
                      `Na aba Configurações\nO campo "VELOCIDADE MÁXIMA"\né obrigatório e deve ser superior a 0 Km/h!`
                    );
                  } else if (!(Number(formik.values.idle_time) >= 5)) {
                    // eslint-disable-next-line no-alert
                    alert(
                      `Na aba Configurações\nO campo "ALERTA DE VEÍCULO OCIOSO"\né obrigatório e não pode ser inferior a 05 minutos!`
                    );
                  } else {
                    setShowModal(true);
                  }
                },
              }}
            />
          </HeaderFormContainer>
          <SimpleContainer>
            <TabContainer>
              <TabView active={tab === 1} onClick={() => setTab(1)}>
                DADOS
              </TabView>
              <TabView active={tab === 2} onClick={() => setTab(2)}>
                CONFIGURAÇÕES
              </TabView>
              <TabView active={tab === 4} onClick={() => setTab(4)}>
                MOVIMENTAÇÃO
              </TabView>
              <TabView active={tab === 3} onClick={() => setTab(3)}>
                CERCA
              </TabView>
              <TabView active={tab === 5} onClick={() => setTab(5)}>
                PONTO DE REFERÊNCIA
              </TabView>
            </TabContainer>
            <FormData>
              {!loading ? (
                <DataContainer>{getTab()}</DataContainer>
              ) : (
                <Loading />
              )}
            </FormData>
          </SimpleContainer>
        </FormContainer>
      </form>
      <Modal visible={showModal} onClickAway={() => setShowModal(false)}>
        <ModalContainer style={{ paddingBottom: '20px' }}>
          <ModalHeader>
            <ModalTextHeader> REPLICAR CONFIGURAÇÃO </ModalTextHeader>
          </ModalHeader>
          <ConfirmBox style={{ padding: '20px 10px 10px 10px' }}>
            <div
              tabIndex={0}
              role="button"
              title="replicar velocidade máxima"
              style={{ marginBottom: '6px', userSelect: 'none' }}
              onClick={() =>
                setOptionsApplyAll({
                  ...optionsApplyAll,
                  speed: !optionsApplyAll.speed,
                })
              }
              onKeyDown={(e) => {
                if (![9].includes(e.which)) {
                  e.preventDefault();
                }
                if (![32, 13].includes(e.which)) {
                  return;
                }
                setOptionsApplyAll({
                  ...optionsApplyAll,
                  speed: !optionsApplyAll.speed,
                });
              }}
            >
              <input type="checkbox" checked={optionsApplyAll.speed} />
              &nbsp; VELOCIDADE MÁXIMA: &nbsp;
              <strong>{formik.values.speed_max} KM/H</strong>
            </div>
            <div
              tabIndex={-1}
              role="button"
              title="replicar alerta de veículo ocioso"
              style={{ marginBottom: '6px', userSelect: 'none' }}
              onClick={() =>
                setOptionsApplyAll({
                  ...optionsApplyAll,
                  idleTime: !optionsApplyAll.idleTime,
                })
              }
              onKeyDown={(e) => {
                if (![9].includes(e.which)) {
                  e.preventDefault();
                }
                if (![32, 13].includes(e.which)) {
                  return;
                }
                setOptionsApplyAll({
                  ...optionsApplyAll,
                  idleTime: !optionsApplyAll.idleTime,
                });
              }}
            >
              <input type="checkbox" checked={optionsApplyAll.idleTime} />
              &nbsp; ALERTA VEÍCULO OCIOSO: &nbsp;
              <strong>{formik.values.idle_time} MINUTOS</strong>
            </div>
            <div
              tabIndex={-2}
              role="button"
              title="replicar horário permitido para movimentação do veículo"
              style={{ marginBottom: '6px', userSelect: 'none' }}
              onClick={() =>
                setOptionsApplyAll({
                  ...optionsApplyAll,
                  moveHour: !optionsApplyAll.moveHour,
                })
              }
              onKeyDown={(e) => {
                if (![9].includes(e.which)) {
                  e.preventDefault();
                }
                if (![32, 13].includes(e.which)) {
                  return;
                }
                setOptionsApplyAll({
                  ...optionsApplyAll,
                  moveHour: !optionsApplyAll.moveHour,
                });
              }}
            >
              <input type="checkbox" checked={optionsApplyAll.moveHour} />
              &nbsp; HORÁRIO PERMITIDO PARA MOVIMENTAÇÃO DO VEÍCULO
            </div>
            <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '10px',
                  marginBottom: '10px',
                }}
              >
                <select
                  tab={-3}
                  style={{
                    width: '100%',
                    height: '30px',
                    fontSize: '18px',
                    fontWeight: optionsApplyAll.type ? 600 : undefined,
                    backgroundColor: 'rgb(242, 241, 240)',
                  }}
                  onChange={(e) => {
                    setOptionsApplyAll({
                      ...optionsApplyAll,
                      type: e.target.value,
                    });
                  }}
                >
                  <option value=""> Selecione... </option>
                  <option value="group"> Por grupo </option>
                  <option value="allcars"> Todos os veículos </option>
                </select>
                {/* <span> selecionar: </span> */}
                {optionsApplyAll.type === 'group' && (
                  <>
                    {/* TODO grupo para 1 e grupos para +1 */}
                    {groups.length > 0 && (
                      <>
                        <span style={{ width: '100%', paddingTop: '4px' }}>
                          {groups.length} grupo{groups.length > 1 && 's'}
                        </span>
                        <button
                          type="button"
                          title="selecionar todas as opções"
                          tab={-4}
                          onClick={() =>
                            setGroups(
                              groups.map((i) => {
                                i.selected = true;
                                return i;
                              })
                            )
                          }
                        >
                          todos
                        </button>
                        <button
                          type="button"
                          title="inverter a seleção atual"
                          tab={-5}
                          onClick={() =>
                            setGroups(
                              groups.map((i) => {
                                i.selected = !i.selected;
                                return i;
                              })
                            )
                          }
                        >
                          inverter
                        </button>
                        <button
                          type="button"
                          title="desmarcar todas as opções"
                          tab={-6}
                          onClick={() =>
                            setGroups(
                              groups.map((i) => {
                                i.selected = false;
                                return i;
                              })
                            )
                          }
                        >
                          limpar
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
              {optionsApplyAll.type === 'group' && groups.length === 0 && (
                <span
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  este usuário não possui grupos
                </span>
              )}
            </div>
            {/* <pre>{JSON.stringify(groups, null, 2)}</pre> */}
            {optionsApplyAll.type === 'group' &&
              Array.isArray(groups) &&
              groups.map((i, idx) => (
                <div
                  tabIndex={0 - idx}
                  role="button"
                  title={`replicar configurações do grupo ${i.name}`}
                  key={`_grupo${i.id}`}
                  style={{
                    paddingBottom: '8px',
                    fontFamily: 'monospace',
                    fontSize: '18px',
                    marginLeft: '6px',
                    userSelect: 'none',
                  }}
                  onClick={() => {
                    const group = groups.find((g) => g.id === i.id);
                    group.selected = !group.selected;
                    setGroups([...groups]);
                  }}
                  onKeyDown={(e) => {
                    if (![9].includes(e.which)) {
                      e.preventDefault();
                    }
                    if (![32, 13].includes(e.which)) {
                      return;
                    }
                    const group = groups.find((g) => g.id === i.id);
                    group.selected = !group.selected;
                    setGroups([...groups]);
                  }}
                >
                  <input type="checkbox" checked={i.selected} />{' '}
                  {/* <div
                    style={{
                      display: 'inline',
                      fontSize: '11px',
                      fontWeight: 600,
                      backgroundColor: '#3949AB',
                      color: '#E3F2FD',
                      marginLeft: '6px',
                      marginRight: '6px',
                      marginBottom: '6px',
                      padding: '2px 6px 2px 6px',
                      borderRadius: '8px',
                    }}
                  >
                    {i.trackers &&
                      i.trackers.length.toString().padStart(3, '0')}{' '}
                    <span style={{ fontSize: 8 }}>CARROS</span>
                  </div>{' '} */}
                  {i.name
                    .substring(0, 30 - 2)
                    .toUpperCase()
                    .concat(' ')
                    .padEnd(30 - 1, '.')
                    .concat(' ')}
                  {i.trackers && i.trackers.length.toString().padStart(3, '0')}{' '}
                  CARROS
                </div>
              ))}
            {optionsApplyAll.type === 'allcars' && (
              <div
                title={`a replicação afetará ${trackersCount} veículo${
                  trackersCount > 1 ? 's' : ''
                }`}
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: 22,
                  fontWeight: 600,
                }}
              >
                {trackersCount} veículo{trackersCount > 1 ? 's' : ''}
              </div>
            )}
          </ConfirmBox>
          <ConfirmButtonsContainer style={{ padding: '10px 10px 0px 10px' }}>
            <CommandConfirmButton
              type="button"
              color="#ff4e4e"
              onClick={() => setShowModal(false)}
            >
              <FaTimes />
              &nbsp;cancelar
            </CommandConfirmButton>
            <CommandConfirmButton
              disabled={!(enableApplyAll && !loadingReplicate)}
              type="submit"
              color="#293e52"
              title="replica as configurações deste item de rastreamento de acordo com as configurações informadas acima"
              onClick={async () => {
                saveAndRedirect = false;
                try {
                  setLoadingReplicate(true);
                  setTimeout(async () => {
                    const response = await api.trackerReplicateConfig({
                      trackerId: Number(match.params.id),
                      type: optionsApplyAll.type,
                      speed: optionsApplyAll.speed ? true : undefined,
                      idleTime: optionsApplyAll.idleTime ? true : undefined,
                      moveHour: optionsApplyAll.moveHour ? true : undefined,
                      groups:
                        optionsApplyAll.type === 'group'
                          ? groups.filter((i) => i.selected).map((i) => i.id)
                          : undefined,
                    });
                    setLoadingReplicate(false);
                    if (response.data) {
                      toast.success(response.data.message);
                    }
                    saveAndRedirect = true;
                    setShowModal(false);
                  }, 1250);
                  await formik.submitForm();
                } catch (err) {
                  toast.error('Erro ao aplicar a replicação da configuração!');
                  console.log(err);
                }
              }}
            >
              {loadingReplicate ? (
                <>Aguarde...</>
              ) : (
                <>
                  <FaCheck /> &nbsp; Aplicar
                </>
              )}
            </CommandConfirmButton>
          </ConfirmButtonsContainer>
        </ModalContainer>
      </Modal>
    </>
  );
}

Tracker.propTypes = {
  match: PropTypes.string.isRequired,
};

export default Tracker;
