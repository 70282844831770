import React from 'react';
import Lottie from 'react-lottie';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import animationData from '~/assets/lottie/loadingPage.json';

const Div = styled.div`
  align-self: center;
`;

export default function LottieLoading({ height }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return (
    <Div>
      <Lottie
        options={defaultOptions}
        height={height}
        width="100%"
        isPaused={false}
      />
    </Div>
  );
}

LottieLoading.propTypes = {
  height: PropTypes.number,
};

LottieLoading.defaultProps = {
  height: 300,
};
