export default function getColorCar({ status = false, ignition = false }) {
  let colorCar;
  if (status === 'SLEEP') {
    colorCar = '#EAAA08';
  } else if (status === 'DANGER') {
    colorCar = '#D30807';
  } else if (status === 'DISCONNECTED') {
    colorCar = '#CECECE';
  } else if (ignition) {
    colorCar = '#2C8D14';
  } else if (!ignition) {
    colorCar = '#141414';
  } else {
    colorCar = false;
  }
  return colorCar;
}
