import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px 1fr;
  grid-gap: 10px;
  grid-template-areas: 'header header' 'config types';
  > header {
    grid-area: header;
    font-size: 25px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 5px;
    color: #333;
  }
`;

export const ContainerAllCars = styled.div`
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-areas: 'headermodel select select select' 'inputModel inputModel inputModel inputModel';

  grid-template-columns: 1fr 1fr 1fr 1fr;

  > header {
    grid-area: headermodel;
    font-size: 18px;
  }
  > button {
  }
  > div {
    height: 500px;
    grid-area: inputModel;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    > label {
      height: 60px;
      padding: 5px;
      background: #fff;
      margin: 5px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      cursor: pointer;
      :hover {
        background: rgba(0, 0, 0, 0.05);
      }
      > span {
        margin-left: 5px;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
`;

export const ContainerModelOptions = styled.div`
  height: 80px;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'headermodel headermodel headermodel' 'inputModel inputModel inputModel';
  grid-gap: 10px;
  margin-bottom: 7px;
  > header {
    grid-area: headermodel;
    font-size: 18px;
  }

  > label {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
    > span {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  }
`;
export const ContainerGroup = styled.div`
  height: 80px;
  display: grid;
  grid-template-rows: 20px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'headermodel headermodel headermodel' 'inputModel inputModel inputModel';
  grid-gap: 10px;
  > header {
    grid-area: headermodel;
  }

  > label {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    :hover {
      background: rgba(0, 0, 0, 0.05);
    }
    > span {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

export const ContainerModel = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 80px 1fr;
`;
