import { Line, HorizontalBar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import { FaUpload } from 'react-icons/fa';
import { MdMoreVert } from 'react-icons/md';
import Select from 'react-select';
import MaskedInput from 'react-text-mask';

import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import Menu, { Item as MenuItem } from 'rc-menu';

import CurrencyInput from 'react-currency-input';

export const WhiteLabel = styled.label`
  color: #fff;
`;

export const SelectStyledFilter = styled(Select)`
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 3px;
  height: 35px;
  width: 180px;
  z-index: 999;
  padding: 0 10px;
  cursor: pointer;
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    padding: 0;
  }
  .css-6q0nyr-Svg {
    width: 12px;
    height: 12px;
  }
  .css-1wa3eu0-placeholder {
    color: #000;
    font-size: 12px;
  }
  .css-yk16xz-control {
    background-color: transparent;
    border: 0px !important;
    width: 100%;
  }
  .css-1pahdxg-control {
    background-color: transparent;
    border: 0;
    width: 100%;
  }
`;

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 7px;
`;

export const ButtonStyled = styled.button`
  margin-top: 10px;
  position: relative;
  outline: none;
  text-decoration: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  height: 40px;
  width: 100%;
  opacity: 1;
  background-color: ${(props) => props.color};
  color: ${(props) => (props.textColor ? props.textColor : '#fff')};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.7px;
  border: none;
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ModalHeader = styled.div`
  grid-area: header;
  text-align: center;
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 5px 5px 0 0;
  display: flex;
`;

export const ModalTextHeader = styled.div`
  padding-top: 7px;
  /* padding-left: 60px; */
  width: 100%;
  font-size: 25px;
  color: #333;
  font-weight: 600;
  color: #293e52;
  /* text-transform: uppercase; */
`;

export const ItemList = styled.div`
  padding: 5px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 0 5px;
  img {
    transform: ${(props) => props.rotate && `rotate(${props.rotate}deg)`};
    height: 15px;
    width: 15px;
    opacity: 0.8;
    transition: opacity 0.3s;
    :hover {
      opacity: 1;
    }
  }
`;

export const ConfirmButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: flex-end; */
`;

export const ConfirmBox = styled.div`
  height: 100%;
  grid-area: body;
  /* height: 245px !important; */
  /* width: 350px; */
  width: 100%;
  border-radius: 7px;
`;

export const ConfirmDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 90px; */
  > div {
    display: flex;
    justify-content: space-between;
  }
`;

export const BodyFilterConfirm = styled.div`
  grid-area: card;
  background: #f4f8f9;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding: 5px 0;
  height: 100%;
`;

export const TextStyled = styled.label`
  /* font-weight: 700; */
  text-transform: uppercase;
  display: flex;
  font-size: 12px;
  color: #7e8b97;
  align-items: center;
  justify-content: center;
  margin-bottom: 27px;
`;

export const CommandConfirmButton = styled(ButtonStyled)`
  border: none;
  /* margin-top: 3px; */
  /* margin-left: 10px; */
  /* margin-bottom: 10px; */
  margin: 0 5px;
  padding: 3px;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 148px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 15px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const CommandNameStyled = styled.div`
  color: #f7f4f7;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextStyledBold = styled.label`
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 15px;
  color: #7f8b97;
`;

export const DivModalGrid = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas: 'header' 'body' 'buttons';
  height: 100%;
`;

export const DivFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -20px; */
`;

export const DivBlockChart = styled.div`
  width: 90%;
  display: block;
  height: ${(props) => props.heigth && `${props.heigth}px`};
  /* canvas {
    height: 100%;
  } */
`;

export const LabelDiv = styled.label`
  display: flex;
  align-items: center;
`;

export const BlockDiv = styled.div`
  display: flex;
`;

export const IconStyled = styled.img`
  width: 31px;
  height: 31px;
  padding: 3px;
  border-radius: 5px;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  background: ${(props) => {
    return props.selected ? '#ddd' : '#fff';
  }};
  :hover {
    opacity: 1;
    background: #f3f3f3;
  }
`;

export const ImportIconStyled = styled.img`
  width: 31px;
  height: 31px;
  padding: 3px;
  border-radius: 5px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
`;

export const Item = styled(MenuItem)`
  height: 35px;
  width: 100%;
  padding: 5px;
  color: ${(props) =>
    props.bgtheme === 'exit' ? '#fff !important' : '#333 !important'};
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  :hover {
    background: ${(props) =>
    props.bgtheme === 'exit'
      ? 'rgba(168,0,18) !important'
      : 'rgba(0, 0, 0, 0.05) !important'};
  }
  span {
    margin-left: 5px;
  }

  ${(props) =>
    props.bgtheme === 'exit'
      ? `
    background: #c10015;
    border-radius: 0 0 5px 5px;
  `
      : false}
`;
export const ItemFilter = styled(MenuItem)`
  height: 5x;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const MenuStyled = styled(Menu)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased !important;
  border: none;
  height: 100%;
  /* overflow: scroll; */
  border-radius: 5px;
  margin-top: 8px;
  margin-left: -69px;
  ::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    top: -7px;
    margin-left: ${(props) => (props.select ? '188px' : '75px')};
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;

    @media (max-width: 600px) {
      margin-left: 120px;
    }
  }
`;

export const MoreOptions = styled(MdMoreVert)`
  margin-left: 5px;
  border-radius: 10px;
  transition: all 0.3s;
  :hover {
    background: #dfdfdf;
    cursor: pointer;
  }
`;

export const ImportOptions = styled(FaUpload)`
  margin-left: 5px;
  border-radius: 10px;
  transition: all 0.3s;
  :hover {
    background: #dfdfdf;
    cursor: pointer;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const ImportButtonLabel = styled.label`
  width: 200px;
  height: 40px;
  cursor: pointer;
`;

export const TableGrid = styled.div`
  /* border-radius: 7px 7px 7px 7px; */
  height: 100%;
  /* padding: 10px; */
  display: grid;
  grid-template-rows: 1fr 40px;
  grid-template-areas: 'table' 'buttons';
  #output {
    display: inline-block;
    vertical-align: middle;
  }
`;

export const ModalMaintenanceDiv = styled.div`
  /* border-radius: 7px 7px 7px 7px; */
  height: 100%;
  padding: 10px;
  display: grid;
  grid-template-rows: 1fr 60px;
  grid-template-areas: 'table' 'buttons';
`;

export const HorizontalBarResize = styled(HorizontalBar)``;

export const MaskedInputStyled = styled(MaskedInput)`
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  width: 100%;
  color: #999;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  padding-left: 5px;
  height: 33.2px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  :focus {
    border: 1px solid #245378 !important;
  }
  :hover {
    border: 1px solid #245378 !important;
  }
`;

export const NoRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const TextAreaStyled = styled.textarea`
  width: 100%;
  height: 100%;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  border-radius: 3px;
  color: #999;
  font-size: 14px;
  font-weight: 300;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
  display: block;
  resize: none;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  :focus {
    border: 1px solid #245378 !important;
  }
  :hover {
    border: 1px solid #245378 !important;
  }
`;

export const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 10px;
  > div {
    display: flex;
    justify-content: space-between;
  }
  ${(props) =>
    !props.full ||
    `grid-column-start: 1;
     grid-column-end: 4;`}
`;

export const ActionContainer = styled.div`
  grid-area: Action;
  padding: 5px;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const ProfileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'data';
`;

export const TrackerProfileContainer = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 2fr; */
  /* grid-template-areas: "data"; */
`;

export const Row = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 60px;
  grid-gap: 20px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const DoubleRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 60px;
  grid-gap: 10px;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const TabContainer = styled.span`
  display: flex;
`;

export const TabView = styled.div`
  background: ${(props) => (props.active ? '#f2f1f0' : '#c4c4c4')};
  width: 200px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s;
  color: ${(props) => (props.active ? '#605F5F' : '#9F9B9B')};
  cursor: pointer;
  /* border-radius: 7px; */
  :first-child {
    border-radius: 7px 0 0 7px;
  }
  :last-child {
    border-radius: 0 7px 7px 0;
  }
`;

export const TableContainer = styled.div`
  grid-area: table;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const FilterContainer = styled.form`
  grid-area: filter;
  display: grid;
  grid-template-rows: 30.4px 1fr 30px;
  grid-template-areas: 'header' 'body' 'buttons';
  /* background: #3a40d3; */
  /* border-radius: 5px 0 0 5px; */
  height: 100%;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const MoneyDiv = styled.div`
  width: 6%;
  height: 33.2px;
  font-size: 14px;
  color: #999;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  border-right: none !important;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: ${(props) => (props.disabled ? '#DFDFDF' : '#fff')};
  padding-left: 5px;
  padding-top: 7px;
  :disabled {
    color: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  /* p {
  background-color: yellow;
} */
  /* background: ${(props) => (props.error ? '#fcd7d7' : '#fff')}; */
`;

export const MaskMoneyStyle = styled.input`
  font-size: 14px;
  color: #000;
  width: 94%;
  font-weight: 300;
  height: 33.2px !important;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  border-left: none !important;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  :disabled {
    background-color: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  /* background: ${(props) => (props.error ? '#fcd7d7' : '#fff')}; */
`;

export const InputStyled = styled.input`
  flex: 0 1 100%;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 10px;
  display: block;
  height: 40px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
`;

export const LinkStyled = styled.a`
  color: #666;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  width: 50%;
  text-align: right;
  cursor: pointer;
  margin: 5px 0px 5px 0px;
`;

export const CheckboxStyled = styled.div`
  width: 50%;
  color: #666;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  margin: 5px 0px 5px 0px;
`;

export const Container = styled.div`
  margin: 10px 10px;
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  transition: 0.3s;
`;

export const ErrorMessage = styled.div`
  color: #e33939;
  font-weight: 600;
  font-size: 11px;
  float: right;
`;

export const DateStyle = styled(DatePicker)`
  height: 33.2px;
  width: ${(props) => props.width ? props.width : '100%'};
  font-size: 14px !important;
  border-radius: 3px;
  padding-left: 5px;
  font-family: 'Montserrat', sans-serif !important;
  color: #000;
  margin: ${(props) => props.margin ? props.margin : 0};;
  font-weight: 300 !important;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  :focus {
    border: 1px solid #245378 !important;
  }
  :hover {
    border: 1px solid #245378 !important;
  }
`;

export const FilterButton = styled(ButtonStyled)`
  border: none;
  margin: 5px;
  padding: 5px;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
  :disabled {
    /* background: black; */
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const ImportButton = styled(ButtonStyled)`
  border: none;
  margin: 5px;
  padding: 5px;
  width: 140px;
  height: 35px;
  background: ${(props) => props.back};
  color: ${(props) => props.front};
  text-transform: uppercase;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const Button = styled(ButtonStyled)`
  border: none;
  margin: 5px;
  padding: 5px;
  background: ${(porps) => porps.color};
  text-transform: uppercase;
  border-radius: 5px;
  height: 35px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 10px;
    color: #fff;
    margin-left: 5px;
    font-weight: 600;
  }
  :hover {
    opacity: 0.8;
  }
  :focus {
    transform: translateY(1px);
  }
`;

export const SelectStyled = styled(Select)`
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  /* margin-left: 5px; */
  border-radius: 3px;
  color: #000;
  font-weight: 300;
  text-align: left;
  /* margin-bottom: 10px; */
  display: block;
  height: 33.2px !important;
  /* background-color: transparent; */
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  text-transform: uppercase;
  font-size: 14px;
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  .css-yk16xz-control {
    border-radius: 3px;
    width: 100%;
    min-height: 30px !important;
    height: 33.2px !important;
    /* border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'}; */
    border: 0px;
    border-bottom: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  }
  .css-1fhf3k1-control {
    width: 100%;
    background-color: #dfdfdf;
    height: 33.2px !important;
    min-height: 30px !important;
  }
  .css-p22abc-control {
    width: 100%;
    background-color: #dfdfdf;
    height: 33.2px !important;
    min-height: 30px !important;
  }
  .css-q1ugys-control {
    min-height: 33.2px !important;
    color: #dbdbdb;
  }
  .css-1ehar69-control {
    min-height: 33.2px !important;
  }
  .css-1-control {
    width: 100%;
    background-color: #dfdfdf;
    height: 33.2px !important;
    min-height: 30px !important;
  }
  .css-yk16xz-control:focus {
    border-radius: 3px;
    /* border: none; */
    border-bottom: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
    width: 100%;
    min-height: 30px !important;
  }
  .css-yk16xz-control:hover {
    border: 1px solid black;
  }
  .css-1hwfws3 {
    position: static;
  }
  .css-1pahdxg-control:hover {
    border-color: #245378 !important;
    box-shadow: 0px;
  }
  .css-1pahdxg-control:isselected {
    background-color: #000 !important;
    border-width: 0px !important;
  }
  .css-1pahdxg-control {
    /* :hover {
    border-color: transparent !important;
  } */
    /* border-color: #245378; */
    /* box-shadow: none; */
    /* border: none; */
    /* border-bottom: ${(props) => (props.error ? '1px solid #E33939' : '')}; */
    width: 100%;
    min-height: 30px !important;
    box-shadow: 0 0 0 0px !important;
    border-width: 0px !important;
  }
  .css-1pahdxg-control:hover {
    /* border: none; */
    /* border-bottom: ${(props) => (props.error ? '1px solid #E33939' : '')}; */
  }
  .css-tlfecz-indicatorContainer:hover {
    color: #545454;
  }
  .css-fk0x0p-control {
    min-height: 30px !important;
    border: none;
  }

  .css-1g48xl4-IndicatorsContainer {
    max-height: -webkit-fill-available;
  }
  .css-1wa3eu0-placeholder {
    color: #999;
    font-family: 'Montserrat', sans-serif;
  }
  .css-1uccc91-singleValue {
    font-size: 14px;
    color: #000;
  }
  .css-1hwfws3 {
    padding: 0 !important;
  }
  .css-w8afj7-Input {
    font-size: 14px;
    color: #000;
  }
`;

export const CurrencyInputFilter = styled.input`
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'} !important;
  /* background: ${(props) => (props.error ? '#fcd7d7' : '#fff')} !important; */
  flex: 0 1 100%;
  border: solid 1px #dbdbdb;
  border-radius: 3px;
  color: #000;
  font-size: 14px;
  font-weight: 300;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 10px;
  display: block;
  height: 40px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  border: none;
  border: 1px solid #dbdbdb;
  background: #fff;
`;

export const Input = styled(InputStyled)`
  height: 33.2px;
  width: 100%;
  border: none;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  :focus {
    border: 1px solid #245378 !important;
  }
  :hover {
    border: 1px solid #245378 !important;
  }
`;

export const SelectStyles = styled.select`
  width: 100%;
  border: none;
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  flex: 0 1 100%;
  border-radius: 3px;
  color: #000;
  font-size: 12px;
  font-weight: 300;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 10px;
  display: block;
  height: 40px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
`;

export const ButtonsFilter = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const BodyFilter = styled.div`
  grid-area: body;
  background: #fff;
  height: 100%;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 0 0 5px 5px;
`;

export const HeaderFilter = styled.div`
  grid-area: header;
  background: #f2f1f0;
  height: 30.4px;
  width: 100%;
  display: flex;
  padding-left: 10px;
  /* justify-content: flex-end; */
  align-items: center;
  background: #f4f8f9;
  font-weight: 600;
  transition: all 0.3s;
  color: #333;
  border-radius: 5px 5px 0 0;
  .titlePage {
    font-size: 20px;
  }
`;

export const ContainerFilters = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerTripFilters = styled.div`
  /* height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; */
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  width: 100%;
  > div {
    display: flex;
    justify-content: space-between;
  }
  ${(props) =>
    !props.full ||
    `grid-column-start: 1;
     grid-column-end: 4;`}
`;

export const LabelStyled = styled.label`
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
`;

export const FiltersContainer = styled.div`
  grid-area: filters;
`;

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  justify-content: flex-end;
`;

export const FormDocs = styled.div`
  background: #f2f1f0;
  border-radius: 0 7px 7px 7px;
  grid-area: detail;
  padding: 10px;
  height: 100%;
`;

export const FilterHeader = styled.div`
  border-radius: 5px 5px 0 0;
  background: #245378;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding-left: 5px;
  font-weight: 600;
  color: #fff;
  height: 100%;
  text-transform: uppercase;
  text-align: left;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  @media screen and (max-width: 700px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`;

export const HeaderTitle = styled.div`
  grid-area: header;
  width: 100%;
  height: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const OptionPlaceholder = styled.option`
  background-color: #ccc;
`;

export const FormFilter = styled.form`
  padding-top: 5px;
  padding-right: 5px;
  padding-left: 5px;
  /* padding-bottom: 10px; */
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 40px;
  grid-template-areas: 'filters' 'buttons';
`;

export const ContainerTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #605f5f;
  margin-bottom: 10px;
  margin-top: 5px;
`;

export const ContainerStyled = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  box-shadow: none;
  grid-template-areas:
    'title'
    'table';
  margin: 0.5rem 0.5rem 0.5rem 1rem;
  transition: all 0.3s ease 0s;
  background: transparent;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0px;
  border-radius: 0px;
  gap: 10px;

  /* @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: "filter" "table";
  } */
`;
export const ContainerStyledTrip = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas: 'filter table';
  grid-gap: 10px;
  margin: 0.5rem 0.5rem 0.5rem 1rem;

  /* @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: "filter" "table";
  } */
`;

export const ContainerChartStyled = styled(Container)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 50px 1fr 2fr;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  grid-template-areas:
    'title title'
    'chart chart'
    'table table';

  grid-gap: 10px;

  /* @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    grid-template-areas: "filter table" "filter chart";
  } */
`;

export const FormDataContainer = styled.div`
  grid-area: form;
`;

export const FormContainerWithoutTab = styled.div`
  grid-area: filter / filter / table / table;
  display: grid;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const FormContainer = styled.div`
  grid-area: table;
  /* display: grid;
  grid-template-rows: 30px 1fr;
  grid-template-areas: "tab" "detail"; */
  /* height: 100%; */
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const FormForm = styled.form`
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 5px;
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 40px;
  grid-template-areas: 'form' 'buttons';
`;

export const FormData = styled.div`
  /* background: #f2f1f0; */
  border-radius: 0 7px 7px 7px;
  grid-area: detail;
  height: 95%;
  padding: 10px;
  /* display: grid; */
  /* grid-template-rows: 1fr 40px; */
  /* grid-template-areas: "form" "buttons"; */
  /* > div {
    padding: 5px;
    background: #ededed;
    border-radius: 0 0 7px 7px;
  } */
`;

export const FormDataContainerDiv = styled.div`
  background: #fff;
  border-radius: 7px;
  grid-area: detail;
  height: 100%;
  padding: 10px;
  /* display: grid; */
  /* grid-template-rows: 1fr 40px; */
  /* grid-template-areas: "form" "buttons"; */
  /* > div {
    padding: 5px;
    background: #ededed;
    border-radius: 0 0 7px 7px;
  } */
`;
export const DataContainer = styled.div`
  grid-area: form;
`;

export const ChartIndexContainer = styled.div`
  grid-area: chart;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
`;

export const ChartContainer = styled.div`
  grid-area: chart;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: 20px 1fr;
  grid-template-areas: 'pages' 'detail';
`;

export const ChartGridContainer = styled.div`
  display: grid;
  /* height: 100%; */
  /* width: 100%; */
  grid-column-gap: 10px;
  background: #fff;
  grid-template-columns: 2fr 1fr;
  border: none;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  /* grid-template-areas: "firstChart" "secondChart" "secondChart"; */
`;

export const ChartNameContainer = styled.div`
  display: grid;
  /* height: 100%; */
  /* width: 100%; */
  padding: 5px;
  /* background: #fff; */
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07); */
  border-radius: 5px;
`;

export const ChartPages = styled.div`
  grid-area: pages;
  display: flex;
`;

export const TableContainerBox = styled.div`
  grid-area: table;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const ChartTitle = styled.div`
  background: #f2f1f0;
  height: 30.4px;
  width: 100%;
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
  align-items: center;
  background: #f4f8f9;
  font-weight: 600;
  transition: all 0.3s;
  color: #333;
  border-radius: 5px 5px 0 0;
  .titlePage {
    font-size: 20px;
  }
`;

export const PageContainer = styled.div`
  background: #f2f1f0;
  height: 20px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.active ? '#f2f1f0' : '#c4c4c4')};
  font-weight: 600;
  transition: all 0.3s;
  color: ${(props) => (props.active ? '#605F5F' : '#9F9B9B')};
  cursor: pointer;
  :first-child {
    border-radius: 5px 0 0 0;
  }
  :last-child {
    border-radius: 0 5px 0 0;
  }
`;

export const ChartDetail = styled.div`
  grid-area: detail;
  background: #f4f8f9;
  border-radius: 0 5px 5px 5px;
`;

export const LineStyled = styled(Line)`
  canvas {
    width: 100% !important;
    height: 300px !important;
  }
`;

export const ContainerHeader = styled.div`
  height: 100%;
  width: 100%;
  grid-area: title;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #245378;
  background: #fff;
  padding: 0 5px;
  font-size: 25px;
  font-weight: 600;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  > div {
    display: flex;
  }
`;
export const ButtonContainer = styled.button`
  font-size: 13px;
  border: none;
  background: ${(props) => (props.color ? props.color : '#245378')};
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  font-weight: 500;
  width: fit-content;
  cursor: ${(props) => {
    if (props.disabled) {
      return 'not-allowed';
    }
    if (props.loading) {
      return 'progress';
    }
    return 'pointer';
  }};
  width: 100px;
  margin: 0 5px;
  :hover {
    opacity: 0.8;
  }
  :disabled {
    opacity: 0.6;
    background: #ccc;
    color: #000;
    cursor: not-allowed;
  }
`;

export const FilterSelect = styled.button`
  font-size: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 3px;
  height: 35px;
  width: 150px;
  padding: 0 10px;
  cursor: pointer;
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const ButtonFilter = styled.div`
  display: flex;
  align-items: center;
  .react-datepicker-popper {
    z-index: 99999999;
  }
`;

export const FilterBox = styled.button`
  font-size: 13px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  cursor: pointer;
  border-radius: 10px;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.4);
  :hover {
    background: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.8);
  }
`;

export const Currency = styled(CurrencyInput)`
  border: ${(props) =>
    props.error ? '1px solid #E33939' : '1px solid #dbdbdb'};
  width: 100%;
  color: #999;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  padding-left: 5px;
  height: 33.2px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  :disabled {
    background: #dfdfdf;
    border: 1px solid #ddd;
    cursor: not-allowed;
  }
  :focus {
    border: 1px solid #245378 !important;
  }
  :hover {
    border: 1px solid #245378 !important;
  }
`;
