import styled from 'styled-components';

export const Container = styled.div`
  > header {
    font-weight: 600;
    font-size: 18px;
    color: #605f5f;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;
