function base64urlUnescape(str) {
  str += new Array(5 - (str.length % 4)).join('=');
  return str.replace(/-/g, '+').replace(/_/g, '/');
}

function base64urlDecode(str) {
  return Buffer.from(base64urlUnescape(str), 'base64').toString();
}

export const parseJwt = (token) => {
  try {
    if (typeof token !== 'string') return null;
    const [, payload] = token.split('.');
    if (!payload || !payload.trim()) return null;
    return JSON.parse(base64urlDecode(payload));
  } catch (e) {
    return null;
  }
};

export const getClientId = () => {
  const token = localStorage.getItem('token');
  const { cli: clientId } = parseJwt(token);
  return Number(clientId);
};
