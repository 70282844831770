import React, { useState, useEffect } from 'react';
import { MdSearch } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import pt from 'date-fns/locale/pt';
import { useFormik } from 'formik';
import moment from 'moment';

import {
  FormContainer,
  HeaderFormContainer,
  SimpleContainer,
} from '~/components/containers';
import { ButtonContainer } from '~/components/Global';
import { Creators as AuditActions } from '~/store/ducks/auditSauce';

import { DateStyle, InputCustom, Form } from './styles';
import Table from './table';

export default function Audit(props) {
  const dispatch = useDispatch();
  const [editablePermission, setEditablePermission] = useState(false);
  const me = useSelector((state) => state.globalState.me);
  const formik = useFormik({
    initialValues: {
      date: moment().toDate(),
    },
    onSubmit: (data) => {
      dispatch(
        AuditActions.fetchAuditRequest(moment(data.date).format('YYYY-MM-DD'))
      );
    },
  });
  useEffect(() => {
    if (me) {
      if (me.role === 'master') {
        setEditablePermission(true);
      }
    }
  }, [me]);

  return (
    <FormContainer>
      <HeaderFormContainer title="Auditoria">
        <Form onSubmit={formik.handleSubmit}>
          <DateStyle
            dateFormat="dd/MM/yyyy"
            selected={formik.values.date}
            onChange={(value) =>
              formik.setFieldValue('date', moment(value).toDate())
            }
            locale={pt}
            customInput={<InputCustom mask="99/99/9999" />}
          />
          <ButtonContainer type="submit" disabled={!editablePermission}>
            <MdSearch size={15} />
            Pesquisar
          </ButtonContainer>
        </Form>
      </HeaderFormContainer>
      <SimpleContainer>
        <Table {...props} />
      </SimpleContainer>
    </FormContainer>
  );
}
