import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  globalRequest: ['data'],
  globalSuccess: [],
  globalFailure: [],
  clearMe: [],
});

const INITIAL_STATE = {
  loading: false,
  me: {},
};

const request = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const success = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  me: action.payload,
});

const failure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  me: {},
});

const clearMe = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  me: {},
});

export default createReducer(INITIAL_STATE, {
  [Types.GLOBAL_REQUEST]: request,
  [Types.GLOBAL_SUCCESS]: success,
  [Types.GLOBAL_FAILURE]: failure,
  [Types.CLEAR_ME]: clearMe,
});
