import moment from 'moment';
import { createActions, createReducer } from 'reduxsauce';

import { mountParams } from '~/util/Mountparams';

export const { Types, Creators } = createActions({
  fetchAlertRequest: ['query'],
  fetchAlertSuccess: ['data'],
  fetchAlertFailure: [],
  updateAlertRequest: ['data'],
  setQueryParams: ['query'],
  setVisible: ['visible'],
  setTrackerSelected: ['tracker'],
});

const INITIAL_STATE = {
  alerts: [],
  loading: false,
  query: {},
  params: '',
  visible: false,
  trackerSelected: {},
};

const fetchAlertRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchAlertSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  alerts: actions.data,
  loading: false,
});

const fetchAlertFailure = (state = INITIAL_STATE) => {
  return {
    ...state,
    loading: false,
    alerts: [],
  };
};

const updateAlertRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  trackerSelected: {},
  visible: false,
});

const setQueryParams = (state = INITIAL_STATE, { query }) => {
  const params = { checked: query.checked };
  if (query.initialDate !== '')
    params.initialDate = moment
      .utc(query.initialDate)
      .format('YYYY-MM-DD HH:mm');
  if (query.finalDate !== '')
    params.finalDate = moment.utc(query.finalDate).format('YYYY-MM-DD HH:mm');
  const parmsMounted = mountParams(params);
  return { ...state, params: parmsMounted, query };
};

const setVisible = (state = INITIAL_STATE, actions) => ({
  ...state,
  visible: actions.visible,
});
const setTrackerSelected = (state = INITIAL_STATE, actions) => ({
  ...state,
  trackerSelected: actions.tracker,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_ALERT_REQUEST]: fetchAlertRequest,
  [Types.FETCH_ALERT_SUCCESS]: fetchAlertSuccess,
  [Types.FETCH_ALERT_FAILURE]: fetchAlertFailure,
  [Types.UPDATE_ALERT_REQUEST]: updateAlertRequest,
  [Types.SET_QUERY_PARAMS]: setQueryParams,
  [Types.SET_VISIBLE]: setVisible,
  [Types.SET_TRACKER_SELECTED]: setTrackerSelected,
});
