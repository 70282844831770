import React, { useEffect, useState } from 'react';
import { MdCheck, MdClear } from 'react-icons/md';
import Scroll from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { useFormik } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';

import api from '~/services/api';

import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import { Creators as BufferActions } from '~/store/ducks/bufferSauce';
import Loading from '~/components/Loading';

import {
  Container,
  ContainerButtons,
  Inputstyle,
  ContainerInput,
  ContainerCars,
  CheckContainer,
} from './styles';

import { ButtonContainer } from '~/components/Global';

function Group({ groupSelected }) {
  const bufferImutable = useSelector(
    (state) => state.bufferState.bufferImutable
  );
  const dispatch = useDispatch();
  const [loading] = useState(false);
  const [height] = useState(0);
  const formik = useFormik({
    initialValues: {
      name: '',
      cars: [],
    },
    validate: (values) => {
      const err = {};
      const message = 'Campo obrigatório';
      if (!values.name) err.name = message;
      return err;
    },
    onSubmit: async (values) => {
      const carsSelect = await values.cars
        .filter((item) => item.checked)
        .map((item) => item.tracker_id);
      const data = {
        name: values.name,
        id_trackers: carsSelect,
      };
      if (!values.id) {
        const response = await api.setGroup(data);
        if (response.status === 200) {
          dispatch(BufferActions.clearGroupBuffer());
          dispatch(BufferActions.bufferRequest());
          dispatch(BufferActions.setViewGroup());

          toast.success('Grupo cadastrado com sucesso!');
        }
      } else {
        const response = await api.updateGroups(values.id, data);
        if (response.status === 200) {
          dispatch(BufferActions.filterGroupBuffer(response.data.id_trackers));
          toast.success('Grupo atualizado com sucesso!');
          dispatch(BufferActions.setViewGroup());
        }
      }
      dispatch(BufferActions.groupRequest());
    },
    enableReinitialize: true,
  });

  function setCars() {
    const cars = bufferImutable.map((item) => {
      return { ...item, checked: false };
    });
    formik.setValues({ name: '', cars });
  }
  async function getGroupSelected(data) {
    if (!_.isEmpty(data)) {
      await setCars();
      const cars = await bufferImutable.map((item) => {
        if (data.id_trackers.includes(item.tracker_id)) {
          return { ...item, checked: true };
        }
        return { ...item, checked: false };
      });
      formik.setValues({ name: data.label, cars, id: data.value });
    } else {
      setCars();
    }
  }

  useEffect(() => {
    getGroupSelected(groupSelected);
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container onSubmit={formik.handleSubmit}>
            <ContainerInput>
              <Inputstyle
                name="name"
                placeholder="Nome do grupo"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && formik.errors.name}
              />
            </ContainerInput>

            <ContainerCars height={`${height}px`}>
              <Scroll>
                {formik.values.cars.map((car, index) => {
                  return (
                    <CheckContainer key={car.id}>
                      <div className="pretty p-default p-curve p-smooth">
                        <input
                          type="checkbox"
                          checked={car.checked}
                          onChange={(event) =>
                            formik.setFieldValue(
                              `cars[${index}].checked`,
                              event.target.checked
                            )
                          }
                          value={formik.values.cars[index].checked}
                          id={car.id}
                        />
                        <div className="state p-success">
                          <label htmlFor={car.id}>{car.label}</label>
                        </div>
                      </div>
                    </CheckContainer>
                  );
                })}
              </Scroll>
            </ContainerCars>
            <ContainerButtons>
              <ButtonContainer
                color="#E33939"
                type="button"
                onClick={() => dispatch(BufferActions.setViewGroup())}
              >
                <MdClear size={15} />
                Sair
              </ButtonContainer>
              <ButtonContainer color="#555" type="submit">
                <MdCheck size={15} />
                Salvar
              </ButtonContainer>
            </ContainerButtons>
          </Container>
        </>
      )}
    </>
  );
}

Group.propTypes = {
  groupSelected: PropTypes.string.isRequired,
};

export default Group;
