import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'lodash';

import history from '~/services/history';
import { iconMarker } from '~/components/Map/marker';
import { initControls } from '../../Map/controlsInit';
import mapStyle from '~/assets/map.json';
import markerAlert from '~/assets/markerAlert.svg';
import markerRun from '~/assets/markerRun.svg';
import markerStop from '~/assets/markerStop.svg';

import markerBlue from '~/assets/markerBlue.svg';
import markerYellow from '~/assets/markerYellow.svg';
import markerOrange from '~/assets/markerOrange.svg';

import { Creators as BufferActions } from '~/store/ducks/bufferSauce';
import geoJson from '../../Map/geojson';
import { saveZone, cancelZone } from '../../Map/controls';
import Modal from '../../Map/Modal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Map({ match }) {
  const dispatch = useDispatch();
  const query = useQuery();
  const buffer = useSelector(({ bufferState }) => bufferState.buffer);
  const id_tracker = query.get('id');
  const [map, setMap] = useState();
  const [marker, setMarker] = useState([]);
  const [data, setData] = useState({});
  const [typeModal, setTypeModal] = useState('polygon');
  const [GeoJson, setGeoJson] = useState('');
  const drawingMode = useSelector((state) => state.bufferState.drawingMode);

  function drawMarker() {
    if (!_.isEmpty(data)) {
      marker.map((item) => item.setMap(null));
      const point = [];
      let Icon = markerStop;
      if (data.ignition) {
        Icon = markerRun;
        const coloredIcons = {
          blue: markerBlue,
          orange: markerOrange,
          yellow: markerYellow,
        };
        const icon =
          data.icon_color && Object.keys(coloredIcons).includes(data.icon_color)
            ? coloredIcons[data.icon_color]
            : markerRun;
        Icon = icon;
      } else if (data.status === 'DANGER') {
        Icon = markerAlert;
      } else if (data.status === 'OFF') {
        Icon = markerStop;
      }
      let alertPlate;
      if (data.status === 'DISCONNECTED') {
        alertPlate = 'DISCONNECTED';
      } else if (data.alert_on) {
        alertPlate = 'ALERT';
      }

      const pointer = new window.RichMarker({
        position: new window.google.maps.LatLng(data.lat, data.lng),
        map,
        draggable: false,
        content: iconMarker(Icon, data.angle, alertPlate, data.label),
        flat: true,
        anchor: window.RichMarkerPosition.MIDDLE,
      });
      point.push(pointer);
      setMarker(point);
      map.setCenter(new window.google.maps.LatLng(data.lat, data.lng));
    }
  }

  useEffect(() => {
    const mapInit = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -20.361797, lng: -40.660631 },
      fullscreenControl: false,
      mapTypeControl: true,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: true,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      zoom: 15,
      controlSize: 25,
      styles: mapStyle,
      mapId: '3b0d90149cc80c08',
    });
    initControls(
      mapInit,
      null,
      dispatch,
      setTypeModal,
      setGeoJson,
      query,
      history
    );

    const drawingManager = new window.google.maps.drawing.DrawingManager({
      // drawingControl: editablePermission ? true : false,
      drawingControl: true,

      // circleOptions: {
      //   editable: true,
      // },
      // polygonOptions: {
      //   editable: true,
      // },
      // rectangleOptions: {
      //   editable: true,
      // },
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
        // drawingModes: ['marker', 'polygon', 'rectangle'],
        drawingModes: ['marker'],
      },
    });
    drawingManager.setMap(mapInit);
    window.google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      function (event) {
        drawingManager.setDrawingMode(null);
        setGeoJson(geoJson(event));
        setTypeModal(event.type);
        /*
        if (event.type === 'circle') {
          window.google.maps.event.addListener(
            event.overlay,
            'radius_changed',
            function () {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'polygon') {
          window.google.maps.event.addListener(
            event.overlay.getPath(),
            'set_at',
            function () {
              setGeoJson(geoJson(event));
            }
          );

          window.google.maps.event.addListener(
            event.overlay.getPath(),
            'insert_at',
            function () {
              setGeoJson(geoJson(event));
            }
          );
        } else if (event.type === 'rectangle') {
          window.google.maps.event.addListener(
            event.overlay,
            'bounds_changed',
            function () {
              setGeoJson(geoJson(event));
            }
          );
        }
        */
        // Change Drawing Mode
        dispatch(BufferActions.setDrawingMode());

        // To hide drawing tools control
        drawingManager.setOptions({
          drawingControl: false,
        });
        // Remove measure tools options
        // mapInit.controls[9].clear();

        // Create DIV for save and cancel buttons
        let zoneControlDiv = document.createElement('div');
        const count = zoneControlDiv.getElementsByTagName('div').length;
        if (count === 4) {
          // map.controls[window.google.maps.ControlPosition.RIGHT_TOP].removeAt(1);
          // Destroy the old zoneControlDiv and create a new one if there is more than 2 buttons(save and cancel)
          zoneControlDiv = document.createElement('div');
        }

        saveZone(
          zoneControlDiv,
          mapInit,
          dispatch,
          BufferActions,
          event.overlay,
          drawingManager,
          query
        );
        cancelZone(
          zoneControlDiv,
          mapInit,
          dispatch,
          BufferActions,
          event.overlay,
          drawingManager,
          query
        );
        zoneControlDiv.index = 1;
        mapInit.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
          zoneControlDiv
        );
      }
    );
    if (drawingMode) {
      dispatch(BufferActions.setDrawingMode());
      initControls(
        map,
        null,
        dispatch,
        setTypeModal,
        setGeoJson,
        query.params,
        history
      );
      // setHideSearchBox(false);
      /*
      if (drawingMode) {
        mapInit.controls[9].clear(); // Remove ruller tools
        mapInit.controls[6].clear(); // Remove small map in the left
      }
      */
    }

    setMap(mapInit);
  }, []);

  useEffect(() => {
    if (buffer.length !== 0) {
      setData(
        buffer.find((item) => Number(item.tracker_id) === Number(id_tracker))
      );
    }
  }, [buffer]);

  useEffect(() => {
    // console.log(data);
    drawMarker();
  }, [data]);

  useEffect(() => {
    setData(
      buffer.find((item) => Number(item.tracker_id) === Number(id_tracker))
    );
  }, [id_tracker]);

  return (
    <>
      <div
        className={`p-2 bg-white rounded-lg shadow xl:col-span-2 ${
          history.location.pathname === '/monitoringFullscreen' &&
          'row-span-3 xl:row-span-2'
        }`}
      >
        <Modal type={typeModal} geoJson={GeoJson} match={match} />
        <div className="h-full w-full rounded-lg" id="map" />
      </div>
    </>
  );
}

Map.propTypes = {
  match: PropTypes.string.isRequired,
};

export default Map;
