import React, { useState } from 'react';

import pt from 'date-fns/locale/pt';
import PropTypes from 'prop-types';

import { DateField, MaskedField, SelectField } from '~/components/fields';
import { MaskedInput } from '~/components/inputs';
import translateWeek from '~/util/translateDayWeek';

import weekDays from '../weekDays.json';

function Command({ keyUpdate, formik }) {
  const [commandOptions] = useState([
    { label: 'Bloqueio', value: 'lock' },
    { label: 'Desbloqueio', value: 'unlock' },
    { label: 'Bloqueio/desbloqueio', value: 'all' },
  ]);
  return (
    <>
      <SelectField
        key={keyUpdate}
        label="Tipo de comando"
        placeholder="Selecione um comando"
        noOptionsMessage="Nenhum comando encontrado"
        options={commandOptions}
        isDisabled={formik.values.id}
        defaultValue={
          formik.values.id &&
          commandOptions.find(({ value }) => value === formik.values.type)
        }
        onChange={({ value }) => {
          formik.setFieldValue('type', value);
        }}
        error={formik.touched.type && formik.errors.type}
        errorMessage={formik.errors.type}
      />
      {formik.values.schedule.type === 'once' && (
        <>
          {formik.values.type === 'all' ? (
            <>
              <DateField
                borderRadius="10px"
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm:ss"
                label="Dia do bloqueio"
                selected={formik.values.schedule.dateLock}
                onChange={(value) =>
                  formik.setFieldValue('schedule.dateLock', value)
                }
                locale={pt}
                customInput={<MaskedInput mask="99/99/9999 99:99" />}
                error={formik.errors.dateLock}
                errorMessage={formik.errors.dateLock}
              />
              <DateField
                borderRadius="10px"
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm:ss"
                label="Dia do desbloqueio"
                selected={formik.values.schedule.dateUnlock}
                onChange={(value) =>
                  formik.setFieldValue('schedule.dateUnlock', value)
                }
                locale={pt}
                customInput={<MaskedInput mask="99/99/9999 99:99" />}
                error={formik.errors.dateUnlock || formik.errors.datePast}
                errorMessage={
                  formik.errors.dateUnlock || formik.errors.datePast
                }
              />
            </>
          ) : (
            <DateField
              borderRadius="10px"
              showTimeSelect
              dateFormat="dd/MM/yyyy HH:mm:ss"
              label="Dia do comando"
              selected={formik.values.schedule.date}
              onChange={(value) => formik.setFieldValue('schedule.date', value)}
              locale={pt}
              customInput={<MaskedInput mask="99/99/9999 99:99" />}
              error={formik.errors.date}
              errorMessage={formik.errors.date}
            />
          )}
        </>
      )}
      {formik.values.schedule.type === 'weekly' && (
        <>
          {formik.values.type === 'all' ? (
            <>
              <SelectField
                key={keyUpdate}
                label="Dia do bloqueio"
                placeholder="Selecione um dia para o bloqueio"
                noOptionsMessage="Nenhum dia da semana encontrado"
                options={weekDays}
                getOptionValue={({ week }) => week}
                getOptionLabel={({ week }) => translateWeek(week)}
                onChange={({ week }) => {
                  formik.setFieldValue('schedule.weekLock', week);
                }}
              />
              <MaskedField
                label="Hora do bloqueio:"
                mask="99:99"
                value={formik.values.schedule.timeLock}
                onChange={(e) =>
                  formik.setFieldValue(`schedule.timeLock`, e.target.value)
                }
              />
              <SelectField
                key={keyUpdate}
                label="Dia do desbloqueio"
                placeholder="Selecione um dia para o desbloqueio"
                noOptionsMessage="Nenhum dia da semana encontrado"
                options={weekDays}
                getOptionValue={({ week }) => week}
                getOptionLabel={({ week }) => translateWeek(week)}
                onChange={({ week }) => {
                  formik.setFieldValue('schedule.weekUnlock', week);
                }}
              />
              <MaskedField
                label="Hora do desbloqueio:"
                mask="99:99"
                value={formik.values.schedule.timeUnlock}
                onChange={(e) =>
                  formik.setFieldValue(`schedule.timeUnlock`, e.target.value)
                }
              />
            </>
          ) : (
            <div className="grid grid-cols-2 w-full items-center">
              <div className="h-full grid grid-rows-7 gap-2">
                {weekDays.map(({ name, week }) => (
                  <label htmlFor={name}>
                    <input
                      onChange={({ target: { value: valueType } }) => {
                        formik.setFieldValue(
                          'schedule.week',
                          Number(valueType)
                        );
                      }}
                      id={name}
                      value={week}
                      checked={formik.values.schedule.week === week}
                      name="weekOptions"
                      type="radio"
                    />
                    <span className="mx-2">{translateWeek(week)}</span>
                  </label>
                ))}
              </div>
              <MaskedField
                label="Hora do comando:"
                mask="99:99"
                value={formik.values.schedule.time}
                onChange={(e) =>
                  formik.setFieldValue(`schedule.time`, e.target.value)
                }
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

Command.propTypes = {
  formik: PropTypes.objectOf(
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
    PropTypes.number
  ).isRequired,
  keyUpdate: PropTypes.number.isRequired,
};

export default Command;
