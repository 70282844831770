import styled from 'styled-components';

export const Container = styled.form`
  padding: 5px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.07);
  display: grid;
  grid-template-rows: 35px 1fr 50px;
  > header {
    font-size: 20px;
    font-weight: 600;
    color: #245378;
    background: #f4f8f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0 0;
  }
  > div {
    margin: 10px 0;
    > div {
      padding: 5px 0;
    }
  }
  > footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
`;
