import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as DailyActions } from '~/store/ducks/dailySauce';

import { StatisticsContainer, ItemStatistics, NotStatistics } from './styles';

export default function Statistics() {
  const dispatch = useDispatch();
  const {
    max,
    distance,
    avg,
    liters,
    fuelprice,
    total,
    lat,
    lng,
    date,
  } = useSelector((state) => state.dailyState.statistics);
  return (
    <StatisticsContainer>
      <div>Estatísticas</div>
      {max ||
      distance ||
      avg ||
      liters ||
      fuelprice ||
      total ||
      lat ||
      lng ||
      date ? (
        <>
          <ItemStatistics
            onClick={() =>
              dispatch(
                DailyActions.setCoordinateInfo({
                  lat,
                  lng,
                  date,
                  ignition: true,
                })
              )
            }
            style={{ cursor: 'pointer' }}
          >
            <div>
              {max ? max.toFixed(0) : '-'}
              <span>Km/h</span>
            </div>
            <span>Velocidade Máxima</span>
          </ItemStatistics>
          <ItemStatistics>
            <div>
              {avg ? parseFloat(avg).toFixed(0) : '-'}
              <span>Km/h</span>
            </div>
            <span>Velocidade Média</span>
          </ItemStatistics>
          <ItemStatistics>
            <div>
              {' '}
              {distance ? (distance / 1000).toFixed(0) : '-'}
              <span>Km</span>
            </div>
            <span>Distância (aprox.)</span>
          </ItemStatistics>
          <ItemStatistics>
            <div>
              {liters ? liters.toFixed(2) : '-'}
              <span>L</span>
            </div>
            <span>Litros (aprox.)</span>
          </ItemStatistics>
          <ItemStatistics>
            <div>
              <span>R$</span>
              {fuelprice ? fuelprice.toFixed(2) : '-'}
            </div>
            <span>Preço por litro</span>
          </ItemStatistics>
          <ItemStatistics>
            <div>
              <span>R$</span>
              {total ? total.toFixed(2) : '-'}
            </div>
            <span>Total gasto (aprox.)</span>
          </ItemStatistics>
        </>
      ) : (
        <NotStatistics>Nenhuma estatística pra exibir</NotStatistics>
      )}
    </StatisticsContainer>
  );
}
