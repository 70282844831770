import React from 'react';
import { DndProvider } from 'react-dnd';
import Html5Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import store from './store';
import GlobalTheme from './styles/global';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '~/styles/tailwind.css';
import 'rc-tooltip/assets/bootstrap.css';

export default function App() {
  return (
    <Provider store={store}>
      <DndProvider backend={Html5Backend}>
        <ToastContainer style={{ zIndex: '999999' }} />
        <GlobalTheme />
        <Routes />
      </DndProvider>
    </Provider>
  );
}
