import React from 'react';
import { MdCheck, MdClear, MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { SimpleButton, IconButton } from '~/components/buttons';
import api from '~/services/api';
import history from '~/services/history';
import { Creators as MaintenanceActions } from '~/store/ducks/maintenanceSauce';

function Header({ editablePermission, idParam }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.maintenanceState.loading);
  function cancelButton() {
    dispatch(MaintenanceActions.clearMaintenance());

    history.push('/maintenance');
  }

  async function deleteButton() {
    try {
      await api.deleteMaintenance(idParam);
      dispatch(MaintenanceActions.clearMaintenance());

      history.push('/maintenance');
    } catch (error) {
      console.log('error');
    }
  }
  if (editablePermission) {
    if (loading) {
      return (
        <SimpleButton width="w-44" height="h-8" disabled type="button">
          Aguarde
        </SimpleButton>
      );
    }
    return (
      <div
        className={`grid ${
          idParam ? 'grid-cols-3' : 'w-64 grid-cols-2'
        } gap-2 h-8`}
      >
        <IconButton
          background="bg-red-600"
          margin="0px"
          Icon={MdClear}
          size={15}
          type="button"
          onClick={() => cancelButton()}
        >
          Cancelar
        </IconButton>
        {idParam && (
          <IconButton
            margin="0px"
            background="bg-red-600"
            Icon={MdDelete}
            size={15}
            onClick={deleteButton}
          >
            Excluir
          </IconButton>
        )}
        <IconButton
          background="bg-gray-600"
          margin="0px"
          Icon={MdCheck}
          size={15}
          type="submit"
        >
          Salvar
        </IconButton>
      </div>
    );
  }
  return (
    <IconButton
      Icon={MdCheck}
      size={15}
      type="button"
      onClick={() => cancelButton()}
      width="w-44"
      height="h-8"
    >
      Voltar
    </IconButton>
  );
}

Header.propTypes = {
  editablePermission: PropTypes.bool,
  idParam: PropTypes.bool,
};

Header.defaultProps = {
  editablePermission: false,
  idParam: '',
};

export default Header;
