import { toast } from 'react-toastify';

export default function setArrayFence({ formik, fenceSelected, weekDays }) {
  if (
    formik.values.fences.length === 0 ||
    (formik.values.fences.length !== 0 &&
      formik.values.fences.map((item) => item.id).indexOf(fenceSelected.id))
  ) {
    formik.setFieldValue('fences', [
      ...formik.values.fences,
      {
        ...fenceSelected,
        typeFence: true,
        configurationFence: 'always',
        week: weekDays,
        once: {},
      },
    ]);
  } else {
    toast.error('Cerca já adicionada');
  }
}
