import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  fetchChartSpeedRequest: ['id'],
  fetchChartSuccess: ['data'],
  fetchChartFailure: [],
});

const INITIAL_STATE = {
  speeds: [],
  loading: false,
};

const fetchChartSpeedRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
});

const fetchChartSuccess = (state = INITIAL_STATE, actions) => ({
  ...state,
  speeds: actions.data,
  loading: false,
});

const fetchChartFailure = (state = INITIAL_STATE) => ({
  ...state,
  speed: [],
  loading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.FETCH_CHART_SPEED_REQUEST]: fetchChartSpeedRequest,
  [Types.FETCH_CHART_SUCCESS]: fetchChartSuccess,
  [Types.FETCH_CHART_FAILURE]: fetchChartFailure,
});
