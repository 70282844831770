import React, { useState, useEffect } from 'react';
import { momentLocalizer } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import PropTypes from 'prop-types';

import { Creators as WorksheetActions } from '~/store/ducks/worksheetSauce';
import { mountParams } from '~/util/Mountparams';

import Modal from './modal';
import {
  FormContainer,
  FormData,
  DataContainer,
  ContainerStyled,
  CalendarStyled,
} from './styles';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';

const localizer = momentLocalizer(moment);
function Forms({
  match: {
    params: { id: idParam },
  },
}) {
  const dispatch = useDispatch();
  const worksheet = useSelector((state) => state.worksheetState.worksheet);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (idParam) {
      const body = {};
      body.driver_id = idParam;
      const query = mountParams(body);
      dispatch(WorksheetActions.fetchWorksheetRequest(query, null));
    }
  }, []);

  useEffect(() => {
    const newEvents = [];
    if (worksheet.length > 0) {
      worksheet.map((item) =>
        newEvents.push({
          id: item.id,
          title: `${moment(item.date).format('HH:mm:SS')} - ${item.event}`,
          start: moment(item.date),
          end: moment(item.date),
          desc: item.observation,
        })
      );
      setEvents(newEvents);
    }
  }, [worksheet]);

  function ShowHideModal() {
    dispatch(WorksheetActions.setViewModal());
  }

  function showMoreButton() {
    ShowHideModal();
  }

  return (
    <>
      <ContainerStyled>
        <FormContainer>
          <form>
            <FormData>
              <DataContainer>
                <CalendarStyled
                  popup={false}
                  onShowMore={() => showMoreButton()}
                  views={['month']}
                  localizer={localizer}
                  events={events}
                  startAccessor="start"
                  endAccessor="end"
                  popupOffset={{ x: 30, y: -20 }}
                  messages={{
                    allDay: 'Dia todo',
                    month: 'Mês',
                    day: 'Dia',
                    today: 'Hoje',
                    previous: 'Voltar',
                    next: 'Avançar',
                    week: 'Semana',
                    date: 'Data',
                    event: 'Evento',
                    showMore: (total) => `+ Mostrar mais (${total})`,
                  }}
                />
              </DataContainer>
            </FormData>
          </form>
        </FormContainer>
      </ContainerStyled>
      <Modal events={events} />
    </>
  );
}

Forms.propTypes = {
  match: PropTypes.objectOf(PropTypes.object),
};
Forms.defaultProps = {
  match: { params: {} },
};

export default Forms;
