import { toast } from 'react-toastify';
import { call, put, all, takeEvery } from 'redux-saga/effects';

import api from '~/services/api';

import { Types } from '../ducks/commandSauce';

export function* getCommand({ id }) {
  try {
    const { data: tracker } = yield call(api.fetchTracker, `/${id}`);
    const { data: buffer } = yield call(api.fetchBuffer, `?tracker_id=${id}`);

    const { data: commands } = yield call(
      api.fetchCommand,
      `?tracker_id=${id}`
    );
    const { data: commandList } = yield call(
      api.fetchCommandList,
      `?tracker_id=${id}`
    );
    yield put({
      type: Types.FETCH_COMMAND_SUCCESS,
      tracker,
      commands,
      buffer: buffer[0],
      commandList: commandList[0],
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_COMMAND_FAILURE });
  }
}
export function* commandBufferSaga({ id }) {
  try {
    const { data: buffer } = yield call(api.fetchBuffer, `?tracker_id=${id}`);

    yield put({
      type: Types.FETCH_COMMAND_BUFFER_SUCCESS,
      buffer: buffer[0],
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_COMMAND_BUFFER_FAILURE });
  }
}

export function* createCommand({ body }) {
  try {
    yield call(api.createCommand, body);
    toast.success('Comando enviado com sucesso!');

    yield put({
      type: Types.FETCH_CREATE_SUCCESS,
    });
  } catch (error) {
    toast.error('Ocorreu um erro');
    yield put({ type: Types.FETCH_CREATE_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(Types.FETCH_COMMAND_REQUEST, getCommand)]);
  yield all([takeEvery(Types.FETCH_COMMAND_BUFFER_REQUEST, commandBufferSaga)]);
  yield all([takeEvery(Types.CREATE_COMMAND, createCommand)]);
}
