import React from 'react';

import PropTypes from 'prop-types';

import { MaskedField, CheckField } from '~/components/fields';
import translateWeek from '~/util/translateDayWeek';

import { Container } from './styles';

function MovimentationConfig({ formik, editablePermission }) {
  return (
    <>
      <header className="text-lg">
        HORÁRIO PERMITIDO PARA MOVIMENTAÇÃO DO VEÍCULO
      </header>
      {formik.values.configuration.movimentation &&
        formik.values.configuration.movimentation.map((week, index) => (
          <Container>
            <CheckField
              id={week.week}
              disabled={!editablePermission}
              type="checkbox"
              checked={formik.values.configuration.movimentation[index].check}
              onChange={(e) =>
                formik.setFieldValue(
                  `configuration.movimentation[${index}].check`,
                  e.target.checked
                )
              }
              value={formik.values.configuration.movimentation[index].check}
              label={translateWeek(week.week)}
            />

            <MaskedField
              disabled={
                !editablePermission ||
                !formik.values.configuration.movimentation[index].check
              }
              label="Inicío: "
              mask="99:99"
              value={formik.values.configuration.movimentation[index].start}
              onChange={(e) =>
                formik.setFieldValue(
                  `configuration.movimentation[${index}].start`,
                  e.target.value
                )
              }
            />
            <MaskedField
              disabled={
                !editablePermission ||
                !formik.values.configuration.movimentation[index].check
              }
              label="Fim: "
              mask="99:99"
              value={formik.values.configuration.movimentation[index].end}
              onChange={(e) =>
                formik.setFieldValue(
                  `configuration.movimentation[${index}].end`,
                  e.target.value
                )
              }
            />
          </Container>
        ))}
    </>
  );
}

MovimentationConfig.propTypes = {
  formik: PropTypes.objectOf.isRequired,
  editablePermission: PropTypes.bool,
};

MovimentationConfig.defaultProps = {
  editablePermission: false,
};

export default MovimentationConfig;
