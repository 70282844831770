import axios from './axios';

const verification = async (method, url, data) => {
  try {
    const response = await axios({ method, url, data });
    return response;
  } catch (error) {
    return error.response;
  }
};

export default verification;
