import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { ProfileContainer } from '~/components/Global';
import Table from '~/components/TableSimple';

import ExceptionConfig from './ExceptionConfig';
import { TableContainer } from './styles';
import TrackerConfig from './TrackerConfig';

function ConfigurationData({ formik, editablePermission }) {
  return (
    <ProfileContainer>
      <div>
        <TrackerConfig
          editablePermission={editablePermission}
          formik={formik}
        />

        <ExceptionConfig
          editablePermission={editablePermission}
          formik={formik}
        />

        <TableContainer>
          <Table
            data={formik.values.exceptions.map((item) => ({
              start: moment(item.start).format('DD/MM/YYYY HH:mm'),
              end: moment(item.end).format('DD/MM/YYYY HH:mm'),
            }))}
            labelsNames={['start', 'end']}
            name="Exceção"
            header={['Inicio', 'Fim']}
          />
        </TableContainer>
      </div>
    </ProfileContainer>
  );
}

ConfigurationData.propTypes = {
  formik: PropTypes.objectOf.isRequired,
  editablePermission: PropTypes.bool,
};

ConfigurationData.defaultProps = {
  editablePermission: false,
};

export default ConfigurationData;
